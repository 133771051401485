/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:45:14
 * @LastEditTime: 2022-05-09 11:42:54
 * @LastEditors: zjc
 */
import "babel-polyfill"
import Vue from 'vue'
import app from './app.vue'
import VueRouter from 'vue-router'
import router from '@/router'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"
import '@/styles/global.scss'
import cloneDeep from 'lodash/cloneDeep'
import Fragment from 'vue-fragment'
import store from "@/store"; //引入store
import '@/permissions';
import '@/utils/globalComponent';
import * as format from '@/filters/index.js'
import '../icons/index' // icon
import validatenull from '@/utils/common'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/chart/pie';
import { isAuth, moreAuth } from '@/permissions/buttonPermission'
import uploader from 'vue-simple-uploader'
import fetch from '@/utils/request'
Vue.use(uploader);
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Fragment.Plugin)
Vue.use(ElementUI)
// import Mock from '../mock/mock' //mock数据，后面会删掉
import axios from 'axios'
Vue.prototype.$http = axios
Vue.prototype.$fetch = fetch
Vue.prototype.$echarts = echarts //全局echarts
Vue.prototype.$isAuth = isAuth //按钮权限
Vue.prototype.$moreAuth = moreAuth //更多按钮权限
Vue.prototype.$validatenull = validatenull.validatenull //全局注册判空
// 加入elementUI 全局提示
Vue.prototype.$ok = (message, title = '消息') => {
  ElementUI.Notification({
    type: 'success',
    title: title,
    message: message,
    duration: 2000,
  })
}
Vue.prototype.$fail = (message, title = "错误") => {
  ElementUI.Notification({
    type: 'error',
    title: title,
    message: message,
    duration: 2000,
  })
}
// 保存vuex初始状态
window.SITE_CONFIG = {}
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)
Object.keys(format).forEach(item => Vue.filter(item, format[item]))
new Vue({
  store,
  router,
  render: h => h(app),
}).$mount('#app')
