<!--  新增需求 -->
<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;">
        <v-tabs :activeName="activeName" style="width:100%" :tabList="tabList" @tabValue="tabValue">
        </v-tabs>
        <div v-show="activeName == '-1'">
          <div>
            <div class="tab-one">
              <p class="tab-title">平台信息</p>
              <div class="tab-content2">
                <div class="box_tab_one">
                  <div class="content">
                    <div class="item">
                      <span class="l1">ID：</span>
                      <span class="r1">{{ruleForm.userId}}</span>
                    </div>
                    <div class="item">
                      <span class="l1">手机号：</span>
                      <span class="r1">{{ruleForm.mobile}}</span>
                    </div>
                    <div class="item">
                      <span class="l1">昵称：</span>
                      <span class="r1">{{ruleForm.nickName}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-one" style="margin:30px 0 30px 0">
            <div class="tab-title">
              <div class="left_title">授权记录</div>
            </div>
            <v-table style="width:100%;margin:0 auto 20px;background-color:#ffffff" class="itemTble"
              ref="table" :tableData="tableList" :tableOption="tableOption"
              @handleSelectionChange="selectionChange">
              <template slot="book" slot-scope="scope">
                <div
                  style="width: 100%;text-align: left;display: flex;justify-content: center;align-items: center;">
                  <img :src="scope.row.orgLogo ? scope.row.orgLogo : defaultBook" alt=""
                    style="width: 56px;height: 80px;">
                  <div style="flex:1;
                        vertical-align: top;
                        margin-left: 10px;
                        width: 100px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;">
                    {{scope.row.orgName}}<br>
                  </div>
                </div>
              </template>
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="searchMsg(scope.row)">捐赠信息</el-button>
              </template>
            </v-table>
          </div>
          <div class="tab-one" style="margin:30px 0 30px 0">
            <div class="tab-title">
              <div class="left_title">已获勋章</div>
            </div>
            <v-table style="width:100%;margin:0 auto 20px;background-color:#ffffff" class="itemTble"
              ref="table" :tableData="tableList3" :tableOption="tableOption3">
              <template slot="medalName" slot-scope="scope">
                <span>{{scope.row.medal.medalName}}</span>
              </template>
              <template slot="orgName" slot-scope="scope">
                <span v-if="scope.row.orgId == 0">通用机构</span>
                <span v-else>{{scope.row.orgName}}</span>
              </template>
              <template slot="lightDesc" slot-scope="scope">
                <span>{{scope.row.medal.lightDesc}}</span>
              </template>

              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="searchMsg(scope.row)">捐赠信息</el-button>
              </template>
            </v-table>
          </div>
        </div>
        <!-- 订单记录 -->
        <div v-show="activeName == '0'" style="width:100%">
          <div class="searchForm">
            <!-- 搜索条件 -->
            <el-form :model="filters" label-width="80px" size="small" class="formData">
              <el-collapse-transition>
                <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
                  @reset="reset" ref="filters">
                  <template #searchType>
                    <el-form-item label="" label-width="0">
                      <div class="box_choose">
                        <el-select class="typeSelect" v-model="filters.searchType" clearable
                          placeholder="请选择">
                          <el-option v-for="item in searchTypeList" :key="item.value"
                            :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                        <div v-if="filters.searchType == 2 || filters.searchType == 3">
                          <el-select v-if="filters.searchType == 2" v-model="chooseResult1"
                            clearable placeholder="请选择">
                            <el-option v-for="item in objectList" :key="item.orgId"
                              :label="item.orgName" :value="item.orgId">
                            </el-option>
                          </el-select>
                          <el-select v-if="filters.searchType == 3" v-model="chooseResult2"
                            clearable placeholder="请选择">
                            <el-option v-for="item in channelList" :key="item.orgId"
                              :label="item.orgName" :value="item.orgId">
                            </el-option>
                          </el-select>
                        </div>
                        <!-- 手机号 -->
                        <!-- <el-input v-else-if="filters.searchType == 4" placeholder="请输入"
                          v-model="inputResult2" class="input-with-select">
                        </el-input> -->
                        <!-- 订单号 -->
                        <el-input v-else placeholder="请输入" v-model="inputResult"
                          class="input-with-select">
                        </el-input>
                      </div>
                    </el-form-item>
                    <!-- <el-form-item label="" label-width="0">
                      <el-input placeholder="请输入内容" v-model="filters.content"
                        class="input-with-select">
                        <el-select style="width:130px" v-model="filters.searchType" slot="prepend"
                          placeholder="请选择">
                          <el-option style="width:130px" v-for="item in searchTypeList"
                            :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-input>
                    </el-form-item> -->
                  </template>
                  <template #orderStatus>
                    <el-form-item label="状态" label-width="100px">
                      <el-select v-model="filters.orderStatus" clearable placeholder="请选择">
                        <el-option v-for="item in authOptions" :key="item.value" :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </form-item>
              </el-collapse-transition>
            </el-form>
            <form-menu :tableOption="tableOption2" @refresh="reset">
            </form-menu>
          </div>
          <v-table ref="table" :tableData="tableList2" :tableOption="tableOption2"
            @sortChange="sortChange">
            <template slot="orgName" slot-scope="scope">
              <span v-if="scope.row.orgId == '0'">通用机构</span>
              <span v-else>{{scope.row.orgName}}</span>
            </template>
            <template slot="orderStatus" slot-scope="scope">
              <span v-if="scope.row.orderStatus == 1">待支付</span>
              <span v-else-if="scope.row.orderStatus == 2">处理中</span>
              <span v-else-if="scope.row.orderStatus == 3">已完成</span>
              <span v-else-if="scope.row.orderStatus == 4">捐赠失败</span>
              <span v-else-if="scope.row.orderStatus == 5">已取消</span>
            </template>
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="jumpToSearch(scope.row)">查看</el-button>
              <!-- <el-button v-show="scope.row.orderStatus !== 5" type="text" size="small"
              @click="cancel(scope.row)">取消</el-button> -->
            </template>
          </v-table>
          <!-- 分页 -->
          <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
            @hanlePage="handleSizeChange"></pagination>
        </div>
      </div>
      <!-- 捐赠信息 -->
      <el-dialog @closed="closed" title="捐赠信息" :visible.sync="dialogVisible" width="50%"
        :close-on-click-modal="false" append-to-body>
        <div style="height:300px;overflow-y:scroll">
          <div class="box_descContent" v-for="(item,index) in msgList" :key="item.inscriptionId"
            :label="item.inscriptionName" :value="item.inscriptionId">
            <div class="descTitle">信息{{index +1 }}</div>
            <div class="descContent">
              <div class="el-descriptions-item">
                <div class="label">落款名称：</div>
                <div class="desc">{{item.inscriptionName}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </basic-container>
</template>
<script>
import vTabs from '@/components/CRUD/v-tabs'
import { ROLE_API } from "@/service/permissions/user";
import { Order_API } from "@/service/permissions/order";
// import { ROLE_API} from "@/service/permissions/channel";
import { DONATION_API } from "@/service/permissions/donation";
export default {
  components: { vTabs },
  data () {
    return {
      filters: {
        size: 10,
        current: 1,
      },
      chooseResult1: '',
      chooseResult2: '',
      inputResult: '',
      inputResult2: '',
      searchTypeList: [
        { 'label': '订单号', value: 1 },
        { 'label': '捐赠对象', value: 2 },
        { 'label': '捐赠机构', value: 3 },
        // { 'label': '捐赠人手机号', value: 4 },
      ],
      authOptions: [
        { label: '待支付', value: 1 },
        { label: '处理中', value: 2 },
        { label: '已完成', value: 3 },
        // { label: '捐赠失败', value: 4 },
        { label: '已取消', value: 5 },
      ],
      options: [
        { label: "待支付", value: 1 },
        { label: "处理中", value: 2 },
        { label: "已完成", value: 3 },
        { label: "已取消", value: 4 },
      ],
      userId: '',
      activeName: '-1',
      ruleForm: {},
      tabList: [
        { label: '基础信息', name: '-1' },
        { label: '订单记录', name: '0' },
      ],
      optionsType: [
        { label: "公共图书馆", value: 1 },
        { label: "学校图书馆", value: 2 },
        { label: "物业图书馆", value: 3 },
        { label: "政协图书馆", value: 4 },
        { label: "出版发行图书馆", value: 5 },
        { label: "党建图书馆", value: 6 },
      ],
      dialogVisible: false,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      tableList: [],
      tableList2: [],
      tableList3: [],
      msgList: [],//捐赠信息
      //表格配置
      tableOption: {
        // selection: true,
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
        column: [
          { prop: 'book', label: '捐赠机构', slot: true, tableProp: true, },
          { prop: 'nickName', label: '昵称', tableProp: true },
          { prop: 'createTime', label: '首次授权时间', tableProp: true },
          { prop: 'donateTimes', label: '捐赠次数', tableProp: true },
        ],
      },
      tableOption2: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'orderId', label: '订单号', tableProp: true, },
          // { prop: 'doneeName', label: '捐赠对象', tableProp: true },
          { prop: 'orgName', label: '捐赠机构', slot: true, tableProp: true },
          // { prop: 'mobile', label: '捐赠人手机号', tableProp: true, },
          { prop: 'goodsCnt', label: '复本数（册）', tableProp: true },
          { prop: 'totalAmount', label: '原价（元）', tableProp: true, },
          { prop: 'actualAmount', label: '应付金额（元）', width:150, tableProp: true, },
          { prop: 'orderStatus', label: '状态', slot: true, tableProp: true, },
          { prop: 'createTime', label: '下单时间', tableProp: true, },
        ],
      },
      tableOption3: {
        align: "center",
        sort: true,
        column: [
          { prop: 'medalName', label: '勋章名称', slot: true, tableProp: true, },
          { prop: 'orgName', label: '获取机构', slot: true, tableProp: true },
          { prop: 'lightDesc', label: '获取条件', slot: true, tableProp: true },
          { prop: 'lightTime', label: '获取时间', tableProp: true, },
        ],
      },
      libraryList: [],
      fileList: [],
      rules: {
        tenantName: [
          { required: true, message: "请输入租户名称", trigger: "blur" },
          { max: 30, message: "租户名称最多30个字符", trigger: "blur" },
        ],
        libraryType: [
          { required: true, message: "请选择租户类型", trigger: "change" },
        ],
      },
      createTime: null,
      searchOption: [
        {
          prop: 'searchType',
          label: '创建时间',
          col: 8,
          type: 'roleName',
          slot: true,
          placeholder: '请选择',
        },
        {
          prop: "orderStatus",
          label: "应用名称",
          col: 5,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },
      ],
      objectList: [],//捐赠对象列表
      channelList: [],
      itemType: -1,
      // useInfo: [],
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (val) {
        // console.log('1111')
        // this.getData();
      }
    },
  },
  created () {
    this.getObjectList();
    this.getaChannelList();

    if (this.$route.query.userId) {
      console.log(this.$route.query.userId)
      this.userId = this.$route.query.userId
      this.ruleForm = this.$route.query
      localStorage.setItem("userId", this.$route.query.userId)
      localStorage.setItem("ruleFormUserId", this.$route.query.userId)
      localStorage.setItem("ruleFormMobile", this.$route.query.mobile)
      localStorage.setItem("ruleFormNickName", this.$route.query.nickName)
    }
    else {
      if (localStorage.getItem("userId")) {
        this.userId = localStorage.getItem("userId")
        this.ruleForm.userId = localStorage.getItem("ruleFormUserId")
        this.ruleForm.mobile = localStorage.getItem("ruleFormMobile")
        this.ruleForm.nickName = localStorage.getItem("ruleFormNickName")
      } else {
        this.$router.push({
          path: "./userList",
        });
      }
    }
    this.getWord();
  },
  mounted () {
    this.tabValue({ label: '基础信息', name: '-1' },)
  },
  methods: {
    //获取用户勋章
    async getWord () {
      try {
        let params = {}
        params.userId = this.userId
        console.log(params)
        await ROLE_API.getUserWord(params).then(res => {
          console.log(res)
          this.tableList3 = res.data.lights
          // this.tableList3 = [
          //   { orgName: '机构名称', lightTime: '2021-02-01', medal: { medalName: '勋章名称', lightDesc: "点亮说明" } }
          // ]
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
      // 
    },
    // 获取捐赠机构列表
    async getaChannelList () {
      try {
        let params = {
          size: 100,
          current: 1
        }
        await ROLE_API.getChannelList(params).then(res => {
          console.log(res)
          this.channelList = res.data.records;
          this.channelList.unshift({
            orgName: '通用机构',
            orgId: 0
          })
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }

    },
    // 获取捐赠对象列表
    async getObjectList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        let params = {
          size: 100,
          current: 1
        }
        const { data } = await DONATION_API.getObjectList(params);
        // console.log(data)
        this.objectList = data.records;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    // 跳转
    jumpToSearch (row) {
      console.log(row)
      this.$router.push({
        path: "../../order/orderDetail",
        query: { orderId: row.orderId }
      });
    },
    search () {
      this.pageData.current = 1;
      if (this.filters.searchType == 1) {
        this.filters.content = this.inputResult
      } else if (this.filters.searchType == 2) {
        this.filters.content = this.chooseResult1
      } else if (this.filters.searchType == 3) {
        this.filters.content = this.chooseResult2
      }
      // this.pageData.size = 10;
      this.getData();
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.chooseResult1 = ''
      this.chooseResult2 = ''
      this.inputResult = ''
      this.inputResult2 = ''
      this.$nextTick(() => {
        this.getData();
      });
    },
    async getData () {
      const params = {}
      params.orgId = this.orgId
      params.userId = this.userId
      await Order_API.getOrderList({
        ...this.pageData,
        ...this.filters,
        ...params,
      }).then((res) => {
        this.tableList2 = res.data.records
        this.pageTotal = res.data.total;
      });
    },
    searchMsg (row) {
      // donationMsg
      console.log(row)
      this.$router.push({
        path: "./donationMsg",
        query: { orgId: row.orgId, userId: row.userId }
      });
      this.getUserOrg(row.orgId, row.userId)
      this.dialogVisible = true
    },
    // 获取详情 
    async getList () {
      try {
        let params = {}
        params.userId = this.userId
        const { data } = await ROLE_API.getUserDetail(params)
        console.log(data)
        this.tableList = data.records
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 获取用户捐赠信息
    async getUserOrg (id, userId) {
      try {
        let params = {}
        params.orgId = id
        params.userId = userId
        const { data } = await ROLE_API.getInfoByOrgId(params)
        console.log(data)
        this.msgList = data
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    //tab选项卡
    tabValue (val) {
      console.log(val)
      this.activeName = val.name
      if (val.name == '-1') {
        // 基础信息
        this.getList()
      } else if (val.name == '0') {
        // 订单记录
        this.getData();
      }
    },
    // 新增捐赠渠道
    addOrUpdate (row = {}) {
      this.title = "新增捐赠对象";
      this.dialogVisible = true;

    },
    jumptoPage (url) {
      window.location.href = url
    },
    //多选回调
    selectionChange (val) {
      let that = this
      this.selectList = val
      console.log(this.selectList.length)
      if (this.selectList.length == 0) {
        this.disabled = true
      } else {
        this.disabled = false
        if (that.selectList.findIndex(item => item.totalStock === 0) !== -1) {
          console.log('存在库存为0得图书')
          that.disabled2 = true
        }
        else {
          console.log('不存在库存为0得图书')
          that.disabled2 = false
        }
      }
    },
    closeDialog () {
      this.showDetail = false
      this.formData = {}
      this.packageImg = null
      this.packageName = null
      this.packageDetail = null
      this.tableList = []
      this.tableList2 = []
      this.msgList = []
    },
  }
}
</script>
<style scoped lang="scss">
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}
</style>

<style scoped lang="scss">
.box_choose {
  display: flex;
  flex-wrap: nowrap;
  .typeSelect {
    border-right: none;
  }
}
.box_descContent {
  .descTitle {
    font-weight: 500;
    margin: 0 0 20px 0;
    font-weight: 650;
    font-style: normal;
    font-size: 14px;
    color: #555555;
  }
  .descContent {
    display: flex;
    flex-wrap: wrap;
  }
  .el-descriptions-item {
    margin: 0 0 20px 0;
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    .label {
      display: flex;
      font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC",
        sans-serif;
      font-weight: 650;
      font-style: normal;
      color: #999999;
      font-size: 13px;
      text-align: right;
    }
    .desc {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
  }
}
</style>