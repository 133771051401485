<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:52:33
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #status>
              <el-form-item label="参数类型" label-width="90px">
                <el-select clearable v-model="filters.status" placeholder="请选择"
                  @change="changeStatus">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <el-button type="primary" :loading="btnLoading" size="small">缓存
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange">
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" style="color: red">删除</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 新增参数 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="30%" append-to-body @closed="closed('ruleForm')">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="参数名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入参数名"></el-input>
        </el-form-item>
        <el-form-item label="参数键名" prop="name1">
          <el-input v-model="ruleForm.name1" placeholder="请输入参数键名"></el-input>
        </el-form-item>
        <el-form-item label="参数键值" prop="name2">
          <el-input v-model="ruleForm.name2" placeholder="请输入参数键值"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="name3">
          <el-select v-model="ruleForm.name3" placeholder="请选择">
            <el-option label="1" value="shanghai"></el-option>
            <el-option label="2" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="name4">
          <el-radio-group v-model="ruleForm.name4">
            <el-radio :label="1">有效</el-radio>
            <el-radio :label="0">无效</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="字典描述" prop="name5">
          <el-input v-model="ruleForm.name5" type="textarea" :rows="3" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
export default {
  data () {
    return {
      filters: {},
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      title: '新增字典',
      btnLoading: false,
      dialogVisible: false,
      ruleForm: {
        name: null,
        name1: null,
        name2: null,
        name3: null,
        name4: 0,
        name5: null,
      },
      rules: {
        name: [{ required: true, message: "请输入参数名称", trigger: "blur" }],
        name1: [{ required: true, message: "请输入参数键名", trigger: "blur" }],
        name2: [{ required: true, message: "请输入参数键值", trigger: "blur" }],
      },
      type: null,
      list: [{}],
      selectionList: [],
      //搜索
      searchOption: [
        {
          prop: "tenantName",
          label: "参数名称",
          type: "input",
          col: 5,
          placeholder: "请输入",
        },
        {
          prop: "domain",
          label: "参数键名",
          type: "input",
          col: 5,
          placeholder: "请输入",
        },
        {
          prop: "status",
          label: "参数类型",
          type: "select",
          col: 5,
          slot: true,
          placeholder: "请选择",
        },
      ],
      options: [
        { label: "类型1", value: 1 },
        { label: "类型2", value: 2 },
        { label: "类型3", value: 3 },
        { label: "类型4", value: 4 },
        { label: "类型5", value: 5 },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          {
            prop: "tenantName",
            label: "参数名称",
            tableProp: true,
            width: 120,
          },
          { prop: "tenantId", label: "参数键名", tableProp: true },
          { prop: "domain", label: "参数键值", tableProp: true },
          {
            prop: "accountLimit",
            label: "类型",
            tableProp: true,
            width: 150,
          },
          {
            prop: "accountLimit",
            label: "状态",
            tableProp: true,
            width: 150,
          },
          {
            prop: "expirationDate",
            label: "参数描述",
            tableProp: true,
          },
        ],
      },
    };
  },
  methods: {
    getData () { },
    // 勾选的数据
    selectionChange (val) {
      this.selectionList = val;
    },
    addOrUpdate (type, item) {
      this.type = type;
      if (type === 1) {
        this.title = "新增字典";
      } else {
        this.title = "编辑字典";
      }
      this.dialogVisible = true;
    },
    //   重置
    reset () {
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },

    // 搜索
    search () { },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    closed (formName) {
      this.$refs[formName].resetFields();
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //
        } else {
          console.log("error submit!!");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>