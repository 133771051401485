/*
 * @Description: 公共接口
 * @Author: zjc
 * @Date: 2022-05-05 10:21:45
 * @LastEditTime: 2022-05-07 11:56:00
 * @LastEditors: zjc
 */
import request from '@/utils/request'
const getBookList = (params) => request('post', '/reserve/lms/lib-book/page', params)
const uniqueFunc = (arr, id) => {
  const res = new Map();
  return arr.filter((item) => !res.has(item[id]) && res.set(item[id], true));
}
export const COMMON_API = {
  getBookList,
  uniqueFunc,
}

