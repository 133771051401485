<template>
  <basic-container>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="time" slot-scope="scope">
        <span>{{scope.row.statYear}}年{{scope.row.statMonth}}月</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="openPermissions(scope.row)">查看
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
  </basic-container>
</template>
<script>
import { FINATION_API } from "@/service/permissions/finicial";
export default {
  data () {
    return {
      list: [],
      // 表格查询条件配置
      tableOption: {
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
        column: [
          { prop: "time", label: "时间", slot: true, tableProp: true },
          { prop: "totalAmount", label: "订单总额(元)", tableProp: true },
          { prop: "offlinePayAmount", label: "线下支付(元)", tableProp: true },
          { prop: "onlinePayAmount", label: "在线支付(元)", tableProp: true },
        ],
      },
    };
  },
  mounted () {
    this.getList();
  },
  methods: {
    // 获取渠道列表
    async getList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { data } = await FINATION_API.getList({
          ...this.pageData,
          ...this.filters,
        });
        console.log(data.records)
        this.list = data.records;
        this.pageTotal = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getList();
    },
    // 跳转到详情页 查看
    openPermissions (row) {
      console.log(row)
      this.$router.push({
        path: "./detail",
        query: { year: row.statYear, month: row.statMonth }
      });
    },
    cancelDialog () {
      this.showDialog = false;
      this.tableForm = {};
      this.tableForm.themeImg = '';
    },


  },

};
</script>

<style lang="scss">
.ql-editor {
  min-height: 200px;
}

.box_opration {
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 12px;
  }
  .redTit {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .redTit3 {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 90px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
