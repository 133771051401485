<template>
  <basic-container id="fullscreen">
    <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
      size="medium" :rules="tableFormRules">
      <div class="table_one">
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="" prop="title">
          <span class="redTit3">标题：</span>
          <el-input maxlength="20" show-word-limit v-model="tableForm.title" clearable
            placeholder="请输入" style="width: 80%">
          </el-input>
        </el-form-item>
        <div class="goodImgBox">
          <span class="redTit3">图片：</span>
          <div style="display: inline-block; vertical-align: top">
            <img v-if="tableForm.img" :src="tableForm.img" class="avatar" style="
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 20px 0 0;
                " />
            <el-upload class="upload-demo" v-if="tableForm.img" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload"
              style="display: inline-block">
              <el-button size="small" type="primary">更换图片</el-button>
            </el-upload>
            <el-upload v-if="!tableForm.img" class="avatar-uploader"
              style="display: block; text-align: left" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
          <div class="rightText">
            <span class="atext">仅可上传jpg、jpeg、png</span>
          </div>
        </div>
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="" prop="jumpUrl">
          <span class="redTit3">跳转：</span>
          <el-input v-model="tableForm.jumpUrl" clearable placeholder="请输入" style="width: 80%">
          </el-input>
        </el-form-item>
        <div class="table_one">
          <div class="box_opration">
            <div class="left_opration">
              <el-button style="margin-right: 20px" size="small" type="primary" @click="checkBooks">
                选择图书
              </el-button>
              <el-button style="margin-right: 20px" size="small" type="primary"
                @click="checkPackage">
                选择捐赠包
              </el-button>
            </div>
          </div>
        </div>
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="" prop="indexNo">
          <span class="redTit3">排序：</span>
          <el-input maxlength="3" show-word-limit v-model="tableForm.indexNo" clearable
            placeholder="请输入" style="width: 80%">
          </el-input>
          <p class="textMsg">数字越小banner展示越靠前</p>
        </el-form-item>
      </div>
    </el-form>
    <check-book ref="checkBook" @handleSelect="handleSelectBack" type="setPro" :list="tableList" />
    <check-package ref="checkPackage" @handleSelect="handleSelectBack2" type="setPro"
      :list="tableList2" />
    <div style="text-align:right">
      <el-button type="primary" @click="rowSave" :loading="loading">保 存</el-button>
      <el-button @click="beforeClose">取 消</el-button>
    </div>
  </basic-container>
</template>

<script>
import checkBook from '@/views/bookManeger/component/checkBookRadio.vue'
import checkPackage from '@/views/bookManeger/component/checkPackage.vue'
// import checkBook from "@/views/bookManeger/component/checkBook.vue";
import { getStore } from '@/utils/store'
import { BANNER_API } from "@/service/permissions/banner";
export default {
  components: { checkBook, checkPackage },
  data () {
    return {
      allCnt: 1, //批量设置
      // defalutBook: defalutBook, // 默认图书封面
      selectList: [], //多选数据
      visible: false,
      loading: false,
      sumData: 0, //总和
      // 上传部分的参数----------
      videoFlag: false,
      tableForm: {
        title: "",
        img: "",
        jumpUrl: '',
        // bannerDesc
        indexNo: '',
        status: '',
      },
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },

      //富文本的参数
      ueContent: "",
      // 选择图书
      //表格配置
      tableOption: {
        selection: true,
        align: "center",
        operationData: {
          prop: "operation",
          label: "操作",
          operation: true,
          width: 120,
          align: "center",
        },
        column: [
          {
            prop: "indexNo",
            label: "序号",
            slot: true,
            tableProp: true,
            width: 100,
          },
          {
            prop: "book",
            label: "图书",
            tableProp: true,
            slot: true,
            width: 200,
          },
          { prop: "isbn", label: "ISBN", tableProp: true, width: 100 },
          {
            prop: "paperPrice",
            label: "定价（元）",
            tableProp: true,
            slot: true,
          },
          { prop: "stock", label: "库存（册）", tableProp: true, slot: true },
          {
            prop: "copyCnt",
            label: "拷贝数（册）",
            tableProp: true,
            slot: true,
            width: 250,
          },
          {
            prop: "isSale",
            label: "状态",
            tableProp: true,
            slot: true,
            width: 100,
          },
        ],
      },
      tableList: [],
      tableFormRules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
      },
      goodsCnt: 0,
      totalAmount: 0,
      row: {},
      id: '',
      ibookid: '',//选中的书或者包
      ipackageId: '',
    };
  },
  created () {
    // 注意这里之所以是itemId，是因为我们路由配置中配的是path: '/detail/:itemId'
    // this.row = this.$route.query.row;
    this.iid = this.$route.query.bannerId;
  },
  mounted () {
    this.init(this.iid)
  },
  watch: {
    'tableForm.indexNo': function () {
      // var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
      this.tableForm.indexNo = this.tableForm.indexNo
        .replace(/\D/g, '')
    },
  },
  methods: {
    // 初始化
    async init (iid) {
      this.visible = true;
      if (iid) {
        this.title = "编辑";
        let params = {
          bannerId: iid,
        };
        const { data } = await BANNER_API.getDetail(params);
        this.tableForm = { ...data };
        let url = data.jumpUrl
        // 判断选择的是图书还是捐赠包
        console.log(url)
        if (url.lastIndexOf("bookid")) {
          let index = url.lastIndexOf("=")
          let str = ''
          str = url.substring(index + 1, url.length)
          console.log(str)
          this.ibookid = str
        }
        if (url.lastIndexOf("packageId")) {
          let index = url.lastIndexOf("=")
          let str = ''
          str = url.substring(index + 1, url.length)
          console.log(str)
          this.ipackageId = str
        }


      } else {
        // this.title = "创建套装";
      }
    },
    //多选回调
    selectionChange (val) {
      let that = this;
      this.selectList = val;
    },
    // //获取选择图书或者导入图书去重数据排序 
    // handleSelectBack (row, type = null) {
    //   if (this.tableList.length > 0) {
    //     var temp = this.tableList //一个新的临时数组
    //     for (var i = 0; i < row.length; i++) {
    //       for (var j = 0; j < temp.length; j++) {
    //         if (temp[j].bookId == row[i].bookId) {
    //           row.splice(i, 1);//重复则删除
    //         }
    //       }

    //     }
    //     this.tableList = this.tableList.concat(row)
    //   } else {
    //     this.tableList = [...row, ...this.tableList]
    //   }
    //   console.log(this.tableList)
    //   this.tableList.forEach(item => {
    //     console.log(item.bookId)
    //   })
    // },
    //获取选择图书或者导入图书去重数据排序
    handleSelectBack (row, type = null) {
      // console.log(row)
      this.ibookid = row.bookId
      this.ipackageId = ''
      console.log(this.ibookid)
      this.tableForm.jumpUrl = '/pages/bookClassify/bookInfo?bookId=' + this.ibookid
    },
    //获取选择图书或者导入图书去重数据排序
    handleSelectBack2 (row, type = null) {
      this.ipackageId = row.packageId
      this.ibookid = ''
      this.tableForm.jumpUrl = '/pages/index/packageDetail?packageId=' + this.ipackageId
    },
    checkBooks () {
      this.$refs.checkBook.visible = true;
      console.log(this.tableForm)
      let params = {}
      params.bookId = this.ibookid
      this.$refs.checkBook.init(params, 2)
    },
    checkPackage () {
      this.$refs.checkPackage.visible = true;
      // console.log(this.tableForm)
      let params = {}
      params.packageId = this.ipackageId
      this.$refs.checkPackage.init(params, 2)
    },
    beforeClose () {
      this.$refs["submitForm"].resetFields();
      this.visible = false;
      this.tableList = [];
      this.tableForm = [];
      this.ueContent = "";
      this.allCnt = 1;
      this.$router.back()
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      this.tableForm.img = res.data.link
      this.$set(this.tableForm, "img", res.data.link);
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    // ----------------------------------------------------------------
    async rowSave () {
      console.log(this.tableForm)
      if (!this.tableForm.title) {
        this.$message.error("请输入标题");
        return;
      }
      if (!this.tableForm.img) {
        this.$message.error("请上传图片");
        return;
      }
      let indexItem = ''
      if (this.tableForm.indexNo == '') {
        indexItem = 0
      } else {
        indexItem = this.tableForm.indexNo
      }
      let params = {
        title: this.tableForm.title, //名称
        img: this.tableForm.img, //封面
        // img: 'http://dp-test-default.oss.zgepub.cn/4132aa0d-56b9-4787-a66b-846d534be7f5.jpg',
        jumpUrl: this.tableForm.jumpUrl,
        indexNo: indexItem
      };
      if (this.tableForm.bannerId) {
        params.bannerId = this.tableForm.bannerId
        const data = await BANNER_API.editBanner(params);
        if (data.code === 0) {
          this.visible = false;
          this.beforeClose();
          this.$router.push({
            path: "./bannerList",
          });
        }
      } else {
        const data = await BANNER_API.addBanner(params);
        if (data.code === 0) {
          this.visible = false;
          this.beforeClose();
          this.$router.push({
            path: "./bannerList",
          });
        }
      }


      // await this.$emit("getList");
      // this.$router.back()
    },
  },
};
</script>
<style lang="scss">
.main {
  width: 98.5%;
}
.textMsg {
  border-width: 0px;
  width: 100%;
  padding: 0 0 0 100px;
  height: 24px;
  font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
.ql-editor {
  min-height: 200px;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.box_opration {
  // padding: 0 0 0 55px;
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit3 {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    text-align: right;
    padding-right: 13px;
    font-weight: 500;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
    width: 89%;
    margin: 0 auto 20px;
    justify-content: flex-start;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
