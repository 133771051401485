/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-08 10:26:03
 * @LastEditTime: 2022-04-18 17:25:51
 * @LastEditors: Please set LastEditors
 */
import { asyncRouteMap, staticRoute } from '@/router/index'
import { COMMON_API } from '@/utils/common'
const context = require.context('../../views', true, /\.vue$/);
function hasPermission (roles) {
  // console.log(roles.url)
  if (roles.url == null) {
    console.log(roles)
    roles.url = ''
  }
  let modules = null
  let routerList = {}
  for (const key of context.keys()) {
    modules = key.substring(2, key.length - 4)
    if (modules === roles.url.replace('/', '')) {
      routerList = {
        path: `${roles.url}`,
        name: roles.name,
        meta: { menuId: roles.id },
        component: require('../../views' + roles.url + '.vue').default,
      }
      return routerList
    }


  }

}
export default {
  state: {
    routers: staticRoute,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      const list = { path: '*', component: '../../router/404/404.vue', redirect: '/404' }
      state.addRouters = state.addRouters.concat(routers, list)
      state.routers = staticRoute.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, permission) {
      const router = []
      const menuList = COMMON_API.treeToList(permission)
      const authList = menuList.filter(item => {
        return 'children' in item === false
      })
      return new Promise(resolve => {
        for (const list of authList) {
          const routerObj = hasPermission(list)
          if (routerObj) {
            router.push(routerObj)
          }
        }
        for (const i of asyncRouteMap) {
          i.children = router
        }
        commit('SET_ROUTERS', asyncRouteMap)
        resolve()
      })
    }
  }
}