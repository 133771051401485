/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-17 09:11:02
 * @LastEditTime: 2022-05-24 10:00:13
 * @LastEditors: ym ym@geeboo.cn  
 */
import request from '@/utils/request'
const getUserList = (params) => request('post', '/user/info/oms/page', params) //获取列表
const getUserDetail = (params) => request('post', '/user/org-user/oms/userPageByUserId', params) //
const getUserOrgList = (params) => request('post', '/user/org-user/oms/detail', params) //
const getInfoByOrgId = (params) => request('post', '/user/user-inscription/getInfoByOrgId', params) //用户机构下捐赠信息
// inscriptionAdd
//新增捐赠落款
const inscriptionAdd = (param) => request('post', '/user/user-inscription/save', param, { requestType: 'JSON' })
// const updateRole = (params) => request('post', '/oms/role/update', params, { requestType: 'JSON' }) //修改角色
// const delRole = (params) => request('post', `/oms/role/delete?roleId=${params.roleId}`, params) //删除角色
// const updatePermissions = (params) => request('post', '/oms/role/auths', params, { requestType: 'JSON' }) //修改权限
//获取机构列表
const getChannelList = (params) => request('post', '/user/org/page', params) //获取机构列表
const getTemplateList = (params) => request('get', '/order/flyleaf-template/list', params) //获取列表

const getUserWord = (params) => request('get', '/user/user-medal/info', params,) //获取列表
export const ROLE_API = {
  getUserList,
  getUserDetail,
  getUserOrgList,
  getInfoByOrgId,
  inscriptionAdd,
  getChannelList,
  getTemplateList,
  getUserWord
  // updateRole,
  // delRole,
  // updatePermissions,
}