/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-16 16:14:24
 * @LastEditTime: 2022-04-14 16:06:05
 * @LastEditors: zjc
 */
// 状态
const stateList = [
  { label: '正常', value: 1 },
  { label: '禁用', value: 0 },
]
//性别
const sexOptions = [
  { label: '女', value: 0 },
  { label: '男', value: 1 },
  { label: '未知', value: 2 }
]
//是否隐藏
const isShow = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
]
const authType = [
  { label: '目录', value: 1 },
  { label: '菜单', value: 2 },
  { label: '按钮', value: 3 },
]
export {
  stateList,
  sexOptions,
  isShow,
  authType
}