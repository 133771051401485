<!--
 * @Description: 菜单管理
 * @Author: zjc
 * @Date: 2021-12-27 10:50:39
 * @LastEditTime: 2022-07-05 14:52:01
 * @LastEditors: zjc
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="batchDel(1)"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="parentId" slot-scope="scope">
        <el-tag v-if="scope.row.type === 1">顶部菜单</el-tag>
        <el-tag v-else-if="scope.row.type === 2" type="success">左侧菜单</el-tag>
        <el-tag v-else type="info">按钮</el-tag>
      </template>
      <template slot="isHide" slot-scope="scope">
        <span v-if="scope.row.isHide === 0">否</span>
        <span style="color: red" v-else>是</span>
      </template>

      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="add(scope.row)">新增子项</el-button>
        <el-button type="text" size="small" style="color: red" @click="batchDel(2, scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 新增菜单 -->
    <el-dialog @closed="closed" :title="title" :visible.sync="dialogVisible" width="30%"
      append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="菜单类型：">
          <el-radio-group v-model="ruleForm.authType">
            <el-radio-button v-if="!disabled" label="1">顶部菜单</el-radio-button>
            <el-radio-button label="2">左侧菜单</el-radio-button>
            <el-radio-button label="3">按钮</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上级菜单：">
          <el-cascader :disabled="disabled" v-model="ruleForm.parentAuthId" ref="cascader"
            :options="optionList" :show-all-levels="false" :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              checkStrictly: true,
            }" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="菜单编码：" prop="authCode">
          <el-input v-model="ruleForm.authCode" placeholder="请输入菜单编码"></el-input>
        </el-form-item>
        <el-form-item label="菜单名称：" prop="authName">
          <el-input v-model="ruleForm.authName" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="组件路径：">
          <el-input v-model="ruleForm.authUrl" placeholder="请输入路径"></el-input>
        </el-form-item>
        <el-form-item label="图标：">
          <el-popover placement="bottom-start" width="450" trigger="click"
            @show="$refs['iconSelect'].reset()">
            <IconSelect ref="iconSelect" @selected="selected" />
            <el-input slot="reference" v-model="ruleForm.authIcon" placeholder="点击选择图标" readonly>
              <svg-icon v-if="ruleForm.authIcon" slot="prefix" :icon-class="ruleForm.authIcon"
                class="el-input__icon" style="height: 32px; width: 16px" />
              <i v-else slot="prefix" class="el-icon-search el-input__icon" />
            </el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="ruleForm.indexNo" type="number" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="是否隐藏：">
          <el-radio-group v-model="ruleForm.isHide">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { PERMISSION_API } from "@/service/permissions/permission";
import IconSelect from "@/components/IconSelect";
import { isShow, authType } from "@/enums/permissions";
const formData = {
  authType: 1,
  authIcon: null,
  authUrl: null,
  authName: null,
  authCode: null,
  parentAuthId: null,
  authId: null,
  indexNo: 0,
  isHide: 0,
  authId: null,
};
export default {
  components: { IconSelect },
  data () {
    return {
      loading: false,
      disabled: false,
      type: 1,
      title: "新增菜单",
      dialogVisible: false,
      //搜索
      searchOption: [
        {
          prop: "authName",
          label: "菜单名称",
          type: "input",
          placeholder: "请输入",
        },
        {
          prop: "authCode",
          label: "菜单编码",
          type: "input",
          placeholder: "请输入",
        },
      ],
      list: [],
      optionList: [],
      ruleForm: Object.assign({}, formData),
      rules: {
        authCode: [
          { required: true, message: "请输入菜单编码", trigger: "blur" },
        ],
        authName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
      },
      // 表格查询条件配置
      tableOption: {
        align: "left",
        selection: true,
        sort: true,
        getRowKey (row) {
          return row.id;
        },
        getDefaultKey: 'id', //跟getRowKey的id保持一致
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "name", label: "菜单名称", tableProp: true, align: "left" },
          { prop: "icon", label: "图标", tableProp: true },
          { prop: "index_no", label: "排序", tableProp: true },
          { prop: "url", label: "组件路径", tableProp: true, align: "left" },
          { prop: "parentId", label: "类型", tableProp: true, slot: true },
          {
            prop: "isHide",
            label: "是否隐藏",
            slot: true,
            width: 80,
            tableProp: true,
          },
          {
            prop: "code",
            label: "菜单编码",
            tableProp: true,
            width: 170,
          },
        ],
      },
      filters: {},
      isShow: isShow, //是否隐藏列表
      authType: authType, //权限类型列表
      btnEdit: true,
      formEdit: true,
      formAdd: true,
      formStatus: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      form: {
        parentResourceId: null,
        resourceCode: null,
        resourceName: null,
        resourceUrl: null,
        resourceType: 1,
        resourceIcon: null,
        indexNo: 0,
        isHide: 0,
      },
      currentId: 0,
    };
  },
  methods: {
    getOptionList () {
      PERMISSION_API.getTree(this.filters).then((res) => {
        this.optionList = res.data;
        // console.log(res, "res");
      });
    },
    getData () {
      PERMISSION_API.getTree(this.filters).then((res) => {
        this.list = res.data;
        // console.log(res, "res");
      });
    },

    // 删除
    batchDel (type, item) {
      this.$confirm(`是否确认删除名称为“${item.name}”的数据项?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await PERMISSION_API.delPermission({
            authId: item.id,
          });
          this.$ok("删除成功");
          await this.$store.dispatch("getInfo", { parentId: 0, type: "left" });
          this.getData();
        })
        .catch(() => {
          //
        });
      // delPermission
    },
    // 新增
    addOrUpdate (type, item) {
      this.type = type;
      if (type === 2) {
        this.title = "编辑菜单";
        this.ruleForm.authId = item.id;
        PERMISSION_API.getAuthDetail(item.id).then((res) => {
          this.ruleForm = Object.assign({}, res.data);
          console.log(this.ruleForm, "ruleForm");

          if (this.ruleForm.parentAuthId > 0)
            console.log(this.ruleForm, "ruleForm");
        });
      } else {
        this.title = "新增菜单";
      }
      this.dialogVisible = true;
    },
    // 新增子项
    add (item) {
      this.ruleForm.parentAuthId = item.id;
      this.type = 1;
      this.title = "新增菜单";
      this.ruleForm.authType = 2; //子项不能为顶部菜单
      this.disabled = true;
      this.dialogVisible = true;
    },
    search () {
      this.getData();
    },
    reset () {
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //
          if (this.$refs.cascader.getCheckedNodes().length) {
            this.ruleForm.parentAuthId =
              this.$refs.cascader.getCheckedNodes()[0].value;
            // console.log(this.$refs.cascader.getCheckedNodes()[0].value, "000");
          } else {
            this.ruleForm.parentAuthId = 0;
          }
          this.loading = true;
          if (this.type === 1) {
            PERMISSION_API.addPermission(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("新增成功");
                this.$store.dispatch("getInfo", { parentId: 0, type: "left" });
                this.getData();
                console.log(res, "res");
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            PERMISSION_API.updatePermission(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("编辑成功");
                this.$store.dispatch("getInfo", { parentId: 0, type: "left" });
                this.getData();
                console.log(res, "res");
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    closed () {
      this.ruleForm = Object.assign({}, formData);
      this.loading = false;
      this.disabled = false;
      this.$refs.cascader.$refs.panel.checkedValue = [];
      this.$refs.ruleForm.resetFields();
    },
    selected (name) {
      this.ruleForm.authIcon = name;
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    async getNodeData (obj) {
      this.btnEdit = false;
      if (!this.formEdit) {
        this.formEdit = false;
        this.formStatus = "update";
      }
      const { data } = await PERMISSION_API.getAuthDetail(obj.id);
      const {
        resourceCode,
        resourceIcon,
        resourceId,
        resourceName,
        resourceType,
        resourceUrl,
        indexNo,
        parentResourceId,
      } = data;
      this.form = {
        resourceCode,
        resourceIcon,
        resourceId,
        resourceName,
        resourceType,
        resourceUrl,
        indexNo,
        parentResourceId,
      };
      this.currentId = obj.id;
      this.currentAuthName = data.resourceName;
    },
    handlerEdit () {
      this.formEdit = false;
      this.formStatus = "update";
    },
    handlerAdd () {
      this.resetForm();
      this.formEdit = false;
      this.formStatus = "create";
    },
    //删除
    handleDelete () {
      this.$confirm("是否要删除权限配置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { data } = await PERMISSION_API.delPermission({
          authId: this.currentId,
        });
        this.$ok("删除成功");
        this.getTreeList();
        this.resetForm();
        this.onCancel();
      });
    },
    //获取权限配置树
    async getTreeList () {
      const { data } = await PERMISSION_API.getTree();
      this.treeData = data;
    },
    async update () {
      const data = await PERMISSION_API.updatePermission(this.form);
      this.$ok(data.msg);
      this.getTreeList();
    },
    async create () {
      const data = await PERMISSION_API.addPermission(this.form);
      this.$ok(data.msg);
      this.getTreeList();
    },
    onCancel () {
      this.formEdit = true;
      this.formStatus = "";
    },
    resetForm () {
      this.form = {
        parentAuthId: null,
        resourceCode: null,
        resourceName: null,
        resourceUrl: null,
        resourceType: 1,
        resourceIcon: null,
        indexNo: 0,
        isHide: 0,
      };
    },
  },
  mounted () {
    // this.getTreeList();
    this.getData();
    this.getOptionList();
  },
};
</script>
<style lang="scss">
.filter-tree {
  border: 1px solid #dcdfe6;
  padding: 15px 20px;
}
</style>