<template>
  <basic-container >
    <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="formData"
      size="medium" :rules="formRules" label-width="100px">
        <el-form-item label="捐赠折扣:" prop="num">
          <el-input-number style="width:200px" type="number" v-model="formData.num" controls-position="right" 
            placeholder="请输入" :min="6" :precision="1" :max="10" :step="0.1">
          </el-input-number>
          折
        </el-form-item>
        <p class="tip">
          请输入6或10以内数字，填写10为无折扣，折扣调整后，全捐赠平台折扣将同步调整
        </p>
    </el-form>
    <div style="text-align:right">
      <el-button type="primary" @click="save" :loading="loading">保存并应用</el-button>
    </div>
  </basic-container>
</template>

<script>
import request from '@/utils/request'
export default {
  data () {
    return {
      loading:false,
      formRules: {
        num: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
      },
      formData:{
        num: 10
      }
   
    };
  },
  mounted () {
    this.init()
  },
  methods: {
    // 初始化
    init () {
      this.$fetch("get","/oms/param/value/BOOK_DISCOUNT",{}).then(res=>{
        this.formData.num = res.data || 10
      })
    },
    save(){
      this.$fetch("post","/oms/param/update",{
        "paramId":"1",
        "paramCode":"BOOK_DISCOUNT",
        "paramName":"BOOK_DISCOUNT",
        "paramValue":this.formData.num,
        "status":"1",
        "type":"0"
      },{
        requestType:"application/x-www-form-urlencoded;charset=UTF-8"
      }).then(res=>{
        this.$message.success("保存成功！");
      })
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .tip {
  padding-left:25px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
</style>
