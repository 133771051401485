/*
 * @Description:正则
 * @Author: zjc
 * @Date: 2021-12-07 11:40:26
 * @LastEditTime: 2022-04-29 16:02:55
 * @LastEditors: zjc
 */
const isExternal = (path) => {
  return /^(https?:|mailto:|tel:)/.test(path)
}
//密码校验
const validatePwd = (value) => {
  return /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(value)
}
//正整数校验
const isInteger = (value) => {
  return /^([0-9]*)$/.test(value)
}
//数字字母组合
const isAlphanumeric = (value) => {
  return /[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g.test(value)
}
const isReg = (value) => {
  return /[^a-zA-Z0-9\u4E00-\u9FA5]/g.test(value)
}
export {
  isExternal,
  validatePwd,
  isInteger,
  isAlphanumeric,
  isReg
}