
import request from '@/utils/request'
const getList = (param) => request('get', '/order/flyleaf-template/page', param)
const addDonation = (param) => request('post', '/order/flyleaf-template/add', param, { requestType: 'JSON' })
const editDonation = (param) => request('post', '/order/flyleaf-template/edit', param, { requestType: 'JSON' })
const detailDonation = (param) => request('get', '/order/flyleaf-template/detail', param)
const delectDonation = (param) => request('post', '/order/flyleaf-template/delete', param, { requestType: 'JSON' })
const getThemeList = (param) => request('get', '/book/package-theme/page', param)
const packageList = (param) => request('get', '/book/package/page', param)


export const DONATION_API = {
  getThemeList,packageList,getList, addDonation, editDonation, detailDonation, delectDonation,

}
