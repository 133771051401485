/*
 * @Description:公共
 * @Author: zjc
 * @Date: 2021-12-07 11:52:29
 * @LastEditTime: 2022-05-27 14:30:40
 * @LastEditors: ym ym@geeboo.cn
 */
import { setStore, getStore } from '@/utils/store'
export default {
  state: {
    isFullScreen: false,
    contentScreen: false,
    isCollapse: false,
    tableLoading: false,
    isScreen: null,
    title: getStore({ name: 'routeTitle' }) || '',
    activeIndex: null,
    isSearch: true,
    crumbsList: [],//面包屑列表
  },
  actions: {
    // 自定义导航栏
    UPDATE_CRUMBS ({ commit }, data) {
      console.log(data, 'data')
      commit('SET_CRUMBS', data)
    },
  },
  mutations: {
    // 收缩菜单栏
    UPDATES_IDEBAR_FOLD (state) {
      state.isCollapse = !state.isCollapse
    },
    // 列表表格loading
    SET_TABLE_LOADING (state, bool) {
      state.tableLoading = bool
      // bool === true ? state.tableLoading = bool : setTimeout(() => state.tableLoading = bool, 100)
    },
    SET_TITLE (state, routeTitle) {
      state.title = routeTitle
      setStore({ name: 'routeTitle', content: routeTitle })
    },
    SET_SCREEN (state, val) {
      state.isScreen = val
      val <= 1 ? state.isCollapse = true : state.isCollapse = false
    },
    // 全屏
    SET_FULLSCREN (state, bool) {
      state.isFullScreen = !state.isFullScreen
    },
    CONTENT_FULLSCREN (state, bool) {
      state.contentScreen = !state.contentScreen
    },
    SET_ACTIVE_INDEX (state, val) {
      state.activeIndex = val
    },
    SHOW_SEARCH (state, bool) {
      state.isSearch = bool
    },
    SET_CRUMBS (state, list) {
      state.crumbsList = list
    }
  }
}