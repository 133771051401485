<!--
 * @Description: 菜单列表
 * @Author: zjc
 * @Date: 2021-12-07 15:26:42
 * @LastEditTime: 2022-04-14 13:48:32
 * @LastEditors: zjc
-->
<template>
  <div>
    <template v-for="(item) in menus">
      <el-submenu v-show="item.isHide == 0" v-if="item.children && item.children.length>0"
        :index="item.url" :key="item.url">
        <template slot="title">
          <svg-icon :icon-class="item.icon" />
          <span>{{item.name}}</span>
        </template>
        <!-- 递归组件 -->
        <nav-menu-item :menus=item.children></nav-menu-item>
      </el-submenu>
      <el-menu-item v-show="item.isHide == 0" v-else :index="item.url" :key="item.url">
        <svg-icon :icon-class="item.icon" />
        <span slot="title">{{item.name}}</span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  name: 'navMenuItem',
  props: {
    menus: {
      type: Array,
      default: function () {
        return [];
      },
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {

  },
  methods: {
  },
  mounted () {
  }
}
</script>