<!--
 * @Author: 租户管理
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-08-25 11:44:52
 * @FilePath: \cloud-library-platform-ui\src\views/operation/classList
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters" resetName="重置">
            <template #daterange>
              <el-form-item label="创建时间" label-width="90px">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']" v-model="filters.daterange"
                  type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="dialogVisible = true">
            新增
          </el-button>
          <el-button type="primary" size="small" :disabled='!selection.length' @click="copyRow">复制分类
          </el-button>
        </template>
      </form-menu>
    </div>
    <section class="box">
      <el-menu :default-active="menuActive" class="main-nav">
        <h3 class="main-nav_title">
          图书馆应用
        </h3>
        <el-menu-item v-for="item in menu" :key="item.index"
          @click="()=>changeStatus(item.tenantId)" :index="item.tenantId">
          <span slot="title">{{ item.libraryName }}</span>
        </el-menu-item>
      </el-menu>
      <div class="table">
        <v-table ref="table" @handleSelectionChange='handleSelectionChange' :tableData="list"
          :tableOption="tableOption">
          <template slot="indexNo" slot-scope="scope">
            <el-input controls-position="right" :min="0" :max='99999'
              @input="(e)=>setIndexNo(scope.row,e)" @change="()=>handleIndexNo(scope.row)"
              v-model="scope.row.indexNo" placeholder="请输入">
            </el-input>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="updateRow( scope.row)">编辑</el-button>
            <el-button type="text" size="small" style="color: red" @click="batchDel( scope.row)">删除
            </el-button>

          </template>
        </v-table>
        <!-- 分页 -->
        <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
          @hanlePage="handleSizeChange"></pagination>

      </div>
    </section>
    <!-- 新增分类 -->
    <el-dialog @closed="closed('')" :title="title" :visible.sync="dialogVisible" width="40%"
      :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="所属应用：" prop="tenantId">
          <el-select v-model="ruleForm.tenantId" placeholder="请输入">
            <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
              :value="item.tenantId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称：" prop="className">
          <el-input v-model="ruleForm.className" maxlength="10" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="indexNo">
          <el-input v-model="ruleForm.indexNo" @input="(val)=>setIndexNo(ruleForm,val)"
            maxlength='5' placeholder="数字越小排序越前"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="()=>save()" :loading="loadingCopy">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="复制分类" @closed="closed('copy')" :visible.sync="dialogVisibleCopy" width="40%"
      :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm2" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="复制给应用：" prop="tenantId">
          <el-select v-model="ruleForm.tenantId" placeholder="请输入">
            <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
              :value="item.tenantId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCopy = false">取 消</el-button>
        <el-button type="primary" @click="()=>saveCopy(section)" :loading="loadingCopy">确 定
        </el-button>
      </span>
    </el-dialog>

  </basic-container>
</template>
<script>
import { COMMON_API } from '@/utils/common'
export default {
  data () {
    return {
      loading: false,
      selection: [],
      loadingCopy: false,
      dialogVisible: false,
      dialogVisibleCopy: false,
      type: null,
      menu: [],
      menuActive: '',
      pageTotal: 0,
      title: '新增分类',
      pageData: {
        current: 1,
        size: 10,
      },
      filters: {
        daterange: [],
      },
      //搜索
      searchOption: [
        {
          prop: 'className',
          label: '分类名称',
          type: 'input',
          placeholder: '请输入',
        },
        {
          prop: 'daterange',
          label: '创建时间',
          col: 8,
          type: 'daterange',
          slot: true,
          placeholder: '请选择',
        },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: 'center',
        sort: true,
        operationData: {
          prop: 'operation',
          label: '操作',
          slot: true,
          operation: true,
          width: 200,
          fixed: 'right',
        },
        column: [
          {
            prop: 'className',
            label: '分类名称',
            tableProp: true,
          },
          { prop: 'indexNo', label: '排序', width: 100, tableProp: true, slot: true },
          { prop: 'createTime', label: '创建时间', tableProp: true },
        ],
      },
      list: [],
      ruleForm: {
        tenantId: '',
        className: '',
        indexNo: '',
      },

      rules: {
        classId: '',
        tenantId: [{ required: true, message: '请输入', trigger: 'blur' }],
        className: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    };
  },
  created () {
    this.getLibInfos();
  },
  methods: {
    handleSelectionChange (value) {
      this.selection = value;
    },
    /**
     * 租户
     */
    getLibInfos () {
      this.$fetch('post', '/oms/help/libInfos', {
        current: 1,
        size: 100,
      }).then((res) => {
        this.menu = res.data.records;
        this.menuActive = this.menu[0].tenantId;
        this.getData();
      });
    },

    changeStatus (e) {
      this.menuActive = e;
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    /**
     * 列表
     */
    getData () {
      const params = Object.assign({}, this.filters);
      if (params.daterange && params.daterange.length) {
        params.beginTime = params.daterange[0];
        params.endTime = params.daterange[1];
        delete params.daterange;
      }
      this.$fetch(
        'post',
        '/oms/help/categoryDisplay',
        {
          ...this.pageData,
          ...params,
          tenantId: this.menuActive,
        },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.list = res.data.records;
        this.pageTotal = res.data.total;
      });
    },
    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    closed (val) {
      //清空
      if (val) this.$refs.ruleForm2.resetFields()
      else this.$refs.ruleForm.resetFields()
      this.dialogVisible = false;
      this.title = '新增分类';
      this.dialogVisibleCopy = false;
      this.ruleForm = {
        tenantId: '',
        helpTitle: '',
        indexNo: '',
      };
    },
    copyRow () {
      this.dialogVisibleCopy = true;
    },
    setIndexNo (ruleForm, e) {
      if (e) {
        const val = String(e).replace(/\D/g, '');
        ruleForm.indexNo = Number(val);
      }
    },
    handleIndexNo (params) {
      console.log(params);
      this.$fetch(
        'post',
        '/oms/help/categoryModify',
        {
          ...params,
          operation: params.classId ? '2' : '1',
        },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.$message.success(res.msg);
        this.list = COMMON_API.dataSort(this.list, 'indexNo')
      });
    },
    // 确认提交
    save (row) {
      this.loading = true;
      const params = row || this.ruleForm;
      this.$refs.ruleForm.validate().then((res) => {
        if (res) {
          this.$fetch(
            'post',
            '/oms/help/categoryModify',
            {
              ...params,
              operation: params.classId ? '2' : '1',
            },
            {
              requestType: 'application/json',
            }
          ).then(
            (res) => {
              this.loading = false;

              this.closed();
              this.$message.success(res.msg);
              this.getData();
            },
            () => {
              this.loading = false;
            }
          );
        }
      });
    },
    saveCopy () {
      this.$refs.ruleForm2.validate().then((res) => {
        if (res) {
          this.loadingCopy = true;

          this.$fetch(
            'post',
            '/oms/help/categoryModify',
            {
              targetTenantId: this.ruleForm.tenantId,
              tenantId: this.menuActive,
              classIds: this.selection.map((i) => i.classId),
              operation: '4',
            },
            {
              requestType: 'application/json',
            }
          ).then(
            (res) => {
              this.loadingCopy = false;
              this.closed();
              this.$message.success(res.msg);
              this.getData();
            },
            () => {
              this.loadingCopy = false;
            }
          );
        }
      });
    },
    /**
     *更新
     */
    updateRow (row) {
      this.ruleForm = COMMON_API.deepClone(row);
      this.dialogVisible = true;
      this.title = "编辑"
    },
    /**
     *
     */
    batchDel (row) {
      this.$confirm('确定删除该帮助分类？', '提示', {
        type: 'warning',
      }).then(() => {
        this.$fetch(
          'post',
          '/oms/help/categoryModify',
          {
            helpId: row.helpId,
            operation: '3',
            classId: row.classId,
            tenantId: row.tenantId,
          },
          {
            requestType: 'application/json',
          }
        ).then((res) => {
          this.$message.success(res.msg);
          this.getData();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  .table {
    width: 100%;
  }
}
.main-nav {
  width: 200px;
  flex: none;
  border: 1px solid #eee !important;
  box-sizing: border-box;
  margin-right: 5px;
  .main-nav_title {
    background: #f5f7fa;
    height: 63px;
    margin: 0;
    line-height: 63px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-menu-item {
    border-bottom: 1px solid #eee;
  }
}
::v-deep .el-date-editor {
  width: 100%;
}
</style>
