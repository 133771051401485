

import request from '@/utils/request'


const getList = (param) => request('get', '/oms/banner/page', param)
const addBanner = (param) => request('post', '/oms/banner/save', param, { requestType: 'JSON' })
const editBanner = (param) => request('post', '/oms/banner/update', param, { requestType: 'JSON' })
const getDetail = (param) => request('get', '/oms/banner/detailById', param)
const changeBanner = (param) => request('post', '/oms/banner/enable', param)

export const BANNER_API = {
  getList,
  addBanner,
  editBanner,
  getDetail,
  changeBanner
}


