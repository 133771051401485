<template>
  <basic-container id="fullscreen">
    <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
      size="medium" :rules="tableFormRules" label-width="120px">
      <div class="table_one">
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="" prop="configName">
          <p class="redTit" style="position:absolute;left:-131px;top:0;width:130px !important;">
            模板名称：</p>
          <el-input maxlength="20" show-word-limit v-model="configName" clearable placeholder="请输入"
            style="width: 80%">
          </el-input>
          <div class="el-form-item__error" v-show="errorMsg0">{{errorMsg0}}</div>
        </el-form-item>
        <el-form-item style="width: 89%; margin: 0 auto 20px">
          <p class="redTit" style="position:absolute;left:-131px;top:0;width:130px !important;">
            用户填写顺序：</p>
          <div class="box-item"
            style="width: 80%;height:36px;border-radius: 4px;border: 1px solid #DCDFE6;">
            <div class="item" v-for="(item,index) in chooseList2" :key="item.identityLabel">
              <span class="box_label">{{item.identityLabel}}
                <span class="item_close" v-if="item.identityCode !== 'LUOKUAN'"
                  style="cursor:pointer" @click="closeItem(item,index)">x</span></span>
            </div>
          </div>
          <div class="el-form-item__error" v-show="errorMsg">{{errorMsg}}</div>
        </el-form-item>
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="">
          <div class="box-item">
            <div style="cursor:pointer" @click="chooseItem(item,index)" class="item"
              v-for="(item,index) in chooseList" :key="item.identityLabel">
              <span class="box_label">{{item.identityLabel}}</span>
            </div>

          </div>
        </el-form-item>
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="落款预览：" prop="preview">
          <el-input v-model="preview" clearable placeholder="请输入" style="width: 80%">
          </el-input>
        </el-form-item>

      </div>
    </el-form>

    <div style="text-align:right">
      <el-button type="primary" @click="rowSave" :loading="loading">保 存</el-button>
      <el-button @click="beforeClose">取 消</el-button>
    </div>
  </basic-container>
</template>

<script>
import { getStore } from '@/utils/store'
import { BANNER_API } from "@/service/permissions/banner";
import { DONATION_API } from "@/service/permissions/donation";
export default {
  data () {
    return {
      chooseList2: [],
      chooseList: [],
      errorMsg0: '',
      errorMsg: '',
      preview: '',
      tableForm: {
        configName: "",
        pressOrgNo: "",
      },
      configName: '',
      // tableFormRules: {
      // configName: [
      //   { required: true, message: "请输入后保存", trigger: "blur" },
      // ],
      // chooseList2: [
      //   { required: true, message: "请选择用户填写顺序", trigger: "blur" },
      // ]
      // },
    };
  },
  created () {
    console.log(this.$route.query)
    if (this.$route.query.pressOrgNo) {
      this.pressOrgNo = this.$route.query.pressOrgNo
      localStorage.setItem("pressOrgNo", this.$route.query.pressOrgNo)
    }
    else {
      if (localStorage.getItem("pressOrgNo")) {
        this.pressOrgNo = localStorage.getItem("pressOrgNo")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.getUseridentity();
    this.getList();
    // this.getUseridentity();
    // this.getDonationList();
  },
  mounted () {
    // this.getList();

    // this.init(this.iid)
  },
  watch: {
    'configName': function () {
      this.errorMsg0 = ''
    },
    'chooseList2': function () {
      this.errorMsg = ''
      this.preview = '尊敬的'
      let arr = '（示例：尊敬的'
      this.chooseList2.forEach(item => {
        if (item.identityCode == 'SCHOOL') {
          this.preview += '[' + item.identityLabel + ']'
          arr += '厦门大学'
        }
        else if (item.identityCode == 'ENTRY_YEAR') {
          this.preview += '[' + item.identityLabel + ']'
          arr += '2021级'
        }
        else if (item.identityCode == 'EDUCATION') {
          this.preview += '[' + item.identityLabel + ']'
          arr += '本科'
        }
        else if (item.identityCode == 'LUOKUAN') {
          this.preview += '[' + item.identityLabel + ']'
          arr += '某某某'
        }
      })
      this.preview += arr + '）'
    },
  },
  methods: {
    // 主题详情
    async getUseridentity () {
      try {
        let params = {}
        params.pressOrgNo = this.pressOrgNo
        await DONATION_API.getUseridentity(params).then(res => {
          if (res.data) {
            this.configName = res.data.configName
            this.tableForm = res.data
            res.data.items.forEach(otem => {
              if (otem.identityCode !== 'LUOKUAN') {
                this.chooseList2.push({
                  identityLabel: otem.identityLabel,
                  identityCode: otem.identityCode
                })
              }

            })
          }

          // console.log('111111')
          this.chooseList2.push({
            identityLabel: '落款名称',
            identityCode: 'LUOKUAN'
          })
          // this.chooseList2 = res.data.items
          this.preview = '尊敬的'
          this.chooseList2.forEach(item => {
            if (item.identityCode == 'SCHOOL') {
              this.preview += '[' + item.identityLabel + ']校友'
            }
            else if (item.identityCode == 'ENTRY_YEAR') {
              this.preview += '[' + item.identityLabel + ']级'
            }
            else if (item.identityCode == 'EDUCATION') {
              this.preview += item.identityLabel
            }

          })
          // this.preview = 
          // this.tableForm.pressOrgNo = '尊敬的' + '所属院校校友' + res.data.modifyTime + res.data.modifyTime
          // （示例：尊敬的厦门大学2021级某某某）
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    chooseItem (item, index) {
      console.log(this.chooseList2)
      console.log(item)
      let i = true;
      this.chooseList2.forEach(otem => {
        if (otem.identityLabel == item.identityLabel) {
          console.log('存在')
          this.$message.error("已存在");
          i = false;
          // return
        }
      })
      if (i == true) {
        this.chooseList2.splice(this.chooseList2.length - 1, 0, item)
      }

      // if (this.chooseList2.indexOf(item) == -1) {
      //   console.log("不存在")
      //   this.chooseList2.push(item)
      // } else {
      //   console.log("存在,索引是：",)
      //   this.$message.error("已存在");
      //   return;
      // }

    },
    closeItem (item, index) {
      this.chooseList2.splice(index, 1)
    },
    async getList () {
      let params = {};
      const { data } = await DONATION_API.getitemOption(params);
      console.log(data)
      this.chooseList = data;
      // let arr = {
      //   identityLabel: "落款名称",
      //   identityCode: 'LUOKUAN'
      // }
      // this.chooseList.push(arr)
    },
    // 初始化
    // async init (iid) {
    //   this.visible = true;
    //   if (iid) {
    //     this.title = "编辑";
    //     let params = {
    //       bannerId: iid,
    //     };
    //     const { data } = await BANNER_API.getDetail(params);
    //     this.tableForm = { ...data };
    //     let url = data.jumpUrl
    //     // 判断选择的是图书还是捐赠包
    //     console.log(url)
    //     if (url.lastIndexOf("bookid")) {
    //       let index = url.lastIndexOf("=")
    //       let str = ''
    //       str = url.substring(index + 1, url.length)
    //       console.log(str)
    //       this.ibookid = str
    //     }
    //     if (url.lastIndexOf("packageId")) {
    //       let index = url.lastIndexOf("=")
    //       let str = ''
    //       str = url.substring(index + 1, url.length)
    //       console.log(str)
    //       this.ipackageId = str
    //     }


    //   } else {
    //     // this.title = "创建套装";
    //   }
    // },

    beforeClose () {
      this.$refs["submitForm"].resetFields();
      this.visible = false;
      // this.tableList = [];
      this.tableForm = {};
      // this.ueContent = "";
      // this.allCnt = 1;
      this.$router.back()
    },

    // ----------------------------------------------------------------
    async rowSave () {
      if (!this.configName) {
        this.errorMsg0 = '请输入模板名称'
        // this.$message.error("请输入模板名称");
        return;
      }
      if (this.chooseList2.length == 0) {
        // this.$message.error("请选择用户填写顺序");
        this.errorMsg = '请选择后保存'
        return;
      }
      let luokuan = false;
      let arr = []
      this.chooseList2.forEach((item, index) => {
        let a = {}
        a = item,
          a.indexNo = index
        arr.push(a)
        if (item.identityCode == 'LUOKUAN') {
          luokuan = true
        }
      })
      if (!luokuan) {
        this.$message.error("必须选择落款名称");
        // this.errorMsg = '请选择用户填写顺序'
        return;
      }

      let list = []
      arr.forEach(item => {
        if (item.identityCode !== 'LUOKUAN') {
          list.push(item)
        }
      })
      let params = {
        configName: this.configName, //名称
        pressOrgNo: this.pressOrgNo,
        items: list,
      };
      const data = await DONATION_API.editOption(params);
      if (data.code === 0) {
        this.$message.success("编辑成功");
        this.$router.back()
      }


    },
  },
};
</script>
<style lang="scss">
.box-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .item {
    position: relative;
    margin: 0 5px 0 0;

    .box_label {
      flex-wrap: nowrap;
      position: relative;
      font-size: 13px;
      border-width: 0px;
      width: 130px;
      height: 31px;

      font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
      font-weight: 400;
      font-style: normal;
      justify-content: center;
      display: flex;
      align-items: center;
      align-content: center;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-radius: 50px;
      border: 1px solid rgba(215, 215, 215, 1);
      .item_close {
        // position: absolute;
        color: #d9001b;
        font-size: 12px;
        display: flex;
        align-items: center;
        align-content: center;
        margin: 0 0 0 10px;
      }
    }
  }
}
.main {
  width: 98.5%;
}
.textMsg {
  border-width: 0px;
  width: 100%;
  padding: 0 0 0 55px;
  height: 24px;
  font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
.ql-editor {
  min-height: 200px;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.box_opration {
  // padding: 0 0 0 55px;
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit3 {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    text-align: right;
    padding-right: 13px;
    font-weight: 500;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
    width: 89%;
    margin: 0 auto 20px;
    justify-content: flex-start;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
