<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2022-03-07 14:45:19
 * @LastEditTime: 2022-03-08 16:16:13
 * @LastEditors: zjc
-->
<!-- 导入图书 -->
<template>
  <div style="display: inline-block;">
    <el-upload class="upload-demo" ref="upload" action="" :auto-upload="false" :file-list="fileList"
      :on-change="handleChange" multiple :show-file-list="false" accept=".xlsx,.xls">
      <el-button type="primary" size="small">导入图书</el-button>
    </el-upload>
  </div>
</template>
<script>
export default {
  data () {
    return {
      fileList: [],
      file: ""
    }
  },
  methods: {
    handleChange (file, fileList) {
      // console.log(file)
      // console.log(fileList)
      if (!file) return
      // var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$refs.upload.clearFiles()
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'error'
        })
        return false
      }

      if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
        this.fileList = [fileList[fileList.length - 1]]; // 只能上传一个Excel，重复上传会覆盖之前的        
        this.file = file.raw;
        let reader = new FileReader()
        let _this = this
        reader.readAsArrayBuffer(this.file)
        reader.onload = () => {
          let buffer = reader.result
          let bytes = new Uint8Array(buffer)
          let length = bytes.byteLength
          let binary = ''
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          let XLSX = require('xlsx')
          let wb = XLSX.read(binary, {
            type: 'binary'
          })

          const exlname = wb.SheetNames[0] // 取第一张表

          let arr = wb.Sheets[exlname]
          console.log(arr)
          if (arr.A1.v == 'ISBN书号' && arr.B1.v == "图书名"
            && arr.C1.v == "定价" && arr.D1.v == "拷贝数（册）"
            && arr.E1.v == "出版社" && arr.F1.v == "作者/译者") {
            const exl = XLSX.utils.sheet_to_json(wb.Sheets[exlname]) // 生成json表格内容
            let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
            this.$emit('importortXlsx', outdata)

          } else {
            this.$message.error("按照要求提交正确数据");
            return
          }

        }
      } else {
        this.$message.error("仅支持xls、xlsx格式的文件!");
        return
      }

    }
  }
}
</script>