<!-- 选择模板 -->
<template>
  <el-dialog id="fullscreen" modal-append-to-body title="捐赠落款" width="1000px" @close="closeDialog"
    append-to-body :close-on-click-modal="false" top="10vh" :visible.sync="visible"
    v-loading="loading">
    <el-button type="primary" size="small" @click="jumpToadd('')">
      新增落款
    </el-button>
    </br>
    <div class="itemRadio" style="margin:20px auto 0">
      <div class="grid-content bg-purple" v-for="item in tableList" :key="item"
        @click="changeTheme(item)">
        <div :class="inscriptionId == item.inscriptionId ?'activeCircle':'circle'">
        </div>
        <span
          :class="inscriptionId == item.inscriptionId ?'activeText':'text'">{{item.inscriptionName}}</span>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowUpdate">提 交</el-button>
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import { CALENDAR_API } from '@/service/bookExtract/calendar'
// import { defalutAvatar } from '@/enums/bookManager'
import { ROLE_API } from "@/service/permissions/user";
export default {

  data () {
    return {
      // defalutAvatar: defalutAvatar,
      visible: false,
      radio: {},
      inscriptionId: -1,
      rowList: [],
      tableList: [],
      orgId: '',
      // userId: '',
    }
  },
  mounted () {
  },
  methods: {
    changeTheme (val) {
      console.log(val)
      this.radio = val;
      this.inscriptionId = val.inscriptionId
    },
    jumpToadd () {
      // this.$router.push({
      //   path: "../../user/userDetail?userId=" + this.userId,
      // });
      console.log('111')
      // console.log(this.rowList)
      this.$emit('addOrUpdate', this.rowList)
    },
    init (row, list) {
      console.log(row)
      console.log(list)
      // orgId=1&userId=1001
      this.orgId = 0
      // this.userId = row.donateUserId
      this.rowList = row;
      this.radio = list;
      this.inscriptionId = list.inscriptionId;
      console.log(this.radio)
      this.visible = true
      this.getUserOrg()

    },
    async getUserOrg () {
      try {
        let params = {}
        params.orgId = 0
        params.userId = this.rowList.donateUserId
        console.log(params)
        const { data } = await ROLE_API.getInfoByOrgId(params)
        console.log(data)
        this.tableList = data
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },

    // 提交
    rowUpdate () {

      if (this.radio == null) {
        this.$fail('请选择模板')
        return
      }
      console.log(this.radio)
      this.$emit('choiceImg', this.radio)
      this.closeDialog()
    },
    // 关闭
    closeDialog () {
      this.visible = false
      this.radio = null
    },

  }
}
</script>
<style lang="scss" scoped>
.grid-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin: 0 0 20px 0;
  border: 1px solid red;
  width: 800px;
  height: 60px;
  line-height: 60px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 0 20px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(170, 170, 170, 1);
  border-radius: 5px;
}
</style>


<style scoped lang="scss">
.itemRadio {
  .el-radio {
    width: 100%;
    height: 60px;
    color: #606266;
    font-weight: 500;
    line-height: 60px;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px;
  }
}
.circle {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 0;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
.activeCircle {
  border: 4px solid #409eff;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 0;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
}
.activeText {
  color: #409eff;
  max-width: 700px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>