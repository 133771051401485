<!--
 * @Description: 编辑器
 * @Author: zjc
 * @Date: 2021-12-08 15:16:23
 * @LastEditTime: 2022-05-13 13:59:10
 * @LastEditors: zjc
-->
<template>
  <div>
    <quill-editor ref="myTextEditor" v-model="content" :options="quillOption"
      @change="onEditorChange($event)">
    </quill-editor>
    <!-- 上传图片 -->
    <upload-image ref="image" @success="imageSuccess"></upload-image>

    <!-- 上传视频 -->
    <upload-video ref="video" @success="videoSuccess" v-show="isVideo"></upload-video>
  </div>
</template>
<script>
import { quillEditor, Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import uploadImage from './component/uploadImage'
import uploadVideo from './component/uploadVideo'
import Video from "./quill";
// import ImageResize from 'quill-image-resize-module'
// Quill.register('modules/imageResize', ImageResize)
Quill.register(Video, true);
export default {
  components: {
    quillEditor,
    uploadImage,
    uploadVideo
  },
  props: {
    value: {
      type: String
    },
    isVideo: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
  },
  computed: {
    editor () {
      return this.$refs.myTextEditor.quill
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.content = newVal
    }
  },
  data () {
    return {
      content: null,//编辑器内容
      quillOption: {
        theme: "snow", // 主题
        placeholder: this.placeholder,
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["uploadImage", "uploadVideo"],      //新添加的工具
            ], // 自定义工具栏选项
            imageResize: {
              displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                width: '100%'
              },
              // modules: ['Resize', 'DisplaySize', 'Toolbar']
            },
            handlers: {
              uploadImage: () => {    //新增按钮绑定事件 自定义  调用vue实例上的method   这里注意名字要和上面新增按钮的名字一样
                this.btnMethod('img');
              },
              uploadVideo: () => {
                this.btnMethod('video');
              }
            },
          },
        },
        initButton: function () {
          //在使用的页面中初始化按钮样式
          const addBtn = document.querySelector(".ql-uploadImage");
          addBtn.innerHTML = '上传图片'
          addBtn.style.cssText =
            "width:80px; border:1px solid #1890FF; border-radius:5px;font-size:14px;padding-bottom:30px;line-height: 24px;background-color: #1890FF;color:#fff";
          addBtn.className = "iconfont icon-reset";

          const uploadImage = document.querySelector(".ql-uploadVideo");
          uploadImage.innerHTML = '上传视频'
          uploadImage.style.cssText =
            "width:80px; border:1px solid #1890FF; border-radius:5px;font-size:14px;padding-bottom:30px;line-height: 24px;margin-left: 10px;background-color: #1890FF;color:#fff";
          uploadImage.className = "iconfont icon-reset";
        },
      }
    }
  },
  methods: {
    onEditorChange (val) {
      this.$emit('update:value', val.html)
    },
    btnMethod (type) {
      if (type === 'img') this.$refs.image.init()
      else {
        this.$refs.video.init()
      }
    },
    //图片上传成功
    imageSuccess (imageList) {
      try {
        let imageTemplateList = "";
        const image = imageList.insertionImage
        imageTemplateList = imageTemplateList + image;
        this.inserthtml(imageTemplateList, 'image');
        this.$message.success("上传成功！");
      } catch (error) {
        this.$message.error(error);
      }
    },
    //视频上传成功
    videoSuccess (videoList) {
      try {
        this.inserthtml({
          width: `${videoList.width + '%'}`,
          height: `${videoList.height}`,
          poster: `${videoList.videoImage}`,
          url: `${videoList.insertionImage}`,
          controls: 'controls',
          style: 'object-fit:fill',
        }, 'video');
        this.$message.success("上传成功！");
      } catch (error) {
        this.$message.error(error);
      }
    },
    //插入html
    inserthtml (content, type) {
      const quill = this.$refs.myTextEditor.quill
      const length = quill.getSelection(true).index
      quill.insertEmbed(length, type, content)
      quill.setSelection(length + 1)
    },
  },
  mounted () {
    this.content = this.value
    this.$refs.myTextEditor.quill.enable(true)
    this.$nextTick(() => {

      this.quillOption.initButton();
    })
  },
}
</script>
<style lang="scss">
.ql-editor {
  white-space: normal !important;
}

.ql-container {
  white-space: pre-wrap !important;
}
.ql-snow .ql-editor img {
  width: 100%;
}
</style>