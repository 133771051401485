<!--
 * @Description: 操作日志
 * @Author: zjc
 * @Date: 2022-04-16 10:45:15
 * @LastEditTime: 2022-04-25 17:41:29
 * @LastEditors: zjc
-->
<template>
  <basic-container style="height:auto">
    <div class="person-title">操作日志</div>
    <div class="searchForm" style="padding-top:20px">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #createTime>
              <el-form-item label="创建时间" prop="type">
                <el-date-picker v-model="createTime" type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  style="width: 100%">
                </el-date-picker>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot-scope="{row}" slot="isEnabled">
        <p class="hot-status">
          <span class="hot" :style="{background:row.isEnabled ===1?'#1890FF':'#F5222D'}"></span>
          {{row.isEnabled ===1?'正常':'禁用'}}
        </p>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
  </basic-container>
</template>
<script>
import { OPERATOR_API } from '@/service/permissions/operator'
export default {
  data () {
    return {
      filters: {},
      pageTotal: 0,
      createTime: [],
      list: [],
      // 表格查询条件配置
      tableOption: {
        align: 'center',
        column: [
          { prop: 'oprId', label: '标题', tableProp: true, width: 120 },
          { prop: 'username', label: '链接', tableProp: true, },
          { prop: 'realName', label: 'ip', tableProp: true, },
          { prop: 'createTime', label: '操作时间', tableProp: true },
        ],
      },
      //搜索
      searchOption: [
        { prop: 'title', label: '标题', type: 'input', placeholder: '请输入标题' },
        { prop: 'createTime', label: '操作时间', col: 10, type: 'select', slot: true, },
      ],
    }
  },
  methods: {
    changePage (val) {
      this.pageData.current = val
      this.onLoad()
    },
    handleSizeChange (val) {
      this.pageData.current = 1
      this.pageData.size = val
      this.onLoad()
    },
    //重置
    reset () {
      this.$refs.filters.initForm()
      this.filters.beginTime = null
      this.filters.endTime = null
      this.createTime = []
      this.pageData.size = 10
      this.pageData.current = 1
      this.$nextTick(() => {
        this.onLoad()
      })
    },
    //搜索条件
    search () {
      this.pageData.current = 1
      if (this.createTime && this.createTime.length > 0) {
        this.filters.beginTime = this.createTime[0]
        this.filters.endTime = this.createTime[1]
      } else {
        this.filters.beginTime = null
        this.filters.endTime = null
      }
      this.onLoad()
    },
    // 获取列表
    async onLoad () {
      this.$store.commit('SET_TABLE_LOADING', true)
      try {
        const { data } = await OPERATOR_API.getOperatorList({ ...this.pageData, ...this.filters })
        this.list = data.records
        this.pageTotal = data.total
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
  },
  mounted () {
    this.onLoad()
  },
}
</script>