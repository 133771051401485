/*
 * @Description: 个人资料
 * @Author: zjc
 * @Date: 2022-04-28 10:29:22
 * @LastEditTime: 2022-04-28 10:36:21
 * @LastEditors: zjc
 */
import request from '@/utils/request'
const personData = (params = {}) => request('get', '/lms/opr/info', params) // 获取个人信息
export const PERSONAL_API = {
  personData
}