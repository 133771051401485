

import request from '@/utils/request'

const getTree = (param) => request('get', '/book/book-categories/tree', param) //获取部门列表树
// const getList = (param) => request('get', '/book/book-info/page', param) //获取
const getDetail = (param) => request('get', '/book/book-info/detail', param) //获取图书目录
const getList = (param) => request('post', '/book/book-info/page', param, {
  requestType: 'JSON'
}) //授权
export const BOOK_API = {
  getTree,
  getList,
  getDetail,
}


