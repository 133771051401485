<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-14 15:28:59
 * @LastEditTime: 2022-04-18 17:32:50
 * @LastEditors: zjc
-->
<template>
  <div class="menu-horizontal">
    <el-tabs v-model="menuIndex" @tab-click="handleClick">
      <el-tab-pane v-for="item in menuList" :key="item.url" :label="item.name" :name="item.url">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { COMMON_API } from '@/utils/common'
export default {
  data () {
    return {
      menuName: [],
    };
  },
  computed: {
    ...mapGetters(['menuList', 'activeIndex']),
    menuIndex () {
      if (COMMON_API.validatenull(this.menuList)) return
      const menuList = COMMON_API.treeToList(this.menuList)
      let authId = null
      let authUrl = null
      for (let i of menuList) {
        if (this.activeIndex === i.url) {
          authId = i.parentId
          for (const j of menuList) {
            if (j.id === authId) {
              authUrl = j.url
              authId = j.parentId
              for (const c of menuList) {
                if (c.id === authId) {
                  authUrl = c.url
                }
              }
            }
          }
        }
      }
      for (const i of menuList) {
        if (i.url === authUrl) {
          this.$store.commit('SET_SUBMENU_LIST', i.children)
        }
      }
      if (this.activeIndex === '/oms/dashboard') {
        this.$store.commit('SET_SUBMENU_LIST', [])
      }
      return authUrl || '/oms/dashboard'
    },

  },
  methods: {
    handleClick (tab, event) {
      for (const menu of this.menuList) {
        if (tab.name === '/oms/dashboard') {
          this.$router.push({ path: '/oms/dashboard' });
        }
        if (menu.url === tab.name) {
          this.$store.commit('SET_SUBMENU_LIST', menu.children)
          const submenu = COMMON_API.filterMenu(menu.children)
          for (const i of submenu) {
            if (Reflect.has(i, 'children')) {
              this.$router.push(i.children[0].url)
              break;
            } else {
              this.$router.push(i.url)
              break;
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-horizontal {
  ::v-deep .el-tabs__nav-wrap::after {
    height: 0px;
    background-color: transparent;
  }
  ::v-deep .el-tabs__item {
    font-size: 16px;
    padding: 0px 16px;
    color: #09172f;
    height: 63px;
    line-height: 63px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: $--color-primary;
  }
  ::v-deep .el-tabs__active-bar {
    height: 4px;
    background-color: $--color-primary;
  }
  ::v-deep .el-tabs__nav-next,
  ::v-deep .el-tabs__nav-prev {
    font-size: 16px;
    color: #ccc;
    width: 30px;
    line-height: 30px;
    text-align: center;
    top: 17px;
  }
  ::v-deep .el-tabs__nav-scroll {
    margin: 0px 20px;
  }
}
</style>