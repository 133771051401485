/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-17 09:11:02
 * @LastEditTime: 2022-05-24 10:00:13
 * @LastEditors: ym ym@geeboo.cn
 */
import request from '@/utils/request'
const getRoleList = (params) => request('post', '/oms/role/page', params) //获取角色列表
const addRole = (params) => request('post', '/oms/role', params, { requestType: 'JSON' }) //新增角色
const updateRole = (params) => request('post', '/oms/role/update', params, { requestType: 'JSON' }) //修改角色
const delRole = (params) => request('post', `/oms/role/delete?roleId=${params.roleId}`, params) //删除角色
const updatePermissions = (params) => request('post', '/oms/role/auths', params, { requestType: 'JSON' }) //修改权限
const getPermissions = () => {
  return request('get', `/oms/auth/tree`)
} //获取所有权限
const getPermissions1 = (params = {}) => {
  return request('get', `/oms/auth/tree/${params.roleId}`, params)
} //获取所有权限
export const ROLE_API = {
  getRoleList,
  addRole,
  updateRole,
  delRole,
  updatePermissions,
  getPermissions,
  getPermissions1
}