<template>
  <basic-container id="fullscreen">
    <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
      size="medium" :rules="tableFormRules">
      <div class="table_one">
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="" prop="title">
          <span class="redTit3">名称：</span>
          <el-input maxlength="20" show-word-limit v-model="tableForm.title" clearable
            placeholder="请输入" style="width: 80%">
          </el-input>
        </el-form-item>
        <div class="goodImgBox">
          <span class="redTit3">封面：</span>
          <div style="display: inline-block; vertical-align: top">
            <img v-if="tableForm.cover" :src="tableForm.cover" class="avatar" style="
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 20px 0 0;
                " />
            <el-upload class="upload-demo" v-if="tableForm.cover" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png,.bmp,.gif" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload"
              style="display: inline-block">
              <el-button size="small" type="primary">更换图片</el-button>
            </el-upload>
            <el-upload v-if="!tableForm.cover" class="avatar-uploader"
              style="display: block; text-align: left" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png,.bmp,.gif" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
          <div class="rightText">
            <span class="atext">支持格式：.jpg、.png、.bmp、.gif ，单个文件不能超过 3MB</span>
          </div>
        </div>
        <!-- <div class="el-form-item__error">
          请选择封面
        </div> -->
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="">
          <span class="redTit3">故事内容：</span>
          <ue-ditor style="display:inline-block;vertical-align: top;width: 80%;" ref="ue"
            :html="ueContent" type="image" />
          <!-- <ue-ditor style="display:inline-block;vertical-align: top;width: 90%;" ref="ue"
          :html="ueContent" type="image" /> -->
        </el-form-item>
        <el-form-item style="width: 89%; margin: 0 auto 20px;" label="" prop="jumpUrl">
          <span class="redTit3">展示机构：</span>
          <el-input @click.native="checkBooks" readonly v-model="chooseObject.orgName" clearable
            placeholder="点击选择" style="width: 70%">
          </el-input>

        </el-form-item>
      </div>
    </el-form>
    <check-book ref="checkBook" @handleSelect="handleSelectBack" type="setPro" :list="tableList" />
    <div style="text-align:right">
      <el-button type="primary" @click="rowSave" :loading="loading">保 存</el-button>
      <el-button @click="beforeClose">取 消</el-button>
    </div>
  </basic-container>
</template>

<script>
import checkBook from './component/checkBook.vue'
import { getStore } from '@/utils/store'
import { DONATION_API } from "@/service/permissions/donationStory";
import ueDitor from "@/components/UEditor";
export default {
  components: { ueDitor, checkBook, },
  data () {
    return {
      //富文本的参数
      ueContent: "",
      chooseObject: {},
      // defalutBook: defalutBook, // 默认图书封面
      selectList: [], //多选数据
      visible: false,
      loading: false,
      sumData: 0, //总和
      // 上传部分的参数----------
      videoFlag: false,
      tableForm: {
        title: "",//民称
        cover: "",//封面
        content: '',//内容
        status: '',
      },
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      // 选择图书

      tableFormRules: {
        title: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
      },
      id: '',
      ibookid: '',//选中的书或者包
      ipackageId: '',
    };
  },
  created () {
    // 注意这里之所以是itemId，是因为我们路由配置中配的是path: '/detail/:itemId'
    // this.row = this.$route.query.row;
    this.iid = this.$route.query.articleId;
  },
  mounted () {
    this.init(this.iid)
  },
  watch: {
    'tableForm.title': function () {
      // var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
      this.tableForm.title = this.tableForm.title
        .replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
    },
  },
  methods: {
    // 初始化
    async init (iid) {
      this.visible = true;
      if (iid) {
        let params = {
          articleId: iid,
        };
        const { data } = await DONATION_API.getDetail(params);
        this.tableForm = { ...data };
        this.ueContent = data.content;
        this.chooseObject.orgId = data.orgId;
        if (data.orgId == 0) {
          this.chooseObject.orgName = '通用机构';
        } else {
          this.chooseObject.orgName = data.orgName;
        }
        console.log(this.chooseObject)
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.ue.ueditor.setContent(this.ueContent, false);
        }, 300);
      });
    },

    //获取去重数据排序
    handleSelectBack (row, type = null) {
      console.log(row)
      // row.forEach(item => { // 多选的时候
      //   this.chooseObject += item.orgName + '、'
      // })
      this.chooseObject = row

      // this.tableForm.jumpUrl = '/pages/bookClassify/bookInfo?bookId=' + this.ibookid
    },

    checkBooks () {
      // this.$refs.checkBook.visible = true;
      let params = {}
      this.$refs.checkBook.init(this.chooseObject)
    },

    beforeClose () {
      // this.$refs["submitForm"].resetFields();
      this.visible = false;
      this.tableList = [];
      this.tableForm = [];
      this.ueContent = "";
      // this.allCnt = 1;
      this.$router.back()
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      this.tableForm.cover = res.data.link
      this.$set(this.tableForm, "cover", res.data.link);
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/bmp" ||
        file.type === "image/jpg";
      // const isJPG =
      //   file.type === "image/gif" ||
      //   file.type === "image/bmp" ||
      //   file.type === "image/png" ||
      //   file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        // .jpg、.png、.bmp、.gif 
        this.$message.error("图片只能是 JPG/PNG/BMP/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    // ----------------------------------------------------------------
    async rowSave () {
      // console.log(this.$refs.ue.getContent())
      if (!this.tableForm.title) {
        this.$message.error("请输入名称");
        return;
      }
      if (!this.tableForm.cover) {
        this.$message.error("请上传封面");
        return;
      }
      if (!this.$refs.ue.getContent()) {
        this.$message.error("请输入故事内容");
        return;
      }
      if (!this.chooseObject.orgName) {
        this.$message.error("请选择展示对象");
        return;
      }

      console.log(this.ueContent)
      let params = {
        orgId: this.chooseObject.orgId,
        orgName: this.chooseObject.orgName,
        title: this.tableForm.title, //名称
        cover: this.tableForm.cover, //封面
        intro: '',
        content: this.$refs.ue.getContent() == '' ? '' : this.$refs.ue.getContent(),
        indexNo: ''
      };
      if (this.iid) {
        params.articleId = this.iid
        const data = await DONATION_API.editList(params);
        if (data.code === 0) {
          this.visible = false;
          this.beforeClose();
          this.$router.push({
            path: "./index",
          });
        }
      } else {
        const data = await DONATION_API.addList(params);
        if (data.code === 0) {
          this.visible = false;
          this.beforeClose();
          this.$router.push({
            path: "./index",
          });
        }
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.custom-ueditor {
  overflow: hidden;
}
.main {
  width: 98.5%;
}
.textMsg {
  border-width: 0px;
  width: 100%;
  padding: 0 0 0 55px;
  height: 24px;
  font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
.ql-editor {
  min-height: 200px;
}
.table_one {
  width: 100%;
  margin: auto;
  text-align: left;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.box_opration {
  // padding: 0 0 0 55px;
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit3 {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    text-align: right;
    padding-right: 13px;
    font-weight: 500;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    // margin: 0 0 15px 0;
    width: 89%;
    // margin: 0 auto 20px;
    justify-content: flex-start;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
#fullscreen ::v-deep .el-form-item__error {
  left: 96px !important;
}
.edui-editor {
  z-index: 0 !important;
}
.btnClass {
  z-index: 0 !important;
}
</style>

