<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: ym
 * @LastEditTime: 2022-08-25 14:27:01
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #tenantId>
              <el-form-item label="租户名称">
                <el-select @change="search" v-model="filters.tenantId" clearable placeholder="请选择">
                  <el-option v-for="item in tenantOptions" :key="item.tenantId"
                    :label="item.tenantName" :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template #applicationId>
              <el-form-item label="应用名称" label-width="100px">
                <el-select @change="search" v-model="filters.applicationId" clearable
                  placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.applicationId"
                    :label="item.applicationName" :value="item.applicationId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template #createTime>
              <el-form-item label="授权时间" label-width="100px">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                  v-model="filters.createTime" type="datetimerange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">授权
          </el-button>
          <!-- <el-button type="danger" size="small" @click="batchDel(1)"
            >批量取消授权
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="expirationDate" slot-scope="scope">
        <el-tag v-if="scope.row.expirationDate === null" type="success" size="small">永久</el-tag>
        <el-tag v-else type="success" size="small">{{
          scope.row.expirationDate
        }}</el-tag>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="renewal(scope.row)">续期</el-button>
        <el-button type="text" size="small" @click="cancel(scope.row)">取消授权</el-button>
        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 授权弹窗 -->
    <el-dialog @closed="closed" title="授权" :visible.sync="dialogVisible" width="800px"
      append-to-body>
      <el-row class="department-name" :gutter="20">
        <el-col :span="10">
          <el-input style="height: 32px" placeholder="请输入租户名称" v-model="pageDataTenant.tenantName"
            class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="searchTenant1"></el-button>
          </el-input>

          <el-table ref="table" :data="tenantList" :row-key="getRowKeys" border
            style="width: 100%; margin-top: 20px" @selection-change="handleSelectionChange">
            <el-table-column :reserve-selection="true" type="selection" width="55">
            </el-table-column>
            <el-table-column prop="tenantName" label="租户名称">
            </el-table-column>
          </el-table>
          <el-pagination style="margin-top: 20px" @size-change="changePageTenant"
            @current-change="handleSizeChangeTenant" :current-page="pageDataTenant.current"
            :page-sizes="[10, 20, 30, 100]" :page-size="pageDataTenant.size"
            :total="pageTotalTenant" layout="sizes, prev, pager, next">
          </el-pagination>
        </el-col>
        <el-col :span="14">
          <div style="width: 90%">
            <el-form style="margin-left: 20px" ref="form" :model="form" label-width="90px">
              <el-form-item label="应用:">
                <el-select v-model="form.applicationId" placeholder="请选择应用"
                  @change="applicationIdChange">
                  <el-option v-for="item in authOptions" :key="item.applicationId"
                    :label="item.applicationName" :value="item.applicationId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="到期时间:">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                  v-model="form.expirationDate" type="datetime" placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
              <el-input style="height: 32px; margin: 20px 0 0 23px; width: 285px"
                placeholder="请输入资源名称" v-model="resourceName" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="searchTenant2"></el-button>
              </el-input>
            </el-form>

            <el-tree ref="treeList" style="margin: 10px 0 0 35px" :data="treeList" show-checkbox
              :props="defaultProps" @check="changeRole" @node-click="handleNodeClick"></el-tree>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 续期 -->
    <el-dialog @closed="renewalClosed" title="续期" :visible.sync="renewalDialogVisible" width="600px"
      append-to-body>
      <el-form ref="forms" :model="renewalForm" label-width="90px">
        <el-form-item label="租户名称：">
          <el-select disabled v-model="renewalForm.tenantName" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用名称：">
          <el-select disabled v-model="renewalForm.applicationNmae" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到期时间:">
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
            v-model="renewalForm.expirationDate" type="datetime" placeholder="选择到期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renewalDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="renewalConfirm()" :loading="renewalLoading">确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog @closed="editClosed" title="编辑" :visible.sync="editDialogVisible" width="600px"
      append-to-body>
      <el-input style="height: 32px; margin: 20px 0 0 23px; width: 285px" placeholder="请输入资源名称"
        v-model="resourceName" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="searchTenant"></el-button>
      </el-input>
      <el-tree ref="treeLists" style="margin: 10px 0 0 35px" :data="treeList" node-key="id"
        show-checkbox :props="defaultProps" @check="changeRole1" @node-click="handleNodeClick"
        :default-checked-keys="checkedRoleIds"></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm()" :loading="editLoading">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { AUTHORIZATION_API } from "@/service/permissions/authorization";
import { LESSEE_API } from "@/service/permissions/lessee";
import { RESOURCE_API } from "@/service/permissions/resource";

const requestData = {
  tenantId: null,
  applicationId: null,
  createTime: [],
  startTime: null,
  endTime: null,
};
export default {
  data () {
    return {
      checkedRoleIds: [],
      loading: false,
      renewalLoading: false,
      editLoading: false,
      applicationId: null, //应用id
      name: null,
      editDialogVisible: false,
      tenantApplicationId: null, //租户应用id
      renewalDialogVisible: false,
      tenantIdList: [], //勾选的租户列表
      resourceIdList: [], //勾选的资源Id
      editResourceIdList: [], //编辑勾选的资源Id
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeList: [],
      form: {
        applicationId: null, //应用id
        expirationDate: null, // 过期时间
      },
      renewalForm: {
        tenantName: null,
        applicationNmae: null,
        expirationDate: null,
      },
      tenantName: "", //租户名称
      resourceName: "", //资源名称
      tenantOptions: [],
      authOptions: [],
      ooooo: "",
      dialogVisible: false,
      type: null,
      pageTotal: 0,
      ruleForm: {
        status: 0,
        ooqwe: 0,
      },
      rules: {},
      title: "新增应用",
      pageData: {
        current: 1,
        size: 10,
      },
      pageDataTenant: {
        current: 1,
        size: 10,
        tenantName: null,
      },
      pageTotalTenant: 0,
      filters: Object.assign({}, requestData),
      //搜索
      searchOption: [
        {
          prop: "tenantId",
          label: "租户名称",
          slot: true,
          type: "select",
          col: 5,
          placeholder: "请输入",
        },
        {
          prop: "applicationId",
          label: "应用名称",
          col: 5,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },
        {
          prop: "createTime",
          label: "授权时间",
          slot: true,
          col: 8,
          type: "select",
          placeholder: "请输入",
        },
      ],
      tenantList: [],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "tenantName", label: "租户名称", tableProp: true },
          { prop: "applicationName", label: "应用名称", tableProp: true },
          {
            prop: "expirationDate",
            label: "到期日期",
            tableProp: true,
            slot: true,
          },
          { prop: "createTime", label: "授权时间", tableProp: true },
        ],
      },
      list: [{}],
    };
  },
  created () {
    this.getSearch();
    this.getData();
  },
  methods: {
    // 复选框选中赋值操作
    handleSelectionChange (rows) {
      this.tenantIdList = [];
      if (rows) {
        rows.forEach((row) => {
          if (row) {
            this.tenantIdList.push(row.tenantId);
          }
        });
      }
    },
    // 行数据的 Key
    getRowKeys (row) {
      return row.tenantId;
    },
    // 编辑
    async edit (item) {
      this.form.applicationId = item.applicationId;
      await this.searchTenant();
      this.tenantApplicationId = item.tenantApplicationId;
      this.applicationId = item.applicationId;
      await AUTHORIZATION_API.getRenewalGrantEd({
        tenantApplicationId: item.tenantApplicationId,
      }).then((res) => {
        if (this.treeList && res.data) {
          const checkedRoleIds = [];
          const treeList = this.getLastTree(this.treeList);
          for (const i of res.data) {
            for (const j of treeList) {
              if (i === j.id) {
                checkedRoleIds.push(j.id);
              }
            }
          }
          this.checkedRoleIds = checkedRoleIds;
        }

      });

      this.editDialogVisible = true;
    },
    // 获取最后一级节点
    getLastTree (arr) {
      const result = [];
      arr.forEach((item) => {
        const loop = (data) => {
          let child = data.children;
          if (child) {
            // 是否有子节点，有则继续遍历下一级，无则是叶子节点
            for (let i = 0; i < child.length; i++) {
              loop(child[i]);
            }
          } else {
            result.push(data);
          }
        };
        loop(item);
      });
      return result;
    },
    // 编辑确认
    editConfirm () {
      this.editLoading = true;
      this.changeRole1();
      AUTHORIZATION_API.getRenewalEditGrant({
        applicationId: this.applicationId,
        resourceIdList: this.editResourceIdList,
        tenantApplicationId: this.tenantApplicationId,
      })
        .then(() => {
          this.editDialogVisible = false;
          this.$ok("操作成功");
          this.getData();
          this.editLoading = false;
        })
        .catch(() => {
          this.editLoading = false;
        });
    },
    editClosed () {
      this.resourceName = null;
      this.treeList = [];
      this.form.applicationId = null;
    },
    handleNodeClick (val) {
      // resourceIdList
      console.log(val, "val");
    },
    // 租户复选
    selectionTenant (val) {
      this.tenantIdList = [];
      this.tenantIdList = val.map((item) => {
        return item.tenantId;
      });
    },
    changeRole () {
      const getTreeId = this.$refs.treeList.getCheckedNodes(false, true);
      this.resourceIdList = getTreeId.map((item) => {
        return item.id;
      });
    },
    //编辑勾选的树形控件
    changeRole1 () {
      const getTreeId = this.$refs.treeLists.getCheckedNodes(false, true);
      this.editResourceIdList = getTreeId.map((item) => {
        return item.id;
      });
    },
    // 确定授权
    confirm () {
      if (!this.tenantIdList.length) {
        this.$message.error("请选择租户");
        return;
      }
      if (!this.form.applicationId) {
        this.$message.error("请选择应用");
        return;
      }
      if (!this.resourceIdList.length) {
        this.$message.error("请选择资源");
        return;
      }
      this.loading = true;
      let obj = this.form;
      obj.tenantIdList = this.tenantIdList;
      obj.resourceIdList = this.resourceIdList;
      AUTHORIZATION_API.getAccredit(obj)
        .then((res) => {
          this.dialogVisible = false;
          this.$ok("操作成功");
          this.getData();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 续期按钮
    renewal (item) {
      this.renewalForm = {
        tenantName: item.tenantName,
        applicationNmae: item.applicationName,
        expirationDate: null,
      };
      this.tenantApplicationId = item.tenantApplicationId;
      this.renewalDialogVisible = true;
    },
    // 确定续期
    renewalConfirm () {
      this.renewalLoading = true;
      let obj = {};
      obj.tenantApplicationId = this.tenantApplicationId;
      obj.expirationDate = this.renewalForm.expirationDate;
      AUTHORIZATION_API.getRenewalGrant(obj)
        .then((res) => {
          this.renewalDialogVisible = false;
          this.$ok("操作成功");
          this.getData();
          this.renewalLoading = false;
        })
        .catch(() => {
          this.renewalLoading = false;
        });
    },
    // 取消授权
    cancel (item) {
      this.$confirm("是否确认取消授权？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          AUTHORIZATION_API.getAccreditCancel({
            tenantApplicationId: item.tenantApplicationId,
          }).then((res) => {
            this.$ok("已取消");
            this.getData();
          });
        })
        .catch(() => {
          //
        });
    },
    // 租客名称搜索
    searchTenant1 () {
      this.pageDataTenant.current = 1;
      this.pageDataTenant.size = 10;
      this.addOrUpdate();
    },
    // 授权选择应用
    applicationIdChange () {
      this.searchTenant();
    },
    // 资源搜索
    searchTenant2 () {
      if (!this.form.applicationId) {
        return;
      }
      this.searchTenant();
    },
    // 搜索租户
    searchTenant () {
      RESOURCE_API.getApplicationIdTree({
        resourceName: this.resourceName,
        applicationId: this.form.applicationId,
      }).then((res) => {
        this.treeList = res.data;
      });
    },
    async getSearch () {
      // 应用
      await AUTHORIZATION_API.getApplication({ isFilterPublic: 1 }).then(
        (res) => {
          this.authOptions = res.data;
          if (this.authOptions.length) {
            this.form.applicationId = this.authOptions[0].applicationId;
          }
        }
      );
      //  租户
      await AUTHORIZATION_API.getTenant().then((res) => {
        this.tenantOptions = res.data;
      });
    },
    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    async getData () {
      let params = this.filters;
      if (this.filters.createTime === null || !this.filters.createTime.length) {
        params.startTime = null;
        params.endTime = null;
      } else {
        params.startTime = this.filters.createTime[0];
        params.endTime = this.filters.createTime[1];
      }
      await AUTHORIZATION_API.getTenantApplicatio({
        ...params,
        ...this.pageData,
      }).then((res) => {
        this.list = res.data.records;
        this.pageTotal = res.data.total;
      });
      await RESOURCE_API.getApplicationIdTree({
        resourceName: this.resourceName,
      }).then((res) => {
        this.treeList = res.data;
      });
    },
    // 授权按钮
    async addOrUpdate () {
      this.searchTenant();
      // this.treeList = [];
      this.dialogVisible = true;
      await LESSEE_API.getTenant({
        ...this.pageDataTenant,
      }).then((res) => {
        this.tenantList = res.data.records;
        this.pageTotalTenant = res.data.total;
      });
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    batchDel (type, item) {
      this.$confirm(`是否确认删除名称为“${item.name}”的数据项？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 租户弹窗分页
    changePageTenant (val) {
      this.pageDataTenant.current = 1;
      this.pageDataTenant.size = val;
      this.addOrUpdate();
    },
    // 租户弹窗分页
    handleSizeChangeTenant (val) {
      this.pageDataTenant.current = val;
      this.addOrUpdate();
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    // 关闭弹窗后的操作
    closed () {
      this.$refs.table.clearSelection();

      this.tenantIdList = []; //勾选的租户列表
      this.resourceIdList = []; //勾选的资源Id
      this.tenantName = ""; //租户名称
      this.resourceName = ""; //资源名称
      this.pageDataTenant.tenantName = null;
      this.treeList = [];
      if (this.authOptions.length) {
        this.form = {
          applicationId: this.authOptions[0].applicationId, //应用id
          expirationDate: null, // 过期时间
        };
      }
    },
    // 关闭续期弹窗
    renewalClosed () { },
  },
};
</script>
<style lang="scss" scoped>
.department-name {
  min-height: 400px;
}
</style>
<style>
.department-name .el-input__inner {
  height: 32px !important;
  line-height: 32px;
}
.department-name .el-form-item {
  margin-bottom: 10px;
}
</style>