/*
 * @Author: qiuwt
 * @Email: 423822728@qq.com
 * @Date: 2022-06-21 09:08:42
 * @Description: 帮助内容
 * @Route: \cloud-library-platform-ui\src\views/operation/classInfoList
<template>

  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters" resetName="重置">
            <template #daterange>
              <el-form-item label="创建时间" label-width="90px">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']" v-model="filters.daterange"
                  type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </template>
            <template #classId>
              <el-form-item label="所属分类" label-width="90px">
                <el-select v-model="filters.classId" placeholder="请选择">
                  <el-option v-for="item in classList" :key="item.classId" :label="item.className"
                    :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="dialogVisible = true">
            新增
          </el-button>
          <el-button type="primary" size="small" :disabled='!selection.length' @click="copyRow">复制内容
          </el-button>
          <el-button type="danger" size="small" :disabled='!selection.length'
            @click="handleDelRows">批量删除
          </el-button>
        </template>
      </form-menu>
    </div>
    <section class="box">
      <el-menu :default-active="menuActive" class="main-nav">
        <h3 class="main-nav_title">
          图书馆应用
        </h3>
        <el-menu-item v-for="item in menu" :key="item.index"
          @click="()=>changeStatus(item.tenantId)" :index="item.tenantId">
          <span slot="title">{{ item.libraryName }}</span>
        </el-menu-item>
      </el-menu>
      <div class="table">
        <v-table ref="table" @handleSelectionChange='handleSelectionChange' :tableData="list"
          :tableOption="tableOption">
          <template slot="indexNo" slot-scope="scope">
            <el-input controls-position="right" :min="0" :max='99999' maxlength='5'
              @input="(e)=>setIndexNo(scope.row,e)" @change="()=>handleIndexNo(scope.row)"
              v-model="scope.row.indexNo" placeholder="请输入"></el-input>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="updateRow( scope.row,1)">详情</el-button>
            <el-button type="text" size="small" @click="updateRow( scope.row)">编辑</el-button>
            <el-button type="text" size="small" style="color: red" @click="batchDel( scope.row)">删除
            </el-button>

          </template>
        </v-table>
        <!-- 分页 -->
        <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
          @hanlePage="handleSizeChange"></pagination>

        <!-- 新增租户 -->
      </div>
    </section>
    <!-- 内容 -->
    <el-dialog @closed="closed('')" :title="title" :visible.sync="dialogVisible" width="900px"
      :close-on-click-modal="isDialogRead" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="所属应用：" prop="tenantId">
          <el-select v-if="!isDialogRead" v-model="ruleForm.tenantId"
            @change="ruleForm.classId = ''" placeholder="请选择">
            <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
              :value="item.tenantId">
            </el-option>
          </el-select>
          <div v-else>
            {{ menu.find(i=>i.tenantId === ruleForm.tenantId).libraryName }}
          </div>
        </el-form-item>
        <el-form-item label="帮助分类：" prop="classId">
          <el-select v-if="!isDialogRead" v-model="ruleForm.classId" placeholder="请选择">

            <el-option v-for="item in dialogClassList" :key="item.classId" :label="item.className"
              :value="item.classId">
            </el-option>
          </el-select>
          <div v-else>
            {{ ruleForm.className }}
          </div>
        </el-form-item>
        <el-form-item label="帮助标题：" prop="helpTitle">
          <el-input v-if="!isDialogRead" v-model="ruleForm.helpTitle" maxlength="30" show-word-limit
            placeholder="请输入"></el-input>
          <div v-else>
            {{ ruleForm.helpTitle }}
          </div>
        </el-form-item>
        <el-form-item label="排序：" prop="indexNo">
          <el-input v-if="!isDialogRead" v-model="ruleForm.indexNo"
            @input="(val)=>setIndexNo(ruleForm,val)" maxlength='5' placeholder="数字越小排序越前">
          </el-input>
          <div v-else>
            {{ ruleForm.indexNo }}
          </div>
        </el-form-item>
        <el-form-item label="链接地址：" prop="linkAddress">
          <el-input v-if="!isDialogRead" v-model="ruleForm.linkAddress" placeholder="请输入">
          </el-input>
          <div v-else>
            {{ ruleForm.linkAddress }}
          </div>
        </el-form-item>
        <p v-if="!isDialogRead"
          style="color: #999; margin-left: 128px;margin-top: 0;margin-bottom: 16px">
          当填写链接后点击标题将直接调整到链接地址，不显示内容。链接格式请以https://开头
        </p>
        <el-form-item label="帮助内容：" prop="helpContent">
          <vue-editor v-if="!isDialogRead" :value.sync="ruleForm.helpContent" placeholder="请输入"
            :isVideo="false">
          </vue-editor>
          <div v-else class="editor-box" v-html="ruleForm.helpContent"> </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!isDialogRead">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="()=>save()" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="复制内容" @closed="closed('copy')" :visible.sync="dialogVisibleCopy" width="40%"
      :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm2" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="所属应用：" prop="tenantId">
          <el-select v-model="ruleForm.tenantId" placeholder="请输入">
            <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
              :value="item.tenantId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="帮助分类：" prop="classId">
          <el-select v-model="ruleForm.classId" placeholder="请输入">
            <el-option v-for="item in dialogClassList" :key="item.classId" :label="item.className"
              :value="item.classId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCopy = false">取 消</el-button>
        <el-button type="primary" @click="()=>saveCopy()" :loading="loadingCopy">确 定</el-button>
      </span>
    </el-dialog>

  </basic-container>
</template>
<script>
import vueEditor from '@/components/vueEditor';
import { COMMON_API } from '@/utils/common';
export default {
  components: { vueEditor },

  data () {
    return {
      loading: false,
      selection: [],
      loadingCopy: false,
      dialogVisible: false,
      dialogVisibleCopy: false,
      isDialogRead: false,
      type: null,
      menu: [],
      menuActive: '',
      classList: [],
      dialogClassList: [],
      pageTotal: 0,
      title: '新增帮助内容',
      pageData: {
        current: 1,
        size: 10,
      },
      filters: {
        daterange: [],
      },
      //搜索
      searchOption: [
        {
          prop: 'helpTitle',
          label: '内容标题',
          type: 'input',
          col: 5,
          placeholder: '请输入',
        },
        {
          prop: 'classId',
          label: '所属分类',
          type: 'select',
          col: 5,
          slot: true,
          placeholder: '请选择',
        },
        {
          prop: 'daterange',
          label: '创建时间',
          col: 8,
          type: 'daterange',
          slot: true,
          placeholder: '请选择',
        },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: 'center',
        sort: true,
        operationData: {
          prop: 'operation',
          label: '操作',
          slot: true,
          operation: true,
          width: 200,
          fixed: 'right',
        },
        column: [
          {
            prop: 'helpTitle',
            label: '内容标题',
            tableProp: true,
          },
          { prop: 'indexNo', label: '排序', width: 100, tableProp: true, slot: true },
          {
            prop: 'className',
            label: '所属分类',
            tableProp: true,
          },
          { prop: 'createTime', label: '创建时间', tableProp: true },
        ],
      },
      list: [],
      ruleForm: {
        tenantId: '',
        classId: '',
        helpTitle: '',
        indexNo: '',
        linkAddress: '',
        helpContent: '',
      },
      ruleFormCopy: {
        tenantId: '',
      },
      rules: {
        classId: [{ required: true, message: '请输入', trigger: 'blur' }],
        tenantId: [{ required: true, message: '请输入', trigger: 'blur' }],
        helpTitle: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    };
  },
  watch: {
    'ruleForm.tenantId' () {
      this.getDialogClassList();
    },
  },
  created () {
    this.getLibInfos();
    this.$fetch(
      'post',
      '/oms/help/categoryDisplay',
      {
        current: 1,
        size: 100,
      },
      {
        requestType: 'application/json',
      }
    ).then((res) => {
      this.classList = res.data.records;
    });
  },
  methods: {
    handleSelectionChange (value) {
      this.selection = value;
    },
    /**
     * 租户
     */
    getLibInfos () {
      this.$fetch('post', '/oms/help/libInfos', {
        current: 1,
        size: 100,
      }).then((res) => {
        this.menu = res.data.records;
        this.menuActive = this.menu[0].tenantId;
        this.getData();
      });
    },
    getDialogClassList () {
      this.$fetch(
        'post',
        '/oms/help/categoryDisplay',
        {
          tenantId: this.ruleForm.tenantId,
          current: 1,
          size: 100,
        },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.dialogClassList = res.data.records;

      });
    },
    changeStatus (e) {
      this.menuActive = e;
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    /**
     * 列表
     */
    getData () {
      const params = Object.assign({}, this.filters);
      if (params.daterange && params.daterange.length) {
        params.beginTime = params.daterange[0];
        params.endTime = params.daterange[1];
      }
      this.$fetch(
        'post',
        '/oms/help/display',
        {
          ...this.pageData,
          ...params,
          tenantId: this.menuActive,
        },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.list = res.data.records;
        this.selection = [];
        this.pageTotal = res.data.total;
      });
    },
    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    closed (val) {
      if (val) this.$refs.ruleForm2.resetFields();
      else this.$refs.ruleForm.resetFields();

      // 清空
      this.dialogVisible = false;
      this.dialogVisibleCopy = false;
      this.title = '新增帮助内容';
      this.isDialogRead = false;
      Object.keys(this.ruleForm).forEach((k) => {
        this.ruleForm[k] = '';
      });
    },
    copyRow () {
      this.dialogVisibleCopy = true;
    },
    /**
     *删除
     */
    batchDel (row) {
      this.$confirm('确认后删除该帮助内容', '提示', {
        type: 'warning',
      }).then(() => {
        this.$fetch(
          'post',
          '/oms/help/infoModify',
          {
            helpId: row.helpId,
            operation: '3',
            tenantId: row.tenantId,
          },
          {
            requestType: 'application/json',
          }
        ).then((res) => {
          this.$message.success(res.msg);
          this.getData();
        });
      });
    },
    handleDelRows () {
      this.$confirm('确认后删除帮助内容', '提示', {
        type: 'warning',
      }).then(() => {
        this.$fetch(
          'post',
          '/oms/help/infoModify',
          {
            helpIds: this.selection.map((i) => i.helpId),
            operation: '3',
            targetTenantId: this.menuActive,
            tenantId: this.menuActive,
          },
          {
            requestType: 'application/json',
          }
        ).then((res) => {
          this.$message.success(res.msg);
          this.getData();
        });
      });
    },
    setIndexNo (ruleForm, e) {
      if (e) {
        const val = String(e).replace(/\D/g, '');
        ruleForm.indexNo = Number(val);
      }
    },
    handleIndexNo (params) {
      this.$fetch(
        'post',
        '/oms/help/infoModify',
        {
          ...params,
          operation: params.classId ? '2' : '1',
        },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.$message.success(res.msg);
        this.list = COMMON_API.dataSort(this.list, 'indexNo');
      });
    },

    /**
     *更新
     */
    updateRow (row, isread) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
      this.isDialogRead = isread;
      if (isread) {
        this.title = '详情';
      } else {
        this.title = '编辑';
      }
    },
    // 确认提交
    save (row) {
      const params = row || this.ruleForm;
      if (!params.helpContent) {
        delete params.helpContent
      }
      this.$refs.ruleForm.validate().then((res) => {
        if (res) {
          this.loading = true;
          this.$fetch(
            'post',
            '/oms/help/infoModify',
            {
              ...params,
              operation: params.helpId ? '2' : '1',
            },
            {
              requestType: 'application/json',
            }
          ).then(
            (res) => {
              this.loading = false;

              this.closed();
              this.$message.success(res.msg);
              this.getData();
            },
            () => {
              this.loading = false;
            }
          );
        }
      });
    },
    /**
     * 复制
     */
    saveCopy () {
      this.$refs.ruleForm2.validate().then((res) => {
        if (res) {
          this.loadingCopy = true;
          this.$fetch(
            'post',
            '/oms/help/infoModify',
            {
              tenantId: this.menuActive,
              targetTenantId: this.ruleForm.tenantId,
              helpIds: this.selection.map((i) => i.helpId),
              operation: '4',
              classId: this.ruleForm.classId,
            },
            {
              requestType: 'application/json',
            }
          ).then(
            (res) => {
              this.loadingCopy = false;
              this.closed();
              this.$message.success(res.msg);
              this.getData();
            },
            () => {
              this.loadingCopy = false;
            }
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  .table {
    width: 100%;
  }
}
.main-nav {
  width: 200px;
  flex: none;
  border: 1px solid #eee !important;
  box-sizing: border-box;
  margin-right: 5px;
  .main-nav_title {
    background: #f5f7fa;
    height: 63px;
    margin: 0;
    line-height: 63px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-menu-item {
    border-bottom: 1px solid #eee;
  }
}
::v-deep .el-date-editor {
  width: 100%;
}
</style>
