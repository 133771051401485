<template>
  <basic-container id="fullscreen">
    <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
      size="medium" :rules="tableFormRules" label-width="125px">
      <div class="table_one">
        <el-form-item style="width: 89%; margin: 0 auto 20px" label="模板名称：" prop="templateName">
          <el-input maxlength="5" show-word-limit v-model="tableForm.templateName" clearable
            placeholder="请输入" style="width: 80%">
          </el-input>
        </el-form-item>
        <div class="goodImgBox">
          <span class="redTit3">扉页图片：</span>
          <div style="display: inline-block; vertical-align: top">
            <img v-if="tableForm.templateImg" :src="tableForm.templateImg" class="avatar" style="
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 20px 0 0;" />
            <el-upload class="upload-demo" v-if="tableForm.templateImg"
              action="/api/file/oss/upload" accept=".jpg,.jpeg,.png" :show-file-list="false"
              multiple :headers="headers" name="file" :on-success="handleNomalSuccess"
              :before-upload="beforeAvatarUpload" style="display: inline-block">
              <el-button size="small" type="primary">更换图片</el-button>
            </el-upload>
            <el-upload v-if="!tableForm.templateImg" class="avatar-uploader"
              style="display: block; text-align: left" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload">
              <i class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
          <div class="rightText">
            <span class="atext">仅可上传jpg、jpeg、png,单个文件不能超过1MB</span>
          </div>
        </div>
        <div class="goodImgBox">
          <span class="redTit3">略缩图：</span>
          <div style="display: inline-block; vertical-align: top">
            <img v-if="tableForm.previewImg" :src="tableForm.previewImg" class="avatar" style="
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 20px 0 0;" />
            <el-upload class="upload-demo" v-if="tableForm.previewImg" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess2" :before-upload="beforeAvatarUpload2"
              style="display: inline-block">
              <el-button size="small" type="primary">更换图片</el-button>
            </el-upload>
            <el-upload v-if="!tableForm.previewImg" class="avatar-uploader"
              style="display: block; text-align: left" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleNomalSuccess2" :before-upload="beforeAvatarUpload2">
              <i class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
          <div class="rightText">
            <span class="atext">仅可上传jpg、jpeg、png</span>
          </div>
        </div>
        <el-form-item label="捐赠对象：" prop="donation" style="width: 89%; margin: 0 auto 20px">
          <el-radio-group v-model="tableForm.donation"  @change="radioChange">
            <el-radio :label="1">所有捐赠对象可用</el-radio>
            <el-radio :label="2">指定捐赠对象可用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择渠道列表：" prop="appId" style="width: 89%; margin: 0 auto 20px"
          v-if="tableForm.donation==2">
          <el-select v-model="tableForm.appId" placeholder="请选择渠道列表" clearable style="width:80%;"
            @change="selectChange">
            <el-option v-for="i in channelList" :key="i.appId" :label="i.appName"
                :value="i.appId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择捐赠对象：" prop="orgId" style="width: 89%; margin: 0 auto 20px"
          v-if="tableForm.appId">
          <el-select v-model="tableForm.orgId" placeholder="请选择捐赠对象" style="width: 80%"
            clearable>
            <el-option v-for="i in channelItem" :key="i.orgId" :label="i.orgName"
              :value="i.orgId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div style="text-align:right">
      <el-button type="primary" @click="rowSave" :loading="loading">保 存</el-button>
      <el-button @click="beforeClose">取 消</el-button>
    </div>
  </basic-container>
</template>

<script>
import { DONATION_API } from "@/service/permissions/donateTemplate";
import { getStore } from '@/utils/store'
// import { BANNER_API } from "@/service/permissions/banner";
import { ROLE_API } from "@/service/permissions/channel";

export default {
  data () {
    return {
      // selectList: [], //多选数据
      // visible: false,
      loading: false,
      // 上传部分的参数----------
      tableForm: {
        templateName: "",
        templateImg: "",
        previewImg: '',
        donation: 1,
        appId: null,
        orgId: null
      },
      channelList: [],
      channelItem: [],
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      //表格配置
      tableList: [],
      tableFormRules: {
        templateName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        donation: [
          { required: true, message: "选择捐赠对象", trigger: "change" },
        ],
        appId: [
          { required: true, message: "选择渠道列表", trigger: "change" },
        ],
        orgId: [
          { required: true, message: "选择捐赠对象", trigger: "change" },
        ],
      },
      id: '',
      // ibookid: '',//选中的书或者包
      // ipackageId: '',
    };
  },
  created () {
    this.iid = this.$route.query.templateId;
  },
  mounted () {
    this.init(this.iid)
  },
  watch: {
    'tableForm.templateName': function () {
      this.tableForm.templateName = this.tableForm.templateName
        .replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
    },
  },
  methods: {
    radioChange (val) {
      this.tableForm.donation = val
      this.tableForm.orgId = null
      this.tableForm.appId = null
    },
    // 获取渠道列表
    async getTheme () {
      const { data } = await ROLE_API.getList({ current: 1, size: 200 });
      this.channelList = data.records;
    },
    // 获取渠道列表
    // async getList () {
    //   const { data } = await DONATION_API.getThemeList({ current: 1, size: 200 });
    //   this.themeList = data.records;
    //   console.log(this.themeList)
    // },
    selectChange () {
      this.tableForm.orgId = null
      this.getDonationList()
    },
    async getDonationList () {
      let params = { current: 1, size: 200 }
      params.appId = this.tableForm.appId
       await ROLE_API.getChannelList(params).then(res => {
        this.channelItem = res.data.records;
      });
    },
    // 初始化
    async init (iid) {
      this.getTheme()
      // this.visible = true;
      if (iid) {

        let params = {
          templateId: iid,
        };
        const { data } = await DONATION_API.detailDonation(params);
        this.tableForm = { ...data,  };
        this.tableForm = { ...data,  donation: this.tableForm.orgId != 0 ? 2 : 1};
        if(this.tableForm.orgId) {
          this.getDonationList()
        }

      } else {

      }
    },

    beforeClose () {
      this.$refs["submitForm"].resetFields();
      // this.visible = false;
      this.tableList = [];
      this.tableForm = [];
      this.$router.back()
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      this.tableForm.templateImg = res.data.link
      this.$set(this.tableForm, "templateImg", res.data.link);
    },
    handleNomalSuccess2 (res, file) {
      console.log(res)
      this.tableForm.previewImg = res.data.link
      this.$set(this.tableForm, "previewImg", res.data.link);
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 1MB!");
      }
      return isJPG && isLt2M;
    },
    beforeAvatarUpload2 (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      // const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG 等格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("图片大小不能超过 1MB!");
      // }
      return isJPG;
    },
    // ----------------------------------------------------------------
    async rowSave () {
      console.log(this.tableForm)
      if (!this.tableForm.templateName) {
        this.$message.error("请输入模板名称");
        return;
      }
      if (!this.tableForm.templateImg) {
        this.$message.error("请上传扉页图片");
        return;
      }
      if (!this.tableForm.previewImg) {
        this.$message.error("请上传缩略图");
        return;
      }
      if (this.tableForm.donation === 2) {
        if (!this.tableForm.appId) {
          this.$message.error("选择渠道列表");
          return;
        }
        if (!this.tableForm.orgId) {
          this.$message.error("请选择捐赠对象");
          return;
        }
      }

      let params = {
        templateName: this.tableForm.templateName, //名称
        templateImg: this.tableForm.templateImg, //封面
        previewImg: this.tableForm.previewImg,
        appId: this.tableForm.appId,
        orgId: this.tableForm.donation ==1? 0: this.tableForm.orgId
      };
      if (this.tableForm.templateId) {//编辑
        params.templateId = this.tableForm.templateId
        const data = await DONATION_API.editDonation(params);
        if (data.code === 0) {
          // this.visible = false;
          this.beforeClose();
          this.$router.push({
            path: "./index",
          });
        }
      } else {//新增
        const data = await DONATION_API.addDonation(params);
        if (data.code === 0) {
          // this.visible = false;
          this.beforeClose();
          this.$router.push({
            path: "./index",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.main {
  width: 98.5%;
}
.textMsg {
  border-width: 0px;
  width: 100%;
  padding: 0 0 0 55px;
  height: 24px;
  font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
.ql-editor {
  min-height: 200px;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.box_opration {
  // padding: 0 0 0 55px;
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit3 {
    width: 125px !important;
    font-size: 14px;
    color: #606266;
    display: inline-block;
    text-align: right;
    padding-right: 13px;
    font-weight: 500;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    // margin: 20px 0 15px 0;
    width: 89%;
    margin: 30px auto 20px;
    justify-content: flex-start;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
