<!--
 * @Description: 部门管理
 * @Author: zjc
 * @Date: 2021-12-27 10:51:43
 * @LastEditTime: 2022-07-05 14:51:41
 * @LastEditors: zjc
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="batchDel(1)"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange">
      <template slot="parentId" slot-scope="scope">
        <el-tag v-if="scope.row.type === 1">顶部菜单</el-tag>
        <el-tag v-else-if="scope.row.type === 2" type="success">左侧菜单</el-tag>
        <el-tag v-else type="info">按钮</el-tag>
      </template>
      <template slot="isHide" slot-scope="scope">
        {{ scope.row.isHide === 0 ? "否" : "是" }}
      </template>

      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="add(scope.row)">新增子项</el-button>
        <el-button type="text" size="small" style="color: red" @click="batchDel(2, scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 新增菜单 -->
    <el-dialog @closed="closed" :title="title" :visible.sync="dialogVisible" width="30%"
      append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="上级部门：">
          <el-cascader v-model="ruleForm.parentDeptId" ref="cascader" :disabled="disabled"
            :options="optionList" :show-all-levels="false" :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              checkStrictly: true,
            }" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="部门编码：" prop="deptCode">
          <el-input v-model="ruleForm.deptCode" placeholder="请输入部门编码"></el-input>
        </el-form-item>
        <el-form-item label="部门名称：" prop="deptName">
          <el-input v-model="ruleForm.deptName" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人：">
          <el-input v-model="ruleForm.director" placeholder="请输入负责人"></el-input>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="ruleForm.indexNo" type="number" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ruleForm.remark">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { DEPT_API } from "@/service/permissions/dept";
const formData = {
  deptId: null,
  deptName: null,
  deptCode: null,
  parentDeptId: null,
  director: null,
  indexNo: 0,
  remark: null,
};
export default {
  data () {
    return {
      loading: false,
      //搜索
      searchOption: [
        {
          prop: "deptName",
          label: "部门名称",
          type: "input",
          placeholder: "请输入",
        },
        {
          prop: "deptCode",
          label: "部门编码",
          type: "input",
          placeholder: "请输入",
        },
      ],
      filters: {},
      list: [{}],
      optionList: [{}],
      type: null,
      disabled: false,
      dialogVisible: false,
      ruleForm: Object.assign({}, formData),
      rules: {
        deptCode: [
          { required: true, message: "请输入部门编码", trigger: "blur" },
        ],
        deptName: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
        ],
      },
      title: "新增部门",
      // 表格查询条件配置
      tableOption: {
        align: "left",
        selection: true,
        sort: true,
        getRowKey (row) {
          return row.id;
        },
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "name", label: "部门名称", tableProp: true, align: "left" },
          { prop: "deptCode", label: "部门编码", tableProp: true },
          { prop: "indexNo", label: "排序", tableProp: true },
          {
            prop: "createTime",
            label: "创建时间",
            tableProp: true,
            align: "left",
          },
        ],
      },
      btnEdit: true,
      formEdit: true,
      formAdd: true,
      formStatus: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      labelPosition: "right",
      form: {
        parentDeptId: null,
        parentDeptName: null,
        orgCode: null,
        deptName: null,
        director: null,
        indexNo: 0,
        remark: null,
      },
      // rules: {
      //   orgCode: [{ required: true, message: "必填！", trigger: "blur" }],
      //   deptName: [{ required: true, message: "必填！", trigger: "blur" }],
      //   director: [{ required: true, message: "必填！", trigger: "blur" }],
      // },
      currentDeptName: null,
      currentId: 0,
    };
  },
  created () {
    this.getData();
    this.getList();
  },
  mounted () {
    // this.getTreeList();
  },
  methods: {
    getList () {
      DEPT_API.getTree(this.filters).then((res) => {
        console.log(res.data)
        this.optionList = res.data;
      });
    },
    getData () {
      DEPT_API.getTree(this.filters).then((res) => {
        console.log(res.data)
        this.list = res.data;
      });
    },
    search () {
      this.getData();
    },
    reset () {
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    addOrUpdate (type, item) {
      console.log(type, item)
      // console.log(this.ruleForm)
      // console.log('id', item.id)
      // console.log('')
      this.type = type;
      if (type === 2) {
        this.title = "编辑部门";
        this.ruleForm.deptId = item.id;
        DEPT_API.getDeptDetail(item.id).then((res) => {
          // console.log(res)
          this.ruleForm = Object.assign({}, res.data);
          console.log(res, "res");
        });
      } else {
        this.title = "新增部门";
      }
      this.dialogVisible = true;
    },
    batchDel (type, item) {
      this.$confirm(`是否确认删除名称为“${item.name}”的数据项?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await DEPT_API.delDept({
            deptId: item.id,
          });
          this.$ok("删除成功");
          this.getData();
        })
        .catch(() => {
          //
        });
    },
    selectionChange () { },
    // 新增子项
    add (item) {
      this.ruleForm.parentDeptId = item.id;
      this.type = 1;
      this.disabled = true;
      this.dialogVisible = true;
    },
    closed () {
      this.loading = false;
      this.ruleForm = Object.assign({}, formData);
      this.disabled = false;
      this.$refs.ruleForm.resetFields();
    },
    save (formName) {
      console.log(this.ruleForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$refs.cascader.getCheckedNodes().length) {
            this.ruleForm.parentDeptId =
              this.$refs.cascader.getCheckedNodes()[0].value;
          } else {
            this.ruleForm.parentDeptId = 0;
          }
          this.loading = true;
          if (this.type === 1) {
            DEPT_API.addDept(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("新增成功");
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            console.log(this.ruleForm)
            DEPT_API.updateDept(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("编辑成功");
                this.getData();
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getTreeList () {
      const { data } = await DEPT_API.getTree();
      this.treeData = data;
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    async getNodeData (obj) {
      this.btnEdit = false;
      if (!this.formEdit) {
        this.formEdit = false;
        this.formStatus = "update";
      }
      const { data } = await DEPT_API.getDeptDetail(obj.id);
      console.log(data, "数据");
      this.form = data;
      this.currentId = obj.id;
      this.currentDeptName = obj.deptName;
    },
    handlerEdit () {
      this.formEdit = false;
      this.formStatus = "update";
    },
    handlerAdd () {
      this.resetForm();
      this.formEdit = false;
      this.formStatus = "create";
    },
    handleDelete () {
      this.$confirm("是否要删除部门?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { data } = await DEPT_API.delDept({ deptId: this.currentId });
        this.$ok("删除成功");
        this.getTreeList();
        this.resetForm();
        this.onCancel();
      });
    },
    async update () {
      const data = await DEPT_API.updateDept(this.form);
      this.$ok(data.msg);
      this.getTreeList();
    },
    async create () {
      const data = await DEPT_API.addDept(this.form);
      this.$ok(data.msg);
      this.getTreeList();
    },
    onCancel () {
      this.formEdit = true;
      this.formStatus = "";
    },
    resetForm () {
      this.form = {
        parentDeptId: this.currentId,
        parentDeptName: this.currentDeptName,
        orgCode: null,
        deptName: null,
        director: null,
        indexNo: 0,
        remark: null,
      };
    },
  },

};
</script>
<style lang="scss" scoped>
</style>