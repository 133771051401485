<template>
  <div>
    <el-input
      v-model="values"
      placeholder="请选择"
      clearable
      @focus="focus"
    ></el-input>
    <el-dialog
      :title="category == 1 ? '捐赠对象' : '捐赠机构'"
      modal-append-to-body
      append-to-body
      width="900px"
      :visible.sync="dialogVisible"
      top="10vh"
      :close-on-click-modal="false"
      @closed="closed"
    >
      <el-input
        style="width: 240px; margin-right: 12px"
        v-model="orgName"
        :placeholder="category == 1 ? '捐赠对象' : '捐赠机构'"
        clearable
      ></el-input>
      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="searchChange"
        >搜索
      </el-button>
      <el-button size="small" icon="el-icon-refresh" @click="reset"
        >重置</el-button
      >
      <div v-loading="loading" style="margin-top: 10px">
        <v-table
          ref="table"
          :tableData="objectList"
          :tableOption="category == 1 ? tableOption : tableOption1"
        >
          <template slot="radio" slot-scope="scope">
            <el-radio v-model="orgRadio" :label="scope.row"><i /></el-radio>
          </template>
        </v-table>
      </div>

      <!-- 分页 -->
      <pagination
        :pageData="pageData"
        :total="pageTotal"
        @hanleSize="changePage"
        @hanlePage="handleSizeChange"
      ></pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DONATION_API } from "@/service/permissions/donation";
import { ROLE_API } from "@/service/permissions/channel";
export default {
  props: {
    category: {
      type: [Number, String],
      default: 1, // 1捐赠对象 2捐赠机构
    },
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.values = "";
      }
    },
  },
  data() {
    return {
      loading: false,
      orgRadio: {},
      values: "",
      orgName: "",
      pageTotal: 0,
      dialogVisible: false,
      objectList: [],
      pageData: {
        current: 1,
        size: 10,
      },
      // 表格查询条件配置
      tableOption: {
        column: [
          {
            prop: "radio",
            label: "选择",
            tableProp: true,
            slot: true,
            width: 100,
          },
          {
            prop: "orgName",
            label: "捐赠对象",
            tableProp: true,
            tooltip: true,
          },
        ],
      },
      tableOption1: {
        column: [
          {
            prop: "radio",
            label: "选择",
            tableProp: true,
            slot: true,
            width: 100,
          },
          {
            prop: "orgName",
            label: "捐赠机构",
            tableProp: true,
            tooltip: true,
          },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.orgName = "";
      this.pageData.size = 10;
      this.searchChange();
    },
    searchChange() {
      this.pageData.current = 1;
      this.getObjectList();
      this.orgRadio = {};
    },
    closed() {
      this.orgName = "";
      this.orgRadio = {};
      this.objectList = [];
      this.pageTotal = 0;
      this.pageData.current = 1;
      this.pageData.size = 10;
    },
    focus() {
      this.dialogVisible = true;
      this.getObjectList();
    },
    changePage(val) {
      this.pageData.current = val;
      this.getObjectList();
    },
    handleSizeChange(val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getObjectList();
    },
    // 获取捐赠对象列表
    async getObjectList() {
      try {
        this.loading = true;
        const { data } =
          this.category == 1
            ? await DONATION_API.getObjectList({
                ...this.pageData,
                orgName: this.orgName,
              })
            : await ROLE_API.getChannelList({
                ...this.pageData,
                orgName: this.orgName,
              });
        this.loading = false;
        this.objectList = data.records;
        let s = "通用机构";
        if (
          (this.category == 2 && !this.orgName) ||
          (this.category == 2 && s.indexOf(this.orgName) > -1)
        ) {
          if (this.pageData.current == 1) {
            this.objectList.unshift({
              orgName: "通用机构",
              orgId: "0",
            });
          }

          this.pageTotal = data.total + 1;
        } else {
          this.pageTotal = data.total;
        }
      } catch (err) {
        this.loading = false;
        console.log(err, "err123");
      }
    },
    confirm() {
      if (this.orgRadio == null || this.orgRadio.orgId == undefined) {
        this.$message.error(
          this.category == 1 ? "请先选择捐赠对象" : "请先选择捐赠机构"
        );
      } else {
        this.values = this.orgRadio.orgName;
        this.dialogVisible = false;
        this.$emit("input", this.orgRadio.orgId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>