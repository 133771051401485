<template>
  <el-dialog modal-append-to-body title="图书详情" width="1250px" @close="beforeClose" append-to-body
    :close-on-click-modal="false" top="4vh" :visible.sync="visible" class="bookInfo">
    <!-- <div class="box_tabs">
      <span class="black"></span>
      <el-tabs @tab-click="handleClick" v-model="activeName">
        <el-tab-pane style="margin:0 300px 0 0" label="图书详情" name="first"></el-tab-pane>
        <el-tab-pane label="关联套装" name="third">
        </el-tab-pane>
      </el-tabs>
    </div> -->
    <el-row v-show="activeName == 'first'">
      <el-col :span="3">
        <div class="box_img">
          <img :src="tableForm.bookCover?tableForm.bookCover:defaultBook" alt="">
        </div>
      </el-col>
      <el-col :span="21">
        <div class="descriptions">
          <div class="descriptions-title">图书名：
            <span>{{tableForm.bookName}}</span>
          </div>
          <div class="descriptions-detile">
            <div class="descriptions-item">
              <label class="itemLabel" for="">作者：</label>
              <span>{{tableForm.bookAuthor}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">ISBN：</label>
              <span style="margin-top:4px">{{tableForm.isbn}}</span>
            </div>
            <div class="descriptions-item ">
              <label for="">分类：</label>
              <span>{{tableForm.categoryName}}</span>
            </div>
            <div class="descriptions-item">
              <label for="">出版社：</label>
              <span>{{tableForm.pressName}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">总章节：</label>
              <span style="margin-top:4px">{{tableForm.chapterTotal}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">总字数：</label>
              <span style="margin-top:4px">{{tableForm.wordTotal}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">是否富媒体：</label>
              <span v-if="tableForm.isRichMedia ==1">是</span>
              <span v-else>否</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">是否丛书：</label>
              <span v-if="tableForm.isSeries ==1">是</span>
              <span v-else>否</span>
            </div>
          </div>
          <div class="descriptions-desc"
            style="border-bottom: 1px solid #f2f2f2;padding: 0 0 20px 0;margin:0 0 20px 0">
            <label for="">图书简介：</label>
            <span>{{tableForm.bookIntro}}</span>
          </div>
          <div class="descriptions-detile">
            <div class="descriptions-item descriptions-item2">
              <label for="">出版日期：</label>
              <span style="margin-top:4px">{{tableForm.publishTime}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">定价：</label>
              <span style="margin-top:4px">{{tableForm.pressPrice}}</span>
            </div>
            <div class="descriptions-item">
              <label for="">语种：</label>
              <span v-if="tableForm.language == 1">中文</span>
              <span v-else-if="tableForm.language == 2">英语</span>
              <span v-else-if="tableForm.language == 3">法语</span>
              <span v-else-if="tableForm.language == 4">日语</span>
              <span v-else-if="tableForm.language == 5">德语</span>
              <span v-else-if="tableForm.language == 6">俄语</span>
              <span v-else-if="tableForm.language == 7">西班牙语</span>
              <span v-else-if="tableForm.language == 8">葡萄牙语</span>
              <span v-else-if="tableForm.language == 9">阿拉伯语</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">电子出版物发行时间：</label>
              <span style="margin-top:4px">{{tableForm.firstPublishTime}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">版权持有：</label>
              <span>{{tableForm.copyrightHolder}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">电子出版物发行单位：</label>
              <span>{{tableForm.electronicPressName}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">电子出版物发行网站：</label>
              <span style="margin-top:4px">{{tableForm.pressDomain}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">电子出版物发行数量：</label>
              <span style="margin-top:4px">{{tableForm.totalPublishCnt}}</span>
            </div>
            <div class="descriptions-item descriptions-item2">
              <label for="">电子出版物发行批次：</label>
              <span style="margin-top:4px">{{tableForm.totalBatchCnt}}</span>
            </div>
          </div>
        </div>

      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button type="primary" @click="rowSave" :loading="loading">确 定</el-button> -->
      <el-button @click="beforeClose">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import { detailBook } from "@/api/book/bookList";
// import { defaultBook } from '@/enums/bookManager'
import { BOOK_API } from "@/service/permissions/book";
import { defaultBook } from '@/enums/default'
export default {
  // components: { bookInfoFirst, bookInfoScond, bookInfoThird },
  data () {
    return {
      defaultBook: defaultBook,// 默认图书封面
      tableForm: {},
      activeName: 'first',
      visible: false,
      loading: false,
      tabActive: 0,
      tableList: [],
      iid: '',
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10
      },
      //表格配置
      tableOption: {
        selection: true,
        align: 'center',
        // props: 'createTime',
        order: '',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 200, fixed: 'right', align: 'center' },
        column: [
          { prop: 'book', label: '套装名称', tableProp: true, slot: true, width: 200 },
          { prop: 'packageDetail', label: '套装简介', tableProp: true },
          { prop: 'copyCnt', label: '本书复本数（册）', tableProp: true },
          { prop: 'packageStatus', label: '套装状态', tableProp: true, slot: true },
        ],
      },
    }
  },

  methods: {
    // async getList () {
    //   this.$store.commit('SET_TABLE_LOADING', true)
    //   try {
    //     let params = {}
    //     params.current = this.pageData.current
    //     params.size = this.pageData.size
    //     params.bookId = this.iid
    //     // const { data } = await getCollect(params)
    //     // console.log(data.records)
    //     // this.tableList = data.records
    //     // this.pageTotal = data.total
    //     this.$store.commit('SET_TABLE_LOADING', false)
    //   } catch (err) {
    //     this.$store.commit('SET_TABLE_LOADING', false)
    //   }
    // },
    // handleClick (tab, event) {
    //   console.log(event);
    //   console.log(tab)
    // },
    beforeClose () {
      this.visible = false
      this.tableForm = []
      this.activeName = 'first'
    },
    // 表格勾选框改变
    selectionChange (val) {
      this.selectBook = val
    },

    async init (id) {
      let params = {}
      params.bookId = id
      this.iid = id
      const { data } = await BOOK_API.getDetail(params)
      console.log(data)
      this.tableForm = data

    },
    // changePage (val) {
    //   this.pageData.current = val
    //   this.getList()
    // },
    // handleSizeChange (val) {
    //   this.pageData.current = 1
    //   this.pageData.size = val
    //   this.getList()
    // },

  }
}
</script>
<style  lang="scss" >
.bookInfo {
  .el-menu--horizontal {
    border-bottom: solid 1px #e6e6e6 !important;
  }
  .box_tabs {
    position: relative;
    padding: 0 0 0 20px;
  }
  .black {
    position: absolute;
    top: 10px;
    left: 0;
    width: 10px;
    height: 23px;
    background-position: inherit;
    background-size: inherit;
    background-repeat: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: rgb(85, 85, 85);
    border: none;
    border-radius: 0px;
  }
  .el-tabs__nav-wrap::after {
    background-color: #ffffff !important;
  }
  .box_img {
    margin: 20px 0 0 0;
    img {
      width: 101px;
      height: 147px;
    }
  }
  .box_img2 {
    display: flex;
    // margin: 30px 0 0 0;
    flex-wrap: wrap;
    span {
      width: 100%;
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
      font-weight: bold;
    }
    img {
      margin: 10px 0 0 0;
      width: 101px;
      height: 147px;
    }
  }
  .descriptions {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    width: 90%;
    margin: 20px 0 0 20px;

    .descriptions-title {
      width: 100%;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .descriptions-detile {
      margin: 0px auto 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      padding-right: 2px;

      .descriptions-item {
        margin: 0 0 25px 0;
        width: 33.33%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        // .itemLabel {
        //   display: inline-block;
        //   text-align: justify;
        //   text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
        //   text-align-last: justify;
        //   width: 56px;
        // }
        label {
          text-align: justify;
          font-weight: 700;
          height: 100%;
        }
        span {
          display: inline-block;
          font-weight: 400;
          // margin: 10px auto 0;
          height: 100%;
        }
      }
    }

    .descriptions-desc {
      width: 100%;
      label {
        font-weight: 700;
      }
      span {
        line-height: 30px;
        font-weight: 400;
      }
    }
  }
}
</style>