/*
 * @Description: 密码策略
 * @Author: zjc
 * @Date: 2022-07-07 14:43:27
 * @LastEditTime: 2022-07-07 14:51:43
 * @LastEditors: zjc
 */
import request from '@/utils/request'
const policyList = (params) => request('post', '/oms/password-policy/page', params, { requestType: 'JSON' })//密码策略列表
const updatePolicy = (params) => request('post', '/oms/password-policy/update', params, { requestType: 'JSON' }) //更新密码策略
const addPolicy = (params) => request('post', '/oms/password-policy', params, { requestType: 'JSON' }) //新增密码策略
const delPolicy = (params) => request('post', '/oms/password-policy/delete', params, { requestType: 'JSON' }) //删除密码策略
export const PWD_API = {
  policyList,
  updatePolicy,
  delPolicy,
  addPolicy
}