/*
 * @Description:getters
 * @Author: zjc
 * @Date: 2021-12-07 11:52:09
 * @LastEditTime: 2022-04-28 15:35:00
 * @LastEditors: zjc
 */
import { getStore } from '@/utils/store'
const getters = {
  //user
  userId: state => state.user.userId,
  menuList: state => state.user.menuList,
  subMenuList: state => state.user.subMenuList,
  name: state => state.user.name,
  token: state => state.user.token,
  libraryInfo: state => state.user.libraryInfo,

  //tabs
  options: state => state.tabs.options,

  //common
  isCollapse: state => state.common.isCollapse,
  tableLoading: state => state.common.tableLoading,
  title: state => state.common.title,
  isScreen: state => state.common.isScreen,
  activeIndex: state => state.common.activeIndex,
  isFullScreen: state => state.common.isFullScreen,
  contentScreen: state => state.common.contentScreen,
  crumbsList: state => state.common.crumbsList,

  // permissions 
  routers: state => state.permissions.routers,
  addRouters: state => state.permissions.addRouters,
}
export default getters