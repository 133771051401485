<!--
 * @Description: 新增、修改组件
 * @Author: zjc
 * @Date: 2021-12-10 13:44:10
 * @LastEditTime: 2022-05-12 10:08:22
 * @LastEditors: zjc 
-->
<template>
  <el-dialog modal-append-to-body :title="dialogData.title" :width="option.dialogWdith"
    @close="beforeClose" append-to-body :close-on-click-modal="false" :top="option.top || '10vh'"
    :visible.sync="visible">
    <el-form ref="submitForm" class="modal" :model="tableForm" :size="option.size"
      :label-width="option.labelWidth" :rules="tableFormRules">
      <el-row :gutter="option.gutter || 0">
        <template v-for="(item,index) in formOption">
          <el-col :span="item.span || 24" :key="`${index}`">
            <!-- input -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='input'">
              <el-input @input="handleInput" v-model.trim="tableForm[item.prop]"
                :placeholder="item.placeholder || '请输入'" :type="item.inputType" :rows="item.rows"
                :clearable="option.clearable" :disabled="item.isDisabled"
                :maxlength="item.maxLength" :show-word-limit="item.limit">
                <template v-if="item.append" slot="append">{{item.slotName}}</template>
              </el-input>
            </el-form-item>

            <!-- select -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='select'" class="select_form_item">
              <el-select @change="selectChange" v-model="tableForm[item.prop]"
                :filterable="item.filterable" :placeholder="item.placeholder || '请选择'"
                style="width: 100%;" :clearable="option.clearable">
                <el-option v-for="i in item.selectList" :key="i.value" :label="i.label"
                  :value="i.value"></el-option>
              </el-select>
            </el-form-item>

            <!-- radio -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='radio'">
              <el-radio-group v-model="tableForm[item.prop]" @change="radioChange">
                <el-radio v-for="(i,index) in item.radioList" :key="index" :label="i.value"
                  :border="item.border">
                  {{i.label}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- checkBox -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='checkbox'">
              <el-checkbox-group v-model="tableForm[item.prop]" @change="checkboxChange">
                <el-checkbox v-for="(i,index) in item.checkboxList" :label="i.value" :key="index"
                  :border="item.border">
                  {{i.label}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <!-- date -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='date'">
              <el-date-picker @change="dateChange" v-model="tableForm[item.prop]"
                :type="item.inputType" :placeholder="item.placeholder || '请选择'"
                :clearable="option.clearable" :value-format="item.valueFormat"
                :format="item.format"></el-date-picker>
            </el-form-item>

            <!-- time -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='time'">
              <el-time-picker @change="timeChange" :arrow-control="item.arrow"
                v-model="tableForm[item.prop]" :size="option.size" value-format="HH:mm:ss"
                :picker-options="item.selectableRange" :placeholder="item.placeholder || '请选择时间'">
              </el-time-picker>
            </el-form-item>

            <!-- textarea -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='textarea'">
              <el-input type="textarea" :rows="3" :placeholder="item.placeholder || '请输入内容'"
                v-model="tableForm[item.prop]" :maxlength="item.maxLength"
                :show-word-limit="item.limit">
              </el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="medium">取消</el-button>
      <el-button type="primary" @click="rowUpdate" size="medium" :loading="loading"
        v-if="dialogData.id">确定</el-button>
      <el-button type="primary" @click="rowSave" size="medium" :loading="loading"
        v-if="!dialogData.id">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  /**
   * @param {Object} dialogData 弹窗对象 有id title showDialog
   * @param {Object} formData 表单新增、修改数据 
   * @param {Object} option 新增、修改配置
   *          -- dialogWdith 弹窗宽度
   *          -- top 弹窗距离顶部位置
   *          -- labelWidth 表单文字宽度
   *          -- gutter 栅格间隔
   *          -- size 尺寸
   *          -- clearable 是否显示清除按钮
   *       column  表单配置
   *          prop 字段名
   *          label 名称
   *          width 名称宽度
   *          placeholder 占位
   *          type 标签type input select 等等
   *          inputType 输入框类型
   *          rules  表单校验
   */
  props: {
    dialogData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    option: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      tableForm: {},
      visible: false,
      tableFormRules: {},
      type: '',
      loading: false,
    }
  },
  computed: {
    formOption () {
      if (this.dialogData.id) {
        return this.option.column.filter((item) => !item.hasOwnProperty('modalHide'))
      } else {
        return this.option.column.filter((item) => !item.hasOwnProperty('edit'))
      }
    },
  },
  watch: {
    tableForm: {
      deep: true,
      handler (val) {
        this.$emit('update:form', Object.assign(val))
      }
    },
  },
  methods: {
    // 表单字段初始化 检验规则初始化
    init () {
      let tableForm = {}
      for (let i of this.formOption) {
        if (i.type === 'checkbox' || i.type === 'cascader' || i.type === 'datetimerange') {
          tableForm[i.prop] = []
        } else if (i.type === 'number') {
          tableForm[i.prop] = 0
        } else {
          tableForm[i.prop] = ''
        }
        if (i.rules) {
          this.tableFormRules[i.prop] = i.rules
        }
      }
      this.tableForm = tableForm
      if (!this.$validatenull(this.dialogData.id)) {
        this.tableForm = this.formData
      }
    },
    // 新增
    rowSave () {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.$emit('row-save', Object.assign({}, this.tableForm))
        }
      })
    },
    // 修改 
    rowUpdate () {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.$emit('row-update', Object.assign({}, this.tableForm))
        }
      })
    },
    beforeClose () {
      this.visible = false
      this.dialogData.showDialog = false
      this.$refs['submitForm'].resetFields()
    },
    // radio
    radioChange (val) {
      this.$emit('radio', val)
    },
    // select
    selectChange (val) {
      this.$emit('select', val)
    },
    // date
    dateChange (val) {
      this.$emit('date', val)
    },
    // time
    timeChange (val) {
      this.$emit('time', val)
    },
    // checkBox
    checkboxChange (val) {
      this.$emit('checkbox', val)
    },
    // input
    handleInput (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    this.visible = this.dialogData.showDialog
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.modal {
  el-date-editor--month > .el-input__inner {
    border: 1px solid #dcdfe6 !important;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
}
</style>