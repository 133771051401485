<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:51:15
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="batchDel(1)"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="applicationIcon" slot-scope="scope">
        <img v-if="scope.row.applicationIcon" style="width: 50px; height: 50px; object-fit: cover"
          :src="scope.row.applicationIcon" alt="" />
      </template>

      <template slot="isPublic" slot-scope="scope">
        <el-tag size="small">{{
          scope.row.isPublic === 0 ? "否" : "是"
        }}</el-tag>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="applyUrl(scope.row)">应用资源配置</el-button>
        <el-button type="text" size="small" style="color: red" @click="batchDel(2, scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
    <!-- 新增租户 -->
    <el-dialog @closed="closed" :title="title" :visible.sync="dialogVisible" width="30%"
      append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px"
        class="demo-ruleForm">
        <el-form-item label="应用名称：" prop="applicationName">
          <el-input v-model="ruleForm.applicationName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="应用图标：" prop="applicationIcon">
          <el-upload ref="uploadRef" action="/api/file/oss/upload" accept=".jpg,.jpeg,.png,.gif"
            list-type="picture-card" name="file" :data="listName" :headers="headers"
            :on-remove="handleRemove" :on-change="handleFileChange" :on-success="handleSuccess"
            :on-error="handleError" :before-upload="beforeAvatarUpload" :limit="limitImgNumber"
            :file-list="fileList" :class="{ hide: uploadDisabled }" class="imgBox">
            <i class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="排序：" prop="indexNo">
          <el-input v-model="ruleForm.indexNo" type="number"></el-input>
        </el-form-item>
        <el-form-item label="是否公共应用：">
          <el-radio-group v-model="ruleForm.isPublic">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应用描述：" prop="applicationDesc">
          <el-input type="textarea" :rows="2" placeholder="请输入" v-model="ruleForm.applicationDesc">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { APPLY_API } from "@/service/permissions/apply";
import { getStore } from "@/utils/store";

export default {
  data () {
    return {
      loading: false,
      ooooo: "",
      dialogVisible: false,
      type: null,
      ruleForm: {
        applicationId: null,
        applicationDesc: null,
        isPublic: 0,
        indexNo: 0,
        applicationName: null,
        applicationIcon: null,
      },
      rules: {
        applicationName: [
          { required: true, message: "请输入应用名称", trigger: "blur" },
        ],
      },
      title: "新增应用",
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      fileList: [],
      // 允许上传图片的数量
      limitImgNumber: 1,
      // 控制是否显示图片上传+号
      uploadDisabled: false,
      headers: {
        Authorization: "Bearer" + getStore({ name: "userToken" }),
      },
      filters: {},
      //搜索
      searchOption: [
        {
          prop: "applicationName",
          label: "应用名称",
          type: "input",
          placeholder: "请输入",
        },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          {
            prop: "applicationName",
            label: "应用名称",
            tableProp: true,
            width: 120,
          },
          {
            prop: "applicationIcon",
            label: "应用图标",
            tableProp: true,
            slot: true,
          },
          { prop: "isPublic", label: "公共应用", tableProp: true, slot: true },
          { prop: "indexNo", label: "排序", tableProp: true, width: 150 },
          {
            prop: "createTime",
            label: "创建时间",
            tableProp: true,
          },
        ],
      },
      list: [],
    };
  },
  created () {
    this.getData();
  },
  methods: {
    // 图片删除
    handleRemove (file, fileList) {
      this.ruleForm.applicationIcon = "";
      this.uploadDisabled = false;
    },
    // 判断是否显示添加图片的icon
    handleFileChange (file, fileList) {
      this.uploadDisabled = fileList.length >= this.limitImgNumber;
    },
    // 上传成功
    handleSuccess (file, fileList) {
      this.ruleForm.applicationIcon = file.data.fileName;
    },
    handleError (file) {
      this.$message({
        message: "上传失败,请重新上传",
        type: "warning",
      });
    },
    // 图片上传
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    getData () {
      APPLY_API.getApplication({ ...this.filters, ...this.pageData }).then(
        (res) => {
          this.list = res.data.records;
          this.pageTotal = res.data.total;
        }
      );
    },
    addOrUpdate (type, item) {
      this.type = type;
      if (type === 1) {
        this.ruleForm.applicationId = null;
        this.title = "新增应用";
        //
      } else {
        this.ruleForm = Object.assign({}, item);
        this.ruleForm.applicationId = item.applicationId;
        this.title = "编辑应用";
        this.fileList = [];
        const obj = {
          name: "图片",
          url: this.ruleForm.applicationIcon,
        };
        this.fileList.push(obj);
        console.log(this.ruleForm, "this.ruleForm");
        //
      }
      this.dialogVisible = true;
    },
    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    // 配置跳转
    applyUrl (item) {
      console.log(item.applicationId, "item");
      this.$router.push({
        name: "资源管理",
        params: {
          applicationId: item.applicationId,
        },
      });
    },
    batchDel (type, item) {
      this.$confirm(
        `是否确认删除名称为“${item.applicationName}”的数据项？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          APPLY_API.getApplicationDelete({
            applicationId: item.applicationId,
          }).then((res) => {
            this.$ok("删除成功");
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    closed () {
      this.ruleForm = {
        applicationId: null,
        applicationDesc: null,
        isPublic: 0,
        indexNo: 0,
        applicationName: null,
        applicationIcon: null,
      };
      this.fileList = [];
      this.$refs.ruleForm.resetFields();
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.type === 1) {
            APPLY_API.getApplicationAdd(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("新增成功");
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            APPLY_API.getApplicationUpdate(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("编辑成功");
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>