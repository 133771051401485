<!--
 * @Description: 操作提示
 * @Author: zjc
 * @Date: 2021-12-09 14:42:43
 * @LastEditTime: 2021-12-20 14:43:27
 * @LastEditors: zjc
-->
<template>
  <div class="tips">

    <el-alert :type="tipType" :closable="false">
      <div class="alert-title">
        <i class="el-icon-warning-outline alert-icon"></i>
        <span>{{title}}</span>
        <i class="alert-open" @click="isOpen = !isOpen"
          :class="isOpen?'el-icon-arrow-up':'el-icon-arrow-down'" style="margin-left:6px"></i>
      </div>
      <el-collapse-transition>
        <div v-html="description" v-if="isOpen" class="description"></div>
      </el-collapse-transition>
    </el-alert>
  </div>
</template>
<script>
export default {
  /**
   * @param {String} title 提示语标题 
   * @param {String} description 提示语描述 使用模板字符串
   * @param {String} tipType 类型  success --成功 info --提示 warning --警告 error --错误
   * 
   */
  props: {
    title: {
      type: String,
      default: '操作提示'
    },
    description: {
      type: String
    },
    tipType: {
      type: String,
      default: 'info'
    },
  },
  data () {
    return {
      isOpen: true
    }
  }
}
</script>
<style lang="scss" scoped>
.tips {
  margin-bottom: 10px;
  ::v-deep .el-alert {
    padding: 10px 16px;
    border-radius: 7px;
  }
  ::v-deep .el-alert .el-alert__description {
    margin: 0;
  }
  ::v-deep .el-alert--info.is-light {
    background: rgba(0, 0, 0, 0.04);
  }
}
.description {
  line-height: 24px;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 18px;
  color: #9ba6ba;
}
.alert-title {
  .alert-icon {
    font-size: 13px;
    color: #9ba6ba;
    margin-right: 6px;
  }
  .alert-open {
    font-size: 14px;
    color: #ccc;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>