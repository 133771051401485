<!--
 * @Description: 404
 * @Author: zjc
 * @Date: 2021-12-07 13:50:40
 * @LastEditTime: 2021-12-08 14:04:49
 * @LastEditors: zjc
-->
<template>
  <div class="error-page">
    <div style="margin-right:80px">
      <svg-icon class="img" icon-class="404-copy" />
    </div>
    <div class="content">
      <h1>404</h1>
      <div class="desc">抱歉，你访问的页面不存在</div>
      <div class="actions">
        <router-link :to="{path:'/oms/dashboard'}">
          <el-button type="primary">返回首页</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>
<style lang="scss" scoped>
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f0f2f5;

  .img {
    width: 100%;
    max-width: 430px;
    height: 360px;
    margin-right: 80px;
  }

  .content {
    h1 {
      margin-bottom: 24px;
      color: #434e59;
      font-weight: 600;
      font-size: 72px;
      line-height: 72px;
    }

    .desc {
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
