/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-15 15:06:56
 * @LastEditTime: 2021-12-20 18:05:09
 * @LastEditors: zjc
 */

/**
 * 浏览器判断是否全屏
 */
import store from '@/store/index'
export const fullscreenToggel = () => {
  if (!fullscreenEnable()) {
    reqFullScreen()
  } else {
    exitFullScreen()
  }
}

/**
 * 监听全屏和退出全屏事件
 * @param {*} callback 
 */
export const listenfullscreen = (callback) => {
  function listen () {
    callback()
  }
  document.addEventListener('fullscreenchange', function (e) {
    listen()
  })
  document.addEventListener('mozfullscreenchange', function (e) {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', function (e) {
    listen()
  })
  document.addEventListener('msfullscreenchange', function (e) {
    listen()
  })
}
/**
 * 浏览器判断是否全屏
 * @returns 
 */
export const fullscreenEnable = () => {
  return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
}
/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  let element = document.documentElement;
  if (element.requestFullScreen) {
    element.requestFullScreen()
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}