<!--
 * @Description: tab切换
 * @Author: zjc
 * @Date: 2021-12-21 14:58:13
 * @LastEditTime: 2022-04-15 11:57:47
 * @LastEditors: zjc
-->
<template>
  <div class="switch-tabs" :style="{marginTop:top + 'px'}">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane v-for="(tab,index) in tabList" :key="index" :label="tab.label" :name="tab.name">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  props: {
    activeName: {
      require: true,
      default: null
    },
    tabList: {
      type: Array,
      default: () => []
    },
    top: {
      type: Number,
      default: 20
    },
  },
  data () {
    return {

    }
  },
  methods: {
    handleClick (tab, event) {
      const { label, name } = tab
      this.$emit('tabValue', { label, name })
    },
  },
}
</script>
<style lang="scss" scoped>
.switch-tabs {
  ::v-deep .el-tabs__header {
    margin: 0 0 10px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: $--color-primary;
    background: #fff;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    margin: 0 1px;
    border-left: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px 2px 0px 0px;
    border-bottom: none;
    padding: 0px 16px;
    background: rgba(0, 0, 0, 0.02);
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    margin-left: 0;
  }
}
</style>