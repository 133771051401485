<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" size="small" label-width="0px" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #isDisplay>
              <el-form-item label="状态" label-width="100px">
                <el-select v-model="filters.isDisplay" clearable placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" size="small" @click="addOrUpdate('')">
            新增捐赠故事
          </el-button>
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="cover" slot-scope="scope">
        <div style="width:178px;height:136px;">
          <!-- <div :style="background-image:scope.row.cover"></div> -->
          <img style="width:178px;height:136px;object-fit: fill;" :src="scope.row.cover" alt="">
        </div>
      </template>
      <template slot="indexNo" slot-scope="scope">
        <div>
          <span>{{scope.row.index + 1}}</span>
        </div>
      </template>
      <template slot="status" slot-scope="scope">
        <div v-if="scope.row.isDisplay == 1">展示</div>
        <div v-else>隐藏</div>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="openPermissions(scope.row)">编辑
        </el-button>
        <el-button v-if="scope.row.isDisplay == 0" type="text" size="small"
          @click="showItem(scope.row)">展示
        </el-button>
        <el-button v-else type="text" size="small" @click="showItem(scope.row)">隐藏
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
    <!-- 新增、编辑渠道 -->
    <!-- <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item> -->

    <el-dialog modal-append-to-body title="新增捐赠包主题" width="800px" @close="cancelDialog"
      append-to-body :close-on-click-modal="false" top="10vh" :visible.sync="showDialog">
      <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
        size="medium" :rules="tableFormRules" labelWidth="90px">
        <div class="table_one">
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="主题名称:" prop="themeName">
            <el-input maxlength="5" show-word-limit v-model="tableForm.themeName" clearable
              placeholder="请输入" style="width: 80%">
            </el-input>
          </el-form-item>
          <div class="goodImgBox">
            <span class="redTit3">图片:</span>
            <div style="display: inline-block; vertical-align: top">
              <img v-if="tableForm.themeImg" :src="tableForm.themeImg" class="avatar"
                style="display: inline-block;vertical-align: middle;margin: 0 20px 0 0;" />
              <div>
                <el-upload class="upload-demo" v-if="tableForm.themeImg"
                  action="/api/file/oss/upload" accept=".jpg,.jpeg,.png" :show-file-list="false"
                  multiple :headers="headers" name="file" :on-success="handleNomalSuccess"
                  :before-upload="beforeAvatarUpload" style="display: inline-block">
                  <el-button size="small" type="primary">更换图片</el-button>
                </el-upload>
                <el-upload v-else class="avatar-uploader" style="display: block; text-align: left"
                  action="/api/file/oss/upload" accept=".jpg,.jpeg,.png" :show-file-list="false"
                  multiple :headers="headers" name="file" :on-success="handleNomalSuccess"
                  :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </div>
            </div>
            <div class="rightText">
              <span class="atext">仅可上传jpg、jpeg、png</span>
            </div>
          </div>
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="简介:" prop="themeContent">
            <el-input type="textarea" style="width: 80%" :rows="3" placeholder="请输入50字内简介"
              v-model="tableForm.themeContent" maxlength="50" show-word-limit>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog" size="medium">取消</el-button>
        <!-- <el-button type="primary" @click="rowUpdate" size="medium" :loading="loading"
          v-if="dialogData.id">确定</el-button> -->
        <el-button type="primary" @click="rowSave" size="medium" :loading="loading">确定</el-button>
      </span>
    </el-dialog>

  </basic-container>
</template>
<script>
import { getStore } from '@/utils/store'
import { DONATION_API } from "@/service/permissions/donationStory";
export default {
  data () {
    return {
      authOptions: [
        { label: '展示', value: 1 },
        { label: '隐藏', value: 0 },
      ],
      showDialog: false,
      filters: {},
      pageTotal: 0,
      list: [],
      tableForm: {
        title: "",
        themeImg: "",
        themeContent: '',
      },
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      tableFormRules: {
        title: [
          { required: true, message: "请输入5字内名称", trigger: "blur" },
        ],
        themeContent: [
          { required: true, message: "请输入50字内简介", trigger: "blur" },
        ],
      },
      // 表格查询条件配置
      tableOption: {
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
        column: [
          { prop: "indexNo", label: "序号", slot: true, tableProp: true },
          { prop: "title", label: "故事标题", tableProp: true },
          { prop: "cover", label: "图片", slot: true, width: 200, tableProp: true },
          { prop: "status", label: "状态", slot: true, tableProp: true },
          { prop: "createTime", label: "发布时间", tableProp: true },
          // { prop: "themeStatus", label: "状态", slot: true, tableProp: true },
        ],
      },
      //搜索
      searchOption: [
        {
          prop: "title",
          label: "",
          type: "input",
          placeholder: "搜索标题",
          option: { clearable: true }
        },
        {
          prop: "isDisplay",
          label: "应用状态",
          col: 5,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },

      ],
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
    };
  },
  mounted () {
    this.getList();
  },
  watch: {
    'tableForm.title': function () {
      // var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
      this.tableForm.title = this.tableForm.title
        .replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
    },
  },
  methods: {
    // 展示
    showItem (row) {
      let params = {}
      let msg = row.isDisplay == 1 ? '隐藏' : '展示'
      params.articleId = row.articleId,
        params.isDisplay = row.isDisplay == 1 ? 0 : 1
      this.$confirm(`是否确认` + msg + `该捐赠故事`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DONATION_API.changeStatus(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("更改成功");
            this.getList();
          }
        });
      })
        .catch(() => {
          //
        });
    },
    // 获取渠道列表
    async getList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { data } = await DONATION_API.getList({
          ...this.pageData,
          ...this.filters,
        });
        console.log(data)
        this.list = data.records;
        this.pageTotal = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getList();
    },
    addOrUpdate (row = {}) {
      this.$router.push({
        path: "./add",
        // query: { themeId: row.themeId }
      });
      // this.showDialog = true;
    },
    // 跳转到详情页 查看
    openPermissions (row) {
      console.log(row)
      this.$router.push({
        path: "./add",
        query: { articleId: row.articleId }
      });
    },
    //重置
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.$nextTick(() => {
        this.getList();
      });
    },
    //搜索条件
    search () {
      this.pageData.current = 1;
      this.getList();
    },
    cancelDialog () {
      this.showDialog = false;
      this.tableForm = {};
      this.tableForm.themeImg = '';
    },
    //新增
    async rowSave () {
      if (!this.tableForm.title) {
        this.$message.error("请输入主题名称");
        return;
      }
      if (!this.tableForm.themeContent) {
        this.$message.error("请输入简介");
        return;
      }
      if (!this.tableForm.themeImg) {
        this.$message.error("请上传图片");
        return;
      }
      try {
        let params = this.tableForm;
        const { data } = await DONATION_API.addDonation(params);
        console.log(data);
        this.$ok("新增成功");
        // this.$refs.modal.loading = false;
        this.showDialog = false;
        this.tableForm = {};
        this.getList();
      } catch (err) {
        // this.$refs.modal.loading = false;
      }
    },
    //修改渠道
    async rowUpdate (val) {
      try {
        const { data } = await ROLE_API.updateChannel(val);
        this.$ok("修改成功");
        this.$refs.modal.loading = false;
        this.showDialog = false;
        this.getList();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      console.log(res.data, file)
      this.$set(this.tableForm, "themeImg", res.data.link);
      this.$forceUpdate()
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
  },

};
</script>

<style lang="scss">
.ql-editor {
  min-height: 200px;
}

.box_opration {
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 12px;
  }
  .redTit {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .redTit3 {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 90px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    // margin: 0 0 15px 0;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
