<!--
 * @Description: 表格组件
 * @Author: zjc
 * @Date: 2021-12-07 11:49:02
 * @LastEditTime: 2022-05-30 17:35:31
 * @LastEditors: ym ym@geeboo.cn
-->
<template>
  <div class="table-style">
    <el-table class="demo-form-inline" stripe :key="toggleIndex" :row-id="tableOption.rowKey"
      id="table" :show-header="tableOption ? tableOption.header : 'true'" :default-sort="
        tableOption.sort
          ? { prop: tableOption.props, order: tableOption.order }
          : ''
      " :tree-props="tableOption.tree ? treeConfig : ''" :row-key="tableOption.getRowKey"
      :expand-row-keys="defaultKey" :default-expand-all="tableOption.defaultAll" :data="tableData"
      ref="tableLayout" :empty-text="emptyText" :size="size" :height="height"
      :max-height="maxHeight" @row-click="handleRowClick" @row-dblclick="handleRowDblclick"
      @sort-change="sortChange" @selection-change="handleSelectionChange" @select="selectChange"
      v-loading="tableLoading" element-loading-text="加载中" :row-style="rowStyle"
      :cell-class-name="tableRowClassName">
      <el-table-column v-if="tableOption.index" type="index" label="序号" width="70" align="center">
      </el-table-column>
      <template v-if="tableOption.selection">
        <el-table-column :selectable="tableOption.selectable"
          :reserve-selection="tableOption.reserve" type="selection" width="70" align="center">
        </el-table-column>
      </template>
      <template v-for="item in tableList">
        <el-table-column :key="item.label" v-if="item.tableProp" :tree-node="item.tree || false"
          :prop="item.prop" :sortable="item.sort" :label="item.label"
          :show-overflow-tooltip="item.tooltip ? false : true" :align="item.align || 'center'"
          :width="item.width" :min-width="item.minWidth">
          <template slot-scope="scope">
            <slot :row="scope.row" :$index="scope.$index" v-if="item.slot" :name="item.prop"></slot>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-if="tableOption.operationData" :prop="tableOption.operationData.prop"
        :label="tableOption.operationData.label" :fixed="tableOption.operationData.fixed"
        :align="tableOption.operationData.align || 'center'"
        :width="tableOption.operationData.width" :min-width="tableOption.operationData.minWidth">
        <template slot-scope="scope">
          <slot :row="scope.row" :$index="scope.$index" :name="tableOption.operationData.prop">
          </slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  /**
   * @param {Array} tableData 表格渲染数据
   * @param {Number} maxHeight 表格最大高度
   * @param {Number} height 表格高度
   * @param {emptyText} String 无数据内容
   * @param {String} 表格尺寸
   * @param {Object} 表格配置项
   *        sort 是否需要排序
   *          prop 默认排序字段
   *          order 排序类型 升序 降序
   *        tree 是否树形表格
   *        selection 是否为多选表格
   *        align 对齐方式
   *        getDefaultKey 默认展开行字段名
   *        column  单元格配置
   *          tableProp 是否显示单元格
   *          tooltip 超出是否...
   *          prop 表格字段
   *          label 表格名称
   *          slot 是否插槽
   *          header 是否显示头部
   *          sort 排序 默认 custom
   *          width 宽度
   *          minWidth 最小宽度
   *          search 是否是搜索条件 为true hide也要为true
   *          hide 不显示在表格 可能是搜索条件定义
   */
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
    },
    height: {
      type: Number,
    },
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    tableOption: {
      type: Object,
    },
    size: {
      type: String,
    },
  },
  data () {
    return {
      toggleIndex: 0,
      treeConfig: { children: "children", hasChildren: "hasChildren" },
      selectList: [], //选中数据
    };
  },
  computed: {
    tableList () {
      return this.tableOption.column.filter(
        (item) => !item.hasOwnProperty("hide")
      );
    },
    tableLoading () {
      return this.$store.state.common.tableLoading;
    },
    // 默认展开第一行
    defaultKey () {
      if (this.tableOption.getDefaultKey && this.tableData.length) {
        let list = [];
        var newList = [];
        let id = this.tableOption.getDefaultKey;
        this.tableData.forEach((item) => {
          list.push(item[id] + "");
        });
        if (list.length) {
          // console.log(list.splice(0, 1), 'list.splice(0, 1)');
          return (newList = list.splice(0, 1));
        }
      }
      return "";
    },
  },
  methods: {
    // 单击表格
    handleRowClick (row, column, cell, event) {
      this.$emit("handleRowClick", row, column, cell, event);
    },
    // 双击表格
    handleRowDblclick (row, column, cell, event) {
      this.$emit("handleRowDblclick", row, column, cell, event);
    },
    //多选回调
    handleSelectionChange (selection) {
      this.selectList = Array.from(selection, ({ index }) => index);
      this.$emit("handleSelectionChange", selection);
    },
    selectChange (selection, row) {
      this.$emit("selectChange", selection, row);
    },
    //选中改变样式
    rowStyle ({ row, rowIndex }) {
      for (const i of this.selectList) {
        if (i === rowIndex) return { backgroundColor: "#E6F7FF" };
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 排序回调
    sortChange ({ column, prop, order }) {
      this.$emit("sortChange", { column, prop, order });
    },
    // 初始化
    initTable () {
      this.toggleIndex++;
      this.$nextTick(() => {
        this.$refs.tableLayout.doLayout();
      });
    },
    toggleRowSelection (rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tableLayout.toggleRowSelection(row);
        });
      }
    },
    clearSort () {
      this.$nextTick(() => {
        this.$refs.tableLayout.clearSort();
      });
    },
    clearSelection () {
      this.$refs.tableLayout.clearSelection();
    },
  },
  created () { },
  beforeDestroy () {
    this.toggleIndex = 0;
  },
};
</script>
<style lang="scss" scoped>
.table-style {
  ::v-deep .el-table thead tr {
    height: 54px;
    background: #f5f7fa;
  }

  ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td,
  ::v-deep .el-table th {
    background: #f5f7fa;
  }
  ::v-deep .el-table {
    color: #000;
  }
}
</style>
<style>
.el-tooltip__popper {
  max-width: 1200px;
}
</style>