<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:52:36
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <el-alert title="请谨慎添加或修改路由，如果修改不当将影响正常访问！" type="warning" show-icon :closable="false">
    </el-alert>
    <div class="dynamic">
      <vue-json-editor v-model="resultInfo" :showBtns="false" :mode="'code'"
        @json-change="onJsonChange" @json-save="onJsonSave" @has-error="onError" />
    </div>
    <div style="text-align:center; margin-top: 20px">
      <el-button type="primary">更新路由</el-button>
    </div>
  </basic-container>
</template>
<script>
// 导入模块
import vueJsonEditor from "vue-json-editor";

export default {
  components: { vueJsonEditor },
  data () {
    return {};
  },
  methods: {
    onJsonChange (value) {
      // console.log('更改value:', value);
      // 实时保存
      // this.onJsonSave(value);
    },
    onJsonSave (value) {
      // console.log('保存value:', value);
      // this.resultInfo = value;
      // this.hasJsonFlag = true;
    },
    onError (value) {
      // console.log("json错误了value:", value);
      // this.hasJsonFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dynamic {
  // width: 70%;
  margin-top: 15px;
}

.dynamic div {
  height: 500px !important;
}
</style>
<style>
.jsoneditor-vue {
  height: 100%;
}
</style>