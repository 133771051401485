<!--
 * @Description: 个人信息
 * @Author: zjc
 * @Date: 2022-04-16 10:44:37
 * @LastEditTime: 2022-04-28 18:30:39
 * @LastEditors: zjc
-->
<template>
  <div>
    <basic-container>
      <div class="person-title">个人资料</div>
      <div class="person">
        <img src="@/assets/img/icon-user.png" width="50" alt="" class="person-logo">
        <p class="person-name">admin</p>
      </div>
      <el-form ref="submitForm" :model="personData" size="medium" label-width="70px">
        <el-form-item label="用户名：" prop="username">
          <el-input v-model="personData.username" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="昵称：" prop="realName">
          <el-input v-model="personData.realName" placeholder="请输入昵称">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="personData.mobile" type="number" placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input v-model="personData.password" type="password" placeholder="请输入密码" show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button size="medium" type="primary" @click="save" :loading="loading">提交</el-button>
          <el-button size="medium">重置</el-button>
        </el-form-item>
      </el-form>
    </basic-container>
  </div>
</template>
<script>
import { PERSONAL_API } from '@/service/permissions/personalData'
import { OPERATOR_API } from '@/service/permissions/operator'
import { encryptCode } from '@/utils/jsencrypt'
export default {
  data () {
    return {
      loading: false,
      personData: {
        username: null,//用户名
        realName: null,//昵称
        mobile: null,//手机号
        password: null,//密码
      },
    }
  },
  methods: {
    async onLoad () {
      const { data } = await PERSONAL_API.personData()
      const { username, realName, mobile, password, oprId } = data.opr
      this.personData = { username, realName, mobile, oprId, password }
      this.personData.password = null
    },
    async save () {
      try {
        this.loading = true
        // this.personData.password = this.personData.password ? encryptCode(this.personData.password) : null
        const { data } = await OPERATOR_API.updateOperator(this.personData)
        this.$ok('修改成功')
        this.onLoad()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
  created () {
    this.onLoad()
  },
}
</script>
<style lang="scss">
.person-title {
  text-align: left;
  font-size: 14px;
}
.person {
  text-align: center;
  padding: 30px 0px;
  &-logo {
    border-radius: 100%;
  }
  &-name {
    padding-top: 10px;
  }
}
</style>