<!--  新增需求 -->
<template>
  <div>
    <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;">
      <div class="abox_title">
        <div class="left_title">
          <p class="title">捐赠详情</p>
          <p class="rightTitle" v-if="ruleForm.orderStatus == 1">状态：
            <span>待支付</span>
          </p>
          <p class="rightTitle" v-else-if="ruleForm.orderStatus == 2">状态：
            <span>处理中</span>
          </p>
          <p class="rightTitle" v-else-if="ruleForm.orderStatus == 3">状态：<span>已完成</span></p>
          <p class="rightTitle" v-else-if="ruleForm.orderStatus == 4">状态：
            <span>处理中</span>
          </p>
          <p class="rightTitle" v-else-if="ruleForm.orderStatus == 5">状态：<span>已取消</span></p>
        </div>
      </div>
      <div style="width:100%">
        <div class="tab-one">
          <p class="tab-title">图书馆信息</p>
          <div class="tab-content2">
            <div class="box_tab_one">
              <div class="content">
                <div class="item">
                  <span class="l1">捐赠号：</span>
                  <span class="r1">{{ruleForm.recordId}}</span>
                </div>
                <div class="item">
                  <span class="l1">捐赠对象：</span>
                  <span class="r1">{{ruleForm.pressOrgName}}</span>
                </div>
                <div class="item">
                  <span class="l1">捐赠人手机号：</span>
                  <span class="r1">{{ruleForm.mobile}}</span>
                </div>
                <div class="item">
                  <span class="l1">复本数：</span>
                  <span class="r1">{{ruleForm.copyCnt}}册</span>
                </div>
                <div class="item">
                  <span class="l1">来源：</span>
                  <span class="r1" v-if="ruleForm.donationType== 2">已有书捐赠</span>
                  <span class="r1" v-else>商城捐赠</span>
                </div>
                <!-- <div class="item">
                  <span class="l1">捐赠方式：</span>
                  <span class="r1">用户下单</span>
                </div> -->
                <div class="item">
                  <span class="l1">捐赠时间：</span>
                  <span class="r1">{{ruleForm.createTime}}</span>
                </div>
                <!-- <div class="item" v-show="ruleForm.cancelTime">
                  <span class="l1">取消时间：</span>
                  <span class="r1">{{ruleForm.cancelTime}}</span>
                </div>
                <div class="item" v-show="ruleForm.completeTime">
                  <span class="l1">完成时间：</span>
                  <span class="r1">{{ruleForm.completeTime}}</span>
                </div> -->

              </div>
            </div>
          </div>

        </div>
        <div class="tab-one" style="margin:20px auto 0">
          <p class="tab-title">捐赠信息</p>
          <div class="tab-content2">
            <div class="box_tab_one">
              <div class="content">
                <div class="item2" v-show="otem.identityCode !== 'LUOKUAN' && otem.identityValue"
                  v-for="(otem,ondex) in ruleFormList" :key="ondex">
                  <div style="min-width:30%" v-if="otem.identityCode == 'SCHOOL'">
                    <span class="l1">所属院校：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                  <div style="min-width:30%"
                    v-if="otem.identityCode == 'ENTRY_YEAR' && otem.identityValue">
                    <span class="l1">入学年份：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                  <div style="min-width:30%"
                    v-if="otem.identityCode == 'EDUCATION' && otem.identityValue">
                    <span class="l1">学历：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                  <div v-if="otem.identityCode == 'INSCRIPTION_NAME'">
                    <span class="l1">落款名称：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                </div>
                <div class="item2" v-show="fullName !== ''">
                  <div>
                    <span class="l1">落款名称：</span>
                    <span class="r1">{{fullName}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-one" style="margin:30px 0 30px 0">
        <div class="tab-title">
          <p>捐赠书单</p>
          <!-- &&  -->
          <el-button v-show="ruleForm.orderStatus == 2 || ruleForm.orderStatus == 4"
            style="margin-right: 20px;height:30px" size="small" type="primary" @click="reOrderList">
            重新下单
          </el-button>
        </div>
        <div class="tab-title2" v-if="goodsType == 2">
          <span class="txt1">捐赠包名：</span><span class="txt2">{{packageName}}</span>
        </div>

        <v-table style="width:100%;margin:0 auto 20px;" class="itemTble" ref="table"
          :tableData="tableList" :tableOption="tableOption"
          @handleSelectionChange="selectionChange">
          <template slot="indexNo" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <template slot="book" slot-scope="scope">
            <div v-if="scope.row.bookInfo"
              style="width: 100%;text-align: left;display: flex;justify-content: center;align-items: center;">
              <img :src="scope.row.bookInfo.bookCover ? scope.row.bookInfo.bookCover : defaultBook"
                alt="" style="width: 56px;height: 80px;">
              <div style="flex:1;
                        vertical-align: top;
                        margin-left: 10px;
                        width: 100px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;">
                {{scope.row.bookInfo.bookName}}<br>
                <span
                  style="color: rgb(142 142 142);font-size: 12px;">{{scope.row.bookInfo.bookAuthor}}</span><br>
                <div>
                  <span class="orangeIcon" v-if="scope.row.isSeries == 1">
                    丛书</span>
                  <span class="orangeIcon" v-if="scope.row.isRichMedia == 1">富媒体</span>
                </div>
              </div>
            </div>
          </template>
          <template slot="ISBN" slot-scope="scope">
            <div v-if="scope.row.bookInfo">
              {{scope.row.bookInfo.isbn}}
            </div>
          </template>
          <template slot="price" slot-scope="scope">
            <span>￥{{scope.row.bookInfo.price}}</span><br>
          </template>
          <!-- <template slot="paperPrice" slot-scope="scope">
            <span v-if="scope.row.bookInfo">￥{{scope.row.bookInfo.price}}</span><br>
          </template> -->
          <template slot="cnt" slot-scope="scope">
            <span>{{scope.row.copyCnt}}</span>
          </template>
          <template slot="totalStock" slot-scope="scope">
            <span v-if="scope.row.bookInfo.totalStock > 0">正常</span>
            <span v-else>异常</span>
          </template>
          <!-- <template slot="totalStock" slot-scope="scope">
            <div v-if="ruleForm.orderStatus == 2 || ruleForm.orderStatus == 4">
              <div v-if="scope.row.bookInfo">
                <span
                  v-if="(scope.row.bookInfo.totalStock < scope.row.copyCnt ) && ruleForm.orderStatus !== 3">异常</span>
                <span v-else>正常</span>
              </div>
            </div>
            <div v-else>-</div>

          </template> -->
          <template slot="operation" slot-scope="scope">
            <!-- v-if="scope.row.bookInfoVO.totalStock <= 0" -->
            <el-button type="text" size="small" @click="searchBook(scope.row.bookInfo)">查看
            </el-button>
            <div v-if="ruleForm.orderStatus == 2 || ruleForm.orderStatus == 4">
              <div v-if="scope.row.bookInfo">
                <el-button
                  v-if="(scope.row.bookInfo.totalStock < scope.row.cnt ) && ruleForm.orderStatus !== 3"
                  type="text" size="small" @click="checkBooks(scope.row)">替换图书
                </el-button>
              </div>
              <div v-if="scope.row.bookInfo">
                <el-button
                  v-if="(scope.row.bookInfo.totalStock < scope.row.cnt ) && ruleForm.orderStatus !== 3"
                  type="text" size="small" @click="refrash()">更新库存</el-button>
              </div>
            </div>

          </template>
        </v-table>
      </div>

    </div>
    <!-- 图书信息 -->
    <book-info ref="bookInfo" :categoriesList="newCategoriesList" @getList="getList" />
    <check-book ref="checkBook" @handleSelect="handleSelectBack" type="setPro" :list="tableList" />
  </div>
</template>
<script>
import { defaultBook } from '@/enums/default'
import bookInfo from './order/component/bookInfo.vue'
import { Order_API } from "@/service/permissions/order";
import checkBook from "@/views/bookManeger/component/checkBookRadio.vue";
import { DONATION_API } from "@/service/permissions/donation";
export default {
  components: { bookInfo, checkBook },
  data () {
    return {
      reOrderStatus: false,//重新下单的按钮
      // indexChange: -1,
      defaultBook: defaultBook,
      tableList: [],
      fullName: '',
      //表格配置
      // tableOption0: {
      //   selection: true,
      //   align: 'center',
      //   operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
      //   column: [
      //     { prop: 'indexNo', label: '序号', slot: true, tableProp: true, width: 100 },
      //     { prop: 'book', label: '名称', slot: true, tableProp: true, },
      //     { prop: 'ISBN', label: 'ISBN', slot: true, tableProp: true },
      //     { prop: 'price', label: '定价（元）', slot: true, tableProp: true },
      //     { prop: 'paperPrice', label: '捐赠售价（元）', slot: true, tableProp: true },
      //     { prop: 'cnt', label: '复本数（册）', slot: true, tableProp: true },
      //   ],
      // },
      tableOption: {
        selection: true,
        align: 'center',
        // operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
        column: [
          { prop: 'indexNo', label: '序号', slot: true, tableProp: true, width: 100 },
          { prop: 'book', label: '名称', slot: true, tableProp: true, },
          { prop: 'ISBN', label: 'ISBN', slot: true, tableProp: true },
          { prop: 'price', label: '定价（元）', slot: true, tableProp: true },
          // { prop: 'paperPrice', label: '捐赠售价（元）', slot: true, tableProp: true },
          { prop: 'cnt', label: '复本数（册）', slot: true, tableProp: true },
          // { prop: 'totalStock', label: '状态', slot: true, tableProp: true },
        ],
      },

      libraryList: [],
      ruleForm: {
        // contactName: '',
        // orgId: '',//机构编号
        // content: '',//需求说明
        // budgetRange: '',//预算
        // orderFile: '',//附件
      },
      createTime: null,

      status: 0,//0新增|1查看
      //  allCnt: '',
      goodsCnt: 0,
      sortCnt: 0,
      totalAmount: 0,
      selectList: [],
      goodsType: 1,
      packageName: '',
      ruleFormList: [],
    }
  },
  created () {
    if (this.$route.query.recordId) {
      this.recordId = this.$route.query.recordId
      localStorage.setItem("recordId", this.$route.query.recordId)
    }
    else {
      if (localStorage.getItem("recordId")) {
        this.recordId = localStorage.getItem("recordId")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.getList();
  },

  methods: {
    reOrderList () {
      console.log(this.reOrderStatus)
      if (this.reOrderStatus == false) {
        console.log('存在异常书籍')
        this.$message.error('存在异常书籍');
        return
      } else {
        let params = {};
        params.orderId = this.orderId
        try {
          Order_API.reorderList(params).then((res) => {
            this.$ok("重新下单成功");
            this.getList();
          });
          this.$store.commit('SET_TABLE_LOADING', false)
        } catch (err) {
          this.$store.commit('SET_TABLE_LOADING', false)
        }
      }

    },
    checkBooks (row, index) {
      console.log(index)
      // this.indexChange = index
      this.$refs.checkBook.visible = true;
      let params = {}
      params.bookId = row.bookInfo.bookId
      this.itemorderItemId = row.detailId
      this.$refs.checkBook.init(params, 2)
    },
    //获取选择图书或者导入图书去重数据排序 
    handleSelectBack (row, type = null) {
      console.log(row)
      let params = {}
      params.detailId = this.itemorderItemId
      params.bookId = row.bookId
      Order_API.replaceBook(params).then((res) => {
        console.log(res, "res");
        this.getList();
      });

    },
    refrash () {
      this.getList('refash');
    },
    searchBook (row) {
      console.log(row)
      // detail (id) {
      this.$refs.bookInfo.visible = true
      this.$nextTick(() => {
        this.$refs.bookInfo.init(row.bookId)
      })
      // },
    },

    //多选回调
    selectionChange (val) {
      let that = this
      this.selectList = val
      console.log(this.selectList.length)
      if (this.selectList.length == 0) {
        this.disabled = true
      } else {
        this.disabled = false
        if (that.selectList.findIndex(item => item.totalStock === 0) !== -1) {
          console.log('存在库存为0得图书')
          that.disabled2 = true
        }
        else {
          console.log('不存在库存为0得图书')
          that.disabled2 = false
        }
      }
    },
    // 获取详情 
    async getList (refash) {
      this.reOrderStatus = true
      try {
        let params = {
          recordId: this.recordId
        }
        await DONATION_API.getReDetail(params).then(res => {
          console.log(res, "res");
          this.ruleForm = res.data
          this.ruleFormList = res.data.identities
          // otem.identityCode == 'INSCRIPTION_NAME'
          this.fullName = this.ruleForm.fullInscriptionName
          this.ruleFormList.forEach(item => {
            if (item.identityCode == 'INSCRIPTION_NAME') {
              this.fullName = ''
            } else {
              this.fullName = this.ruleForm.fullInscriptionName
            }
          })

          let arr = []
          console.log(res.data.bookItems, "res");
          // res.data.orderItems.forEach(item => {
          //   console.log(item.goodsType)
          //   this.goodsType = item.goodsType
          //   if (item.goodsType == 1) {
          //     arr = res.data.orderItems
          //   } else {
          //     // console.log(res.data.orderItems)
          //     let list = []
          //     list = res.data.orderItems[0].orderItemDetailList
          //     // console.log(res.data.orderItems[0], '1111')
          //     this.packageName = res.data.orderItems[0].packageInfo.packageName
          //     console.log(list)
          //     let allgoodsCnt = 0
          //     list.forEach(otem => {
          //       allgoodsCnt += otem.cnt
          //       console.log(otem)
          //       if (otem.bookInfo) {
          //         if (otem.bookInfo.totalStock < otem.cnt) { //存在异常图书
          //           // otem.bookInfo.changestatus = true
          //           this.reOrderStatus = false
          //         }
          //         arr.push(otem)
          //       }

          //     })
          //     this.ruleForm.goodsCnt = allgoodsCnt
          //   }
          //   if (refash) {
          //     this.$message.success("更新成功");
          //   }
          // })
          // this.tableList = arr
          // this.tableList = res.data.bookItems
          res.data.bookItems.forEach(item => {
            console.log(item.bookInfo)
            arr.push(item)
          })
          this.tableList = arr
        });

        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },


    closeDialog () {
      this.showDetail = false
      this.formData = {}
      this.packageImg = null
      this.packageName = null
      this.packageDetail = null
      this.tableList = []
    },


  }
}
</script>
<style scoped lang="scss">
.tab-title2 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  background-color: #ffffff;
  .txt1 {
    font-size: 12px;
    color: #999999;
  }
  .txt2 {
    font-size: 13px;
    color: #333333;
  }
}
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item2 {
            width: 33%;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  // width: 1310px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
    .rightTitle {
      box-shadow: none;
      font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
      span {
        color: #02a7f0;
      }
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  border: 1px solid red;
  width: 100%;
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}
</style>
