/*
 * @Author: 租户接口
 * @Date: 2022-05-24 17:05:23
 * @LastEditors: ym ym@geeboo.cn
 * @LastEditTime: 2022-05-26 15:49:43
 * @FilePath: \cloud-library-platform-ui\src\service\permissions\lessee.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const getTenant = (param) => request('get', '/oms/tenant/page', param, {
    requestType: 'JSON'
}) //租户管理列表

const getTenantAdd = (param) => request('post', '/oms/tenant', param, {
    requestType: 'JSON'
}) //新增租户

const getTenantUpdate = (param) => request('post', '/oms/tenant/update', param, {
    requestType: 'JSON'
}) //编辑租户

const getTenantDelete = (param) => request('post', '/oms/tenant/delete', param) //删除租户

export const LESSEE_API = {
    getTenant,
    getTenantAdd,
    getTenantUpdate,
    getTenantDelete
}
