/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:42:50
 * @LastEditTime: 2022-08-26 17:53:51
 * @LastEditors: ym
 */

import CryptoJS from './crypto'
import qs from 'qs'
import { COMMON_API } from '@/utils/common.js'
export default {
  nonce: CryptoJS.randomString(),
  timestamp: new Date().getTime(),
  // secret_key: '6zm1pbsYpvfWmOmuNdV7fofAi9YcMOLW', // 测试环境
  // secret_key: '6zm1pbsYpvfWmOmuNdV7fofAi9YcMOLW', // 开发环境
  secret_key: '4CTZ7892m8xOba48efnN4PBgqXKEKU5J', // 生产环境
  signData ({ type = 'get', data = {} }) {
    const signData2 = COMMON_API.sortEmpty({ ...data, nonce: CryptoJS.randomString(), timestamp: new Date().getTime() });
    const sortData = COMMON_API.sort({ ...data, nonce: signData2.nonce, timestamp: signData2.timestamp })
    const sliceData = qs.stringify(signData2, { encode: false });
    let sliceData2 = { ...signData2, secret_key: this.secret_key };
    sliceData2 = qs.stringify(sliceData2, { encode: false })
    const sign = CryptoJS.MD5(sliceData2).toString().toUpperCase();
    return type === 'get' ?
      `${sliceData}&sign=${sign}` :
      {
        ...sortData,
        sign
      }
  }
}