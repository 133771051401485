<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:48:04
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="90px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @reset="reset"
            @searchChange="search" ref="filters">
            <template #resourceName>
              <el-form-item label="应用名称：">
                <el-select @clear="search" clearable style="width: 300px"
                  v-model="filters.applicationId" placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.applicationId"
                    :label="item.applicationName" :value="item.applicationId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <!-- <el-form :model="applyForm" label-width="90px">
        <el-form-item label="应用名称：">
          <el-select
            @change="change"
            clearable
            style="width: 300px"
            v-model="applicationId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in authOptions"
              :key="item.applicationId"
              :label="item.applicationName"
              :value="item.applicationId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form> -->

      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="batchDel(1)"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange">
      <template slot="parentId" slot-scope="scope">
        <el-tag v-if="scope.row.type === 1">顶部菜单</el-tag>
        <el-tag v-else-if="scope.row.type === 2" type="success">左侧菜单</el-tag>
        <el-tag v-else type="info">按钮</el-tag>
      </template>

      <template slot="resourceStatus" slot-scope="scope">
        <span v-if="scope.row.resourceStatus === 1">启用</span>
        <span v-else style="color: red">停用</span>
      </template>

      <template slot="isPublic" slot-scope="scope">
        <el-tag size="small">{{ scope.row.isPublic === 0 ? "否" : "是" }}</el-tag>
      </template>

      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="add(scope.row)">新增子项</el-button>
        <el-button type="text" size="small" style="color: red" @click="batchDel(2, scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 新增菜单 -->
    <el-dialog @closed="closed" :title="title" :visible.sync="dialogVisible" width="30%"
      :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="菜单类型：">
          <el-radio-group v-model="ruleForm.resourceType">
            <el-radio-button v-if="!disabled" label="1">顶部菜单</el-radio-button>
            <el-radio-button label="2">左侧菜单</el-radio-button>
            <el-radio-button label="3">按钮</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应用名称：" prop="applicationId">
          <el-select clearable style="width: 100%" v-model="ruleForm.applicationId"
            placeholder="请选择" @change="applicationIdChange" @clear="applicationIdClear">
            <el-option v-for="item in authOptions" :key="item.applicationId"
              :label="item.applicationName" :value="item.applicationId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级资源：">
          <el-cascader :disabled="!ruleForm.applicationId" v-model="ruleForm.parentResourceId"
            ref="cascader" :options="seachList" :show-all-levels="false" :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              checkStrictly: true,
            }" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="资源编码：" prop="resourceCode">
          <el-input v-model="ruleForm.resourceCode" placeholder="资源编码"></el-input>
        </el-form-item>
        <el-form-item label="资源名称：" prop="resourceName">
          <el-input v-model="ruleForm.resourceName" placeholder="请输入资源名称"></el-input>
        </el-form-item>
        <el-form-item label="资源路径：">
          <el-input v-model="ruleForm.resourceUrl" placeholder="请输入路径"></el-input>
        </el-form-item>
        <el-form-item label="图标：">
          <el-popover placement="bottom-start" width="450" trigger="click"
            @show="$refs['iconSelect'].reset()">
            <IconSelect ref="iconSelect" @selected="selected" />
            <el-input slot="reference" v-model="ruleForm.resourceIcon" placeholder="点击选择图标"
              readonly>
              <svg-icon v-if="ruleForm.resourceIcon" slot="prefix"
                :icon-class="ruleForm.resourceIcon" class="el-input__icon"
                style="height: 32px; width: 16px" />
              <i v-else slot="prefix" class="el-icon-search el-input__icon" />
            </el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="ruleForm.indexNo" type="number" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-radio-group v-model="ruleForm.resourceStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="2">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否公开：">
          <el-radio-group v-model="ruleForm.isPublic">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <p style="color: #929292">公开资源无需分配权限即可访问</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import IconSelect from "@/components/IconSelect";
import { RESOURCE_API } from "@/service/permissions/resource";
import { AUTHORIZATION_API } from "@/service/permissions/authorization";

const formData = {
  resourceType: 1,
  resourceIcon: null,
  resourceUrl: null,
  resourceName: null,
  resourceCode: null,
  indexNo: 0,
  resourceStatus: 2,
  isPublic: 0,
  parentResourceId: null,
  applicationId: null,
};
export default {
  name: "resourceList",
  components: {
    IconSelect,
  },
  data () {
    return {
      loading: false,
      applicationId: null, //应用id
      filters: {},
      authOptions: [], //应用数组
      ruleForm: Object.assign({}, formData),
      rules: {
        resourceCode: [
          { required: true, message: "请输入资源编码", trigger: "blur" },
        ],
        resourceName: [
          { required: true, message: "请输入资源名称", trigger: "blur" },
        ],
        applicationId: [
          { required: true, message: "请选择应用", trigger: "change" },
        ],
      },
      list: [],
      seachList: [],
      //搜索
      searchOption: [
        {
          prop: "resourceName",
          label: "资源名称",
          type: "select",
          slot: true,
          placeholder: "请输入",
        },
      ],
      title: "新增资源",
      dialogVisible: false,
      // 表格查询条件配置
      tableOption: {
        align: "left",
        selection: true,
        sort: true,
        getRowKey (row) {
          return row.id;
        },
        getDefaultKey: "id", //跟getRowKey的id保持一致
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "name", label: "资源名称", tableProp: true, align: "left" },
          { prop: "icon", label: "图标", tableProp: true },
          { prop: "index_no", label: "排序", tableProp: true },
          { prop: "url", label: "资源路径", tableProp: true, align: "left" },
          { prop: "parentId", label: "类型", tableProp: true, slot: true },
          {
            prop: "resourceStatus",
            label: "状态",
            tableProp: true,
            slot: true,
          },
          {
            prop: "isPublic",
            label: "公开资源",
            slot: true,
            width: 80,
            tableProp: true,
          },
          {
            prop: "code",
            label: "资源编码",
            tableProp: true,
            width: 170,
          },
        ],
      },
    };
  },
  created () {
    if (this.$route.params.applicationId) {
      this.filters.applicationId = this.$route.params.applicationId;
      this.$nextTick(() => {
        this.filters.applicationId = this.$route.params.applicationId;
      });
      this.type === 1;
    } else {
      this.filters.applicationId = null;
    }
    this.getApply();
    this.getData();
  },
  methods: {
    search () {
      this.getData();
    },
    // 应用下拉列表
    getApply () {
      AUTHORIZATION_API.getApplication({ isFilterPublic: 0 }).then((res) => {
        this.authOptions = res.data;
      });
    },
    change () {
      this.getData();
    },
    applicationIdClear () {
      this.ruleForm.parentResourceId = null;
    },
    applicationIdChange () {
      this.getSearchData();
    },
    getSearchData () {
      RESOURCE_API.getResource({
        applicationId: this.ruleForm.applicationId,
      }).then((res) => {
        this.seachList = res.data || [];
        console.log(res, "res");
      });
    },
    getData () {
      RESOURCE_API.getResource({
        applicationId: this.filters.applicationId,
      }).then((res) => {
        this.list = res.data || [];
        console.log(res, "res");
      });
    },
    // 新增
    addOrUpdate (type, item) {
      this.type = type;
      if (type === 2) {
        this.title = "编辑菜单";
        console.log(item, "item");
        // this.$nextTick(() => {
        //   this.ruleForm.resourceIcon = item.icon;
        // });
        this.ruleForm.resourceId = item.id;
        this.ruleForm.resourceType = item.type || null;
        this.ruleForm.resourceIcon = item.icon;

        this.ruleForm.resourceUrl = item.url || null;
        this.ruleForm.resourceName = item.name || null;
        this.ruleForm.resourceCode = item.code || null;
        this.ruleForm.indexNo = item.index_no;
        this.ruleForm.resourceStatus = item.resourceStatus || null;
        this.ruleForm.isPublic = item.isPublic;
        this.ruleForm.parentResourceId = item.parentId;
        this.ruleForm.applicationId = item.applicationId;
        this.getSearchData();

        console.log(this.ruleForm, "this.ruleForm");
        // PERMISSION_API.getAuthDetail(item.id).then((res) => {
        //   this.ruleForm = Object.assign({}, res.data);
        // });
      } else {
        this.title = "新增菜单";
      }
      this.dialogVisible = true;
    },
    // 删除
    batchDel (type, item) {
      this.$confirm(`是否确认删除名称为“${item.name}”的数据项?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await RESOURCE_API.getResourceDelete({
            resourceId: item.id,
          });
          this.$ok("删除成功");
          this.getData();
        })
        .catch(() => {
          //
        });
    },
    reset () {
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    // 新增子项
    add (item) {
      this.ruleForm.parentResourceId = item.id;
      this.ruleForm.applicationId = item.applicationId;
      this.getSearchData();

      this.type = 1;
      this.title = "新增菜单";
      this.ruleForm.resourceType = 2; //子项不能为顶部菜单
      this.disabled = true;
      this.dialogVisible = true;
    },
    closed () {
      this.ruleForm = Object.assign({}, formData);
      this.disabled = false;
      this.$refs.cascader.$refs.panel.checkedValue = [];
      this.$refs.ruleForm.resetFields();
    },
    selectionChange () { },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.$refs.cascader.getCheckedNodes());
          if (this.$refs.cascader.getCheckedNodes().length) {
            this.ruleForm.parentResourceId =
              this.$refs.cascader.getCheckedNodes()[0].value;
            // console.log(this.$refs.cascader.getCheckedNodes()[0].value, "000");
          } else {
            this.ruleForm.parentResourceId = 0;
          }
          this.loading = true;
          if (this.type === 1) {
            RESOURCE_API.getResourceSave(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("新增成功");
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            RESOURCE_API.getResourceUpdate(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("编辑成功");
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    selected (name) {
      this.ruleForm.resourceIcon = name;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>