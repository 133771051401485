/*
 * @Description:vuex 入口
 * @Author: zjc
 * @Date: 2021-12-07 11:49:47
 * @LastEditTime: 2021-12-07 11:52:59
 * @LastEditors: zjc
 */
import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
Vue.use(Vuex)
import getters from './getters'
const context = require.context('./modules', false, /\.js$/);
const moduleStores = {};
context.keys().forEach(key => {
  const fileName = key.slice(2, -3);
  const fileModule = context(key).default;
  moduleStores[fileName] = {
    ...fileModule,
  };
});
export default new Vuex.Store({
  getters,
  modules: {
    ...moduleStores
  },
  mutations: {
    // 重置vuex本地储存状态
    RESTE_STORE (state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    }
  },
});