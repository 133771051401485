<!-- 选择图书 -->
<template>
  <div>
    <el-dialog title="展示机构" width="400px" :close-on-click-modal="false" append-to-body top="8vh"
      :visible.sync="showDetail" @close="closeDialog">
      <div class="flex-class" style="height:400px;overflow-y:scroll;">
        <div class="boxCheck">
          <div class="itemCheck" v-for="(item,index) in tableList" :key="index" :label="item.orgId">
            <el-radio v-model="radio" style="margin-left: 5px;" :label="item.orgId"
              @change.native="getTemplateRow(item)">{{item.orgName}}
            </el-radio>
          </div>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确 定</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { getBook, getCategories } from "@/api/book/bookList";
// import { BOOK_API } from "@/service/permissions/book";
// import { stateList } from '@/enums/userManager'//注册时间
import { ROLE_API } from "@/service/permissions/channel";
export default {
  props: {
    open: {
      type: Boolean
    },
    list: {
      type: Array
    },
    type: {
      type: String,
      default: ''
    },
    operation: {
      type: String
    }
  },
  data () {
    return {
      checkList: {},
      showDetail: false,
      tableList: [],
      radio: '',
    }
  },
  methods: {
    getTemplateRow (row) {
      console.log(row)
      this.checkList = row
      // this.radio = row.orgId
      // this.templateRadio = row
      // this.templateSelection = row
    },
    async init (chooseObject) {
      console.log(chooseObject)
      // this.checkList.push(chooseObject)
      this.showDetail = true
      try {
        let params = {
          size: 100,
          current: 1
        }
        await ROLE_API.getChannelList(params).then(res => {
          this.tableList = res.data.records;
          this.tableList.unshift({
            orgName: '通用机构',
            orgId: '0'
          })
        });
        this.$store.commit('SET_TABLE_LOADING', false)

      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
      this.checkList = {
        orgName: chooseObject.orgName,
        orgId: chooseObject.orgId
      }
      console.log(this.checkList)
      this.radio = chooseObject.orgId

    },

    closeDialog () {
      this.showDetail = false
      // this.categoryId = null
      // this.$emit('update:open', false)
      // this.filters = {};
      // this.createTimeEnd = ''
      // this.createTimeStart = ''
      // this.createTime = [];
      this.pageData.size = 10
      this.pageData.current = 1
      this.categoryId = ''
      this.templateRadio = ''
      this.templateSelection = []
      this.selectData = [];
      // this.$refs.table.$refs.tableLayout.clearSort()
    },

    confirm () {
      this.$emit('handleSelect', this.checkList)
      this.selectData = [];
      this.closeDialog()
    },


  }
}
</script>
<style scoped lang="scss">
.boxCheck {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  .itemCheck {
    width: 100%;
    height: 50px;
  }
}
.flex-class {
  display: flex;
}
</style>
