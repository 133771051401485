<!--
 * @Description: 个人资料
 * @Author: zjc
 * @Date: 2022-04-15 20:47:52
 * @LastEditTime: 2022-04-16 11:36:27
 * @LastEditors: zjc
-->
<template>
  <div>
    <div class="flex">
      <div class="flex-left">
        <!-- 个人信息 -->
        <personal-information></personal-information>
      </div>
      <div class="flex-right">
        <!-- 操作日志 -->
        <operation-log></operation-log>
      </div>
    </div>
  </div>
</template>
<script>
import personalInformation from './component/personalInformation'
import operationLog from './component/operationLog.vue'
export default {
  components: { personalInformation, operationLog },
  data () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  &-left {
    flex: 0 0 350px;
  }
  &-right {
    flex: 1;
  }
}
</style>
