<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" size="small" label-width="0px" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #auditStatus>
              <el-form-item label="状态" label-width="100px">
                <el-select v-model="filters.auditStatus" clearable placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template #remainingAvailable>
              <el-form-item label="剩余可用额度" label-width="100px">
                <el-select v-model="complete" clearable placeholder="请选择">
                  <el-option v-for="item in remainingAvailable" :key="item.value"
                    :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>

          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" size="small" @click="addOrUpdate('')">
            申请代捐
          </el-button>
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="money" slot-scope="scope">
        <div
          v-if="scope.row.auditStatus == 2 && scope.row.donateAmount - scope.row.alreadyDonateAmount >0">
          {{Math.floor((scope.row.donateAmount - scope.row.alreadyDonateAmount) * 100) / 100}}
          <!-- {{ scope.row.donateAmount - scope.row.alreadyDonateAmount}} -->
        </div>
        <div v-else>0.00</div>
      </template>
      <template slot="auditStatus" slot-scope="scope">
        <div v-if="scope.row.donateAmount - scope.row.alreadyDonateAmount >0">
          <div v-if="scope.row.auditStatus == 1">待审核</div>
          <div v-else-if="scope.row.auditStatus == 2">可代捐</div>
          <div v-else-if="scope.row.auditStatus == 3">审核不通过</div>
        </div>
        <div v-else>
          <div v-if="scope.row.auditStatus == 2">已完成</div>
        </div>
      </template>

      <template slot="operation" slot-scope="scope">
        <div style="display:flex;flex-wrap:nowrap;justify-content: flex-start;">
          <div style="margin:0 10px 0 0">
            <el-button type="text" size="small" @click="searchDetail(scope.row)">查看
            </el-button>
          </div>
          <!-- 1-待审核 2-审核通过 3-审核不通过 -->
          <div v-if="scope.row.donateAmount - scope.row.alreadyDonateAmount >0">
            <el-button v-if="scope.row.auditStatus == 2" type="text" size="small"
              @click="openPermissions(scope.row)">代捐
            </el-button>
          </div>
        </div>

      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <el-dialog modal-append-to-body title="申请代捐" width="800px" @close="cancelDialog" append-to-body
      :close-on-click-modal="false" top="10vh" :visible.sync="showDialog">
      <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
        size="medium" :rules="tableFormRules" labelWidth="90px">
        <div class="table_one">
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="用户:" prop="mobile">
            <el-input maxlength="11" show-word-limit v-model.number="tableForm.mobile" clearable
              placeholder="请输入用户手机号" style="width: 80%">
            </el-input>
          </el-form-item>
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="额度:" prop="donateAmount">
            <el-input v-model="tableForm.donateAmount" clearable placeholder="请输入用户捐赠额度"
              style="width: 80%">
            </el-input>
          </el-form-item>
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="捐赠对象:" prop="pressOrgNo">
            <div class="box_item box_item_radio" style="width:100%;">
              <el-cascader ref="ownArea" popper-class="selectArea" filterable style="width:80%"
                :options="options" v-model="chooseList"
                :props="{ multiple: false, checkStrictly: false }" clearable></el-cascader>
            </div>
          </el-form-item>
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="申请说明:" prop="remark">
            <el-input type="textarea" style="width: 80%" :rows="3" placeholder="请输入300字内说明"
              v-model="tableForm.remark" maxlength="300" show-word-limit>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog" size="medium">取消</el-button>
        <el-button type="primary" @click="rowSave" size="medium" :loading="loading">确定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { getStore } from '@/utils/store'
import { ROLE_API } from "@/service/permissions/channel";
import { DONATION_API } from "@/service/permissions/donation";
export default {
  data () {
    const validPhone = (rule, value, callback) => {
      let tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // if (!value.trim()) {
      //   callback(new Error("请输入用户名"));
      // } else {
      if (!tel.test(value)) {
        callback(new Error("手机号格式错误!"));
      } else {
        callback();
      }
      // }
    };
    return {
      complete: '',
      options: [],
      chooseList: [],
      authOptions: [
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '审核不通过', value: 3 },
      ],
      remainingAvailable: [
        { label: '0', value: 1 },
        { label: '1-499.99', value: 2 },

        { label: '500-999.99', value: 3 },
        { label: '1000-1999.99', value: 4 },
        { label: '2000-4999.99', value: 5 },
        { label: '5000以上', value: 6 },
      ],
      showDialog: false,
      filters: {},
      pageTotal: 0,
      list: [],
      tableForm: {
        mobile: '',
        donateAmount: '',
        remark: '',
      },
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      tableFormRules: {
        mobile: [{ required: true, trigger: "blur", validator: validPhone }],
        donateAmount: [
          { required: true, message: "请输入用户捐赠额度", trigger: "blur" },
        ],
        pressOrgNo: [
          { required: true, message: "请选择捐赠对象", trigger: "blur" },
        ],
      },
      // 表格查询条件配置
      tableOption: {
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 100, fixed: 'right', align: 'center' },
        column: [
          { prop: "donateUserMobile", label: "代捐用户", tableProp: true },
          { prop: "donateAmount", label: "申请额度", tableProp: true },
          { prop: "money", label: "剩余可用额度", tableProp: true, slot: true, },
          { prop: "createTime", label: "发起时间", tableProp: true },
          { prop: "auditStatus", label: "状态", slot: true, tableProp: true },
        ],
      },
      //搜索
      searchOption: [
        {
          prop: "content",
          label: "",
          type: "input",
          placeholder: "搜索代捐用户",
          option: { clearable: true }
        },
        {
          prop: "remainingAvailable",
          label: "剩余可用额度",
          col: 5,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },
        {
          prop: "auditStatus",
          label: "状态",
          col: 5,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },

      ],
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数

    };
  },
  mounted () {
    this.getList();
    this.getOptions();
  },
  watch: {
    'tableForm.donateAmount': function () {
      this.tableForm.donateAmount = this.tableForm.donateAmount.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace(/^\./g, '') // 保证第一个为数字而不是.
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
    },

  },
  methods: {
    // 获取渠道列表
    async getList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { data } = await DONATION_API.getdonationList({
          ...this.pageData,
          ...this.filters,
        });
        console.log(data)
        this.list = data.records;
        this.pageTotal = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    async getOptions () {
      try {
        let params = {}
        params.appId = this.appId
        const { data } = await ROLE_API.getOrgList(params)
        console.log(data)
        data.forEach((item) => {
          this.options.push({
            value: item,
            label: item.pressOrgName
          })
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },

    changePage (val) {
      this.pageData.current = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getList();
    },
    addOrUpdate (row = {}) {
      this.showDialog = true;
    },
    searchDetail (row) {
      console.log(row)
      this.$router.push({
        path: "./donationDetail",
        query: { applyId: row.applyId }
      });
    },
    // 跳转到详情页 查看
    openPermissions (row) {
      console.log(row)
      this.$router.push({
        path: "./donationAdd",
        query: { applyId: row.applyId }
      });
    },
    //重置
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.filters.balanceStart = ''
      this.filters.balanceEnd = ''
      this.complete = ''
      this.$nextTick(() => {
        this.getList();
      });
    },
    //搜索条件
    search () {
      this.pageData.current = 1;
      if (this.complete == 1) {
        // console.log('111')
        this.filters.balanceStart = -10
        this.filters.balanceEnd = 0
      }
      else if (this.complete == 2) {
        this.filters.balanceStart = 1
        this.filters.balanceEnd = 499.99
      }
      else if (this.complete == 3) {
        this.filters.balanceStart = 500
        this.filters.balanceEnd = 999.99
      }
      else if (this.complete == 4) {
        this.filters.balanceStart = 1000
        this.filters.balanceEnd = 1999.99
      }
      else if (this.complete == 5) {
        this.filters.balanceStart = 2000
        this.filters.balanceEnd = 4999.99
      }
      else if (this.complete == 6) {
        this.filters.balanceStart = 5000
        this.filters.balanceEnd = 9999999
      } else {
        this.filters.balanceStart = ''
        this.filters.balanceEnd = ''
      }
      this.getList();
    },
    cancelDialog () {
      this.showDialog = false;
      this.tableForm = {};
      this.chooseList = {};
      this.tableForm.themeImg = '';
      this.$nextTick(() => {
        this.$refs['submitForm'].resetFields();
      })
    },
    async rowSave () {
      if (!this.tableForm.mobile) {
        this.$message.error("请输入用户手机号");
        return;
      }
      let value = this.tableForm.mobile
      let tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!tel.test(value)) {
        this.$message.error("手机号格式错误");
        return
      }

      if (!this.tableForm.donateAmount) {
        this.$message.error("请输入用户捐赠额度");
        return;
      }
      console.log(this.chooseList)
      if (this.chooseList == null) {
        this.$message.error('请选择捐赠对象');
        return
      }
      let params = this.tableForm;
      // let arr = []
      this.chooseList.forEach(item => {
        // arr.push({
        //   doneeLogo: item[0].pressOrgLogo,
        //   doneeName: item[0].pressOrgName,
        //   pressOrgNo: item[0].pressOrgNo
        // })
        params.pressOrgNo = item.pressOrgNo
      })
      // params.pressOrgNo = arr
      console.log(params)

      try {
        const { data } = await DONATION_API.changedetailAdd(params);
        console.log(data);
        this.$ok("新增成功");
        // this.$refs.modal.loading = false;
        this.showDialog = false;
        this.tableForm = {};
        this.getList();
      } catch (err) {
        // this.$refs.modal.loading = false;
      }
    },
    //修改渠道
    async rowUpdate (val) {
      try {
        const { data } = await ROLE_API.updateChannel(val);
        this.$ok("修改成功");
        this.$refs.modal.loading = false;
        this.showDialog = false;
        this.getList();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      console.log(res.data, file)
      this.$set(this.tableForm, "themeImg", res.data.link);
      this.$forceUpdate()
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
  },

};
</script>

<style lang="scss">
.ql-editor {
  min-height: 200px;
}

.box_opration {
  display: flex;
  flex-wrap: nowrap;
  width: 74%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  // border-top: 1px solid #e5e5e5;
}
.bottom_table {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto 0;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
  }
  .bottom_right {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .txt1 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .txt2 {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
    .boxPlay {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      width: 300px;
      .title2 {
        width: 100px;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 12px;
  }
  .redTit {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .redTit3 {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 90px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit3::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}

.el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
</style>
<style  lang="scss">
.is-selectable {
  .el-radio {
    width: 90%;
    position: absolute;
    left: 8px;
  }
}
.box_item_radio {
}
</style>
