<!--  新增需求 -->
<template>
  <basic-container>
    <div class="">
      <div class="abox_title">
        <div class="left_title">
          <p class="title">捐赠排行设置</p>
        </div>
      </div>
      <div class="abox_content">
        <div class="item_content">
          <div class="left">
            <p class="numTxt">1</p>
            <div class="left_bottom">
              <p class="txt">捐赠图书榜</p>
            </div>
          </div>
        </div>
        <div class="item_content" v-show="objectMsg[0]">
          <div class="left">
            <p class="numTxt">2</p>
            <div class="left_bottom">
              <p class="txt">{{showName}}榜</p>
              <p class="txt1" v-if="objectMsg[0]">落款：{{objectMsg[0].identityLabel}}
              </p>
            </div>
          </div>
          <div class="right">
            <p @click="addOrUpdate()"><i class="el-icon-edit" style="color:#2d8cf0"></i></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增、编辑渠道 -->
    <el-dialog modal-append-to-body title="榜单名称" width="800px" @close="cancelDialog" append-to-body
      :close-on-click-modal="false" top="10vh" :visible.sync="showDialog">
      <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" :model="tableForm"
        size="medium" :rules="tableFormRules" labelWidth="0px">
        <div class="table_one">
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="" prop="name">
            <div class="box_item">
              <p class="redTitle">排行榜名称</p>
              <el-input type="input" style="width: 80%" placeholder="请输入4个字" v-model="name"
                maxlength="4" show-word-limit>
              </el-input>
              <div class="el-form-item__error" v-show="errorShow">
                请输入4个字以内
              </div>
              <span> 榜</span>
            </div>

          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog" size="medium">取消</el-button>
        <!-- <el-button type="primary" @click="rowUpdate" size="medium" :loading="loading"
          v-if="dialogData.id">确定</el-button> -->
        <el-button type="primary" @click="rowSave" size="medium" :loading="loading">确定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { DONATION_API } from "@/service/permissions/donation";
export default {
  data () {
    return {
      errorShow: false,
      objectMsg: [],
      pressOrgNo: '',
      list: [
        { name: '捐赠图书馆', label: 1 },
        { name: '捐赠价值榜', label: 2 },
        { name: '捐赠图书馆', label: 3, txt: '所属院校' }
      ],
      showDialog: false,
      tableForm: {
        name: "",
      },
      showName: '',
      name: '',
      tableFormRules: {
        // name: [
        //   { required: true, message: "请输入4个字", trigger: "blur" },
        // ],
      },
    }
  },
  watch: {
    name: {
      handler: function (val, oldval) {
        this.errorShow = false
        this.name = this?.name?.replace(/[^\u4E00-\u9FA5]/g, '')
      },
      deep: true,
    },

  },
  created () {
    this.pressOrgNo = this.$route.query.pressOrgNo
    if (this.$route.query.pressOrgNo) {
      this.pressOrgNo = this.$route.query.pressOrgNo
      localStorage.setItem("pressOrgNo", this.$route.query.pressOrgNo)
    }
    else {
      if (localStorage.getItem("pressOrgNo")) {
        this.pressOrgNo = localStorage.getItem("pressOrgNo")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
  },
  mounted () {
    this.getList()
    this.getUseridentity();
  },
  methods: {
    // 主题详情
    async getUseridentity () {
      try {
        let params = {}
        params.pressOrgNo = this.pressOrgNo
        await DONATION_API.getUseridentity(params).then(res => {
          // this.tableForm = res.data

          res.data.items.forEach(otem => {
            this.objectMsg.push({
              identityLabel: otem.identityLabel,
              identityCode: otem.identityCode
            })
          })
          console.log(this.objectMsg)
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    addOrUpdate (row) {
      this.showDialog = true;
      this.name = this.showName
    },
    cancelDialog () {
      this.showDialog = false;
      this.name = {};
    },
    async rowSave (val) {
      if (this.name.length > 4) {
        this.errorShow = true
        return
      } else {
        this.errorShow = false
      }
      // this.tableForm.name = this.name
      try {
        let params = {
          pressOrgNo: this.pressOrgNo,
          name: this.name + '榜'
        }
        await DONATION_API.editrankName(params).then(res => {
          if (res.code == 0) {
            this.$ok("编辑成功");
            this.showDialog = false;
            this.tableForm = {};
            this.getList();
          }
        });
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
    async getList () {
      try {
        let params = {
          pressOrgNo: this.pressOrgNo
        }
        await DONATION_API.getrankName(params).then(res => {
          // this.list = res.data
          this.showName = res?.data?.replace(/榜$/, '');

        });

        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
  }
}
</script>
<style scoped lang="scss">
.el-form-item__error {
  left: 20% !important;
}
.box_item {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  justify-content: flex-end;
  .redTitle {
    width: 20%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 10px;
    position: relative;
  }
  .redTitle::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: red;
  }
}
.abox_content {
  background-color: #ffffff;
  width: 100%;
}
.item_content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(237, 237, 237, 1);
  height: 80px;
  .left {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .left_bottom {
      .txt {
        font-weight: 650;
        color: #666666;
        font-size: 13px;
        margin: 0 0 5px 0;
      }
      .txt1 {
        font-weight: 400;
        color: #7f7f7f;
        font-size: 12px;
      }
    }
  }
  .right {
    width: 50px;
    height: 50px;
    font-size: 22px;
    margin: 10px 20px 0 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .numTxt {
    width: 50px;
    height: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 1);
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    margin: 0 30px;
  }
}
</style>
