
import request from '@/utils/request'
const getList = (param) => request('get', '/user/article/page', param)
const getDetail = (param) => request('get', '/user/article/detail', param)
const addList = (param) => request('post', '/user/article/add', param, { requestType: 'JSON' })
const editList = (param) => request('post', '/user/article/edit', param, { requestType: 'JSON' })

const changeStatus = (param) => request('post', '/user/article/change-status', param, { requestType: 'JSON' })
export const DONATION_API = {
  getList, getDetail, addList, editList, changeStatus
}
