const toolbars = [
  [
    // 'fullscreen', // 全屏
    '|',
    'source', // 源代码
    '|',
    'undo',
    'redo',
    '|',
    'bold',
    'italic',
    'underline',
    'fontborder',
    'strikethrough',
    'superscript',
    'subscript',
    'removeformat',
    'formatmatch',
    'autotypeset',
    'blockquote',
    'pasteplain',
    '|',
    'forecolor',
    'backcolor',
    // 'insertorderedlist',  // 有序
    // 'insertunorderedlist', // 无序
    'selectall',
    'cleardoc',
    '|',
    'rowspacingtop',
    'rowspacingbottom',
    'lineheight',
    '|',
    'customstyle',
    'paragraph',
    'fontfamily',
    'fontsize',
    '|',
    'directionalityltr',  // 文字方向
    'directionalityrtl', // 文字方向
    'indent',
    '|',
    'justifyleft',
    'justifycenter',
    'justifyright',
    'justifyjustify',
    '|',
    'touppercase',
    'tolowercase',
    '|',
    'link',
    'unlink',
    'anchor',
    '|',
    'imagenone',
    'imageleft',
    'imageright',
    'imagecenter',
    '|',
    // 'simpleupload',
    // 'insertimage',
    'emotion',
    // 'scrawl', // 涂鸦
    // 'insertvideo',
    // 'music',
    // 'attachment', // 附件
    // 'map',
    // 'gmap',
    // 'insertframe',
    // 'insertcode',
    // 'webapp',
    'pagebreak', // 分页
    'template',
    'background', // 编辑器背景
    '|',
    'horizontal',
    'date',
    'time',
    'spechars',
    // 'snapscreen', // 截图
    // 'wordimage',
    // '|',
    'inserttable',
    'deletetable',
    'insertparagraphbeforetable',
    'insertrow',
    'deleterow',
    'insertcol',
    'deletecol',
    'mergecells',
    'mergeright',
    'mergedown',
    'splittocells',
    'splittorows',
    'splittocols',
    'charts',
    '|',
    'print',
    // 'preview', //预览
    'searchreplace', // 查询、替换
    'help', // 帮助
    // 'drafts'
  ]
]

export default toolbars
