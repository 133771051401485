/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:49:39
 * @LastEditTime: 2022-05-27 14:36:01
 * @LastEditors: ym ym@geeboo.cn
 */
import router from '@/router'
import store from '@/store'
import { title } from '@/config'
import { COMMON_API } from '@/utils/common'
import { getStore, removeStore } from '@/utils/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Notification } from 'element-ui'
NProgress.configure({ showSpinner: false });
// //获取面包屑列表
// const setCrumbs = (menuId) => {
//   // 将树形菜单转成数组
//   const routeList = COMMON_API.treeToList(store.getters.menuList)
//   let navTitles = []
//   let leftMenuId = null
//   routeList.forEach(item => {
//     if (menuId === item.id) {
//       navTitles.push(item.name)
//       routeList.forEach(item1 => {
//         if (item.parentId === item1.id) {
//           navTitles.push(item1.name)
//           leftMenuId = item.parentId
//           routeList.forEach(item2 => {
//             if (item1.parentId === item2.id) {
//               navTitles.push(item2.name)
//               leftMenuId = item1.parentId
//             }
//           })
//         }
//       })
//     }
//   })
//   if (navTitles?.length) navTitles = navTitles.reverse()
//   else navTitles = ['首页']
//   store.commit('SET_CRUMBS', navTitles)
// }
//不需要登录
const whiteList = ['/']
router.beforeEach(async (to, from, next) => {
  store.commit('SET_ACTIVE_INDEX', to.path)
  // 开启progress bar
  NProgress.start();
  const token = getStore({ name: 'userToken' })
  // setCrumbs(to.meta.menuId)
  if (!COMMON_API.validatenull(token)) {
    if (to.path === '/') {
      next({ path: '/oms/dashboard' })
    } else {
      // console.log(to.path)
      if (COMMON_API.validatenull(store.getters.routers)) {
        store.dispatch('getInfo', { parentId: 0, type: 'left' }).then(res => {
          console.log(res, 'res');
          const permission = res
          store.dispatch('GenerateRoutes', permission).then(() => {
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
            next({ ...to, replace: true })
          })
        }).catch((err) => {
          console.log(err, 'err');
          store.dispatch('logOut').then(() => {
            Notification({ type: 'error', title: '错误', message: '验证失败，请重新登录' })
            store.commit('SET_MENU_ALL', [])
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      NProgress.done();
      next({ path: '/' })
    }
  }
});
router.afterEach((to, form) => {
  // 关闭progress bar
  NProgress.done();
  // document.title = `${to.name ? to.name : ''} - ${title}`
  document.title = `${title}`
  store.commit('SET_TITLE', to.name)
})