<template>
  <div class="replace-donation-record">
    <el-dialog
      title="捐赠寄语替换记录"
      modal-append-to-body
      append-to-body
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleClose"
      @closed="closed"
    >
      <div v-loading="loading">
        <v-table ref="table" :tableData="list" :tableOption="tableOption">
          <template slot="lastDonationImg" slot-scope="scope">
            <img
              class="img"
              :src="scope.row.lastDonationImg"
              alt=""
              @click="check(scope.row.lastDonationImg)"
            />
            <el-button
              style="vertical-align: top; margin: 37px 0 0 6px"
              type="text"
              size="small"
              v-if="scope.row.lastDonationImg"
              @click="check(scope.row.lastDonationImg)"
              >查看</el-button
            >
          </template>
          <template slot="currentDonationImg" slot-scope="scope">
            <img
              class="img"
              :src="scope.row.currentDonationImg"
              alt=""
              @click="check(scope.row.currentDonationImg)"
            />
            <el-button
              style="vertical-align: top; margin: 37px 0 0 6px"
              type="text"
              size="small"
              v-if="scope.row.currentDonationImg"
              @click="check(scope.row.currentDonationImg)"
              >查看</el-button
            >
          </template>
        </v-table>
      </div>
    </el-dialog>

    <look-donation-message
      :imgUrl="imgUrl"
      ref="donationRef"
    ></look-donation-message>
  </div>
</template>
<script>
import lookDonationMessage from "./lookDonationMessage.vue";
import { DONATION_API } from "@/service/permissions/donation";

export default {
  components: {
    lookDonationMessage,
  },
  props: {
    recordId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      imgUrl: "",
      list: [],
      // 表格查询条件配置
      tableOption: {
        align: "center",
        column: [
          {
            prop: "lastDonationImg",
            label: "变更前",
            tableProp: true,
            slot: true,
          },
          {
            prop: "currentDonationImg",
            label: "变更后",
            tableProp: true,
            slot: true,
          },
          { prop: "oprName", label: "变更人", tableProp: true },
          { prop: "createTime", label: "变更时间", tableProp: true },
        ],
      },
    };
  },
  methods: {
    init() {
      this.dialogVisible = true;
      if (this.recordId) {
        this.getDetail();
      }
    },
    check(url) {
      this.imgUrl = url;
      this.$nextTick(() => {
        this.$refs.donationRef.init();
      });
    },
    getDetail() {
      this.loading = true;
      DONATION_API.donationMessageChangeRecordPage({ recordId: this.recordId })
        .then((res) => {
          this.list = res.data;
          this.list.sort((a, b) => {
            const dateA = new Date(a.createTime);
            const dateB = new Date(b.createTime);
            return dateB - dateA;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closed() {
      this.list = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.img {
  height: 110px;
  width: 75px;
  cursor: pointer;
}
</style>