<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;">
        <div class="abox_title">
          <div class="left_title">
            <p class="title">代捐详情</p>
            <div class="rightTitle">状态：
              <div v-if="ruleForm.donateAmount - ruleForm.alreadyDonateAmount >0">
                <span v-if="ruleForm.auditStatus == 1">待审核</span>
                <span v-else-if="ruleForm.auditStatus == 2">可代捐</span>
                <span v-else-if="ruleForm.auditStatus == 3">审核不通过</span>
              </div>
              <div v-else>
                <span v-if="ruleForm.auditStatus == 2">已完成</span>
              </div>

            </div>
          </div>
        </div>
        <div style="width:100%">
          <div class="tab-one" style="width:100%">
            <div class="tab-title">
              <span>申请信息</span>
            </div>
            <div class="tab-content2">
              <div class="box_tab_one">
                <div class="content">
                  <div class="item">
                    <span class="l1">代捐用户：</span>
                    <span class="r1">{{ruleForm.donateUserMobile}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">用户昵称：</span>
                    <span class="r1">{{ruleForm.donateUserName}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">申请额度：</span>
                    <span class="r1">{{ruleForm.donateAmount}}</span>
                  </div>
                </div>
                <div class="content">
                  <div class="item">
                    <span class="l1">捐赠对象：</span>
                    <span class="r1">{{ruleForm.pressOrgName}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">申请说明：</span>
                    <span class="r1">{{ruleForm.remark}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">发起时间：</span>
                    <span class="r1">{{ruleForm.createTime}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="ruleForm.auditStatus == 2 || ruleForm.auditStatus == 3"
          style="width:100%;margin:30px 0 30px 0">
          <div class="tab-one" style="width:100%">
            <div class="tab-title">
              <span>审核信息</span>
            </div>
            <div class="tab-content2">
              <div class="box_tab_one">
                <div class="content">
                  <div class="item">
                    <span class="l1">审核人：</span>
                    <span class="r1">{{ruleForm.auditUserName}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">审核结果：</span>
                    <span class="r1" v-if="ruleForm.auditStatus == 1">待审核</span>
                    <span class="r1" v-else-if="ruleForm.auditStatus == 2">审核通过</span>
                    <span class="r1" v-else-if="ruleForm.auditStatus == 3">审核不通过</span>
                  </div>
                  <div class="item">
                    <span class="l1">审核时间：</span>
                    <span class="r1">{{ruleForm.auditTime}}</span>
                  </div>
                </div>
                <div class="content2">
                  <div class="item">
                    <span class="l1">审核说明：</span>
                    <span class="r1">{{ruleForm.auditOpinion}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="ruleForm.auditStatus == 2 " class="tab-one" style="margin:30px 0 30px 0">
          <div class="tab-title">
            <div class="left_title">捐赠记录</div>
            <div class="right_title">
              <span style="font-weight:500;font-size: 12px;color: #333333;margin:0 20px 0 0">
                剩余捐赠额度：
                <span v-if="ruleForm.donateAmount - ruleForm.alreadyDonateAmount > 0">
                  {{ Number((ruleForm.donateAmount - ruleForm.alreadyDonateAmount).toString().match(/^\d+(?:\.\d{0,2})?/)) }}
                </span>
                <span v-else>
                  <span v-if="ruleForm.auditStatus == 2">0</span>
                </span>
                元</span>
              <el-button v-if="ruleForm.donateAmount - ruleForm.alreadyDonateAmount > 0"
                type="primary" size="small" @click="jumpToadd('')">
                代捐
              </el-button>
            </div>
          </div>
          <v-table style="width:100%;margin:0 auto 20px;" class="itemTble" ref="table"
            :tableData="tableList" :tableOption="tableOption"
            @handleSelectionChange="selectionChange">
            <!-- <template slot="fullInscriptionName" slot-scope="scope">
              <div v-for="(item,index) in scope.row.orderIdentitys" :key="index">
                <span v-if="item.identityCode == 'SCHOOL'">{{item.identityValue}}</span>
              </div>
            </template> -->
            <template slot="doneeName">
              <div>{{ruleForm.pressOrgName}}</div>
            </template>
            <template slot="packageStatus" slot-scope="scope">
              <div v-if="scope.row.packageStatus == 1">启用</div>
              <div v-else-if="scope.row.packageStatus == 2">停用</div>
            </template>
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="searchDetail(scope.row)">查看
              </el-button>
            </template>
          </v-table>
        </div>
        <!-- 分页 -->
        <!-- <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
          @hanlePage="handleSizeChange"></pagination> -->
      </div>
      <!-- 新增、编辑渠道 -->
      <!-- <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item> -->
      <!-- 新增租户 -->
      <el-dialog @closed="cancelDialog" :title="title" :visible.sync="dialogVisible" width="800px"
        :close-on-click-modal="false" append-to-body class="updateModal">
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm" labelWidth="90px"
          :rules="tableFormRules">
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="主题名称:" prop="themeName">
            <div>
              <el-input maxlength="10" style="width: 80%" show-word-limit
                v-model="addForm.themeName" clearable placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <div class="goodImgBox">
            <span class="redTit3">图片:</span>
            <div style="display: inline-block; vertical-align: top;display: flex;
    flex-wrap: nowrap;
    align-items: center;">
              <img v-if="addForm.themeImg" :src="addForm.themeImg" class="avatar"
                style="display: inline-block;vertical-align: middle;margin: 0 20px 0 0;" />
              <div>
                <el-upload class="upload-demo" v-if="addForm.themeImg" action="/api/file/oss/upload"
                  accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
                  name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload"
                  style="display: inline-block">
                  <el-button size="small" type="primary">更换图片</el-button>
                </el-upload>
                <el-upload v-else class="avatar-uploader" style="display: block; text-align: left"
                  action="/api/file/oss/upload" accept=".jpg,.jpeg,.png" :show-file-list="false"
                  multiple :headers="headers" name="file" :on-success="handleNomalSuccess"
                  :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </div>
            </div>
            <div class="rightText">
              <span class="atext">仅可上传jpg、jpeg、png</span>
            </div>
          </div>
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="简介:" prop="themeContent">
            <div>
              <el-input type="textarea" style="width: 80%" :rows="3" placeholder="请输入内容"
                v-model="addForm.themeContent" maxlength="50" show-word-limit>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog()">取 消</el-button>
          <el-button type="primary" @click="rowSave()" :loading="loading">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </basic-container>
</template>
<script>
import { DONATION_API } from "@/service/permissions/donation";
import { getStore } from '@/utils/store'
import { ROLE_API } from "@/service/permissions/channel";
export default {
  data () {
    return {
      tableFormRules: {
        themeName: [
          { required: true, message: "请输入5字内名称", trigger: "blur" },
        ],

        themeContent: [
          { required: true, message: "请输入50字内简介", trigger: "blur" },
        ],
      },
      value: true,
      options: [],
      pageTotal: 0,
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      dialogVisible: false,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数

      searchText: '',
      tableList: [],
      //表格配置
      tableOption: {
        selection: false,
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, align: 'center' },
        // operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: '120px', fixed: 'center', align: 'center' },
        column: [
          { prop: 'orderId', label: '订单号', tableProp: true, },
          { prop: 'doneeName', label: '捐赠对象', slot: true, tableProp: true, },
          { prop: 'goodsCnt', label: '复本数（册）', tableProp: true, },
          { prop: 'totalAmount', label: '原价（元）', tableProp: true, },
          { prop: 'actualAmount', label: '金额（元）', tableProp: true, },
          { prop: 'payTime', label: '代捐时间', tableProp: true, },
        ],
      },
      ruleForm: {},
      addForm: {},
      createTime: null,
      purchaseOrderId: null,
      actualAmount: null,
      status: 0,//0新增|1查看
      goodsCnt: 0,
      sortCnt: 0,
      totalAmount: 0,
      selectList: [],
    }
  },
  created () {
    // console.log(this.$route.query)
    if (this.$route.query.applyId) {
      this.applyId = this.$route.query.applyId
      localStorage.setItem("applyId", this.$route.query.applyId)
    }
    else {
      if (localStorage.getItem("applyId")) {
        this.applyId = localStorage.getItem("applyId")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.getList()
  },
  mounted () {
    // this.getOptions();//获取捐赠对象机构
  },
  // watch: {
  //   'addForm.themeName': function () {
  //     // var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
  //     this.addForm.themeName = this.addForm.themeName
  //       .replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
  //   },
  // },
  methods: {
    // 代捐详情
    async getList () {
      try {
        let params = this.pageData
        params.applyId = this.applyId
        await DONATION_API.getDetail(params).then(res => {
          console.log(res.data)
          this.ruleForm = res.data;
          this.tableList = res.data.orderDetailsList;
          // if (res.data.themeStatus == 3 || res.data.themeStatus == 1) {
          //   this.value = false
          // }
          // else {
          //   this.value = true
          // }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async changeSwitch (val) {
      let params = {}
      params.applyId = this.applyId
      console.log(val)
      if (val === true) {
        // console.log('打开')
        params.themeStatus = 2
      }
      else {
        // console.log('关闭')
        params.themeStatus = 3
      }
      try {
        await DONATION_API.changedetailById(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.dialogVisible = false
            this.getList();//获取捐赠对象
          }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      this.addForm.themeImg = res.data.link
      this.$set(this.addForm, "themeImg", res.data.link);
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    jumpToadd (row) {
      console.log(row)
      this.$router.push({
        path: "./donationAdd",
        query: { applyId: this.applyId }
      });
      // let packageId = ''
      // if (row) {
      //   packageId = row.packageId
      //   this.$router.push({
      //     path: "./donationAdd",
      //     query: { applyId: this.applyId, packageId: row.packageId }
      //   });
      // } else {
      //   this.$router.push({
      //     path: "./donationAdd",
      //     query: { applyId: this.applyId }
      //   });
      // }
    },
    searchDetail (row) {
      console.log(row)

      this.$router.push({
        path: "../../order/orderDetail",
        query: { orderId: row.orderId, pressOrgName2: this.ruleForm.pressOrgName }
      });
    },
    cancelDialog () {
      this.dialogVisible = false
      // this.addForm = {}
      // this.addForm = this.ruleForm

    },
    //新增/编辑捐赠机构
    async rowSave () {
      if (!this.addForm.themeName) {
        this.$message.error('请输入主题名称');
        return
      }
      if (!this.addForm.themeContent) {
        this.$message.error('请输入简介');
        return
      }
      if (!this.addForm.themeImg) {
        this.$message.error("请上传图片");
        return;
      }
      let params = {}
      params.applyId = this.applyId
      params.themeName = this.addForm.themeName
      params.themeImg = this.addForm.themeImg
      params.themeContent = this.addForm.themeContent
      // let arr = []

      try {
        await DONATION_API.editDonation(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.dialogVisible = false
            this.getList();//获取捐赠对象
          }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async getOptions () {
      try {
        let params = {}
        params.appId = this.appId
        const { data } = await ROLE_API.getOrgList(params)
        console.log(data)
        data.forEach((item) => {
          this.options.push({
            value: item,
            label: item.pressOrgName
          })
        });

        // this.tableList = data.records;
        // 
        // this.pageTotal = data.total;
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },

    async getDonationList () {
      try {
        let params = this.pageData
        params.applyId = this.applyId
        await DONATION_API.packageList(params).then(res => {
          console.log(res)
          this.tableList = res.data.records;
          this.pageTotal = res.data.total;
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getDonationList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getDonationList();
    },
    //启用
    openPermissions (item) {
      console.log(item)
      let msg = '';
      let itemStatus = 1
      if (item.packageStatus == 1) {
        msg = '停用'
        itemStatus = 2
      } else {
        msg = '启用'
      }
      let params = {}
      params.packageId = item.packageId,
        params.packageStatus = itemStatus
      this.$confirm(`是否` + msg + `该捐赠包？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DONATION_API.changePackage(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("更改成功");
            // this.$ok("已取消");
            this.getDonationList();
          }
        });
      })
        .catch(() => {
          //
        });
    },
    // 新增捐赠渠道
    addOrUpdate (row = {}) {
      row = this.ruleForm
      this.title = "修改捐赠包主题信息";
      this.dialogVisible = true;
      if (!this.$validatenull(row)) {
        const { themeName, themeContent, themeImg } = row;
        this.addForm = { themeName, themeContent, themeImg };
      }
    },
    jumptoPage (url) {
      window.location.href = url
    },
    closeDialog () {
      this.tableList = []
    },
  }
}
</script>
<style lang="scss">
.selectArea {
  .el-checkbox {
    width: 95%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
  }
}
</style>
<style scoped lang="scss">
.box_item {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  justify-content: flex-end;
  .redTitle {
    width: 16%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 10px;
    position: relative;
  }
  .redTitle::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: red;
  }
}
.redTit3 {
  font-size: 14px;
  color: #606266;
  display: inline-block;
  width: 130px;
  text-align: right;
  padding-right: 13px;
  font-weight: 500 !important;
}
.redTit3::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        background-color: #ffffff;

        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            align-items: center;
            display: flex;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
        .content2 {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 90%;
            margin: 0 0 20px 0;
            align-items: center;
            display: flex;
            align-items: flex-start;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              width: 70%;
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  // width: 1310px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
    .rightTitle {
      display: flex;
      flex-wrap: nowrap;
      box-shadow: none;
      font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
      span {
        color: #02a7f0;
      }
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}

.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 12px;
  }
  .redTit {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 130px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
