/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:43:40
 * @LastEditTime: 2022-06-27 16:47:02
 * @LastEditors: zjc
 */
import { EMPTY_API } from '@/utils/emptyObject.js'
/**
 * 判空
 * @param {val}  
 * */
const validatenull = (val) => {
  if (typeof val == 'boolean' || typeof val == 'number') return false;
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (['null', null, 'undefined', undefined, ''].includes(val)) return true;
    return false;
  }
  return false;
}
// 表单序列化
const serialize = (data, step = '&') => {
  const type = getType(data)
  if (type === 'number' || type === 'date' || type === 'boolean' || type === 'string' || type === 'array') return
  const list = []
  Object.keys(data).forEach((key) => {
    list.push(step === '&' ? `${key}=${data[key]}` : `${data[key]}`)
  })
  return data = list.join(step)
}
/**
 * 数组对象去重
 * @param {arr,id}  
 */

const uniqueFunc = (arr, id) => {
  const res = new Map();
  return arr.filter((item) => !res.has(item[id]) && res.set(item[id], true));
}
/**
 * 数组去重
 * @param {arr}  
 */
const unique = (arr) => {
  if (!Array.isArray(arr)) return
  return [...new Set(arr)]
}
/**
 * 判断数据类型
 * @param {obj} 
 */
const getType = (obj) => {
  return typeof obj != 'object' ? typeof obj : Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
}
/**
 * 对象深拷贝
 * @param {*} data 
 * @returns 
 */
const deepClone = (o) => {
  if (o instanceof Function) {
    return o
  } else if (o instanceof Array) {
    var array = []
    for (var i = 0; i < o.length; ++i) {
      array[i] = deepClone(o[i])
    }
    return array
  } else if (o instanceof Date) {
    return new Date(o.valueOf())
  } else if (o instanceof Object) {
    var obj = {}
    for (var j in o) {
      obj[j] = deepClone(o[j])
    }
    return obj
  } else {
    return o
  }
}


/**
 * 按照ASCII排序
 * @param obj
 * @returns {{}}
 */
const sort = (obj = {}, type) => {
  return Object.keys(obj) // 过滤参数为空不参与签名
    .sort()
    .reduce((pre, cur) => {
      pre[cur] = obj[cur];
      return pre;
    }, {})
}
const sortEmpty = (obj = {}) => {
  return Object.keys(EMPTY_API.getEmptyObject(obj)) // 过滤参数为空不参与签名
    .sort()
    .reduce((pre, cur) => {
      pre[cur] = obj[cur];
      return pre;
    }, {})
}
/**
 * 树形结构转为集合
 * @param {*} tree 
 * @returns 
 */
const treeToList = (tree) => {
  var queen = [];
  var out = [];
  queen = queen.concat(tree);
  while (queen.length) {
    var first = queen.shift();
    if (first.children) {
      queen = queen.concat(first.children)
    }

    out.push(first);
  }
  return out;

}
/**
 * 根据字典的value查找label并显示
 * @param {*} dic 
 * @param {*} value 
 * @returns 
 */
const findValue = (dic, value) => {
  let result = ''
  if (validatenull(dic)) return value
  let index = 0
  if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'string') {
    index = findArray(dic, value)
    if (index != -1) {
      result = dic[index].label
    } else {
      result = '-'
    }
  }
  return result
}
/**
 * 根据字典的value 查找下标
 * @param {*} dic 
 * @param {*} value 
 * @returns 
 */
const findArray = (dic, value) => {
  for (let i in dic) {
    if (dic[i].value === value) return i
  }
  return -1
}
/**
 * 根据key查找配置column
 * @param {*} list 
 * @param {*} key 
 * @returns 
 */
const findObject = (list, key) => {
  for (let i of list) {
    if (i.prop === key) return i
  }
}
/**
 * 下载文件
 * @param {*} data 
 * @param {*} filePath 
 * @returns 
 */
const downBlob = (data, filePath) => {
  let blob = new Blob([data], {
    type: "application/octet-stream; charset=UTF-8"
  })
  if ('download' in document.createElement('a')) { // 非IE下载
    const elink = document.createElement('a')
    elink.download = filePath
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else { // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}
//过滤掉isHide为0的菜单
const filterMenu = (menuList, menuHide = ['/permissions/personal/personal-data']) => {
  if (!menuList) return []
  return menuList.filter(item => { return !menuHide.includes(item.url) }).map(item => {
    item = Object.assign({}, item)
    if (item.children && Reflect.has(item, 'children')) {
      item.children = filterMenu(item.children, menuHide)
    }
    return item
  })
}
//排序
const dataSort = (data, p) => {
  return data.sort((a, b) => {
    let x = a[p]
    let y = b[p]
    return x - y
  })
}
export default {
  validatenull
}
export const COMMON_API = {
  uniqueFunc,
  unique,
  getType,
  deepClone,
  sort,
  sortEmpty,
  serialize,
  validatenull,
  treeToList,
  findValue,
  findObject,
  downBlob,
  filterMenu,
  dataSort
}