/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-04-13 15:46:06
 * @LastEditTime: 2022-04-28 10:59:46
 * @LastEditors: zjc
 */
import JSEncrypt from 'jsencrypt'
// 加密公钥
const publciKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDALqjCE+ysm91es+a94lKQnr2NDlOJOl84hKqzXSVCs47yimr2Kkt6uPuGAXNY3jQ5K1VZTXK5DLgah+xpAkdrrlCyyyFni6VLZkmYEhDLSq9e+fvJmQZtBzU5W7EP8hLnHgoEWKj9bq4LTfKipA3fVi2cxDH+Mg0hQaein6eGewIDAQAB-----END PUBLIC KEY-----`
// 解密私钥
const privateKey = `-----BEGIN PRIVATE KEY-----
MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAMAuqMIT7Kyb3V6z5r3iUpCevY0OU4k6XziEqrNdJUKzjvKKavYqS3q4+4YBc1jeNDkrVVlNcrkMuBqH7GkCR2uuULLLIWeLpUtmSZgSEMtKr175+8mZBm0HNTlbsQ/yEuceCgRYqP1urgtN8qKkDd9WLZzEMf4yDSFBp6Kfp4Z7AgMBAAECgYAXX9VwvYGkwNRc516iK7vFETmWxW5Nm4bnVeN5K1QT18kEIOe/hwdlJsiY1OZVobkSVv3rfar9bNmbiHqtjvRmoyLsY3Z2UkweIcSscqJmiEAgklh7tfGFhATA5fiRQ8FbBQXaHcvcKNRHHNVz+cqf2pvyRJ0stREtz2XuKz80EQJBAPgHfmDdyNPiNaStFklbinSYa1x4u/mAK4IFNPI0agHqfds6Yfy7JgpcpCw6u5wn3lvt61VWwpRYGpt6DqVeO7MCQQDGW7gUfGikOJ4HqdxHOC/C9Y5muDQ+2LdWi76RVBPcvVed7fKY4wUB1cGqEQgaWJtKGL1k+3/jRmOLHLsW/IYZAkEAveX247FK7rcWPTPsiVPWCPkd0hn6ScfRB+Z2qC5rL3Zaz/u+RCMBuBM/+0EonSGFRLs/YAJiTfO6w2lLR1+DswJAN8BHuNpOvvl60USYuRu9sNo7r/tOE+vte9HDOi2bd4twON9MNFR6b82YpSgsUttcqjLR6LQXedWq3Mqyev924QJBAL4wuRYbVtohzMhbq8OJPK7yvgulATDLG/wuTbRxoZKN5rFonrHxmkhTIkG5V19oRSKLElC+6NCkN/KCQmUT80U=
-----END PRIVATE KEY-----`
const jsencrypt = new JSEncrypt()
// 加密
const encryptCode = (params) => {
  jsencrypt.setPublicKey(publciKey)
  return jsencrypt.encrypt(params)
}
// 解密
const decryptCode = (params) => {
  jsencrypt.setPrivateKey(privateKey)
  return jsencrypt.decrypt(params) //解密
}
export {
  encryptCode,
  decryptCode
}
