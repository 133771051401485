
import request from '@/utils/request'
const getList = (param) => request('get', '/book/package-theme/page', param)
const addDonation = (param) => request('post', '/book/package-theme/create', param, { requestType: 'JSON' })
const editDonation = (param) => request('post', '/book/package-theme/edit', param, { requestType: 'JSON' })
const detailDonation = (param) => request('get', '/book/package-theme/info', param)

const addPackage = (param) => request('post', '/book/package/create', param, { requestType: 'JSON' })
const packageList = (param) => request('get', '/book/package/page', param)
const packageDetail = (param) => request('get', `/book/package/detail/${param}`) //获取部门详情
const editPackage = (param) => request('post', '/book/package/edit', param, { requestType: 'JSON' })
const changePackage = (param) => request('post', '/book/package/change-status', param, { requestType: 'JSON' })
const uploadExcel = (param) => request('post', '/book/book-infos/by-isbn', param, { requestType: 'JSON' })

// 代捐
const getdonationList = (param) => request('post', '/order/apply/page', param,)
const changedetailById = (param) => request('post', '/book/package-theme/change-status', param, { requestType: 'JSON' })
const changedetailAdd = (param) => request('post', '/order/apply/add', param, { requestType: 'JSON' })


const getDetail = (param) => request('get', '/order/apply/info', param)
// 捐赠对象管理
const getObjectList = (param) => request('get', '/order/donee/page', param)
const getObjectDetail = (param) => request('get', '/order/donee/info', param)
const getObjectTotal = (param) => request('get', '/order/donee/total-stat', param)
// 根据捐赠对象获取，落款身份配置
const getUseridentity = (param) => request('get', '/user/inscription-identity/info', param)
// 获取可选配置项
const getitemOption = (param) => request('get', '/user/inscription-identity/item-option', param)
const getSchool = (param) => request('get', '/user/inscription-identity/schools', param)
const editOption = (param) => request('post', '/user/inscription-identity/save', param, { requestType: 'JSON' })

// 捐赠订单
const orderListAdd = (param) => request('post', '/order/order/instead', param, { requestType: 'JSON' })

// 审核
const orderAuidit = (param) => request('post', '/order/apply/audit', param, { requestType: 'JSON' })

const checkBookStatu = (param) => request('post', '/book/confirmCheck', param, { requestType: 'JSON' })

const updateCopyDonationPage = (param) => request('post', '/order/donation-record/update-copy-donation-page', param) //捐赠页替换
const donationMessageChangeRecordPage = (param) => request('get', '/order/donation-message-change-record/page', param)//捐赠页替换记录

const doneeUpdate = (param) => request('post', 'user/donee/update', param)



// 捐赠记录
const getReCoard = (param) => request('get', '/order/donation-record/page', param)
// const getReDetail = (param) => request('get', '/order/donation-record/detail', param)
const getReDetail = (param) => request('get', '/order/donation-record/detail', param)
const getrankName = (param) => request('get', '/user/inscription-rank/name', param)
const editrankName = (param) => request('post', '/user/inscription-rank/name-edit', param,)

const updateMessageState = (param) => request('post', '/user/donee/updateMessageState', param)


export const DONATION_API = {
  getList, addDonation, editDonation, detailDonation, getDetail,
  addPackage, packageList, packageDetail, editPackage, changePackage,
  uploadExcel,
  getdonationList, changedetailById, changedetailAdd,
  getObjectList, getObjectDetail, getObjectTotal,
  orderListAdd, orderAuidit,
  checkBookStatu,
  getUseridentity,
  getitemOption,
  editOption,
  getSchool,
  getReCoard, getReDetail, getrankName, editrankName, updateMessageState, updateCopyDonationPage,donationMessageChangeRecordPage, doneeUpdate
}
