/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 17:12:41
 * @LastEditTime: 2022-04-18 16:33:53
 * @LastEditors: zjc
 */
export default {
  state: {
    options: [
      { route: '/oms/dashboard', name: '首页' },
    ],
  },
  mutations: {
    // 关闭所有
    CLOSE_ALL (state) {
      state.options = [{ route: '/oms/dashboard', name: '首页' }]
    },
    // 添加tabs
    ADD_TABS (state, data) {
      state.options.push(data);
    },
    // 删除tabs
    DELETE_TABS (state, route) {
      let index = 0;
      for (let option of state.options) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      state.options.splice(index, 1);
    },
  },
}