<!--  新增需求 -->
<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;">
        <div style="width:100%">
          <div class="tab-one" style="width:100%">
            <p class="tab-title">渠道信息</p>
            <div class="tab-content2">
              <div class="box_tab_one">
                <div class="content">
                  <div class="item">
                    <span class="l1">名称：</span>
                    <span class="r1">{{ruleForm.appName}}</span>
                  </div>
                  <!-- <div class="item">
                  <span class="l1">联系人：</span>
                  <span class="r1">{{ruleForm.contactName}}</span>
                </div>
                <div class="item">
                  <span class="l1">手机号：</span>
                  <span class="r1">{{ruleForm.contactPhone}}</span>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-one" style="margin:30px 0 30px 0">
          <div class="tab-title1">
            <div class="left_title">捐赠机构</div>
            <div class="right_title">
              <el-input size="small" style="width:300px;margin-right:10px;" v-model="orgName" placeholder="搜索捐赠机构" clearable></el-input>
              <el-button type="primary" size="small" icon="el-icon-search" @click="searchChange">搜索
              </el-button>
              <el-button size="small" icon="el-icon-refresh" @click="reset" style="margin-right:30px;">重置</el-button>
              <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate('')">
                新增捐赠机构
              </el-button>
            </div>
          </div>
          <v-table style="width:100%;margin:0 auto 20px;" class="itemTble" ref="table"
            :tableData="tableList" :tableOption="tableOption"
            @handleSelectionChange="selectionChange">
            <template slot="book" slot-scope="scope">
              <div> {{scope.row.orgName}}</div>
            </template>
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="openPermissions(scope.row)">查看
              </el-button>
            </template>
          </v-table>
        </div>
        <!-- 分页 -->
        <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
          @hanlePage="handleSizeChange"></pagination>
      </div>
      <!-- 新增、编辑渠道 -->
      <!-- <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item> -->
      <!-- 新增租户 -->
      <el-dialog @closed="cancelDialog" :title="title" :visible.sync="dialogVisible" width="800px"
        :close-on-click-modal="false" append-to-body>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0"
          class="demo-ruleForm">
          <el-form-item style="position:relative" label="" prop="tenantName">
            <div class="box_item">
              <p class="redTitle">捐赠机构名称</p>
              <el-input maxlength="10" show-word-limit style="width:70%"
                v-model="addForm.tenantName" clearable placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" prop="chooseList">
            <div class="box_item">
              <p class="redTitle">捐赠对象</p>
              <el-cascader ref="ownArea" popper-class="selectArea" style="width:70%"
                :options="options" v-model="addForm.chooseList"
                :props="{ multiple: true, checkStrictly: true }" clearable></el-cascader>
              <!-- <el-select v-model="addForm.chooseList" multiple placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog()">取 消</el-button>
          <el-button type="primary" @click="rowSave()" :loading="loading">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </basic-container>
</template>
<script>
import { ROLE_API } from "@/service/permissions/channel";
export default {
  data () {
    return {
      orgName: '',
      options: [],
      pageTotal: 0,

      dialogVisible: false,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数

      searchText: '',
      tableList: [],
      //表格配置
      tableOption: {
        selection: false,
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, align: 'center' },
        // operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: '120px', fixed: 'center', align: 'center' },
        column: [
          { prop: 'book', label: '捐赠机构', slot: true, tableProp: true, },
          { prop: 'createTime', label: '开通时间', tableProp: true },
        ],
      },

      // libraryList: [],
      // fileList: [],
      ruleForm: {},
      addForm: {},
      // rules: {
      //   tenantName: [
      //     { required: true, message: "请输入租户名称", trigger: "change" },
      //     { max: 30, message: "租户名称最多30个字符", trigger: "blur" },
      //   ],

      //   // chooseList: [
      //   //   { required: true, message: "请选择租户类型", trigger: "change" },
      //   // ],
      // },
      createTime: null,

      purchaseOrderId: null,
      actualAmount: null,
      status: 0,//0新增|1查看
      //  allCnt: '',
      goodsCnt: 0,
      sortCnt: 0,
      totalAmount: 0,
      // actualAmount: 0,
      selectList: [],
    }
  },
  created () {
    if (this.$route.query.appId) {
      this.ruleForm.appName = this.$route.query.appName
      this.appId = this.$route.query.appId
      localStorage.setItem("appId", this.$route.query.appId)
      localStorage.setItem("appName", this.$route.query.appName)
    }
    else {
      if (localStorage.getItem("appId")) {
        this.appId = localStorage.getItem("appId")
        this.ruleForm.appName = localStorage.getItem("appName")
      } else {
        this.$router.push({
          path: "./channelList",
        });
      }
    }
    this.getList()
  },
  mounted () {
    this.getOptions();//获取捐赠对象机构
    // this.getLibrary();
    // this.$refs.student.$on('getName', this.getFileList)

  },
  methods: {
    reset() {
      this.orgName = ''
      this.pageData.size = 10;
      this.searchChange();
    },
    searchChange() {
       this.pageData.current = 1;
       this.getList();
    },
    cancelDialog () {
      this.dialogVisible = false
      this.addForm = {}
    },
    //新增捐赠机构
    async rowSave () {
      if (this.addForm.tenantName == null) {
        this.$message.error('请输入捐赠机构名称');
        return
      }
      if (this.addForm.chooseList == null) {
        this.$message.error('请选择捐赠对象');
        return
      }
      let params = {}
      params.appId = this.appId
      params.orgName = this.addForm.tenantName
      let arr = []

      this.addForm.chooseList.forEach(item => {
        console.log(item[0])
        arr.push({
          doneeLogo: item[0].pressOrgLogo,
          doneeName: item[0].pressOrgName,
          pressOrgNo: item[0].pressOrgNo
        })
      })
      params.dpObjectList = arr
      console.log(params)

      try {
        await ROLE_API.addNewOrg(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("新增成功");
            this.dialogVisible = false
            this.getList();//获取捐赠对象
          }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }

    },
    async getOptions () {
      try {
        let params = {}
        params.appId = this.appId
        const { data } = await ROLE_API.getOrgList(params)
        console.log(data)
        data.forEach((item) => {
          this.options.push({
            value: item,
            label: item.pressOrgName
          })
        });

        // this.tableList = data.records;
        // 
        // this.pageTotal = data.total;
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async getList () {
      try {
        let params = this.pageData
        params.appId = this.appId
        params.orgName = this.orgName
        await ROLE_API.getChannelList(params).then(res => {
          this.tableList = res.data.records;
          this.pageTotal = res.data.total;
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }

    },
    changePage (val) {
      this.pageData.current = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getList();
    },
    // 跳转到详情页 查看
    openPermissions (row) {
      console.log(row)
      this.$router.push({
        path: "./organization",
        query: { orgId: row.orgId }
      });
    },
    // 新增捐赠渠道
    addOrUpdate (row = {}) {
      this.title = "新增捐赠机构";
      this.dialogVisible = true;
    },
    jumptoPage (url) {
      window.location.href = url
    },
    closeDialog () {
      this.tableList = []
    },
  }
}
</script>
<style lang="scss">
.selectArea {
  .el-checkbox {
    width: 95%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
  }
  .el-cascader-node__label{
    margin-left: 30px;
  }
}
</style>
<style scoped lang="scss">
.box_item {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  // justify-content: flex-end;
  .redTitle {
    width: 15%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 10px;
    position: relative;
  }
  .redTitle::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: red;
  }
}
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-title1{
      padding: 10px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
       background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      .left_title{
         font-weight: 650;
         font-size: 16px;
        //  margin-top: 11px;
         margin-top: 5px;
      }
      .right_title{
        display: flex;
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        background-color: #ffffff;

        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}
</style>
