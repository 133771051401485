/*
 * @Description:接口请求
 * @Author: zjc
 * @Date: 2021-12-07 11:49:57
 * @LastEditors: zjc
 * @LastEditTime: 2022-06-24 17:26:05
 * @LastEditors: zjc
 */
import axios from 'axios'

import { Notification, MessageBox } from 'element-ui'
import { getStore } from '@/utils/store'
import store from '@/store'
import qs from 'qs'
import Sign from '@/utils/sign.js'
const whiteUrl = ['/api/oms/uploadBookInfo/uploadBookCheck']
//过滤请求
// let isRefreshing = false
const service = axios.create({
  baseURL: '/api',
  timeout: 30000 // request timeout
});
service.interceptors.request.use(config => {
  const token = getStore({ name: 'userToken' })
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  } else {
    if (config.url === '/auth/oauth/token') config.headers['Authorization'] = 'Basic ' + 'b21zOm9tcw=='
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(response => {
  if (response.config.url === '/api/auth/oauth/token' && response.status === 200) {
    if (Reflect.has(response.data, 'code')) {
      Notification({ type: 'error', title: '错误', message: response.data.msg || '错误' })
    }
    return Promise.resolve(response.data)
  }
  if (response.status === 200 && response.request.responseType === 'blob') {
    return Promise.resolve(response.data)
  }
  if (response.data.code === 0) {
    return Promise.resolve(response.data)
  } else {
    if (!whiteUrl.includes(response.config.url)) {
      Notification({ type: 'error', title: '错误', message: response.data.msg || '错误' })
    }
    return Promise.reject(response.data)
  }
}, error => {

  // 捕获服务器返回的状态码
  if (error && error.response) {
    // if ((error.response.status === 401 && !['/api/auth/oauth/token', `/oms/auth`].includes(error.response.config.url)) || error.response.status === 403) {
    if ((error.response.status === 401 && !['/api/auth/oauth/token', `/oms/auth`].includes(error.response.config.url))) {
      MessageBox.alert('会话已过期或账户在其他地方登录？', '提示', {
        confirmButtonText: '重新登录',
        type: 'warning ',
        showClose: false,
      }).then(() => {
        store.dispatch('logOut')
        store.commit('SET_MENU_ALL', []);
        window.location.reload()
        return Promise.reject(error.response)
      }).catch((err) => {
      });
    } else {
      if (![`/oms/auth`].includes(error.response.config.url)) {
        Notification({ type: 'error', title: '错误', message: error.response.data.msg || error.response.data.error })
        return Promise.reject(error.response)
      }
    }
  }
  // 捕获设置的超时时间
  if (error.message.includes('timeout')) {
    Notification({ type: 'error', title: '错误', message: '网络超时' })
    return Promise.reject(error)
  }
  return Promise.reject(error.response)
}
)
// 请求方法
/**
 * 
 * @param {*} method 
 * @param {*} url 
 * @param {*} data 
 * @param {*} option  
 * @param {*} option.reastData 保留原始请求
 * @returns 
 */
export default function request (method, url, data = {}, option = { requestType: null, responseType: null, requestField: null, dataType: null, reastData: null }) {
  let type = '' //请求类型  requestType为表单 反之为json 默认不传 请求类型是json需要传递
  let httpData = null
  if (option.requestType) {
    httpData = qs.parse(Sign.signData({ type: method.toLocaleLowerCase(), data }))
    type = 'application/json'

  } else {
    httpData = qs.stringify(Sign.signData({ type: method.toLocaleLowerCase(), data }))
    type = 'application/x-www-form-urlencoded'
  }

  method = method.toLocaleLowerCase()
  if (method === 'post') {
    return service.post(url, option.reastData ? data : httpData, { headers: { 'Content-Type': type }, responseType: option?.responseType })
  } else if (method === 'put') {
    return service.put(url, httpData, { headers: { 'Content-Type': type } })
  } else if (method === 'get') {
    return service.get(url, {
      params: qs.parse(Sign.signData({ type: 'get', data }))
    },
      { headers: { 'Content-Type': type } }
    )
  } else if (method === 'delete') {
    return service.delete(url, {
      params: qs.parse(Sign.signData({ type: 'get', data })),
    },
      { headers: { 'Content-Type': type } }
    )
  }
}
