/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 14:54:53
 * @LastEditTime: 2022-07-05 14:58:35
 * @LastEditors: zjc
 */
import { setStore, getStore, removeStore } from '@/utils/store'
import { USER_API } from '@/service/user'
import router from '@/router/index'
import { COMMON_API } from '@/utils/common'

export default {
  state: {
    token: getStore({ name: 'userToken' }) || '',
    roleName: getStore({ name: 'roleName' }) || '',
    roleId: getStore({ name: 'roleId' }) || '',
    userId: getStore({ name: 'userId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || {},
    name: getStore({ name: 'name' }) || '',
    menuList: getStore({ name: 'menuList' }) || [],
    subMenuList: getStore({ name: 'subMenuList' }) || [],//二级、三级菜单
    permission: [],//按钮权限列表
    libraryInfo: getStore({ name: 'libraryInfo' }) || {},//图书馆信息
  },
  actions: {
    // 登录
    loginByUsername ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        USER_API.loginByUserName(userInfo).then((res) => {
          commit('USER_TOKEN', res.access_token)
          commit('USER_NAME', res.username)
          commit('USER_ID', res.user_id)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出登录
    logOut ({ commit }) {
      commit('USER_TOKEN', '')
      commit('USER_ID', '')
      commit('USER_NAME', '')
      commit('SET_SUBMENU_LIST', [])
      removeStore('token')

    },
    getInfo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        USER_API.getUserInfo(params).then((res) => {
          console.log(res, 'res');
          const data = res.data
          commit('SET_MENU_ALL', data)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  mutations: {
    USER_TOKEN (state, token) {
      state.token = token
      setStore({ name: 'userToken', content: token, type: true })
    },
    USER_NAME (state, name) {
      state.name = name
      setStore({ name: 'name', content: name })
    },
    USER_ID (state, id) {
      state.userId = id
      setStore({ name: 'userId', content: id })
    },
    SET_ROLE_ID (state, roleId) {
      state.roleId = roleId
      setStore({ name: 'roleId', content: roleId })
    },
    SET_MENU_ALL (state, menuList) {
      // let indexList = {
      //   icon: "dashboard",
      //   name: "首页",
      //   url: "/oms/dashboard",
      //   id: 1,
      //   children: [],
      // }
      state.menuList = menuList
      // state.menuList.unshift(indexList)
      setStore({ name: 'menuList', content: menuList })
    },
    SET_SUBMENU_LIST (state, menuList) {
      state.subMenuList = COMMON_API.filterMenu(menuList)
      setStore({ name: 'subMenuList', content: menuList })
    },
    SET_LIBRARY (state, obj) {
      state.libraryInfo = obj
      setStore({ name: 'libraryInfo', content: obj })

    },
  }
}