<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;">
        <div style="width:100%">
          <div class="tab-one" style="width:100%">
            <div class="tab-title">
              <span>主题信息</span>
              <div style="margin:0 20px 0 0" @click="addOrUpdate('')">
                <i class="el-icon-edit"></i>
              </div>
            </div>
            <div class="tab-content2">
              <div class="box_tab_one">
                <div class="content">
                  <div class="item">
                    <span class="l1">捐赠包主题：</span>
                    <span class="r1">{{ruleForm.themeName}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">图片：</span>
                    <img v-if="ruleForm.themeImg" :src="ruleForm.themeImg" class="avatar"
                      style="width:50px;height:50px;display: inline-block;vertical-align: middle;margin: 0 20px 0 0;" />
                  </div>

                  <div class="item">
                    <span class="l1">状态：
                      <span v-if="ruleForm.themeStatus == 1">待上架</span>
                      <span v-else-if="ruleForm.themeStatus == 2">上架</span>
                      <span v-else-if="ruleForm.themeStatus == 3">下架</span>
                    </span>
                    <el-switch @change="changeSwitch($event)" style="margin:0 0px 0 10px"
                      v-model="value" active-color="#409eff" inactive-color="#dcdfe6">
                    </el-switch>
                  </div>
                </div>
                <div class="content">
                  <div class="item">
                    <span class="l1">简介：</span>
                    <span class="r1">{{ruleForm.themeContent}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">捐赠对象：</span>
                    <span class="r1">{{ruleForm.orgName || '所有捐赠对象'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-one" style="margin:30px 0 30px 0">
          <div class="tab-title">
            <div class="left_title">捐赠包</div>
            <div class="right_title">
              <el-button type="primary" icon="el-icon-plus" size="small" @click="jumpToadd('')">
                新增捐赠包
              </el-button>
            </div>
          </div>
          <v-table style="width:100%;margin:0 auto 20px;" class="itemTble" ref="table"
            :tableData="tableList" :tableOption="tableOption"
            @handleSelectionChange="selectionChange">
            <template slot="packageStatus" slot-scope="scope">
              <div v-if="scope.row.packageStatus == 1">启用</div>
              <div v-else-if="scope.row.packageStatus == 2">停用</div>
            </template>
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="jumpToadd(scope.row)">编辑
              </el-button>
              <el-button v-if="scope.row.packageStatus == 2" type="text" size="small"
                @click="openPermissions(scope.row)">启用
              </el-button>
              <el-button v-if="scope.row.packageStatus == 1" type="text" size="small"
                @click="openPermissions(scope.row)">停用
              </el-button>
            </template>
          </v-table>
        </div>
        <!-- 分页 -->
        <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
          @hanlePage="handleSizeChange"></pagination>
      </div>
      <!-- 新增、编辑渠道 -->
      <!-- <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item> -->
      <!-- 新增租户 -->
      <el-dialog @closed="cancelDialog" :title="title" :visible.sync="dialogVisible" width="800px"
        :close-on-click-modal="false" append-to-body class="updateModal">
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm" labelWidth="140px"
          :rules="tableFormRules">
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="主题名称:" prop="themeName">
            <div>
              <el-input maxlength="10" style="width:410px" show-word-limit
                v-model="addForm.themeName" clearable placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <div class="goodImgBox">
            <span class="redTit3" style="width:180px!important">图片:</span>
            <div style="display: inline-block; vertical-align: top;display: flex;
    flex-wrap: nowrap;
    align-items: center;">
              <img v-if="addForm.themeImg" :src="addForm.themeImg" class="avatar"
                style="display: inline-block;vertical-align: middle;margin: 0 20px 0 0;" />
              <div>
                <el-upload class="upload-demo" v-if="addForm.themeImg" action="/api/file/oss/upload"
                  accept=".jpg,.jpeg,.png" :show-file-list="false" multiple :headers="headers"
                  name="file" :on-success="handleNomalSuccess" :before-upload="beforeAvatarUpload"
                  style="display: inline-block">
                  <el-button size="small" type="primary">更换图片</el-button>
                </el-upload>
                <el-upload v-else class="avatar-uploader" style="display: block; text-align: left"
                  action="/api/file/oss/upload" accept=".jpg,.jpeg,.png" :show-file-list="false"
                  multiple :headers="headers" name="file" :on-success="handleNomalSuccess"
                  :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </div>
            </div>
            <div class="rightText">
              <span class="atext">仅可上传jpg、jpeg、png</span>
            </div>
          </div>
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="简介:" prop="themeContent">
            <div>
              <el-input type="textarea" style="width:410px" :rows="3" placeholder="请输入内容"
                v-model="addForm.themeContent" maxlength="50" show-word-limit>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="捐赠对象:" prop="donation" style="width: 89%; margin: 0 auto 20px">
            <el-radio-group v-model="addForm.donation" @change="radioChange">
              <el-radio :label="1">所有捐赠对象可用</el-radio>
              <el-radio :label="2">指定捐赠对象可用</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="goodImgBox" v-if="addForm.donation==2"
            style="width: 89%; margin: 0 auto 20px">
            <span class="redTit3" style="width:140px!important;line-height: 40px;">选择渠道列表:</span>
            <div style="display: inline-block; vertical-align: top;display: flex;
    flex-wrap: nowrap;
    align-items: center;">
            </div>
            
            <el-select v-model="addForm.appId" filterable placeholder="请选择渠道列表" clearable
              @clear="clearChange" style="width:410px" @change="selectChange">
              <el-option v-for="i in channelList" :key="i.appId" :label="i.appName"
                :value="i.appId">
              </el-option>
            </el-select>
          </div>
          <!-- <el-form-item label="选择渠道列表:" prop="appId" style="width: 89%; margin: 0 auto 20px"
            v-if="addForm.donation==2">
            <el-select v-model="addForm.appId" filterable placeholder="请选择渠道列表" clearable
              @clear="clearChange" style="width:80%;" @change="selectChange">
              <el-option v-for="i in channelList" :key="i.appId" :label="i.appName"
                :value="i.appId">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="选择捐赠对象:" prop="orgId" style="width: 89%; margin: 0 auto 20px"
            v-if="addForm.appId && addForm.donation==2">
            <div>
              <el-select v-model="addForm.orgId" placeholder="请选择捐赠对象" filterable
                style="width:410px" clearable>
                <el-option v-for="i in channelItem" :key="i.orgId" :label="i.orgName"
                  :value="i.orgId">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog()">取 消</el-button>
          <el-button type="primary" @click="rowSave()" :loading="loading">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </basic-container>
</template>
<script>
import { DONATION_API } from "@/service/permissions/donation";
import { getStore } from '@/utils/store'
import { ROLE_API } from "@/service/permissions/channel";
// import { ROLE_API } from "@/service/permissions/channel";
export default {
  data () {
    return {
      tableFormRules: {
        themeName: [
          { required: true, message: "请输入5字内名称", trigger: "blur" },
        ],

        themeContent: [
          { required: true, message: "请输入50字内简介", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "选择渠道列表", trigger: "change" },
        ],
        orgId: [
          { required: true, message: "请选择捐赠对象", trigger: "change" },
        ],
      },
      value: true,
      options: [],
      pageTotal: 0,
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      dialogVisible: false,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数

      searchText: '',
      tableList: [],
      //表格配置
      tableOption: {
        selection: false,
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, align: 'center' },
        // operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: '120px', fixed: 'center', align: 'center' },
        column: [
          { prop: 'packageName', label: '捐赠包名称', tableProp: true, },
          { prop: 'price', label: '金额（元）', tableProp: true, },
          { prop: 'bookCnt', label: '复本数（册）', tableProp: true, },
          { prop: 'packageStatus', label: '状态', slot: true, tableProp: true },
        ],
      },
      ruleForm: {},
      addForm: {
        orgId: null,
      },
      createTime: null,
      purchaseOrderId: null,
      actualAmount: null,
      status: 0,//0新增|1查看
      goodsCnt: 0,
      sortCnt: 0,
      totalAmount: 0,
      selectList: [],
      channelList: [],
      channelItem: [],
    }
  },
  created () {
    if (this.$route.query.themeId) {
      this.themeId = this.$route.query.themeId
      localStorage.setItem("themeId", this.$route.query.themeId)
    }
    else {
      if (localStorage.getItem("themeId")) {
        this.themeId = localStorage.getItem("themeId")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.getList()
    this.getDonationList();//获取捐赠包列表
  },
  mounted () {
    this.getOptions();//获取捐赠对象机构
  },
  watch: {
    'addForm.themeName': function () {
      // var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
      this.addForm.themeName = this.addForm.themeName
        .replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '')
    },
  },
  methods: {
    async changeSwitch (val) {
      let params = {}
      params.themeId = this.themeId
      console.log(val)
      if (val === true) {
        // console.log('打开')
        params.themeStatus = 2
      }
      else {
        // console.log('关闭')
        params.themeStatus = 3
      }
      try {
        await DONATION_API.changedetailById(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.dialogVisible = false
            this.getList();//获取捐赠对象
          }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      this.addForm.themeImg = res.data.link
      this.$set(this.addForm, "themeImg", res.data.link);
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    jumpToadd (row) {
      let packageId = ''
      if (row) {
        packageId = row.packageId
        this.$router.push({
          path: "./donationAdd",
          query: { themeId: this.themeId, packageId: row.packageId }
        });
      } else {
        this.$router.push({
          path: "./donationAdd",
          query: { themeId: this.themeId }
        });
      }

    },
    cancelDialog () {
      this.dialogVisible = false
      // this.addForm = {}
      // this.addForm = this.ruleForm

    },
    //新增/编辑捐赠机构
    async rowSave () {
      if (!this.addForm.themeName) {
        this.$message.error('请输入主题名称');
        return
      }
      if (!this.addForm.themeContent) {
        this.$message.error('请输入简介');
        return
      }
      if (!this.addForm.themeImg) {
        this.$message.error("请上传图片");
        return;
      }
      if (this.addForm.donation === 2) {
        if (!this.addForm.appId) {
          this.$message.error("选择渠道列表");
          return;
        }
        if (!this.addForm.orgId) {
          this.$message.error("请选择捐赠对象");
          return;
        }
      }
      let params = {}
      if (this.addForm.orgId) {
        params.orgId = this.addForm.orgId
        params.appId = this.addForm.appId
      } else {
        params.orgId = 0
      }
      params.themeId = this.themeId
      params.themeName = this.addForm.themeName
      params.themeImg = this.addForm.themeImg
      params.themeContent = this.addForm.themeContent

      // let arr = []

      try {
        await DONATION_API.editDonation(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.dialogVisible = false
            this.getList();//获取捐赠对象
          }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async getOptions () {
      try {
        let params = {}
        params.appId = this.appId
        const { data } = await ROLE_API.getOrgList(params)
        console.log(data)
        data.forEach((item) => {
          this.options.push({
            value: item,
            label: item.pressOrgName
          })
        });

        // this.tableList = data.records;
        // 
        // this.pageTotal = data.total;
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 主题详情
    async getList () {
      try {
        let params = this.pageData
        params.themeId = this.themeId
        await DONATION_API.detailDonation(params).then(res => {
          console.log(res.data)
          this.ruleForm = res.data;
          if (res.data.themeStatus == 3 || res.data.themeStatus == 1) {
            this.value = false
          }
          else {
            this.value = true
          }
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async getDonationList () {
      try {
        let params = this.pageData
        params.themeId = this.themeId
        await DONATION_API.packageList(params).then(res => {
          console.log(res)
          this.tableList = res.data.records;
          this.pageTotal = res.data.total;
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getDonationList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getDonationList();
    },
    //启用
    openPermissions (item) {
      console.log(item)
      let msg = '';
      let itemStatus = 1
      if (item.packageStatus == 1) {
        msg = '停用'
        itemStatus = 2
      } else {
        msg = '启用'
      }
      let params = {}
      params.packageId = item.packageId,
        params.packageStatus = itemStatus
      this.$confirm(`是否` + msg + `该捐赠包？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DONATION_API.changePackage(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("更改成功");
            // this.$ok("已取消");
            this.getDonationList();
          }
        });
      })
        .catch(() => {
          //
        });
    },
    // 获取渠道列表
    async getTheme () {
      const { data } = await ROLE_API.getList({ current: 1, size: 200 });
      this.channelList = data.records;
      const { appId, orgId } = this.addForm
      if (appId || orgId != '0') {
        this.getDonation()
      }
    },
    radioChange (val) {
      this.addForm.donation = val
      this.addForm.orgId = null
      this.addForm.appId = null
    },
    clearChange () {
      this.addForm.orgId = null
    },
    selectChange () {
      this.addForm.orgId = null
      this.getDonation()
    },
    async getDonation () {
      let params = { current: 1, size: 200 }
      params.appId = this.addForm.appId
      await ROLE_API.getChannelList(params).then(res => {
        this.channelItem = res.data.records;
      });
    },
    // 新增捐赠渠道
    addOrUpdate (row = {}) {
      row = this.ruleForm
      this.title = "修改捐赠包主题信息";
      this.dialogVisible = true;
      if (!this.$validatenull(row)) {
        const { themeName, themeContent, themeImg, appId, orgId } = row;
        this.addForm = { themeName, themeContent, themeImg, appId, orgId };
        let donation = 1
        if (appId && orgId != '0') {
          donation = 2
          this.getTheme()
        } else {
          this.getTheme()
          donation = 1
        }
        this.addForm = { themeName, themeContent, themeImg, donation, appId, orgId };
      }
    },
    jumptoPage (url) {
      window.location.href = url
    },
    closeDialog () {
      this.tableList = []
    },
  }
}
</script>
<style lang="scss">
.selectArea {
  .el-checkbox {
    width: 95%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
  }
}
</style>
<style scoped lang="scss">
.box_item {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  justify-content: flex-end;
  .redTitle {
    width: 16%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 10px;
    position: relative;
  }
  .redTitle::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: red;
  }
}
.redTit3 {
  font-size: 14px;
  color: #606266;
  display: inline-block;
  width: 130px;
  text-align: right;
  padding-right: 13px;
  font-weight: 500 !important;
}
.redTit3::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        background-color: #ffffff;

        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            align-items: center;
            display: flex;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}

.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 12px;
  }
  .redTit {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 130px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>
