<!-- 选择捐赠包 -->
<template>
  <div>
    <el-dialog title="选择捐赠包" width="1200px" :close-on-click-modal="false" append-to-body top="8vh"
      :visible.sync="showDetail" @close="closeDialog">
      <div class="flex-class">
        <div class="table-box">
          <div class="searchForm">
            <el-form :model="filters" label-width="100px" size="small" class="formData">
              <el-collapse-transition>
                <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
                  @reset="reset" ref="filters" width="100%">
                </form-item>
              </el-collapse-transition>
            </el-form>
          </div>
          <!-- 表格数据 -->
          <v-table class="table" ref="table" :tableData="tableList" :tableOption="tableOption"
            @handleSelectionChange="selectionChange" @selectChange="selectChange">
            <template slot="choice" slot-scope="scope">
              <el-radio v-model="templateRadio" style="margin-left: 5px;"
                :label="scope.row.packageId" @change.native="getTemplateRow(scope.row)">{{' '}}
              </el-radio>
            </template>
            <!-- 表格特殊字段处理插槽slot为true -->
            <template slot="book" slot-scope="scope">
              <div
                style="width: 100%;text-align: left;display: flex;justify-content: center;align-items: center;">
                <!-- <img :src="scope.row.packageImg" alt="" style="width: 56px;height: 80px;"> -->
                <div style="flex:1;
                        vertical-align: top;
                        margin-left: 10px;
                        width: 100px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;">
                  {{scope.row.packageName}}<br>
                </div>
              </div>
            </template>
            <template slot="packageBooks" slot-scope="scope">
              <div>
                {{scope.row.packageBooks.length}}
              </div>

            </template>
          </v-table>
          <!-- 分页 -->
          <pagination v-show="pageTotal > 0" :pageData="pageData" :total="pageTotal"
            @hanleSize="changePage" @hanlePage="handleSizeChange"></pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确 定</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DONATION_API } from "@/service/permissions/donation";
export default {
  props: {
    open: {
      type: Boolean
    },
    list: {
      type: Array
    },
    type: {
      type: String,
      default: ''
    },
    operation: {
      type: String
    }
  },
  data () {
    return {
      templateRadio: '',
      createTime: [],
      selectData: [],
      filters: {},
      pageData: {
        current: 1,
        size: 10
      },
      pageTotal: 0,
      //搜素
      searchOption: [
        { prop: 'packageName', type: 'input', label: '捐赠包名称:', placeholder: '请输入', col: 8, },

      ],
      tableList: [],
      showDetail: false,
      categoryId: null,//分类id
      //表格配置
      tableOption: {
        align: 'center',
        sort: true,
        props: 'createTime',
        reserve: true,
        order: '',
        getRowKey (row) {
          return row.packageId
        },
        selectable: (row, index) => {
          if (this.type) {
            if (row.isSale === 1) {
              return true;
            }
          } else {
            if (row.isOnShelf === 1) {
              return true;
            }
          }
        },
        column: [
          { prop: 'choice', label: '选择', tableProp: true, slot: true, },
          { prop: 'index', label: '序号' },
          { prop: 'packageName', label: '捐赠包', tableProp: true, },
          { prop: 'packageBooks', label: '书目数（种）', tableProp: true, slot: true },
          { prop: 'bookCnt', label: '副本数（册）', tableProp: true, },
          { prop: 'price', label: '定价（元）', tableProp: true, },
        ],
      },
    }
  },
  methods: {
    init (row, type) {
      this.templateRadio = null
      this.templateSelection = {}
      if (row.packageId) {
        this.templateSelection = row
        this.templateRadio = row.packageId
      }
      this.showDetail = true
      this.$nextTick(() => {
        this.$refs.table.clearSelection()
      })
      this.filters = {}
      this.pageData = {
        current: 1,
        size: 10,
      }
      // this.getCtegoryList()
      this.getBookList()
    },
    getTemplateRow (row) {
      this.templateRadio = row.packageId
      this.templateSelection = row
    },
    // 获取图书列表
    async getBookList () {
      this.$store.commit('SET_TABLE_LOADING', true)
      try {
        let params = this.filters
        params.current = this.pageData.current
        params.size = this.pageData.size
        if (this.categoryId != null) params.categoryId = this.categoryId
        await DONATION_API.packageList(params).then(res => {
          console.log(res.data.records)
          this.tableList = res.data.records
          this.pageTotal = res.data.total
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    getAllDate () {
      // this.getCtegoryList()//重新获取列表，清空选中状态
      this.getNodeData({ categoryId: '' })
    },
    getNodeData (val) {
      this.categoryId = val.categoryId
      this.pageData.current = 1
      this.getBookList()
    },
    search () {
      this.pageData.current = 1
      this.getBookList()
    },
    reset () {
      this.filters = {};
      this.$refs.filters.initForm()
      this.pageData.size = 10
      this.pageData.current = 1
      // this.isSale
      // this.filters.isSale = null
      this.categoryId = ''
      this.templateRadio = ''
      this.templateSelection = []
      this.$refs.table.$refs.tableLayout.clearSort()
      this.getBookList()
    },
    closeDialog () {
      this.showDetail = false
      this.categoryId = null
      // this.$emit('update:open', false)
      this.filters = {};
      this.$refs.filters.initForm()
      this.pageData.size = 10
      this.pageData.current = 1
      this.categoryId = ''
      this.templateRadio = ''
      this.templateSelection = []
      this.selectData = [];
      this.$refs.table.$refs.tableLayout.clearSort()
    },
    //已经勾选过的不能取消勾选
    selectChange (selection, row) {
      if (this.type) {
        for (let i of this.tableList) {
          if (i.packageId === row.packageId && i.checked === true) {
            this.$nextTick(() => {
              this.$refs.table.$refs.tableLayout.toggleRowSelection(i, true)
            });
          }
        }
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getBookList()
    },
    handleSizeChange (val) {
      this.pageData.size = val
      this.getBookList()
    },
    confirm () {
      if (JSON.stringify(this.templateSelection) === '{}') {
        this.$message('请选择捐赠包');
        return
      }
      this.$emit('handleSelect', { ...this.templateSelection })
      this.closeDialog()
    },
    selectionChange (val, row) {
      this.selectData = val.map((item, index) => {
        return { rank: index + 1, ...item }
      })

      console.log(this.selectData, '选中的数据')
    },
  }
}
</script>
<style scoped  lang="scss">
.flex-class {
  display: flex;
}
.filter-tree {
  border: 0px solid #dcdfe6;
  padding: 15px 20px;
}
.orange-box {
  display: inline-block;
  color: orange;
  border: 1px solid orange;
  margin-right: 2px;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 4px;
}
.table {
  // height: 580px;
  overflow: auto;
}
.tree-box {
  height: 500px;
  overflow: auto;
}
.table-box {
  width: 100%;
}
.statusBox {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .circle1 {
    background-color: #0e77d1;
  }
  .circle2 {
    background-color: #f04134;
  }
  .circle3 {
    background-color: #bfbfbf;
  }
}
</style>