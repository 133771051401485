<!--
 * @Description: 新增、编辑操作员
 * @Author: zjc
 * @Date: 2021-12-16 16:18:32
 * @LastEditTime: 2022-07-05 14:51:49
 * @LastEditors: zjc
-->
<template>
  <div>
    <el-dialog :title="operatorId ? '修改操作员' : '新增操作员'" :width="!operatorId ? '600px' : '900px'"
      :close-on-click-modal="false" append-to-body top="10vh" :visible.sync="showUser"
      @close="closeDialog">
      <el-form v-if="!operatorId" ref="submitForm" :model="formData" size="medium"
        label-width="80px" :rules="formRules">
        <el-form-item label="用户名" prop="username">
          <el-input v-model.trim="formData.username" maxlength="30" placeholder="请输入用户名"
            show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model.trim="formData.realName" placeholder="请输入真实姓名" maxlength="30"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model.trim="formData.mobile" maxlength="11" show-word-limit
            placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="deptId">
          <el-cascader @click.native="handleSelect" :popper-class="'cascader1'"
            v-model="formData.deptId" ref="cascader" :options="deptList" :show-all-levels="false"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
            }" clearable></el-cascader>
        </el-form-item>

        <el-form-item label="角色" prop="role">
          <el-select v-model="formData.role" placeholder="请选择角色" style="width: 100%" clearable
            @change="selectChange">
            <el-option v-for="i in roleList" :key="i.value" :label="i.label" :value="i.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="isEnabled">
          <el-radio-group v-model="formData.isEnabled">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
          <!-- <el-select
            v-model="formData.isEnabled"
            placeholder="请选择"
            clearable
          >
            <el-option
              class="select-option"
              v-for="item in stateList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model.trim="formData.email" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="formData.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
            <el-radio :label="2">未知</el-radio>
          </el-radio-group>
          <!-- <el-select v-model="formData.sex" placeholder="请选择" clearable>
            <el-option
              class="select-option"
              v-for="item in sexOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="初始密码">
          <el-input v-model="formData.password" disabled placeholder="请输入"></el-input>
          <p>操作员登录后可修改密码</p>
        </el-form-item>
      </el-form>

      <el-form v-else ref="submitForm" :model="formData" size="medium" label-width="100px"
        :rules="formRules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户名" prop="username">
              <el-input :disabled="operatorId" v-model.trim="formData.username" maxlength="30"
                placeholder="请输入用户名" show-word-limit>
              </el-input>
            </el-form-item>

            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="formData.mobile" maxlength="11" show-word-limit
                placeholder="请输入手机号">
              </el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="formData.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
                <el-radio :label="2">未知</el-radio>
              </el-radio-group>
              <!-- <el-select v-model="formData.sex" placeholder="请选择" clearable>
            <el-option
              class="select-option"
              v-for="item in sexOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select> -->
            </el-form-item>
            <el-form-item label="所属部门" prop="deptId">
              <el-cascader @click.native="handleSelect" :popper-class="'cascader1'"
                v-model="formData.deptId" ref="cascader" :options="deptList"
                :show-all-levels="false" :props="{
                  label: 'name',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false
                }" clearable></el-cascader>
            </el-form-item>

            <el-form-item label="状态" prop="isEnabled">
              <el-radio-group v-model="formData.isEnabled">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">禁用</el-radio>
              </el-radio-group>
              <!-- <el-select
            v-model="formData.isEnabled"
            placeholder="请选择"
            clearable
          >
            <el-option
              class="select-option"
              v-for="item in stateList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select> -->
            </el-form-item>
            <el-form-item label="是否激活">
              <el-radio-group disabled v-model="formData.isActive">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="最后登录时间">
              <el-input disabled v-model="formData.lastLoginTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realName">
              <el-input v-model="formData.realName" placeholder="请输入真实姓名" maxlength="30"
                show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="formData.email" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker v-model="formData.birthday" value-format="yyyy-MM-dd"
                format="yyyy-MM-dd" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="角色" prop="role">
              <el-select v-model="formData.role" placeholder="请选择角色" style="width: 100%" clearable
                @change="selectChange">
                <el-option v-for="i in roleList" :key="i.value" :label="i.label" :value="i.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="激活时间">
              <el-input disabled v-model="formData.activeTime"></el-input>
            </el-form-item>

            <el-form-item label="创建时间">
              <el-input disabled v-model="formData.createTime"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="primary" @click="save" :loading="loading">提交</el-button>
        <el-button size="medium" @click="closeDialog">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DEPT_API } from "@/service/permissions/dept";
import { stateList, sexOptions } from "@/enums/permissions";
import { OPERATOR_API } from "@/service/permissions/operator";
import { COMMON_API } from "@/utils/common";
const requiredData = {
  oprId: null,
  username: null,
  role: null,
  deptId: null,
  realName: null,
  mobile: null,
  sex: 1,
  isEnabled: 0,
  password: "a123456",
  email: null,
  birthday: null,
};
export default {
  props: {
    operatorId: {
      type: Number,
    },
    deptList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    const validPhone = (rule, value, callback) => {
      let tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!value.trim()) {
        callback(new Error("手机号为空！"));
      } else {
        if (!tel.test(value)) {
          callback(new Error("手机号格式错误!"));
        } else {
          callback();
        }
      }
    };
    return {
      //树状配置
      defaultProps: {
        children: "children",
        label: "name",
      },
      selectObj: { id: null, name: null },
      loading: false, //
      showUser: false,
      formData: Object.assign({}, requiredData),
      stateList: stateList, //状态
      sexOptions: sexOptions, //状态
      roleList: [], //角色列表
      // deptList: [], //部门列表
      formRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        role: [{ required: true, message: "请选择角色", trigger: "change" }],
        isEnabled: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
        deptId: [{ required: true, message: "请选择部门", trigger: "change" }],
        mobile: [{ required: true, trigger: "blur", validator: validPhone }],
      }, //表单校验
    };
  },
  created () { },
  methods: {
    changeSelect () {
      this.selectObj = { id: null, name: null };
    },
    // 点击 tree单选节点
    handleNodeClick (node) {
      this.formData.deptId = node.id;
      this.selectObj.name = node.name;
      this.selectObj.id = node.id;
      this.$refs.selectReport.blur();
    },
    //初始化
    initData () {
      this.showUser = true;
      this.getDeptList();
      this.getRoleList();
      this.$nextTick(() => { });
    },
    selectChange (val) {
      if (val) {
        // 操作选中角色发生变化
        this.$set(this.formData, this.formData.role, val);
      } else {
        this.$set(this.formData, this.formData.role, "");
      }
    },
    //获取操作员详情
    async getUserInfo () {
      const { data } = await OPERATOR_API.operatorDetail({
        id: this.operatorId,
      });
      const {
        username,
        realName,
        mobile,
        sex,
        isEnabled,
        oprId,
        deptId,
        birthday,
        isActive,
        lastLoginTime,
        activeTime,
        createTime,
        email,
      } = data;
      this.formData = {
        username,
        realName,
        mobile,
        sex,
        isEnabled,
        oprId,
        deptId,
        birthday,
        isActive,
        lastLoginTime,
        activeTime,
        createTime,
        email,
      };
      if (data.roleList?.length) {
        for (const role of data.roleList) {
          this.formData.role = role.roleId;
        }
      }
    },
    save () {
      this.$refs["submitForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            if (
              this.formData.birthday !== null &&
              this.formData.birthday.length === 10
            ) {
              this.formData.birthday = this.formData.birthday + " 00:00:00";
            }
            if (this.$refs.cascader.getCheckedNodes().length) {
              this.formData.deptId =
                this.$refs.cascader.getCheckedNodes()[0].value;
              // console.log(this.$refs.cascader.getCheckedNodes()[0].value, "000");
            } else {
              this.formData.deptId = 0;
            }
            const roleData = {
              roles: [this.formData.role],
            };
            const data = this.operatorId
              ? await OPERATOR_API.updateOperator({
                ...this.formData,
                ...roleData,
              })
              : await OPERATOR_API.addOperator({
                ...this.formData,
                ...roleData,
              });
            this.$ok(data.msg);
            this.closeDialog();
            this.$emit("onLoad");
            this.loading = false;
          } catch (e) {
            console.log(e, "e");
            this.loading = false;
          }
        }
      });
    },
    //获取部门列表
    async getDeptList () {
      // DEPT_API.getTree().then((res) => {
      //   this.deptList = res.data;
      // });
      this.operatorId && this.getUserInfo();
    },
    async getRoleList () {
      const { data } = await OPERATOR_API.roleList();
      this.roleList = data.map((item) => {
        return { label: item.roleName, value: item.roleId };
      });
    },
    //关闭弹窗
    closeDialog () {
      this.$refs.submitForm.resetFields();
      this.formData = Object.assign({}, requiredData);
      this.formData.email = null;
      this.showUser = false;
      this.formData.password = "a123456";
    },

    //方法额外加
    handleSelect (event) {
      var that = this;
      this.$nextTick(() => {
        let d = document.getElementsByClassName("cascader1");
        if (d[0] && !d[0].onclick) {
          d[0].onclick = function (e) {
            if (e.target.className == "el-cascader-node__label") {
              e.target.previousElementSibling.click();
            }
          };
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>