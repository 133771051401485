/*
 * @Author: 资源管理接口
 * @Date: 2022-05-24 17:05:23
 * @LastEditors: ym ym@geeboo.cn
 * @LastEditTime: 2022-05-25 17:15:20
 * @FilePath: \cloud-library-platform-ui\src\service\permissions\lessee.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const getResource = (param) => request('get', '/oms/resource/tree', param) //资源管理列表
const getResourceSave = (param) => request('post', '/oms/resource/save', param) //资源添加
const getResourceUpdate = (param) => request('post', '/oms/resource/update', param) //资源修改
const getResourceDelete = (param) => request('post', '/oms/resource/delete', param) //资源删除

const getApplicationIdTree = (param) => request('get', '/oms/resource/applicationIdTree', param) //应用下的资源集合



export const RESOURCE_API = {
  getResource,
  getResourceSave,
  getResourceUpdate,
  getResourceDelete,
  getApplicationIdTree
}
