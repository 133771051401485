<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2022-04-12 11:19:38
 * @LastEditTime: 2022-05-23 11:14:26
 * @LastEditors: ym ym@geeboo.cn
-->
<template>
  <div>
    <div class="tabs arcsin" :class="{'arcsin--tabs1':subMenuList.length === 0}">
      <div class="tabs-content">
        <el-tabs v-model="activeIndex" type="card" :closable="options.length!=1"
          @tab-click="tabClick" @tab-remove="tabRemove">
          <el-tab-pane :key="item.path" v-for="item in options" :name="item.route">
            <span slot="label">
              <!-- <span class="hot"
                :style="{background:activeIndex === item.route?'#fff':'#b9bcc3'}"></span> -->
              {{item.name}}
            </span>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-dropdown style="cursor: pointer;" placement="bottom">
          <span class="close-main">
            更多
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="closeOther('all')">关闭所有</el-dropdown-item>
            <el-dropdown-item @click.native="closeOther('other')">关闭其他</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="main">
      <transition name="tabs-slide-fade" mode="out-in">
        <!-- <keep-alive> -->
        <router-view class="router-view"></router-view>
        <!-- </keep-alive> -->
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      activeName: 'first',
    }
  },
  computed: {
    ...mapGetters(['options', 'isFullScreen', 'subMenuList']),
    activeIndex: {
      get () {
        return this.$store.state.common.activeIndex;
      },
      set (val) {
        this.$store.commit('SET_ACTIVE_INDEX', val);
      }
    },
  },
  watch: {
    '$route': 'setTabList'
  },
  methods: {
    // 关闭所有、关闭其他
    closeOther (type) {
      if (type === 'all') {
        this.$store.commit('CLOSE_ALL',);
        this.$store.commit('SET_ACTIVE_INDEX', '/oms/dashboard');
        this.jumpPage('/oms/dashboard')
      } else {
        this.$store.commit('CLOSE_ALL',);
        this.setTabList(this.$route)
      }
    },
    // tab切换时，动态的切换路由
    tabClick (tab) {
      this.$router.push({ path: this.activeIndex });
    },
    //删除tabs
    tabRemove (targetName) {
      this.$store.commit('DELETE_TABS', targetName);
      if (this.activeIndex === targetName) {
        // 设置当前激活的路由
        if (this.options && this.options.length >= 1) {
          this.$store.commit('SET_ACTIVE_INDEX', this.options[this.options.length - 1].route);
          this.$router.push({ path: this.activeIndex });
        } else {
          this.$router.push({ path: '/oms/dashboard' });
        }
      }
    },
    // 动态添加tabs
    setTabList (route) {
      let flag = false;
      for (let option of this.options) {
        if (option.name === route.name) {
          flag = true;
          this.$store.commit('SET_ACTIVE_INDEX', route.path);
          break
        }
      }
      if (!flag) {
        this.$store.commit('ADD_TABS', { route: route.path, name: route.name });
        this.$store.commit('SET_ACTIVE_INDEX', route.path);
      }
    }
  },
  created () {
    this.setTabList(this.$route)
  },
}
</script>
<style lang="scss">
.tabs-slide-fade-enter-active,
.tabs-slide-fade-leave-active {
  transition: all 0.2s;
}
.tabs-slide-fade-enter,
.tabs-slide-fade-leave-to {
  transform: translate(10px);
  opacity: 0;
}
.main {
  overflow: hidden;
  padding-top: 113px;
}
.arcsin {
  width: 100%;
  z-index: 998;
}
.tabs {
  background: #f5f5f5;
  position: fixed;
  // width: calc(100vw - 208px);
  width: calc(100vw - 258px);
  left: 258px;
  // left: 208px;
  padding-right: 25px;
  /* padding-left: 10px; */
  margin-left: 10px;
  right: 0;
  top: 63px;
  transition: all 0.4s;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
  padding-left: 10px;
  padding-right: 10px;
}
.hot {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
  top: 1px;
}
.tabs .el-tabs__nav-next,
.tabs .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  line-height: 30px;
  font-size: 12px;
  color: #808695;
  width: 30px;
  text-align: center;
  background: #fff;
  border-radius: 100%;
}
.tabs .el-tabs__nav-wrap.is-scrollable {
  padding: 0 35px;
}
.tabs .tabs-content {
  width: calc(100% - 80px);
}
.tabs .el-tabs__header {
  z-index: 99;
  margin: 0px 0 0px;
}

.tabs .el-tabs__active-bar {
  background-color: #2f4050;
}
.tabs .el-tabs__item {
  padding: 0 10px;
  color: #303133;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  border-radius: 3px;
}
.tabs
  .el-tabs--bottom
  .el-tabs--left
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--bottom
  .el-tabs--right
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--bottom.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--bottom.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--top
  .el-tabs--left
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--top
  .el-tabs--right
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:nth-child(1n),
.tabs
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:nth-child(1n) {
  padding-left: 10px;
}
.tabs
  .el-tabs--bottom
  .el-tabs--left
  > .el-tabs__header
  .el-tabs__item:last-child,
.tabs
  .el-tabs--bottom
  .el-tabs--right
  > .el-tabs__header
  .el-tabs__item:last-child,
.tabs
  .el-tabs--bottom.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:last-child,
.tabs
  .el-tabs--bottom.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:last-child,
.tabs .el-tabs--top .el-tabs--left > .el-tabs__header .el-tabs__item:last-child,
.tabs
  .el-tabs--top
  .el-tabs--right
  > .el-tabs__header
  .el-tabs__item:last-child,
.tabs
  .el-tabs--top.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:last-child,
.tabs .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  padding-right: 10px;
}
.tabs .el-tabs__item.is-active,
.tabs .el-tabs__item:hover {
  color: #2d8cf0;
}
.tabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background: #fff;
  color: #2d8cf0;
}
.tabs .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: none;
  margin: 0 3px;
  background: #fff;
  border-bottom: none;
}
.tabs .el-tabs__item:first-child .el-icon-close {
  display: none;
}
.tabs .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
.tabs .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
.tabs .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: #e4e7ed;
  z-index: 1;
}
.router-view {
  height: 100%;
}
.close-main {
  width: 74px;
  height: 32px;
  line-height: 32px;
  background-color: #1890ff;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  .el-icon--right {
    margin-left: 4px;
    font-size: 16px;
    color: #fff;
    vertical-align: middle;
  }
}
</style>