<!--  新增需求 -->
<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;">
        <div style="display:flex;flex-wrap:nowrap;justify-content: space-between;
    width: 100%;">
          <span class="title">捐赠对象</span>
          <el-button type="primary" size="small" @click="addOrUpdate()">新增捐赠信息
          </el-button>
        </div>

        <!-- <div class="box_descContent" v-for="(item,index) in msgList" :key="item.inscriptionId"
          :label="item.inscriptionName" :value="item.inscriptionId">
          <div class="descTitle">信息{{index +1 }}</div>
          <div class="descContent">
            <div class="el-descriptions-item">
              <div class="label">落款名称：</div>
              <div class="desc">{{item.inscriptionName}}</div>
            </div>
          </div>
        </div> -->

        <div style="margin:10px auto 0" class="tab-one" v-for="(item,index) in msgList"
          :key="item.inscriptionId" :label="item.inscriptionName" :value="item.inscriptionId">
          <p class="tab-title">
            <span class="tab_1">信息{{index+ 1}}</span>
            <span class="tab_2" v-if="item.pressOrgName">捐赠对象：{{item.pressOrgName}}</span>
          </p>
          <div class="tab-content2">
            <div class="box_tab_one">
              <div class="content">
                <div class="item"
                  v-show="item.inscriptionIdentities && otem.identityCode !== 'LUOKUAN'"
                  v-for="(otem,ondex) in item.inscriptionIdentities" :key="ondex">
                  <div v-if="otem.identityCode == 'SCHOOL'">
                    <span class="l1">所属院校：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                  <div v-if="otem.identityCode == 'ENTRY_YEAR' && otem.identityValue">
                    <span class="l1">入学年份：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                  <div v-if="otem.identityCode == 'EDUCATION' && otem.identityValue">
                    <span class="l1">学历：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div>
                  <!-- <div v-if="otem.identityCode == 'LUOKUAN'">
                    <span class="l1">落款名称：</span>
                    <span class="r1">{{otem.identityValue}}</span>
                  </div> -->
                </div>
                <div class="item">
                  <div v-show="item.inscriptionName">
                    <span class="l1">落款名称：</span>
                    <span class="r1">{{item.inscriptionName}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- 新增、编辑 -->
      <el-dialog modal-append-to-body title="捐赠落款" width="800px" @close="cancelDialog"
        append-to-body :close-on-click-modal="false" top="10vh" :visible.sync="showDialog">
        <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" size="medium"
          labelWidth="90px" :model="tableForm" :rules="tableFormRules">
          <div class="table_one">
            <el-form-item style="width: 89%; margin: 0 auto 20px" label="捐赠对象:" prop="pressOrgNo">
              <el-select v-model="tableForm.pressOrgNo" placeholder="请选择"
                @change="selectChange($event)" clearable="true">
                <el-option v-for="i in objectList" :key="i.orgId" :label="i.orgName"
                  :value="i.orgId">
                </el-option>
              </el-select>
            </el-form-item>
            <div v-for="(item,index) in this.objectMsg" :key="index">
              <el-form-item v-show="item.identityCode == 'SCHOOL'"
                style="width: 89%; margin: 0 auto 20px" label="所属院校:" prop="pressOrgNo">
                <el-select v-model="school" placeholder="请选择" clearable="true">
                  <el-option v-for="i in schoolList" :key="i.schoolName" :label="i.schoolName"
                    :value="i.schoolName"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="item.identityCode == 'ENTRY_YEAR' && school !== '社会公益人士'"
                style="width: 89%; margin: 0 auto 20px" label="入学年份:" prop="pressOrgNo">
                <el-select v-model="year" placeholder="请选择" clearable="true">
                  <el-option v-for="i in arrYear" :key="i" :label="i" :value="i">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="item.identityCode == 'EDUCATION' && school !== '社会公益人士'"
                style="width: 89%; margin: 0 auto 20px" label="学历:" prop="pressOrgNo">
                <el-select v-model="readType" placeholder="请选择" clearable="true">
                  <el-option v-for="i in readList" :key="i" :label="i" :value="i">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item style="width: 89%; margin: 0 auto 20px" label="落款名称:" prop="preview">
                <el-input maxlength="10" show-word-limit v-model="tableForm.preview" clearable
                  placeholder="请输入落款名称" style="width: 100%">
                </el-input>
              </el-form-item>
            </div>
            <div>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog" size="medium">取消</el-button>
          <el-button type="primary" @click="rowSave" size="medium" :loading="loading">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </basic-container>
</template>
<script>
import { ROLE_API } from "@/service/permissions/user";
import { Order_API } from "@/service/permissions/order";
import { DONATION_API } from "@/service/permissions/donation";
export default {
  data () {
    return {
      filters: {
        size: 10,
        current: 1,
      },
      school: '',
      year: '',
      readType: '',
      preview: "",
      showDialog: false,
      userId: '',
      ruleForm: {},
      dialogVisible: false,
      tableList: [],
      msgList: [],//捐赠信息
      libraryList: [],
      fileList: [],
      createTime: null,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      // 新增、修改 渠道
      objectList: [],
      tableForm: {
        preview: '',
        // pressOrgNo: '',
        // inscriptionName: '',
      },

      tableFormRules: {
        preview: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        pressOrgNo: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
      objectMsg: [],
      schoolList: [],
      arrYear: [],
      readList: ['专科', '本科', '硕士', '博士'],
      object: '',
      itemList: [],
    }
  },
  watch: {
    tableForm: {
      deep: true,
      handler (val) {
        this.$forceUpdate
        // console.log('1111')
        // this.getData();
      }
    },
  },
  created () {
    this.getObjectList();
    if (this.$route.query.userId) {
      console.log(this.$route.query.userId)
      this.userId = this.$route.query.userId
      this.orgId = this.$route.query.orgId
      // this.ruleForm = this.$route.query
      localStorage.setItem("userId", this.$route.query.userId)
      localStorage.setItem("ruleFormUserId", this.$route.query.userId)
      localStorage.setItem("ruleFormMobile", this.$route.query.mobile)
      localStorage.setItem("ruleFormNickName", this.$route.query.nickName)
      this.getUserOrg(this.orgId, this.userId)
    }
    else {
      if (localStorage.getItem("userId")) {
        this.userId = localStorage.getItem("userId")
        // this.ruleForm.userId = localStorage.getItem("ruleFormUserId")
        // this.ruleForm.mobile = localStorage.getItem("ruleFormMobile")
        // this.ruleForm.nickName = localStorage.getItem("ruleFormNickName")
      } else {
        this.$router.push({
          path: "./userList",
        });
      }
    }
  },
  mounted () {
    this.getAllYear();

    this.getSchool()
  },
  methods: {
    getAllYear () {
      var myDate = new Date();
      var thisYear = myDate.getFullYear(); // 获取当年年份
      var Section = thisYear - 1900; // 声明一个变量 获得当前年份至想获取年份差 eg.2008
      // var arrYear = []; // 声明一个空数组 把遍历出的年份添加到数组里
      for (var i = 0; i <= Section; i++) {
        this.arrYear.push(thisYear-- + '级')
      }
    },
    async getSchool () {
      let params = {};
      const { data } = await DONATION_API.getSchool(params);
      console.log(data)
      this.schoolList = data;
      let arr = {
        schoolName: "社会公益人士",
      }
      this.schoolList.push(arr)
      console.log(this.schoolList)
    },
    selectChange (val) {
      console.log(val)
      this.objectMsg = []
      this.getUseridentity(val);
    },
    // 主题详情
    async getUseridentity (val) {
      try {
        let params = {}
        params.pressOrgNo = val
        await DONATION_API.getUseridentity(params).then(res => {
          // this.tableForm = res.data
          res.data.items.forEach(otem => {
            this.objectMsg.push({
              identityLabel: otem.identityLabel,
              identityCode: otem.identityCode
            })
          })



        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 获取捐赠对象列表
    async getObjectList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        let params = {
          size: 100,
          current: 1
        }
        const { data } = await DONATION_API.getObjectList(params);

        this.objectList = data.records;
        console.log(this.objectList)
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    // 获取用户捐赠信息
    async getUserOrg (id, userId) {
      try {
        let params = {}
        params.orgId = id
        params.userId = userId
        const { data } = await ROLE_API.getInfoByOrgId(params)
        console.log(data)
        this.msgList = data
        this.itemList = data.inscriptionIdentities
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 跳转
    jumpToSearch (row) {
      console.log(row)
      this.$router.push({
        path: "../../order/orderDetail",
        query: { orderId: row.orderId }
      });
    },
    search () {
      this.pageData.current = 1;
      // this.pageData.size = 10;
      this.getData();
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.$nextTick(() => {
        this.getData();
      });
    },
    async getData () {
      const params = {}
      params.orgId = this.orgId
      params.userId = this.userId
      await Order_API.getOrderList({
        ...this.pageData,
        ...this.filters,
        ...params,
      }).then((res) => {
        this.pageTotal = res.data.total;
      });
    },
    searchMsg (row) {
      console.log(row)
      this.getUserOrg(row.orgId, row.userId)
      this.dialogVisible = true
    },
    // 获取详情 
    async getList () {
      try {
        let params = {}
        params.userId = this.userId
        const { data } = await ROLE_API.getUserDetail(params)
        console.log(data)
        this.tableList = data.records
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    cancelDialog () {
      this.showDialog = false;
      this.tableForm.pressOrgNo = ''
      this.tableForm = {};
      this.school = ''
      this.year = ''
      this.readType = ''
      this.preview = ''
      this.tableForm.preview = ''
      this.objectMsg = []
      this.$nextTick(() => {
        this.$refs['submitForm'].resetFields();
      })
    },
    // 新增捐赠渠道
    addOrUpdate () {
      this.showDialog = true;
      this.tableForm.pressOrgNo = ''
    },
    jumptoPage (url) {
      window.location.href = url
    },
    //多选回调
    selectionChange (val) {
      let that = this
      this.selectList = val
      console.log(this.selectList.length)
      if (this.selectList.length == 0) {
        this.disabled = true
      } else {
        this.disabled = false
        if (that.selectList.findIndex(item => item.totalStock === 0) !== -1) {
          console.log('存在库存为0得图书')
          that.disabled2 = true
        }
        else {
          console.log('不存在库存为0得图书')
          that.disabled2 = false
        }
      }
    },
    // closeDialog () {
    //   this.showDetail = false
    //   this.formData = {}
    //   this.packageImg = null
    //   this.packageName = null
    //   this.packageDetail = null
    //   this.tableList = []
    //   this.msgList = []
    //   this.objectMsg = []
    // },
    // 新增
    async rowSave () {

      if (!this.tableForm.pressOrgNo) {
        this.$message.error('请选择捐赠对象');
        return
      }
      // if (!this.tableForm.preview) {
      //   this.$message.error('请输入落款名称');
      //   return
      // }
      // if(!this.preview){

      // }
      let list = []
      if (this.objectMsg) {
        this.objectMsg.forEach((item) => {
          if (item.identityCode !== 'LUOKUAN') {
            let a = {}
            a.identityCode = item.identityCode
            a.identityValue = item.identityValue
            a.indexNo = item.indexNo
            list.push(a)
          }
        })
      }
      let schoolStatus = false;
      let yearStatus = false;
      let educatStatus = false;
      let luokuanStatus = false;
      console.log(list)
      list.forEach((item, index) => {
        if (item.identityCode == 'SCHOOL') {
          item.identityValue = this.school
          schoolStatus = true;
        }
        if (item.identityCode == 'ENTRY_YEAR' && this.school !== '社会公益人士') {
          item.identityValue = this.year
          yearStatus = true
        }
        if (item.identityCode == 'EDUCATION' && this.school !== '社会公益人士') {
          item.identityValue = this.readType
          educatStatus = true
        }
        // if (item.identityCode == 'LUOKUAN') {
        //   item.identityValue = this.tableForm.preview
        //   luokuanStatus = true
        // }
        item.indexNo = index
      })

      if (schoolStatus) {
        if (!this.school) {
          this.$message.error('请选择所属院校');
          return
        }
      }
      if (yearStatus) {
        if (!this.year) {
          this.$message.error('请选择入学年份');
          return
        }
      }
      if (educatStatus) {
        if (!this.readType) {
          this.$message.error('请选择学历');
          return
        }
      }
      // if (luokuanStatus) {
      if (!this.tableForm.preview) {
        this.$message.error('请输入落款名称');
        return
      }
      // }
      console.log(this.tableForm)
      let params = {};
      params.userId = this.userId
      params.orgId = this.orgId
      params.inscriptionName = this.tableForm.preview
      params.pressOrgNo = this.tableForm.pressOrgNo,
        params.identities = list
      try {
        await ROLE_API.inscriptionAdd(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("新增成功");
            // this.$refs.modal.loading = false;
            this.showDialog = false;
            this.getUserOrg(this.orgId, this.userId)
          }
        });
      } catch (err) {
        // this.$refs.modal.loading = false;
      }
    },

  }
}
</script>
<style scoped lang="scss">
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-align: left;
      padding: 0 20px;
      margin: 0;
      .tab_1 {
        font-weight: 650;
        font-size: 16px;
      }
      .tab_2 {
        font-weight: 100;
        font-size: 13px;
        color: #333333;
      }
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            min-width: 33%;
            width: auto !important;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}
</style>

<style scoped lang="scss">
.box_descContent {
  .descTitle {
    font-weight: 500;
    margin: 0 0 20px 0;
    font-weight: 650;
    font-style: normal;
    font-size: 14px;
    color: #555555;
  }
  .descContent {
    display: flex;
    flex-wrap: wrap;
  }
  .el-descriptions-item {
    margin: 0 0 20px 0;
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    .label {
      display: flex;
      font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC",
        sans-serif;
      font-weight: 650;
      font-style: normal;
      color: #999999;
      font-size: 13px;
      text-align: right;
    }
    .desc {
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
    }
  }
}
</style>
