/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 14:56:40
 * @LastEditTime: 2022-05-26 15:55:58
 * @LastEditors: ym ym@geeboo.cn
 */
import request from '@/utils/request'
const loginByUserName = (params) => request('post', `/auth/oauth/token`, { user_type: 'P', deviceId: '2e33f78a-e0fb-4cd2-8193-f2efb7e8b3b0', grant_type: 'password', scope: 'server', ...params }); // 登录 
const getUserInfo = (params) => request('get', '/oms/auth', params) // 获取用户信息菜单列表
const logOut = () => request('post', '/auth/token/logout') // 退出登录
const changepwd = (params) => request('post', '/oms/opr/update-password', params)
export const USER_API = {
  loginByUserName, // 登录
  getUserInfo,//获取用户信息菜单列表
  logOut,// 退出登录
  changepwd,//修改密码
}

