<!-- 选择图书 -->
<template>
  <div>
    <el-dialog title="选择图书" width="1200px" :close-on-click-modal="false" append-to-body top="8vh"
      :visible.sync="showDetail" @close="closeDialog">
      <div class="flex-class">
        <div class="tree-box">
          <div style="padding:10px 0 10px 25px;cursor: pointer"
            :style="categoryId == ''?'background-color: #edf6ff;':''" @click="getAllDate">全部分类
          </div>
          <el-tree ref="groupTree" class="filter-tree" :data="treeData" node-key="id"
            highlight-current :props="defaultProps" :filter-node-method="filterNode"
            :default-expanded-keys="[1]" @node-click="getNodeData"></el-tree>
        </div>
        <div class="table-box">
          <div class="searchForm">
            <el-form :model="filters" label-width="100px" size="small" class="formData">
              <el-collapse-transition>
                <form-item :option="searchOption" :column="3" :filters.sync="filters"
                  @searchChange="search" @reset="reset" ref="filters" width="100%">
                  <template #isSale>
                    <el-form-item label="销售状态:" prop="type" width='100%' style="width:100%;">
                      <div style="width:100%;">
                        <el-select style="width:100%" v-model="filters.isSale" placeholder="请选择"
                          clearable>
                          <el-option style="width:100%" v-for="i in saleList" :key="i.value"
                            :label="i.label" :value="i.value"></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </template>
                  <template #attributeType>
                    <el-form-item label="图书属性:" prop="type" width='100%' style="width:100%;">
                      <div style="width:100%;">
                        <el-select style="width:100%" v-model="filters.attributeType"
                          placeholder="请选择" clearable>
                          <el-option style="width:100%" v-for="i in mediaList" :key="i.value"
                            :label="i.label" :value="i.value"></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </template>
                  <template #createTime>
                    <el-form-item label="创建时间:" prop="type">
                      <el-date-picker v-model="createTime" type="datetimerange"
                        @input="changeLoginTime" value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00','23:59:59']" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                  </template>
                </form-item>
              </el-collapse-transition>
            </el-form>
          </div>
          <!-- 表格数据 -->
          <v-table class="table" ref="table" :tableData="tableList" :tableOption="tableOption"
            @handleSelectionChange="selectionChange" @selectChange="selectChange">
            <!-- 表格特殊字段处理插槽slot为true -->
            <template slot="book" slot-scope="scope">
              <div
                style="width: 100%;text-align: left;display: flex;justify-content: center;align-items: center;">
                <img :src="scope.row.bookCover" alt="" style="width: 56px;height: 80px;">
                <div style="flex:1;
                        vertical-align: top;
                        margin-left: 10px;
                        width: 100px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;">
                  {{scope.row.bookName}}<br>
                  {{scope.row.bookAuthor}}<br>
                  <div>
                    <span class="orangeIcon" v-if="scope.row.isSeries == 1">丛书</span>
                    <span class="orangeIcon" v-if="scope.row.isRichMedia == 1">富媒体</span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="price" slot-scope="scope">
              <div v-if="!type">
                <span
                  v-if="scope.row.isSale === 1">￥{{operation=="wholesale" ? scope.row.orignalPrice : scope.row.orignalPrice}}</span>
                <span v-else>-</span>
              </div>
              <div v-if="type">
                <span>￥{{operation=="wholesale" ? scope.row.orignalPrice : scope.row.orignalPrice}}</span>
              </div>
            </template>
			 <template slot="pressPrice" slot-scope="scope">
				 ￥{{scope.row.pressPrice}}
			 </template>
            <template slot-scope="scope" slot="isSale">
              <div class="statusBox" v-if="scope.row.isSale === 1">
                <div class="circle circle1"></div>可售
              </div>
              <div class="statusBox" v-if="scope.row.isSale === 0">
                <div class="circle circle2"></div>不可售
              </div>
            </template>
          </v-table>
          <!-- 分页 -->
          <pagination v-show="pageTotal > 0" :pageData="pageData" :total="pageTotal"
            @hanleSize="changePage" @hanlePage="handleSizeChange"></pagination>
          <!-- <pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageData.current"
            :limit.sync="pageData.size" @pagination="getBookList"
            @handleSizeChange="handleSizeChange" @handleCurrentChange="changePage" /> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确 定</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { getBook, getCategories } from "@/api/book/bookList";
import { BOOK_API } from "@/service/permissions/book";
// import { stateList } from '@/enums/userManager'//注册时间
export default {
  props: {
    open: {
      type: Boolean
    },
    list: {
      type: Array
    },
    type: {
      type: String,
      default: ''
    },
    operation: {
      type: String
    }
  },
  data () {
    return {
      createTime: [],
      selectData: [],
      filters: {},
      pageData: {
        current: 1,
        size: 10
      },
      pageTotal: 0,
      mediaList: [{ label: '富媒体', value: 1 }, { label: '丛书', value: 2 }],
      saleList: [{ label: '不可售', value: 0 }, { label: '可售', value: 1 }],
      //搜素
      searchOption: [
        { prop: 'bookName', type: 'input', label: '图书名称:', placeholder: '请输入', col: 8, },
        { prop: 'isbn', type: 'input', label: 'ISBN:', placeholder: '请输入', col: 8, },
        {
          prop: 'isSale', label: '销售状态：', type: 'select', col: 8, slot: true, placeholder: '请选择'
        },
        {
          prop: 'attributeType', label: '图书属性:', type: 'select', col: 8, slot: true, placeholder: '请选择'
        },
        { prop: 'bookAuthor', type: 'input', label: '作者：', placeholder: '请输入', col: 8, },
        { prop: 'pressName', type: 'input', label: '出版社：', placeholder: '请输入', col: 8, },
        {
          prop: 'createTime',
          label: '创建时间：',
          slot: true,
          col: 14,
          type: 'select',
          // selectList: stateList,
          placeholder: '请选择状态'
        },
      ],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'categoryName'
      },
      tableList: [],
      showDetail: false,
      categoryId: null,//分类id
      //表格配置
      tableOption: {
        align: 'center',
        sort: true,
        selection: true,
        props: 'createTime',
        reserve: true,
        order: '',
        getRowKey (row) {
          return row.bookId
        },
        selectable: (row, index) => {
          if (this.type) {
            if (row.isSale === 1) {
              return true;
            }
          } else {
            if (row.isOnShelf === 1) {
              return true;
            }
          }
        },
        column: [
          { prop: 'index', label: '序号' },
          { prop: 'book', label: '图书', tableProp: true, slot: true },
          { prop: 'pressName', label: '出版社', tableProp: true, },
          { prop: 'isSale', label: '销售状态', tableProp: true, slot: true },
		  { prop: 'pressPrice', label: '定价(元)', tableProp: true, slot: true },
          { prop: 'price', label: '原价(元)', tableProp: true, slot: true },
        ],
      },
    }
  },
  methods: {
    init (tableList) {
      this.showDetail = true
      this.$nextTick(() => {
        this.$refs.table.clearSelection()
      })
      this.filters = {}
      this.pageData = {
        current: 1,
        size: 10,
      }
      this.getCtegoryList()
      this.getBookList()
    },

    // 获取图书分类
    async getCtegoryList () {
      const params = {
        parentCategoryId: 0
      }
      const data = await BOOK_API.getTree(params)
      // this.treeData = data.data
      this.treeData = data.data.slice(1)
    },
    // 获取图书列表
    async getBookList () {
      this.$store.commit('SET_TABLE_LOADING', true)
      try {
        let params = this.filters
        params.current = this.pageData.current
        params.size = this.pageData.size
        params.bookStatus = 1
        if (this.categoryId != null) params.categoryId = this.categoryId
        // if (this.type) {
        //   // 从第三方渠道创建商品点击选择图书的接口不一样
        // }
        const data = await BOOK_API.getList(params)
        for (const [index, i] of data.data.records.entries()) {
          i.cnt = 0
          i.total = null
          i.disCount = null
        }
        this.tableList = data.data.records.map(item => { return { ...item, checked: false } })
        this.pageTotal = data.data.total
        if (this.list.length > 0) {
          for (let i of this.list) {
            for (let j of this.tableList) {
              if (i.bookId === j.bookId) {
                this.$nextTick(() => {
                  j.checked = true
                  this.$refs.table.$refs.tableLayout.toggleRowSelection(j, true)
                });
              }
            }
          }
        }
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }

    },

    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    getAllDate () {
      this.getCtegoryList()//重新获取列表，清空选中状态   
      this.getNodeData({ categoryId: '' })
    },
    getNodeData (val) {
      this.categoryId = val.categoryId
      this.pageData.current = 1
      this.getBookList()
    },

    search () {
      this.pageData.current = 1
      this.getBookList()
    },
    reset () {
      this.filters = {};
      this.$refs.filters.initForm()
      this.createTimeEnd = ''
      this.createTimeStart = ''
      this.createTime = [];
      this.pageData.size = 10
      this.pageData.current = 1
      // this.isSale
      // this.filters.isSale = null
      this.categoryId = ''
      this.templateRadio = ''
      this.templateSelection = []
      this.$refs.table.$refs.tableLayout.clearSort()
      // const params = this.filterData()
      this.init()
    },
    closeDialog () {
      this.showDetail = false
      this.categoryId = null
      // this.$emit('update:open', false)
      this.filters = {};
      this.$refs.filters.initForm()
      this.createTimeEnd = ''
      this.createTimeStart = ''
      this.createTime = [];
      this.pageData.size = 10
      this.pageData.current = 1
      this.categoryId = ''
      this.templateRadio = ''
      this.templateSelection = []
      this.selectData = [];
      this.$refs.table.$refs.tableLayout.clearSort()
    },
    //已经勾选过的不能取消勾选
    selectChange (selection, row) {
      if (this.type) {
        for (let i of this.tableList) {
          if (i.bookId === row.bookId && i.checked === true) {
            this.$nextTick(() => {
              this.$refs.table.$refs.tableLayout.toggleRowSelection(i, true)
            });
          }
        }
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getBookList()
    },
    handleSizeChange (val) {
      this.pageData.size = val
      this.getBookList()
    },
    confirm () {
      this.$emit('handleSelect', this.selectData)
      this.selectData = [];
      this.closeDialog()
    },
    selectionChange (val, row) {
      this.selectData = val.map((item, index) => {
        return { rank: index + 1, ...item }
      })
      console.log(this.selectData, '选中的数据')
    },
    changeLoginTime (value) {
      if (value == null) {
        this.filters.createTimeStart = '';
        this.filters.createTimeEnd = '';
        return
      }
      this.filters.createTimeStart = this.timeConversion(new Date(value[0]))
      this.filters.createTimeEnd = this.timeConversion(new Date(value[1]))
    },
    timeConversion (date) {
      const resDate = date.getFullYear() + '-' + this.replenish((date.getMonth() + 1)) + '-' + this.replenish(date.getDate())
      const resTime = this.replenish(date.getHours()) + ':' + this.replenish(date.getMinutes()) + ':' + this.replenish(date.getSeconds())
      return resDate + ' ' + resTime
    },
    replenish (s) {
      return s < 10 ? '0' + s : s
    },
  }
}
</script>
<style scoped lang="scss">
.flex-class {
  display: flex;
}
.filter-tree {
  border: 0px solid #dcdfe6;
  padding: 15px 20px;
}
.orange-box {
  display: inline-block;
  color: orange;
  border: 1px solid orange;
  margin-right: 2px;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 4px;
}
.table {
  height: 580px;
  overflow: auto;
}
.tree-box {
  height: 500px;
  overflow: auto;
}
.table-box {
  width: 80%;
}
.statusBox {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .circle1 {
    background-color: #0e77d1;
  }
  .circle2 {
    background-color: #f04134;
  }
  .circle3 {
    background-color: #bfbfbf;
  }
}
</style>