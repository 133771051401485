<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 15:26:42
 * @LastEditTime: 2022-07-05 14:48:33
 * @LastEditors: zjc
-->
<template>
  <div class="arcsin-header">
    <div class="arcsin-header__top">
      <div class="arcsin-header__top__left">
        <div class="arcsin-header__top__bar" @click="setCollapse">
          <svg-icon :icon-class="isCollapse?'hide':'show'" style="width: 16px;cursor: pointer;" />
        </div>
      </div>
      <div class="arcsin-header__top__middle">
        <!-- 头部tabs -->
        <header-tabs></header-tabs>
      </div>
      <div class="arcsin-header__top__right">
        <el-tooltip effect="dark" :content="isFullScreen?'退出全屏':'全屏'" placement="bottom">
          <svg-icon icon-class="fullscreen_1" @click="handleFullscreen" class="el-input__icon"
            style="width: 20px;height:20px;cursor: pointer">
          </svg-icon>
        </el-tooltip>
        <div class=" arcsin-header__top__right__item">

          <el-dropdown>
            <span>
              <img src="@/assets/img/icon-user.png" width="40"
                style="vertical-align: middle;border-radius: 100%;margin-right: 5px;" alt="">
              {{name}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="jumpPage('/oms/dashboard')">首页
              </el-dropdown-item>
              <el-dropdown-item @click.native="createDialog">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- 修改密码 -->
    <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :form.sync="form" :option="formOption" @row-save="rowSave"></modal-item>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import headerTabs from './headerTabs'
import { fullscreenToggel, listenfullscreen } from '@/utils/fullscreen'
import ModalItem from '../../../components/CRUD/modalItem.vue'
import { pwd } from '@/validate/el-validate'
import { USER_API } from '@/service/user'
export default {
  components: { headerTabs, ModalItem },
  data () {
    const updatepwd = (rule, value, callback) => {
      if (value != this.form.newPassword) {
        callback(new Error('两次输入不一致，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      showPwd: false,//是否显示修改密码
      activeIndex: '1',
      // 新增、修改 配置
      formOption: {
        dialogWdith: '600px',
        labelWidth: '80px',
        clearable: true,
        size: 'medium',
        column: [
          {
            prop: 'oldPassword', label: '旧密码', type: 'input', inputType: 'password', placeholder: '请输入旧密码', rules: [
              { required: true, message: "请输入旧密码", trigger: "blur" },
              // { required: true, trigger: 'blur', validator: pwd }
            ]
          },
          {
            prop: 'newPassword', label: '新密码', type: 'input', inputType: 'password', placeholder: '请输入新密码', rules: [
              { required: true, message: '请输入新密码', trigger: 'blur' },
              // { required: true, trigger: 'blur', validator: pwd }
            ]
          },
          {
            prop: 'confirmPassword', label: '确认密码', type: 'input', inputType: 'password', placeholder: '请输入确认密码', rules: [
              { required: true, message: '请输入确认密码', trigger: 'blur' },
              // { required: true, trigger: 'blur', validator: pwd },
              { required: true, trigger: 'blur', validator: updatepwd }
            ]
          },
        ]
      },
      form: {

      },//新增修改数据
      dialogData: {},//弹窗需要的参数
    }
  },
  computed: {
    ...mapGetters(['isCollapse', 'isScreen', 'isFullScreen', 'name', 'userId'])
  },
  methods: {
    // 新增、修改 弹窗
    createDialog () {
      this.dialogData = {
        title: '修改密码',
        showDialog: true
      }
    },
    //修改密码
    async rowSave (val) {
      try {
        const params = {
          oldPassword: val.oldPassword,
          newPassword: val.newPassword,
          oprId: this.userId,
        }
        const { data } = await USER_API.changepwd(params)
        this.$ok('修改成功')
        this.$refs.modal.loading = false
        this.dialogData.showDialog = false
      } catch (err) {
        this.$refs.modal.loading = false
      }
    },
    //展开收起菜单
    setCollapse () {
      this.$store.commit('UPDATES_IDEBAR_FOLD')
    },
    // 退出登录
    loginOut () {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('logOut')
        this.$store.commit('SET_MENU_ALL', []);
        window.location.reload()
      }).catch(() => {
      });
    },
    setFullscreen () {
      this.$store.commit('SET_FULLSCREN')
    },
    handleFullscreen () {
      fullscreenToggel()
    }
  },
  mounted () {
    listenfullscreen(this.setFullscreen)
  }
}
</script>
<style lang="scss" scoped>
.hamburger {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}
</style>