<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:52:39
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <el-button type="primary" :loading="btnLoading" size="small">缓存
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange">
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" style="color: red">删除</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 新增终端 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="880px" append-to-body @closed="closed('ruleForm')">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="终端编号" prop="">
              <el-input placeholder="请输入终端编号"></el-input>
            </el-form-item>
            <el-form-item label="域" prop="">
              <el-input placeholder="请输入域"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="终端密钥" prop="">
              <el-input placeholder="请输入终端密钥"></el-input>
            </el-form-item>
            <el-form-item label="自动放行" prop="">
              <el-radio-group>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="授权模式" prop="" class="pattern">
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in pattern" :label="item.value" :key="item.value">
              {{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="令牌时效" prop="">
              <el-input placeholder="请输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="回调地址" prop="">
              <el-input placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="刷新时效" prop="">
              <el-input placeholder="请输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="权限" prop="">
              <el-input placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <p style="
            font-weight: bold;
            font-size: 14px;
            margin-left: 30px;
            color: #000000;
          ">
          安全属性
        </p>
        <el-row style="margin-top: 13px">
          <el-col :span="12">
            <el-form-item label="多设备登录" prop="">
              <el-radio-group>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="同时在线设备" prop="">
              <el-input placeholder="请输入" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
export default {
  data () {
    return {
      checkedCities: [],
      pattern: [
        { label: "密码模式", value: 1 },
        { label: "授权码模式", value: 2 },
        { label: "客户端模式", value: 3 },
        { label: "刷新模式", value: 4 },
        { label: "简化模式", value: 5 },
        { label: "mobile", value: 6 },
        { label: "social", value: 7 },
      ],
      dialogVisible: false,
      selectionList: [],
      filters: {},
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      title: "新增终端",
      list: [{}],
      btnLoading: false,
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          {
            prop: "tenantName",
            label: "终端编号",
            tableProp: true,
            width: 120,
          },
          { prop: "tenantId", label: "密钥", tableProp: true },
          { prop: "domain", label: "域", tableProp: true },
          {
            prop: "accountLimit",
            label: "自动放行",
            tableProp: true,
            width: 150,
          },
          {
            prop: "accountLimit",
            label: "授权模式",
            tableProp: true,
            width: 150,
          },
          {
            prop: "expirationDate",
            label: "令牌时效",
            tableProp: true,
          },
          {
            prop: "expirationDate",
            label: "刷新时效",
            tableProp: true,
          },
        ],
      },
    };
  },
  methods: {
    getData () { },
    // 勾选的数据
    selectionChange (val) {
      this.selectionList = val;
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    addOrUpdate (type, item) {
      this.type = type;
      if (type === 1) {
        this.title = "新增终端";
      } else {
        this.title = "编辑终端";
      }
      this.dialogVisible = true;
    },
    closed (formName) {
      this.$refs[formName].resetFields();
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //
        } else {
          console.log("error submit!!");
        }
      });
    },
    handleCheckedCitiesChange (value) {
      console.log(value, "value");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
<style>
.pattern .el-checkbox {
  margin-right: 20px;
}
</style>