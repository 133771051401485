<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-17 09:26:46
 * @LastEditTime: 2022-07-05 14:52:16
 * @LastEditors: zjc
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" size="small" label-width="0" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate('')">新增渠道
          </el-button>
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="openPermissions(scope.row)">查看
        </el-button>
        <el-button type="text" size="small" @click="addOrUpdate(scope.row)">
          编辑</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 新增、编辑渠道 -->
    <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item>

  </basic-container>
</template>
<script>
import { ROLE_API } from "@/service/permissions/channel";
export default {
  data () {
    return {
      filters: {},
      pageTotal: 0,
      list: [],
      // 表格查询条件配置
      tableOption: {
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
        column: [
          { prop: "appName", label: "渠道名称", tableProp: true },
          { prop: "appId", label: "渠道号", tableProp: true },
        ],
      },
      //搜索
      searchOption: [
        {
          prop: "appName",
          label: "",
          type: "input",
          placeholder: "搜索渠道名",
          option: { clearable: true }
        },

      ],
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      // 新增、修改 渠道
      formOption: {
        dialogWdith: "400px",
        // dialogHeight: "600px",
        labelWidth: "80px",
        clearable: true,
        size: "medium",
        column: [
          {
            prop: "appName",
            label: "渠道名称",
            type: "input",
            placeholder: "请输入",
            maxLength: 10,
            limit: true,
            rules: [
              { required: true, message: "请输入", trigger: "blur" },
            ],
          },
        ],
      },
    };
  },
  mounted () {
    this.getList();
  },
  methods: {
    // 获取渠道列表
    async getList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { data } = await ROLE_API.getList({
          ...this.pageData,
          ...this.filters,
        });
        this.list = data.records;
        this.pageTotal = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getList();
    },
    addOrUpdate (row = {}) {
      this.dialogData = {
        id: row.appId,
        title: row ? "编辑渠道" : "新增渠道",
        showDialog: null,
      };
      if (!this.$validatenull(row)) {
        const { appId, appName, openAppId } = row;
        this.form = { appId, appName, openAppId };
      }
      this.dialogData.showDialog = true;
    },
    // 跳转到详情页 查看
    openPermissions (row) {
      console.log(row)
      this.$router.push({
        path: "./channelDetail",
        query: { appId: row.appId, appName: row.appName }
      });
    },
    //重置
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.$nextTick(() => {
        this.getList();
      });
    },
    //搜索条件
    search () {
      this.pageData.current = 1;
      this.getList();
    },
    //新增渠道
    async rowSave (val) {
      try {
        const { data } = await ROLE_API.addChannel(val);
        console.log(data)
        this.$ok("新增成功");
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
        this.getList();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
    //修改渠道
    async rowUpdate (val) {
      try {
        const { data } = await ROLE_API.updateChannel(val);
        this.$ok("修改成功");
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
        this.getList();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
  },

};
</script>