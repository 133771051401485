/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:47:29
 * @LastEditTime: 2021-12-09 10:13:25
 * @LastEditors: zjc
 */
const myMixin = {
  data () {
    return {
      // 分页      
      pageData: {
        current: 1,
        size: 10,
      }
    }
  },
  methods: {
    /**
     * 路由跳转
     * @param {*} route 
     * @param {*} type 
     */
    jumpPage (route, type = 'push') {
      if (type === 'replace') {
        this.$router.replace(route);
      } else {
        this.$router.push(route);
      }
    },
  }
}
export default myMixin
