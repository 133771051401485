<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:52:30
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #type>
              <el-form-item label="参数类型" label-width="90px">
                <el-select clearable v-model="filters.type" placeholder="请选择"
                  @change="changeStatus">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template #createTime>
              <el-form-item label="授权时间" label-width="100px">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                  v-model="filters.createTime" type="datetimerange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset"> </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption">
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="check(scope.row)">查看</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 查看 -->
    <el-dialog title="查看" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px"
      append-to-body @closed="closed('ruleForm')">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="rule-form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="日志类型：">
              <p>正常</p>
            </el-form-item>
            <el-form-item label="请求url：">
              <p>/auth/oauth/token</p>
            </el-form-item>
            <el-form-item label="请求ip：">
              <p>127.0.0.1</p>
            </el-form-item>
            <el-form-item label="服务名：">
              <p>cl-oms</p>
            </el-form-item>
            <el-form-item label="请求方法类：">
              <p style="line-height: 20px; display: inline-block;">
                com.zglib.oms.controller.SysLinkController
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日志标题：">
              <p>用户登录</p>
            </el-form-item>
            <el-form-item label="请求方式：">
              <p>POST</p>
            </el-form-item>
            <el-form-item label="耗时：">
              <p>2550ms</p>
            </el-form-item>
            <el-form-item label="日志时间：">
              <p>2022-12-25 09:32:15</p>
            </el-form-item>
            <el-form-item label="请求方法名：">
              <p>login</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="用户代理：">
          <p style="line-height: 20px; display: inline-block;">
            Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like
            Gecko) Chrome/86.0.4240.198 Safari/537.36
          </p>
        </el-form-item>
        <el-form-item label="请求数据：">
          <p style="line-height: 20px; display: inline-block;">
            tenantId=000000&username=admin&password=21232f297a57a5a743894a0e4a801fc3&grant_type=captcha&scope=all&type=account
          </p>
        </el-form-item>
        <el-form-item label="异常信息：">
          <p style="line-height: 20px; display: inline-block;">
            tenantId=000000&username=admin
          </p>
        </el-form-item>
      </el-form>
    </el-dialog>
  </basic-container>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      list: [{}],
      filters: {},
      //搜索
      searchOption: [
        {
          prop: "type",
          label: "日志类型",
          type: "select",
          slot: true,
          col: 5,
          placeholder: "请输入",
        },
        {
          prop: "domain",
          label: "服务名",
          type: "input",
          col: 5,
          placeholder: "请输入",
        },
        {
          prop: "createTime",
          label: "参数类型",
          type: "select",
          col: 8,
          slot: true,
          placeholder: "请选择",
        },
      ],
      options: [
        { label: "类型1", value: 1 },
        { label: "类型2", value: 2 },
        { label: "类型3", value: 3 },
        { label: "类型4", value: 4 },
        { label: "类型5", value: 5 },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          {
            prop: "tenantName",
            label: "日志类型",
            tableProp: true,
            width: 120,
          },
          { prop: "tenantId", label: "日志标题", tableProp: true },
          { prop: "domain", label: "请求url", tableProp: true },
          {
            prop: "accountLimit",
            label: "请求方式",
            tableProp: true,
            width: 150,
          },
          {
            prop: "accountLimit",
            label: "请求ip",
            tableProp: true,
            width: 150,
          },
          {
            prop: "expirationDate",
            label: "耗时(ms)",
            tableProp: true,
          },
          {
            prop: "expirationDate",
            label: "服务名",
            tableProp: true,
          },
          {
            prop: "expirationDate",
            label: "日志时间",
            tableProp: true,
          },
        ],
      },
    };
  },
  methods: {
    getData () { },
    //   重置
    reset () {
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    check (item) {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
<style>
.rule-form .el-form-item {
  margin-bottom: 15px;
}
</style>