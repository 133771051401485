<template>
  <div class="custom-ueditor">
    <div :id="id" :style="{ width: width + '%', height: height + 'px' }" />
    <!-- 上传图片 -->
    <ueditor-insertionImage class="btnClass" style="left: 10px;" :button-style="top"
      @success="imageSuccess" />
    <!-- 上传视频 -->
    <UeditorInsertionVideo class="btnClass" style="left: 105px;" v-show="isVideo" :type="type"
      :button-style="top" @success="videoSuccess">
    </UeditorInsertionVideo>
  </div>
</template>
<script>
import config from "./config";
import UeditorInsertionImage from "../UeditorInsertionImage";
import UeditorInsertionVideo from "../UeditorInsertionVideo";
const UE = window.UE;
// 设置 UEDITOR_HOME_URL
window.UEDITOR_HOME_URL = "/static/ueditor";
export default {
  components: { UeditorInsertionImage, UeditorInsertionVideo },
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 400,
    },
    type: {
      type: String,
      default: null,
    },
    isVideo: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      ueditor: null,
      buttonStyle: {
        padding: "3px 6px",
      },
      previewInfo: '',
      top: 0,
    }
  },
  computed: {
    // 生成唯一id
    id () {
      const id =
        Math.random().toString(36).substring(2, 15) + "-ueditor-" + +new Date();
      return id;
    },
  },
  watch: {
    html (val) {
      this.loadUrl(val);
    },
  },
  mounted () {
    this.init();
    this.fetchResize()
  },
  beforeDestroy () {
    this.destroyEditor();
  },
  methods: {
    fetchResize () {
      setTimeout(() => {
        const style = document.getElementsByClassName('edui-editor-toolbarboxinner')
        this.top = style[0].offsetHeight - 30 + 'px'
      }, 100)
      window.addEventListener('resize', () => {
        setTimeout(() => {
          const style = document.getElementsByClassName('edui-editor-toolbarboxinner')
          this.top = style[0].offsetHeight - 30 + 'px'
        }, 100)
      })
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', () => {
          setTimeout(() => {
            const style = document.getElementsByClassName('edui-editor-toolbarboxinner')
            this.top = style[0].offsetHeight - 30 + 'px'
          }, 100)
        })
      })
    },
    // 编辑器初始化
    init () {
      console.log('ue', UE)
      this.ueditor = UE.getEditor(this.id, { ...config });
      if (this.html) {
        this.loadUrl(this.html);
      }
    },
    // 加载html内容
    async loadUrl (url) {
      console.log(url)
      this.setContent(url);
      // try {
      //   const { data } = await axios.get(url)
      //   this.setContent(data)
      // } catch (error) {
      //   this.setContent('服务器数据加载失败，请重试!')
      // }
    },
    // 在当前光标位置插入html内容
    inserthtml (content) {
      console.log(content, 'neirong')
      if (!content) return;
      this.ueditor.execCommand("inserthtml", content);
    },
    // 设置编辑器内容  isAppendTo为true时是追加内容到编辑器，false是覆盖
    setContent (content, isAppendTo) {
      if (!content) return;
      this.ueditor.ready(() => {
        this.ueditor.setContent(content, isAppendTo);
      });
    },
    // 获取编辑器内容
    getContent () {
      return this.ueditor.getContent();
    },
    // 销毁编辑器
    destroyEditor () {
      this.ueditor.destroy();
    },
    // 上传图片
    async imageSuccess (imageList) {
      try {
        let imageTemplateList = "";
        const image = `<img class="allImage" style="width: 100%;margin-top: 20px;margin-bottom: 20px;" src="${imageList.insertionImage}">`;
        imageTemplateList = imageTemplateList + image;
        this.inserthtml(imageTemplateList, true);
        this.$message.success("上传成功！");
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    // 上传视频
    async videoSuccess (videoList) {

      try {
        let videoTemplateList = " ";
        const video = `<div class="allVideo" style="width: 100%;margin-top: 20px;margin-bottom: 20px;text-align:center"><video width="${videoList.width + '%'}" height="${videoList.height}" poster="${videoList.videoImage}" style="object-fit:fill;" src="${videoList.insertionImage}" controls></video></div>`;
        videoTemplateList = videoTemplateList + video;
        this.inserthtml(videoTemplateList, true);
        this.$message.success("上传成功！");
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    // 插入vip会员中心添加到编辑器
    async vipCenterSuccess ({ imgUrl }) {
      try {
        const image = `<div style="margin-top: 20px;margin-bottom: 20px;"><img src="${imgUrl}" class="btn-vip-center"  style="width:100%;"></div>`;
        this.inserthtml(image, true);
        this.$message.success("插入成功！");
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    // VIP结算台
    async vipComputedSuccess ({ imgUrl }) {
      try {
        const image = `<div style="margin-top: 20px;margin-bottom: 20px;"><img  src="${imgUrl}" class="btn-vip-computed"  style="width:100%;"></div>`;
        this.inserthtml(image, true);
        this.$message.success("插入成功！");
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    // 插入盈书四壁
    async ysMainSuccess ({ imgUrl }) {
      try {
        console.log(imgUrl);
        const image = `<div style="margin-top: 20px;margin-bottom: 20px;"><img  src="${imgUrl}" class="btn-sy-main"  style="width:100%;"></div>`;
        this.inserthtml(image, true);
        this.$message.success("插入成功！");
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.custom-ueditor {
  position: relative;
  color: #373737;
  line-height: 22px;
  .zIndex {
    position: absolute;
    z-index: 100;
    top: 63px;
  }
}
.btnClass {
  position: absolute;
  left: 10px;
  top: 60px;
  z-index: 100;
}
</style>
<style>
.edui-button.edui-for-135editor .edui-button-wrap .edui-button-body .edui-icon {
  background-image: url("http://static.135editor.com/img/icons/editor-135-icon.png") !important;
  background-size: 85%;
  background-position: center;
  background-repeat: no-repeat;
}
.edui-default .edui-editor{
  z-index: 99!important;
}
</style>