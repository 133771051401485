<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:52:43
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset"> </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption">
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="del(scope.row)" style="color: red">删除
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
  </basic-container>
</template>
<script>
export default {
  data () {
    return {
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      list: [{}],
      //搜索
      searchOption: [
        {
          prop: "tenantId",
          label: "用户名",
          type: "input",
          col: 7,
          placeholder: "请输入",
        },
      ],
      filters: {},
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "tenantName", label: "用户名", tableProp: true },
          { prop: "applicationName", label: "客户端", tableProp: true },
          {
            prop: "expirationDate",
            label: "令牌",
            tableProp: true,
            slot: true,
          },
          { prop: "createTime", label: "类型", tableProp: true },
          { prop: "createTime", label: "过期时间", tableProp: true },
        ],
      },
    };
  },
  methods: {
    getData () { },
    search () { },
    //   重置
    reset () {
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    del (item) {
      this.$confirm(
        `是否强制bb761017-699c-43da-b687-0fabd647f8ed下线？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          //
        });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>