/*
 * @Description: 按钮权限
 * @Author: zjc
 * @Date: 2021-12-27 14:04:39
 * @LastEditTime: 2022-04-12 11:46:15
 * @LastEditors: zjc
 */
import store from '@/store/index'
/**
   * 按钮权限控制
   * @param {String} button 按钮权限编码 
   * @returns 
   */
const isAuth = (button) => {
  const authList = store.state.user.permission || []
  return authList.includes(button)
}
/**
 * 更多操作按钮权限控制
 * @param {Array} buttonList 按钮权限编码 
 * @returns 
 */
const moreAuth = (buttonList) => {
  const authList = store.state.user.permission || []
  const isMore = authList.filter(auth => buttonList.some(button => button === auth)); //判断是否有交集如果有表示有更多操作按钮，如果length为0表示没有
  if (isMore.length === 0) return false
  else return true
}
export {
  isAuth,
  moreAuth
}