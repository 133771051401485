import { render, staticRenderFns } from "./userDetail.vue?vue&type=template&id=3bb0aade&scoped=true"
import script from "./userDetail.vue?vue&type=script&lang=js"
export * from "./userDetail.vue?vue&type=script&lang=js"
import style0 from "./userDetail.vue?vue&type=style&index=0&id=3bb0aade&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bb0aade",
  null
  
)

export default component.exports