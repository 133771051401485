<!--  新增需求 -->
<template>
  <div style="width:100%;">
    <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;">
      <div class="abox_title">
        <div class="left_title">
          <p class="title" style="margin:auto">用户详情</p>
        </div>
        <div class="right_title" v-show="status == 1">
          <span class="txt1" style="font-size:12px">状态：</span>
          <span class="txt2" style="font-size:12px" v-if="ruleForm.orderStatus == 1 ">
            <span v-if="ruleForm.handleStatus == 1">待确认</span>
            <span v-else-if="ruleForm.handleStatus == 2">待复核</span>
          </span>
          <span class="txt2" style="font-size:12px" v-else-if="ruleForm.orderStatus == 2">已完成</span>
          <span class="txt2" style="font-size:12px" v-else-if="ruleForm.orderStatus == 3">已取消</span>
        </div>
      </div>
      <div style="width:100%;">
        <div class="tab-one" style="width:100%;">
          <p class="tab-title">平台信息</p>
          <div class="tab-content2">
            <div class="box_tab_one">
              <div class="content">
                <div class="item">
                  <span class="l1">ID：</span>
                  <span class="r1">{{ruleForm.orgId}}</span>
                </div>
                <div class="item">
                  <span class="l1">手机号：</span>
                  <span class="r1">{{ruleForm.mobile}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="tab-one" style="margin:20px auto 0;width:100%;">
          <p class="tab-title">捐赠机构信息</p>
          <div class="tab-content2">
            <div class="box_tab_one">
              <div class="content">
                <div class="item itemUserMsg">
                  <img :src="ruleForm.avatar" alt="">
                  <span class="l1">昵称：</span>
                  <span class="r1">{{ruleForm.nickName}}</span>
                </div>
                <div class="item">
                  <span class="l1">首次授权时间：</span>
                  <span class="r1">{{ruleForm.createTime}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-one" style="margin:20px auto 0;width:100%;">
          <p class="tab-title">已使用捐赠信息</p>
          <div class="tab-content2">
            <div class="box_tab_one">
              <div class="content" v-for="(item,index) in tableList" :key="index">
                <div class="item">
                  <span class="l1">落款名称：</span>
                  <span class="r1">{{item.inscriptionName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { ROLE_API } from "@/service/permissions/user";
export default {

  data () {
    return {
      searchText: '',
      tableList: [],
      //表格配置
      tableOption: {
        selection: true,
        align: 'center',
        operationData: { prop: 'operation', label: '操作', slot: true, operation: true, width: 160, fixed: 'right', align: 'center' },
        column: [
          { prop: 'indexNo', label: '序号', slot: true, tableProp: true, width: 100 },
          { prop: 'book', label: '名称', slot: true, tableProp: true, },
          { prop: 'pressName', label: 'ISBN', tableProp: true },
          { prop: 'price', label: '定价（元）', tableProp: true },
          { prop: 'price', label: '捐赠售价（元）', tableProp: true },
          { prop: 'price', label: '复本数（册）', tableProp: true },
        ],
      },

      libraryList: [],
      fileList: [],
      ruleForm: {

      },
      createTime: null,
      purchaseOrderId: null,
      actualAmount: null,
      status: 0,//0新增|1查看
      //  allCnt: '',
      goodsCnt: 0,
      sortCnt: 0,
      totalAmount: 0,
      // actualAmount: 0,
      selectList: [],
    }
  },
  created () {
    if (this.$route.query.orgUserId) {
      this.orgUserId = this.$route.query.orgUserId;
      localStorage.setItem("orgUserId", this.$route.query.orgUserId)

    }
    else {
      if (localStorage.getItem("orgUserId")) {
        this.orgUserId = localStorage.getItem("orgUserId")
      } else {
        this.$router.push({
          path: "./organization",
        });
      }
    }
    this.getList();
  },
  mounted () {

    // this.$refs.student.$on('getName', this.getFileList)
  },
  methods: {
    // 获取详情 
    async getList () {
      try {
        let params = {}
        params.orgUserId = this.orgUserId
        console.log(params)
        const { data } = await ROLE_API.getUserOrgList(params)
        console.log(data)
        this.ruleForm = data.user
        this.tableList = data.userInscriptionList
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },


    closeDialog () {
      this.showDetail = false
      this.formData = {}
      this.packageImg = null
      this.packageName = null
      this.packageDetail = null
      this.tableList = []
    },


  }
}
</script>
<style scoped lang="scss">
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .itemUserMsg {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            justify-content: flex-start;
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin: 0 30px 0 0;
            }
          }
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
}
</style>
