/*
 * @Description:路由入口
 * @Author: zjc
 * @Date: 2021-12-07 14:10:31
 * @LastEditTime: 2021-12-27 16:24:57
 * @LastEditors: zjc
 */
import VueRouter from 'vue-router'
import index from '@/views/layout/index'
const _import = require('./import-' + process.env.NODE_ENV)
export const asyncRouteMap = [
  //权限管理
  {
    path: '/oms', component: index, children: []
  },
]

// 默认路由
export const staticRoute = [
  {
    path: '/oms/dashboard', component: index, children: [
      { path: '/oms/dashboard', component: _import('index/index'), name: '首页', },
    ]
  },
  { path: '/404', component: _import('404/404'), name: '404' },
  { path: '/', component: _import('login/index'), name: '登录' },//登录
  // { path: '/test', component: _import('permissions/permission/configure'), name: '路由' },//登录
]
export default new VueRouter({
  routes: staticRoute
})
