/*
 * @Description:过滤
 * @Author: zjc
 * @Date: 2021-12-07 11:39:53
 * @LastEditTime: 2022-05-06 14:01:48
 * @LastEditors: zjc
 */
import moment from 'dayjs'
import { COMMON_API } from '@/utils/common'
/**
 * 日期格式化
 * @param {value}
 * @param {foramt}    
 */
const formatDate = (value, format = "dateTime") => {
  switch (format) {
    case 'dateTime':
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    case 'date':
      return moment(value).format('YYYY-MM-DD')
    default:
      return moment(value).format('YYYY-MM-DD')
  }
}
const formatDate1 = (value, format = "dateTime") => {
  return value ? moment(value).format(format) : moment().format(format)
}
/**
 * 数字添加千分符
 * @param {value}  
 */
const formatCount = (value = 0) => {
  return value.toLocaleString('zh', { style: 'decimal' })
}
/**
 * 文件大小转换
 * @param {*} size
 * @return {*} 
 */
const transformByte = (size) => {
  if (!size) {
    return '-';
  }
  var num = 1024.0; // byte
  if (size < num) {
    return size + 'B';
  }
  if (size < Math.pow(num, 2)) {
    return (size / num).toFixed(2) + 'KB';
  } // kb
  if (size < Math.pow(num, 3)) {
    return (size / Math.pow(num, 2)).toFixed(2) + 'MB';
  } // M
  if (size < Math.pow(num, 4)) {
    return (size / Math.pow(num, 3)).toFixed(2) + 'G';
  } // G
  return (size / Math.pow(num, 4)).toFixed(2) + 'T'; // T
}
// 设置绝对值
const getNum = (num) => {
  return num < 0 ? Math.abs(num) : num
}
export {
  formatDate,
  formatDate1,
  formatCount,
  transformByte,
  getNum
}