<!--
 * @Description: 左侧导航菜单
 * @Author: zjc
 * @Date: 2021-12-07 15:26:42
 * @LastEditTime: 2022-07-05 14:48:54
 * @LastEditors: zjc
-->
<template>
  <div class="arcsin-sidebar">
    <!-- <el-scrollbar style="height: 100%;"> -->
    <el-menu :collapse="isCollapse" :show-timeout="200" background-color="#fff" mode="vertical"
      :collapse-transition="false" :default-active="menuIndex" :default-openeds="menuName"
      :unique-opened="true" router>
      <template v-for="(item) in subMenuList">
        <el-submenu v-show="item.isHide == 0" v-if="item.children && item.children.length>0"
          :index="item.url" :key="item.url">
          <template slot="title">
            <svg-icon :icon-class="item.icon" />
            <span>{{item.name}}</span>
          </template>
          <!-- 递归组件 -->
          <nav-menu-item :menus="item.children" :collapse="isCollapse"></nav-menu-item>
        </el-submenu>
        <el-menu-item v-show="item.isHide == 0" v-else :index="item.url" :key="item.url">
          <svg-icon :icon-class="item.icon" />
          <span slot="title">{{item.name}}</span>
        </el-menu-item>
      </template>
    </el-menu>
    <!-- </el-scrollbar> -->
  </div>
</template>
<script>
import navMenuItem from './navMenuItem'
import { mapGetters } from 'vuex'
import { COMMON_API } from '@/utils/common'
export default {
  components: { navMenuItem },
  data () {
    return {
      menuName: [],
      validatenull: COMMON_API.validatenull,
    }
  },
  computed: {
    ...mapGetters(['isCollapse', 'activeIndex', 'menuList', 'subMenuList']),
    menuIndex () {
      if (COMMON_API.validatenull(this.subMenuList)) return
      const menuName = this.subMenuList.filter((item) => 'children' in item)
      for (let i of menuName) {
        if (i.url === this.$route.path) {
          // this.menuName = []
        }
      }
      return this.$route.path
    },
  },
  methods: {

  },
  mounted () {
    console.log(this.subMenuList)
  }
}
</script>
<style lang="scss" scoped>
.arcsin-sidebar {
  ::v-deep ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::v-deep .el-scrollbar__thumb {
    display: none;
  }
}
</style>