<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-08 09:30:53
 * @LastEditTime: 2022-07-05 14:51:02
 * @LastEditors: zjc
-->
<template>
  <div class="arcsin-logo">
    <transition name="fade">
      <span v-if="isCollapse" class="arcsin-logo__subtitle" key="0">
        <img :src="libraryInfo.libraryImg" width="40" height="40" alt=""
          style="border-radius: 100%" />
      </span>
    </transition>
    <transition-group name="fade">
      <template>
        <div v-if="!isCollapse" style="
            height: 63px;
            overflow: hidden;
            display: flex;
            align-items: center;
          " key="1">
          <img class="arcsin-logo__img" src="../../../assets/img/total-logo.png" width="40"
            height="40" alt="" style="border-radius: 100%" />
          <!-- <p class="sub-title line-2">{{libraryInfo.libraryName}}</p> -->
          <p class="sub-title line-2">捐赠平台运营管理系统</p>
        </div>
      </template>
    </transition-group>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data () {
    return {};
  },
  computed: {
    ...mapGetters(["isCollapse", "name", "libraryInfo"]),
  },
};
</script>
<style lang="scss">
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter-active {
  transition: opacity 1.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

