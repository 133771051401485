/*
 * @Author: 应用资源授权接口
 * @Date: 2022-05-24 17:05:23
 * @LastEditors: ym ym@geeboo.cn
 * @LastEditTime: 2022-05-27 14:11:20
 * @FilePath: \cloud-library-platform-ui\src\service\permissions\lessee.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const getTenantApplicatio = (param) => request('post', '/oms/tenant-application/page', param) //应用授权列表
const getApplication = (param) => request('get', '/oms/application/list', param) //应用列表
const getTenant = (param) => request('get', '/oms/tenant/getAll', param) //租户列表
const getAccredit = (param) => request('post', '/oms/tenant-application/grant', param, {
    requestType: 'JSON'
}) //授权
const getRenewalGrant = (param) => request('post', '/oms/tenant-application/renewalGrant', param, {
    requestType: 'JSON'
}) //续期
const getRenewalGrantEd = (param) => request('get', '/oms/tenant-application/grantResTree', param, {
    requestType: 'JSON'
}) //已授权的资源列表

const getRenewalEditGrant = (param) => request('post', '/oms/tenant-application/editGrant', param, {
    requestType: 'JSON'
}) //编辑授权列表



const getAccreditCancel = (param) => request('post', '/oms/tenant-application/grant/cancel', param) //取消授权

export const AUTHORIZATION_API = {
    getTenantApplicatio,
    getApplication,
    getTenant,
    getAccredit,
    getAccreditCancel,
    getRenewalGrant,
    getRenewalGrantEd,
    getRenewalEditGrant
}