  <!-- 搜索框 -->
<template>
  <div>
    <el-row class="display-flex">
      <el-col v-for="item in searchList" :key="item.prop" :lg="item.col || 6" :xl="item.col || 6"
        :md="12" :xs="24" :sm="24">
        <div v-if="item.type ==='input' || item.type ==='deviceInput'">
          <!-- input -->
          <el-form-item :label="item.label" :label-width="item.searchWidth">
            <el-input v-model="queryFilters[item.prop]" :placeholder="item.placeholder"
              @keyup.enter.native="searchChange" :type="item.inputType"
              :clearable="option.clearable || true">
              <template v-if="item.slot" slot="append">{{item.slotName}}</template>
            </el-input>
          </el-form-item>
        </div>
        <div v-if="item.type ==='select' && !item.slot">
          <!-- select -->
          <el-form-item :label="item.label" :label-width="item.searchWidth"
            @keyup.enter.native="searchChange">
            <el-select v-model="queryFilters[item.prop]" :placeholder="item.placeholder || '请选择'"
              @change="changeInput" :clearable="option.clearable || true">
              <el-option v-for="i in item.selectList" :key="i.value" :label="i.label"
                :value="i.value" @click.native="clickInput(i)"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="item.slot">
          <slot :name="item.prop"></slot>
        </div>
      </el-col>
      <el-col :lg="col || 6" :xl="col || 6" :md="12" :xs="24" :sm="24">
        <el-form-item label-width="10px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="searchChange">搜索
          </el-button>
          <el-button size="small" icon="el-icon-refresh" @click="reset">{{resetName}}</el-button>
          <span v-if="column!=0" @click="open" class="open-style">
            <span>{{setSearch?'展开':'收起'}}</span>
            <i :class="setSearch?'el-icon-arrow-down':'el-icon-arrow-up'"
              style="margin-left:6px"></i>
          </span>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { COMMON_API } from '@/utils/common'
export default {
  /***
   * @param {Object} option 查询配置
   *      clearable 是否显示清除icon
   *      column 配置
   *          -- col  栅格默认6 查询重置按钮的栅格
   *          -- prop 查询字段名
   *          -- label 查询名称
   *          -- searchWidth 查询名称宽度
   *          -- inputType 类型输入框原始类型 text number password 等等
   *          -- type 类型 input 输入框 select下拉选择
   *          -- slot 是否插槽
   *          -- placeholder 占位
   *          -- search 是否是查询条件
   *          -- selectList 下拉框列表
   * @param {Object} filters 查询字段
   * @param {Number} column 默认为0不需要展开收起  需要展开收起需要指定一列显示几个查询条件 显示3写3 加上查询重置按钮的栅格不得大于24需要自己控制好         
   */
  props: {
    resetName: {
      type: String,
      default: '重置'
    },
    option: {
      type: Object,
      default: () => { }
    },
    filters: {
      type: Object
    },
    col: {
      type: Number,
      default: 6
    },
    column: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    queryFilters: {
      deep: true,
      handler (val) {
        this.$emit('update:filters', Object.assign(val))
      }
    },
  },

  data () {
    return {
      queryFilters: {},// 表单
      searchList: [], // 过滤需要展示的查询
      setSearch: false,
    }
  },

  methods: {
    //展开收起
    open () {
      this.setSearch = !this.setSearch
      if (this.setSearch) {
        this.searchList = this.deepList.slice(0, this.column || this.deepList.length)
      } else {
        this.searchList = this.deepList
      }
    },
    searchChange () {
      this.$emit('searchChange')
    },
    changeInput (val) {
      this.$emit('select', val)
    },
    clickInput (val) {
      this.$emit('clickInput', val)
    },
    reset () {
      this.$emit('reset')
    },
    // 初始化表单
    initForm () {
      const queryFilters = {}
      for (const i of this.searchList) {
        if (i.type === 'checkbox' || i.type === 'cascader' || i.type === 'datetimerange') {
          queryFilters[i.prop] = []
        } else if (i.type === 'number') {
          queryFilters[i.prop] = 0
        } else {
          queryFilters[i.prop] = null
        }
      }
      this.queryFilters = queryFilters
    },
  },
  mounted () {
    this.deepList = COMMON_API.deepClone(this.option) //深拷贝
    this.searchList = this.deepList
    this.initForm();
  }
}
</script>
<style lang="scss" scoped>
.inline-block {
  display: inline-block;
}
/* .el-col:nth-child(5n) {
  clear: both;
} */
.open-style {
  margin-left: 10px;
  color: $--color-primary;
  position: relative;
  top: 2px;
  cursor: pointer;
  font-size: 14px;
}
</style>
