<!--
 * @Description: 重置密码
 * @Author: zjc
 * @Date: 2021-12-16 16:18:09
 * @LastEditTime: 2022-07-05 14:51:53
 * @LastEditors: zjc
-->
<template>
  <div>
    <el-dialog title="重置密码" width="600px" :close-on-click-modal="false" append-to-body top="10vh"
      :visible.sync="showPwd" @close="closeDialog">
      <el-form ref="submitForm" :model="formData" size="medium" label-width="90px"
        :rules="formRules">
        <el-form-item label="操作员编号" prop="oprId">
          <el-input v-model="formData.oprId" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="formData.username" disabled> </el-input>
        </el-form-item>
        <el-form-item label="新的密码" prop="newPassWord">
          <el-input v-model="formData.newPassWord" type="password" show-password
            placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="checkNewPassWord">
          <el-input v-model="formData.checkNewPassWord" type="password" show-password
            placeholder="请输入重复密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="primary" @click="save" :loading="loading">提交</el-button>
        <el-button size="medium" @click="closeDialog">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { OPERATOR_API } from "@/service/permissions/operator";
import { pwd } from "@/validate/el-validate";

export default {
  props: {
    operatorId: {
      type: Number,
    },
  },
  data () {
    const updatepwd = (rule, value, callback) => {
      if (value != this.formData.newPassWord) {
        callback(new Error("两次输入不一致，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      loading: false, //
      showPwd: false,
      formData: {
        oprId: null,
        username: null,
        newPassWord: null,
        checkNewPassWord: null,
      },
      formRules: {
        newPassWord: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          // { required: true, validator: pwd, trigger: 'blur' }
        ],
        checkNewPassWord: [
          { required: true, message: "请输入重复密码", trigger: "blur" },
          // { required: true, validator: pwd, trigger: 'blur' },
          { required: true, validator: updatepwd, trigger: "blur" },
        ],
      }, //表单校验
    };
  },
  created () { },
  methods: {
    //初始化
    initData (data) {
      this.showPwd = true;
      this.formData = {
        oprId: data.oprId,
        username: data.username,
      };
    },
    save () {
      this.$refs["submitForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const params = {
              oprId: this.formData.oprId,
              newPassword: this.formData.newPassWord
            };
            const data = await OPERATOR_API.resetPassword(params);
            this.$ok(data.msg);
            this.closeDialog();
            this.$emit("onLoad");
            this.loading = false;
          } catch (e) {
            this.loading = false;
          }
        }
      });
    },
    //关闭弹窗
    closeDialog () {
      this.showPwd = false;
      this.$refs.submitForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>