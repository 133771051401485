/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:47:40
 * @LastEditTime: 2022-05-27 14:25:20
 * @LastEditors: ym ym@geeboo.cn
 */
// 配置编译环境和线上环境之间的切换
import store from "@/store";
// let title = store.getters.libraryInfo?.libraryName || '厦门图书馆'
let title = '捐赠平台运营管理系统'
const env = process.env
export {
  title,
  env,
}
