/*
 * @Description: 部门管理
 * @Author: zjc
 * @Date: 2021-12-27 10:56:30
 * @LastEditTime: 2022-05-24 15:20:29
 * @LastEditors: ym ym@geeboo.cn
 */
import request from '@/utils/request'
const getTree = (param) => request('get', '/oms/dept/tree', param) //获取部门列表树
const getDeptDetail = (param) => request('get', `/oms/dept/${param}`) //获取部门详情
const addDept = (param) => request('post', '/oms/dept/save', param, { requestType: 'JSON' }) //新增部门
const updateDept = (param) => request('post', '/oms/dept/update', param, { requestType: 'JSON' }) //修改部门
const delDept = (param) => request('post', `/oms/dept/delete?deptId=${param.deptId}`, param, { requestType: 'JSON' })//删除部门
export const DEPT_API = {
  getTree,
  getDeptDetail,
  addDept,
  updateDept,
  delDept
}