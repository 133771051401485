<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;">
        <v-tabs :activeName="activeName" style="width:100%" :tabList="tabList" @tabValue="tabValue">
        </v-tabs>
        <div style="width:100%">
          <div v-show="activeName == '-1'">
            <div style="width:100%">
              <div class="tab-one" style="width:100%">
                <div class="tab-title">
                  <span>基础信息</span>
                </div>
                <div class="tab-content2">
                  <div class="box_tab_one">
                    <div class="content">
                      <div class="item">
                        <span class="l1">捐赠对象名称：</span>
                        <span class="r1">{{ruleForm.orgName}}</span>
                      </div>
                      <div class="item">
                        <span class="l1">状态：</span>
                        <span class="r1" v-if="ruleForm.orgStatus == 1">审核中</span>
                        <span class="r1" v-else-if="ruleForm.orgStatus == 2">正常</span>
                        <span class="r1" v-else-if="ruleForm.orgStatus == 3">禁用</span>
                        <span class="r1" v-else-if="ruleForm.orgStatus == 4">审核不通过</span>
                      </div>
                      <!-- <div class="item3">
                        <span class="l1">捐赠数据链接：</span>
                        <span
                          class="r1">http://dph5-test.zgepub.cn/pages/index/rankMain?pressOrgNo={{pressOrgNo}}</span>
                      </div> -->
                      <div class="item3" style="width:100%">
                        <span class="l1">捐赠模板：</span>
                        <!-- <span class="l2" v-for="(item,index) in wordList"
                      :key="index">{{item.configName}}
                      <span  v-show="index+1 < wordList.length">、</span>
                    </span> -->
                        <span class="l2" v-show="configName">{{configName}}
                        </span>
                        <span class="l3" @click="jumpToEdit(wordList)"
                          style="cursor:pointer">修改</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-one" style="margin:30px 0 30px 0">
              <div class="tab-title">
                <div class="left_title">捐赠数据</div>
                <div class="right_title">
                  <el-button type="primary" icon="el-icon-plus" size="small"
                    @click="addOrUpdate('')">
                    设置捐赠排行
                  </el-button>
                </div>
              </div>
              <div class="tab-content2">
                <div class="box_tab_one">
                  <div class="content">
                    <!-- <div class="item2">
                      <span class="l1">捐赠总额</span>
                      <span class="r1">{{ruleForm2.orderActualAmount }}元</span>
                    </div> -->
                    <!-- <div class="item2">
                      <span class="l1">捐赠价值：</span>
                      <span class="r1">{{ruleForm2.orderAmount}}元</span>
                    </div> -->
                    <div class="item2">
                      <span class="l1">捐赠订单数：</span>
                      <span class="r1">{{ruleForm2.orderCnt}}笔</span>
                    </div>
                    <div class="item2">
                      <span class="l1">捐赠图书种类：</span>
                      <span class="r1">{{ruleForm2.bookCnt}}种</span>
                    </div>
                    <div class="item2">
                      <span class="l1">捐赠图书数：</span>
                      <span class="r1">{{ruleForm2.copyCnt}}册</span>
                    </div>
                    <div class="item3">
                      <span class="l1">捐赠数据链接：</span>
                      <span
                        class="r1">{{locationHost}}/pages/index/rankMain?pressOrgNo={{pressOrgNo}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeName == '1'" style="width:100%">
            <div class="searchForm">
              <!-- 搜索条件  -->
              <el-form :model="filters" label-width="80px" size="small" class="formData">
                <el-collapse-transition>
                  <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
                    @reset="reset" ref="filters">
                    <template #searchType>
                      <el-form-item label="" label-width="0">
                        <div class="box_choose">
                          <el-select class="typeSelect" v-model="searchType" clearable
                            placeholder="请选择">
                            <el-option v-for="item in searchTypeList" :key="item.value"
                              :label="item.label" :value="item.value">
                            </el-option>
                          </el-select>
                          <div v-if="searchType == 3 || searchType == 4">
                            <!-- 捐赠机构 4chooseResult1 -->
                            <!-- <el-select v-if="searchType == 3" v-model="chooseResult1" clearable
                              placeholder="请选择">
                              <el-option v-for="item in objectList" :key="item.orgId"
                                :label="item.orgName" :value="item.orgId">
                              </el-option>
                            </el-select> -->
                            <!-- 捐赠对象 3chooseResult2 -->
                            <!-- <el-select v-if="searchType == 4" v-model="chooseResult2" clearable
                              placeholder="请选择">
                              <el-option v-for="item in channelList" :key="item.orgId"
                                :label="item.orgName" :value="item.orgId">
                              </el-option>
                            </el-select> -->
                          </div>
                          <!-- 手机号 1inputResult2 -->
                          <el-input v-else-if="searchType == 1" placeholder="请输入"
                            v-model="inputResult2" class="input-with-select">
                          </el-input>
                          <!-- 捐赠机构 3inputResult-->
                          <el-input v-else placeholder="请输入" v-model="inputResult"
                            class="input-with-select">
                          </el-input>

                        </div>
                      </el-form-item>
                    </template>
                    <template #orderStatus>
                      <el-form-item label="来源" label-width="60px">
                        <el-select v-model="filters.donationType" clearable placeholder="请选择">
                          <el-option v-for="item in authOptions" :key="item.value"
                            :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                    <!-- <template #createTime>
                      <el-form-item label="捐赠时间" label-width="80px">
                        <el-date-picker
                          v-model="createTime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
                        </el-date-picker>
                      </el-form-item>
                    </template> -->
                    
                  </form-item>
                </el-collapse-transition>
              </el-form>
              <form-menu :tableOption="tableOption" @searchChange="search" @refresh="reset">
              </form-menu>
            </div>
             <!-- <el-button size="small" class="filter-item" style="margin-top:8px;"
              @click="exportEvent(1)" :loading="loading"><span>导出数据</span>
            </el-button> -->
            <v-table ref="table" :tableData="tableList2" :tableOption="tableOption2"
              @handleSelectionChange="selectionChange" @sortChange="sortChange">
              <template slot="orderStatus" slot-scope="scope">
                <span v-if="scope.row.orderStatus == 1">待支付</span>
                <span v-else-if="scope.row.orderStatus == 2">处理中</span>
                <span v-else-if="scope.row.orderStatus == 3">已完成</span>
                <span v-else-if="scope.row.orderStatus == 4">处理中</span>
                <span v-else-if="scope.row.orderStatus == 5">已取消</span>
              </template>
              <template slot="orgName" slot-scope="scope">
                <span v-if="scope.row.orgId == '0'">通用机构</span>
                <span v-else>{{scope.row.orgName}}</span>
              </template>
              <template slot="donationType" slot-scope="scope">
                <span v-if="scope.row.donationType == 1">商城捐赠</span>
                <span v-else>已有书捐赠</span>
              </template>
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="jumpToSearch(scope.row)">查看</el-button>
              </template>
            </v-table>
            <!-- 分页 -->
            <pagination :pageData="pageData2" :total="pageTotal" @hanleSize="changePage"
              @hanlePage="handleSizeChange"></pagination>
          </div>

          <!-- <div v-show="activeName == '2'"> -->
          <div v-if="false">
            <!-- 搜索条件 -->
            <el-form :model="filters1" size="small" label-width="0px" class="formData">
              <el-collapse-transition>
                <form-item :option="searchOption1" :filters.sync="filters1" @searchChange="search1"
                  @reset="reset1" ref="filters1">
                  <template #searchType>
                      <el-form-item label="" label-width="0">
                          <el-date-picker
                          @change="changeDate"
                          value-format="yyyy-MM"
                          style="width:100%;"
                           v-model="timeData"
                           type="month"
                           placeholder="选择月">
                          </el-date-picker>
                      </el-form-item>
                  </template>
                </form-item>
              </el-collapse-transition>
            </el-form>
            <el-button size="small" class="filter-item"
              @click="exportEvent(2)" :loading="loading1"><span>导出数据</span>
            </el-button>
            <v-table ref="table" :tableData="tableList3" :tableOption="tableOption3">
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="donateDetail(scope.row)">捐赠明细</el-button>
              </template>
            </v-table>
             <!-- 分页 -->
            <pagination :pageData="pageData3" :total="pageTotal1" @hanleSize="changePage1"
              @hanlePage="handleSizeChange1"></pagination>
          </div>
        </div>
      </div>
    </div>
  </basic-container>
</template>
<script>
import vTabs from '@/components/CRUD/v-tabs'
import { DONATION_API } from "@/service/permissions/donation";
// import { Order_API } from "@/service/permissions/order";
import Cookies from "js-cookie";
export default {
  components: { vTabs },
  data () {
    return {
      loading1: false,
      loading: false,
      tableList3: [{date: '2024-03'}],
      timeData: '',
      activeName: '-1',
      tabList: [
        { label: '基础信息', name: '-1' },
        { label: '捐赠记录', name: '1' },
        // { label: '捐赠统计', name: '2' }
      ],
      value: true,
      options: [],
      dialogVisible: false,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      searchText: '',
      tableList: [],
      tableList2: [],
      ruleForm: {},
      addForm: {},
      createTime: [],
      purchaseOrderId: null,
      actualAmount: null,
      status: 0,//0新增|1查看
      goodsCnt: 0,
      sortCnt: 0,
      totalAmount: 0,
      selectList: [],
      ruleForm2: {},
      wordList: {
        configName: ''
      },
      configName: '',
      //搜索
      searchOption: [
        {
          prop: 'searchType',
          label: '创建时间',
          col: 7,
          type: 'roleName',
          slot: true,
          placeholder: '请选择',
        },
        {
          prop: "orderStatus",
          label: "应用名称",
          col: 4,
          slot: true,
          type: "select",
          placeholder: "请输入",

        },
        //  {
        //   prop: 'createTime',
        //   label: '捐赠时间',
        //   slot: true,
        //   type: "select",
        //   placeholder: '请选择',
        //   col: 7,
        // }

      ],
      searchOption1: [
        {
          prop: 'searchType',
          label: '创建时间',
          type: 'roleName',
          slot: true,
          type: "select",
          placeholder: '请选择',
          col: 5,
        }
      ],
      searchTypeList: [
        { 'label': '捐赠号', value: 2 },
        { 'label': '捐赠人手机号', value: 1 },
        // { 'label': '捐赠对象', value: 3 },
        // { 'label': '捐赠机构', value: 4 },
      ],
      authOptions: [
        { label: '商城捐赠', value: 1 },
        { label: '已有书捐赠', value: 2 },
      ],
      pageData: { size: 10, current: 1 },
      pageData2: { size: 10, current: 1 },
      pageData3: { size: 10, current: 1 },
      pageTotal: 0,
      pageTotal1: 0,
      pageTotal2: 0,
      filters: {
        searchType: 1
      },
      filters1: {

      },
      tableOption2: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'recordId', label: '捐赠号', tableProp: true, },
          // { prop: 'pressOrgName', label: '捐赠对象', tableProp: true },
          // { prop: 'orgName', label: '捐赠机构', slot: true, tableProp: true },
          { prop: 'mobile', label: '捐赠人手机号', tableProp: true, },
          { prop: 'copyCnt', label: '复本数（册）', tableProp: true },
          // { prop: 'totalAmount', label: '捐赠价值（元）', tableProp: true, },
          { prop: 'donationType', label: '来源', slot: true, tableProp: true, },
          // { prop: 'orderStatus', label: '状态', slot: true, tableProp: true, },
          { prop: 'modifyTime', label: '捐赠时间', tableProp: true, },
        ],
      },
       tableOption3: {
        align: "center",
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'date', label: '月份', tableProp: true, },
          { prop: '', label: '捐赠人数', tableProp: true, },
          { prop: '', label: '捐赠人次', tableProp: true },
          { prop: '', label: '捐赠图书种类', tableProp: true, },
          { prop: '', label: '捐赠图书数（册）', tableProp: true, },
        ],
      },
      searchType: 1,
      chooseResult1: '',
      chooseResult2: '',
      inputResult: '',
      inputResult2: '',
      locationHost: 'https://dph5.zgepub.cn',
    }
  },
  created () {
    // console.log(window.location.host)
    // if (window.location.host == 'localhost:8080' || window.location.host == 'http://dpoms-test.zgepub.cn') {
    //   this.locationHost = 'http://dph5-test.zgepub.cn'
    // }
    // else if (['http://dpoms.zgepub.cn', 'https://dpoms.zgepub.cn'].includes(window.location.host)) {
    //   this.locationHost = 'http://dph5.zgepub.cn'
    // }
    console.log(this.$route.query)
    if (this.$route.query.pressOrgNo) {
      this.pressOrgNo = this.$route.query.pressOrgNo
      localStorage.setItem("pressOrgNo", this.$route.query.pressOrgNo)
    }
    else {
      if (localStorage.getItem("pressOrgNo")) {
        this.pressOrgNo = localStorage.getItem("pressOrgNo")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.getList();
    this.getData();
    this.getUseridentity();
    this.getDonationList();
  },
  mounted () {
  },
  methods: {
    exportEvent() {
        // this.loading = true
    },
    donateDetail(row) {
      let timeObj =  this.getTime(row.date)
      Cookies.set("timeObj", JSON.stringify(timeObj));
      this.$router.push({
        path: "/donationRecord/index"
      });

    },
    changeDate(val) {
      console.log(val, 'val')
    },

    getTime(val) {
      if(!val) return null
      let startTime = val + '-01 00:00:00'
      let endTime = val + '-'+ new Date(val.slice(0,4), val.slice(5,7), 0).getDate()+' 23:23:59'
      return {
        startTime, endTime
      }
    },
    //tab选项卡
    tabValue (val) {
      console.log(val)
      this.activeName = val.name
      if (val.name == -1) {
        // 基础信息
        this.getList()
      } else if (val.name == 1) {
        // 订单记录
        this.getData();
      }
    },
    async getData () {
      console.log(this.filters)
      const params = {}
      params.pressOrgNo = this.pressOrgNo
      params.orgId = this.orgId
      // if(this.createTime.length) {
      //    params.startTime = this.createTime[0]
      //    params.endTime = this.createTime[1]
      // } else {
      //    params.startTime = ''
      //    params.endTime = ''
      // }
      await DONATION_API.getReCoard({
        ...this.pageData2,
        ...this.filters,
        ...params,
      }).then((res) => {
        // console.log(res)
        this.tableList2 = res.data.records
        this.pageTotal = res.data.total;
        // console.log(this.list)
      });
    },
    // 跳转
    jumpToSearch (row) {
      console.log(row)
      this.$router.push({
        path: "../../../donationRecord/detail",
        query: { recordId: row.recordId }
      });
    },
    // 跳转到修改
    jumpToEdit () {
      this.$router.push({
        path: "./editTemplate",
        query: { pressOrgNo: this.pressOrgNo }
        // query: { themeId: row.themeId }
      });
    },
    // 主题详情
    async getList () {
      try {
        let params = {}
        params.pressOrgNo = this.pressOrgNo
        await DONATION_API.getObjectDetail(params).then(res => {
          console.log(res)
          console.log(res.data)
          this.ruleForm = res.data;
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 主题详情
    async getUseridentity () {
      try {
        let params = {}
        params.pressOrgNo = this.pressOrgNo
        await DONATION_API.getUseridentity(params).then(res => {
          console.log(res.data)
          console.log(res.data.items)
          this.wordList = res.data
          this.configName = res.data.configName
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async getDonationList () {
      try {
        let params = {}
        params.pressOrgNo = this.pressOrgNo
        await DONATION_API.getObjectTotal(params).then(res => {
          console.log(res.data)
          this.ruleForm2 = res.data;
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },

    changePage (val) {
      this.pageData2.current = val;
      this.getData();
    },
    changePage1 (val) {
     
    },
    handleSizeChange1 (val) {

    },
    handleSizeChange (val) {
      this.pageData2.current = 1;
      this.pageData2.size = val;
      this.getData();
    },
    //启用
    openPermissions (item) {
      console.log(item)
      let msg = '';
      let itemStatus = 1
      if (item.packageStatus == 1) {
        msg = '停用'
        itemStatus = 2
      } else {
        msg = '启用'
      }
      let params = {}
      params.packageId = item.packageId,
        params.packageStatus = itemStatus
      this.$confirm(`是否` + msg + `该捐赠包？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DONATION_API.changePackage(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("更改成功");
            // this.$ok("已取消");
            this.getDonationList();
          }
        });
      })
        .catch(() => {
          //
        });
    },
    // 新增捐赠渠道
    addOrUpdate () {
      this.$router.push({
        path: "./detail",
        query: { pressOrgNo: this.pressOrgNo }
      });
      // row = this.ruleForm
      // this.title = "修改捐赠包主题信息";
      // this.dialogVisible = true;
      // if (!this.$validatenull(row)) {
      //   const { themeName, themeContent, themeImg } = row;
      //   this.addForm = { themeName, themeContent, themeImg };
      // }
    },
    jumptoPage (url) {
      window.location.href = url
    },
    closeDialog () {
      this.tableList = []
    },
    search () {
      this.pageData2.current = 1;
      this.filters.mobile = ''
      this.filters.recordId = ''
      this.filters.pressOrgNo = ''
      // this.filters.orgId = ''
      if (this.searchType == 1) {
        this.filters.mobile = this.inputResult2
      } else if (this.searchType == 2) {
        this.filters.recordId = this.inputResult
      } else if (this.searchType == 3) {
        this.filters.pressOrgNo = this.chooseResult1
      } else if (this.searchType == 4) {
        // this.filters.orgId = this.chooseResult2
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    reset () {
      this.pageData2.current = 1;
      this.pageData2.size = 10;
      this.chooseResult1 = ''
      this.chooseResult2 = ''
      this.inputResult = ''
      this.inputResult2 = ''
      this.filters = {}
      // this.createTime = []
      this.$nextTick(() => {
        this.getData();
      });
    },
  }
}
</script>
<style lang="scss">
.selectArea {
  .el-checkbox {
    width: 95%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
  }
}
</style>
<style scoped lang="scss">
.box_item {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  justify-content: flex-end;
  .redTitle {
    width: 16%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 10px;
    position: relative;
  }
  .redTitle::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: red;
  }
}
.redTit3 {
  font-size: 14px;
  color: #606266;
  display: inline-block;
  width: 130px;
  text-align: right;
  padding-right: 13px;
  font-weight: 500 !important;
}
.redTit3::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 99%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        background-color: #ffffff;

        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            // min-width: 20%;
            max-width: 100%;
            margin: 0 20px 20px 0;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            .l1 {
              // min-width: 96px;
              color: #999999;
              font-size: 13px;
            }

            .r1 {
              // max-width: 150px;
              display: inline-block;
              word-break: break-all;
              font-size: 13px;
              color: #333333;
            }
          }
          .item3 {
            // min-width: 20%;
            max-width: 100%;
            margin: 0 0px 20px 0;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            .l1 {
              // min-width: 96px;
              color: #999999;
              font-size: 13px;
            }
            .l2 {
              // min-width: 96px;
              color: #333333;
              font-size: 12px;
              margin: 0 0px 0 0;
            }
            .l3 {
              font-size: 12px;
              color: #3399ff;
              margin: 0 0 0 5px;
            }
            .r1 {
              // max-width: 150px;
              display: inline-block;
              word-break: break-all;
              font-size: 13px;
              color: #333333;
            }
          }
          .item2 {
            width: 20%;
            margin: 0 0 20px 0;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .l1 {
              width: 100%;
              color: #666666;
              font-size: 13px;
            }
            .r1 {
              width: 100%;
              // font-size: 13px;
              color: #666666;
              font-size: 18px;
              font-weight: 300;
              margin: 10px 0 0 0;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}

.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 112px;
    text-align: right;
    padding-right: 12px;
  }
  .redTit {
    font-size: 14px;
    color: #606266;
    display: inline-block;
    width: 130px;
    text-align: right;
    padding-right: 13px;
    font-weight: 500 !important;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 15px 0;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>

<style lang="scss" scoped>
.box_choose {
  display: flex;
  flex-wrap: nowrap;
  .typeSelect {
    border-right: none;
  }
}
</style>
