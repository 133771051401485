
import request from '@/utils/request'
// 渠道信息 
const getList = (params) => request('get', '/oms/open-app/page', params) //获取渠道列表
const addChannel = (params) => request('post', '/oms/open-app', params, { requestType: 'JSON' }) //新增渠道
const updateChannel = (params) => request('put', '/oms/open-app', params, { requestType: 'JSON' }) //修改渠道
// 机构信息
//获取机构列表
const getChannelList = (params) => request('post', '/user/org/page', params) //获取机构列表
// 捐赠机构信息
const getChannelListDetail = (params) => request('post', '/user/org/info', params) //获取捐赠机构信息
//捐赠对象删除
const delectChannel = (params) => request('post', '/user/donee/delete', params) //删除捐赠对象
// 捐赠对象数据列表
// /user/donee/press/orgList
// const getOrgList = (params) => request('post', '/user/donee/press/orgList', params) //获取捐赠对象列表
const getOrgList = (params) => request('post', '/user/donee/orgList', params) //获取捐赠对象列表
// 新增捐赠机构
const addNewOrg = (params) => request('post', '/user/org/add', params, { requestType: 'JSON' }) //新增捐赠机构
// 新增捐赠对象
const addObject = (params) => request('post', '/user/donee/add', params, { requestType: 'JSON' }) //新增捐赠对象
// 删除捐赠对象
const delectObject = (params) => request('post', '/user/donee/delete', params) //新增捐赠对象
// 机构下的用户列表
// 
const getUserList = (params) => request('post', '/user/org-user/oms/page', params) //获取机构下的用户列表

const inscriptionAdd = (param) => request('post', '/user/user-inscription/save', param, { requestType: 'JSON' })
export const ROLE_API = {
  getList,
  addChannel,
  updateChannel,
  getChannelList,
  getChannelListDetail,
  delectChannel,
  getOrgList,
  addNewOrg,
  addObject,
  delectObject,
  getUserList, inscriptionAdd
}