<!--  新增需求 -->
<template>
  <basic-container>
    <div>
      <div
        class="container"
        style="
          text-align: left;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        "
      >
        <v-tabs
          :activeName="activeName"
          style="width: 100%"
          :tabList="tabList"
          @tabValue="tabValue"
        >
        </v-tabs>
        <div v-show="activeName == '-1'">
          <div style="width: 100%">
            <div class="tab-one" style="width: 100%">
              <p class="tab-title">捐赠机构信息</p>
              <div class="tab-content2" style="background-color: #ffffff">
                <div class="box_tab_one">
                  <div class="content">
                    <div class="item">
                      <span class="l1">名称：</span>
                      <span class="r1">{{ formMsg.orgName }}</span>
                    </div>
                    <div class="item2">
                      <span class="l1">捐赠链接：</span>
                      <span class="r1">{{ formMsg.orgDonationLink }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-one" style="margin: 30px 0 30px 0; width: 100%">
            <div class="tab-title">
              <div class="left_title">捐赠对象</div>
              <div class="right_title">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="addOrUpdate('')"
                >
                  新增捐赠对象
                </el-button>
              </div>
            </div>

            <div
              style="
                width: 100%;
                margin: 0 auto 20px;
                background-color: #ffffff;
              "
            >
              <v-table
                style="
                  width: 100%;
                  margin: 0 auto 20px;
                  background-color: #ffffff;
                "
                class="itemTble"
                ref="table"
                :tableData="tableList"
                :tableOption="tableOption"
              >
                <template slot="book" slot-scope="scope">
                  <div
                    style="
                      width: 100%;
                      text-align: left;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <!-- <img :src="scope.row.doneeLogo ? scope.row.doneeLogo : defaultBook" alt=""
                    style="width: 56px;height: 80px;"> -->
                    <div>
                      {{ scope.row.doneeName }}
                    </div>
                  </div>
                </template>
                <template slot="sealImg" slot-scope="scope">
                  <div v-if="scope.row.sealImg" class="img-box">
                    <div class="operat">
                      <i class="el-icon-zoom-in" @click="handlePictureCardPreview(scope.row)"></i>
                      <i class="el-icon-delete" @click="del(scope.row)"></i>
                    </div>
                    <img :src="scope.row.sealImg" class="avatar" />
                  </div>
                  <div @click="uploadBtn(scope.row)" v-else>
                    <el-upload
                      class="avatar-uploader"
                      action="/api/file/oss/upload"
                      accept=".jpg,.jpeg,.png"
                      :show-file-list="false"
                      :headers="headers"
                      name="file"
                      :on-success="handleNomalSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <i class="el-icon-plus avatar-uploader-icon" />
                    </el-upload>
                  </div>
                </template>
                <template slot="status" slot-scope="scope">
                  <div>
                    <p v-if="scope.row.status == 2">可捐赠</p>
                    <p v-else>不可捐赠</p>
                  </div>
                </template>
                <template slot="donationMessageState" slot-scope="scope">
                  <el-switch
                    active-text="开启"
                    @change="change(scope.row)"
                    :active-value="1"
                    :inactive-value="0"
                    inactive-text="关闭"
                    v-model="scope.row.donationMessageState"
                  ></el-switch>
                </template>
                <template slot="operation" slot-scope="scope">
                  <!-- <el-button type="text" size="small"
                    @click="check(scope.row)">查看</el-button> -->
                  <el-button
                    type="text"
                    size="small"
                    style="color: #d9001b"
                    @click="delectObject(scope.row)"
                    >删除</el-button
                  >
                </template>
              </v-table>
            </div>
          </div>
        </div>
        <div v-show="activeName == '1'" style="width: 100%">
          <div class="searchForm">
            <!-- 搜索条件  -->
            <el-form
              :model="filters"
              label-width="80px"
              size="small"
              class="formData"
            >
              <el-collapse-transition>
                <form-item
                  :option="searchOption"
                  :filters.sync="filters"
                  @searchChange="search"
                  @reset="reset"
                  ref="filters"
                >
                  <template #searchType>
                    <el-form-item label="" label-width="0">
                      <div class="box_choose">
                        <el-select
                          class="typeSelect"
                          v-model="searchType"
                          clearable
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in searchTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>

                        <div v-if="searchType == 3 || searchType == 4">
                          <!-- 捐赠机构 4chooseResult1 -->
                          <!-- <el-select v-if="searchType == 3" v-model="chooseResult1" clearable
                            placeholder="请选择">
                            <el-option v-for="item in objectList" :key="item.orgId"
                              :label="item.orgName" :value="item.orgId">
                            </el-option>
                          </el-select> -->
                          <el-cascader
                            v-if="searchType == 3"
                            popper-class="selectArea"
                            filterable
                            :options="objectList"
                            v-model="chooseResult1"
                            :props="{ multiple: false, checkStrictly: false }"
                            clearable
                          ></el-cascader>
                          <!-- 捐赠对象 3chooseResult2 -->
                          <el-select
                            v-if="searchType == 4"
                            v-model="chooseResult2"
                            clearable
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in channelList"
                              :key="item.orgId"
                              :label="item.orgName"
                              :value="item.orgId"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <!-- 手机号 1inputResult2 -->
                        <el-input
                          v-else-if="searchType == 1"
                          placeholder="请输入"
                          v-model="inputResult2"
                          class="input-with-select"
                        >
                        </el-input>
                        <!-- 捐赠机构 3inputResult-->
                        <el-input
                          v-else
                          placeholder="请输入"
                          v-model="inputResult"
                          class="input-with-select"
                        >
                        </el-input>
                      </div>
                    </el-form-item>
                  </template>
                  <!-- <template>
              <el-form-item label="" label-width="0">
                <el-select style="width:130px" v-model="searchType" slot="prepend"
                  placeholder="请选择">
                  <el-option style="width:130px" v-for="item in searchTypeList" :key="item.value"
                    :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template> -->
                  <template #orderStatus>
                    <el-form-item label="来源" label-width="100px">
                      <el-select
                        v-model="filters.donationType"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in authOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                  <!-- <template #createTime>
                      <el-form-item label="捐赠时间" label-width="80px">
                        <el-date-picker
                          v-model="createTime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
                        </el-date-picker>
                      </el-form-item>
                    </template> -->
                </form-item>
              </el-collapse-transition>
            </el-form>
            <form-menu :tableOption="tableOption" @refresh="reset"> </form-menu>
          </div>
          <!-- <el-button size="small" class="filter-item" style="margin-top:8px;"
              @click="exportEvent(1)" :loading="loading1"><span>导出数据</span>
          </el-button> -->
          <v-table
            ref="table"
            :tableData="tableList2"
            :tableOption="tableOption2"
            @handleSelectionChange="selectionChange"
            @sortChange="sortChange"
          >
            <template slot="orderStatus" slot-scope="scope">
              <span v-if="scope.row.orderStatus == 1">待支付</span>
              <span v-else-if="scope.row.orderStatus == 2">处理中</span>
              <span v-else-if="scope.row.orderStatus == 3">已完成</span>
              <span v-else-if="scope.row.orderStatus == 4">处理中</span>
              <span v-else-if="scope.row.orderStatus == 5">已取消</span>
            </template>
            <template slot="donationType" slot-scope="scope">
              <span v-if="scope.row.donationType == 1">商城捐赠</span>
              <span v-else-if="scope.row.donationType == 2">已有书捐赠</span>
            </template>

            <template slot="operation" slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="jumpToSearch(scope.row)"
                >查看</el-button
              >
              <!-- <el-button v-show="scope.row.orderStatus !== 5" type="text" size="small"
              @click="cancel(scope.row)">取消</el-button> -->
            </template>
          </v-table>
          <!-- 分页 -->
          <pagination
            :pageData="pageData"
            :total="pageTotal"
            @hanleSize="changePage"
            @hanlePage="handleSizeChange"
          ></pagination>
        </div>
        <div v-show="activeName == '0'" style="width: 100%">
          <div class="searchForm">
            <!-- 搜索条件 -->
            <el-form
              :model="filters2"
              label-width="0"
              size="small"
              class="formData"
            >
              <el-collapse-transition>
                <form-item
                  :option="searchOption2"
                  :filters.sync="filters2"
                  @searchChange="search2"
                  @reset="reset2"
                  ref="filters"
                >
                  <template #roleName>
                    <el-form-item label="" label-width="0">
                      <el-input clearable placeholder="请输入内容" v-model="filters2.search"
                        class="input-with-select">
                        <el-select style="width:100px" v-model="filters2.type" slot="prepend"
                          placeholder="请选择">
                          <el-option style="width:100px" v-for="item in searchUserType"
                            :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-input>
                    </el-form-item>
                  </template>
                </form-item>
              </el-collapse-transition>
            </el-form>
            <form-menu :tableOption="tableOption3" @refresh="reset2">
            </form-menu>
          </div>
          <v-table
            ref="table"
            :tableData="tableList3"
            :tableOption="tableOption3"
            @sortChange="sortChange"
          >
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="searchUser(scope.row)"
                >查看</el-button
              >
            </template>
          </v-table>
          <!-- 分页 -->
          <pagination
            :pageData="pageData2"
            :total="pageTotal2"
            @hanleSize="changePage2"
            @hanlePage="handleSizeChange2"
          ></pagination>
        </div>
      </div>

      <!-- 新增、编辑渠道 -->
      <modal-item
        ref="modal"
        v-if="dialogData.showDialog"
        :dialogData="dialogData"
        :formData="form"
        :option="formOption"
        @row-save="rowSave"
        @row-update="rowUpdate"
      ></modal-item>
      <!-- 新增租户 -->
      <el-dialog
        @closed="cancelDialog"
        :title="title"
        :visible.sync="dialogVisible"
        width="400px"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0"
          class="demo-ruleForm"
        >
          <el-form-item label="" prop="chooseList">
            <div class="box_item">
              <p class="redTitle">捐赠对象</p>
              <el-cascader
                ref="ownArea"
                popper-class="selectArea"
                style="width: 70%"
                :options="options"
                v-model="addForm.chooseList"
                :props="{ multiple: true, checkStrictly: true }"
                clearable
              ></el-cascader>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog()">取 消</el-button>
          <el-button type="primary" @click="rowSave()" :loading="loading"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <el-dialog title="证书章" :visible.sync="dialogVisible1" append-to-body width="500px">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </basic-container>
</template>
<script>
import vTabs from "@/components/CRUD/v-tabs";
import { ROLE_API } from "@/service/permissions/channel";
// import { Order_API } from "@/service/permissions/order";
import { DONATION_API } from "@/service/permissions/donation";
import { getStore } from "@/utils/store";

export default {
  components: { vTabs },
  data() {
    return {
      fileList: [],
      dialogVisible1: false,
      dialogImageUrl: "",
      doneeId: "",
      headers: {
        Authorization: "Bearer " + getStore({ name: "userToken" }),
      },
      loading1: false,
      chooseResult1: "",
      chooseResult2: "",
      inputResult: "",
      inputResult2: "",
      options: [],
      addForm: {},
      formMsg: {},
      activeName: '-1',
      searchUserType: [
        { 'label': 'ID', value: 0 },
        { 'label': '手机号', value: 1 },
      ],
      tabList: [
        { label: "基础信息", name: "-1" },
        { label: "捐赠记录", name: "1" },
        { label: "用户列表", name: "0" },
      ],
      searchType: 1,
      filters: {
        searchType: 1,
      },
      searchTypeList: [
        { label: "捐赠人手机号", value: 1 },
        { label: "捐赠号", value: 2 },
        { label: "捐赠对象", value: 3 },
        // { 'label': '捐赠机构', value: 4 },
      ],

      authOptions: [
        { label: "商城捐赠", value: 1 },
        { label: "已有书捐赠", value: 2 },
      ],
      filters2: {},
      pageData: { size: 10, current: 1 },
      pageData2: { size: 10, current: 1 },
      pageTotal: 0,
      pageTotal2: 0,
      optionsType: [
        { label: "公共图书馆", value: 1 },
        { label: "学校图书馆", value: 2 },
        { label: "物业图书馆", value: 3 },
        { label: "政协图书馆", value: 4 },
        { label: "出版发行图书馆", value: 5 },
        { label: "党建图书馆", value: 6 },
      ],
      dialogVisible: false,
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      // 新增、修改 配置
      formOption: {
        dialogWdith: "600px",
        labelWidth: "120px",
        clearable: true,
        size: "medium",
        column: [
          {
            prop: "roleCode",
            label: "捐赠机构名称",
            type: "input",
            placeholder: "请输入",
            rules: [{ required: true, message: "请输入", trigger: "blur" }],
          },
          {
            prop: "roleName",
            label: "捐赠对象",
            type: "select",
            placeholder: "请输入",
            // rules: [
            //   { required: true, message: "请输入渠道名称", trigger: "blur" },
            // ],
          },
        ],
      },
      searchText: "",
      tableList: [],
      tableList2: [],
      tableList3: [],
      //表格配置
      tableOption: {
        selection: false,
        align: "center",
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 160,
          fixed: "right",
          align: "center",
        },
        column: [
          { prop: "book", label: "捐赠对象", slot: true, tableProp: true },
          { prop: "sealImg", label: "证书章（尺寸200*200）", slot: true, tableProp: true },
          { prop: "status", label: "捐赠状态", slot: true, tableProp: true },
          {
            prop: "donationMessageState",
            label: "捐赠寄语",
            slot: true,
            tableProp: true,
          },
        ],
      },
      tableOption2: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: "recordId", label: "捐赠号", tableProp: true },
          { prop: "pressOrgName", label: "捐赠对象", tableProp: true },
          // { prop: 'orgName', label: '捐赠机构', slot: true, tableProp: true },
          { prop: "mobile", label: "捐赠人手机号", tableProp: true },
          { prop: "copyCnt", label: "复本数（册）", tableProp: true },
          // { prop: 'totalAmount', label: '捐赠价值（元）', tableProp: true, },
          { prop: "donationType", label: "来源", slot: true, tableProp: true },
          // { prop: 'orderStatus', label: '状态', slot: true, tableProp: true, },
          { prop: "createTime", label: "捐赠时间", tableProp: true },
        ],
      },
      tableOption3: {
        align: "center",
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 160,
          fixed: "right",
          align: "center",
        },
        column: [
          { prop: "orgUserId", label: "ID", tableProp: true },
          { prop: "mobile", label: "手机号", tableProp: true },
          { prop: "nickName", label: "昵称", tableProp: true },
          { prop: "createTime", label: "授权时间", tableProp: true },
          { prop: "lastLoginTime", label: "最后登录时间", tableProp: true },
        ],
      },
      ruleForm: {},
      createTime: [],
      //搜索
      searchOption: [
        {
          prop: "searchType",
          label: "创建时间",
          col: 7,
          type: "roleName",
          slot: true,
          placeholder: "请选择",
        },
        {
          prop: "orderStatus",
          label: "应用名称",
          col: 4,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },
        //   {
        //   prop: 'createTime',
        //   label: '捐赠时间',
        //   slot: true,
        //   type: "select",
        //   placeholder: '请选择',
        //   col: 7,
        // }
      ],
      searchOption2: [
        {
          prop: "roleName",
          label: "创建时间",
          col: 8,
          type: "roleName",
          slot: true,
          placeholder: "请选择",
        },
      ],
      objectList: [], //捐赠对象列表
      channelList: [],
    };
  },
  watch: {
    searchType: function () {
      // console.log('1111')
      this.filters.content = "";
    },
    searchType: {
      deep: true,
      handler(val) {
        if (val == 1) {
          this.filters.mobile = this.inputResult2;
        } else if (val == 2) {
          this.filters.recordId = this.inputResult;
        } else if (val == 3) {
          this.filters.pressOrgNo = this.chooseResult1;
        }
        // else if (val == 4) {
        //   this.filters.orgId = this.chooseResult1
        // }
        // pressOrgNo
        //
        this.getData();
      },
    },
  },
  created() {
    if (this.$route.query.orgId) {
      this.orgId = this.$route.query.orgId;
      localStorage.setItem("orgId", this.$route.query.orgId);
    } else {
      if (localStorage.getItem("orgId")) {
        this.orgId = localStorage.getItem("orgId");
      } else {
        this.$router.push({
          path: "./channelDetail",
        });
      }
    }
    this.getObjectList();
    // this.getaChannelList();
    this.searchType = 1;
  },

  mounted() {
    // this.filters.searchType = 1
    this.getOptions(); //获取捐赠对象
    this.getList(); //获取捐赠对象列表
  },
  methods: {
    handlePictureCardPreview(row) {
      this.dialogImageUrl = row.sealImg;
      this.dialogVisible1 = true;
    },
    uploadBtn(row) {
      this.doneeId = row.doneeId
    },
    del(row) {
      DONATION_API.doneeUpdate({sealPic: "", doneeId: row.doneeId }).then(res=> {
        this.getList()
      })
    },
    // 上传图片
    handleNomalSuccess(res, file) {
      DONATION_API.doneeUpdate({sealPic: res.data.link, doneeId: this.doneeId }).then(res=> {
        this.getList()
      })
      this.$forceUpdate();
    },
  async beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
       let width = 200;
      let height = 200;
     const isSize = new Promise(function(resolve, reject) {
        let _URL = window.URL || window.webkitURL;         
        let img = new Image();
        img.onload = function() {
          let valid = img.width == width && img.height == height;
           valid ? resolve() : reject();
        }
        img.src = _URL.createObjectURL(file);
      }).then(() => {
          return file;
      }, () => {
          this.$message.error('上传图片尺寸宽高为200*200!');
          return Promise.reject();
      });
      return isJPG && isLt2M && isSize;
    },
    exportEvent() {
      //  this.loading1 = true
    },
    change(val) {
      DONATION_API.updateMessageState({ 
        doneeId: val.doneeId,
        donationMessageState: val.donationMessageState,
      }).then((res) => {
        this.getList();
      });
    },
    async getData() {
      const params = {};
      params.orgId = this.orgId;
      // this.filters.orgId =
      await DONATION_API.getReCoard({
        ...this.pageData,
        ...this.filters,
        ...params,
      }).then((res) => {
        // console.log(res)
        this.tableList2 = res.data.records;
        this.pageTotal = res.data.total;
        // console.log(this.list)
      });
    },
    // 获取捐赠机构列表
    // async getaChannelList () {
    //   try {
    //     let params = {
    //       size: 100,
    //       current: 1
    //     }
    //     await ROLE_API.getChannelList(params).then(res => {
    //       // console.log(res.data.records)
    //       this.channelList = res.data.records;
    //       this.channelList.unshift({
    //         orgName: '通用机构',
    //         orgId: 0
    //       })
    //     });
    //     this.$store.commit('SET_TABLE_LOADING', false)
    //   } catch (err) {
    //     this.$store.commit('SET_TABLE_LOADING', false)
    //   }

    // },
    // 获取捐赠对象列表
    async getObjectList() {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        let params = {
          size: 100,
          current: 1,
        };
        const { data } = await DONATION_API.getObjectList(params);
        // console.log(data)
        // this.objectList = data.records;
        data.records.forEach((item) => {
          this.objectList.push({
            value: item.orgId,
            label: item.orgName,
          });
        });
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    // 跳转
    jumpToSearch(row) {
      this.$router.push({
        // path: "../../order/orderDetail",
        // query: { orderId: row.orderId }
        path: "../../donationRecord/detail",
        query: { recordId: row.recordId },
      });
    },
    //重置
    reset() {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.chooseResult1 = "";
      this.chooseResult2 = "";
      this.inputResult = "";
      this.inputResult2 = "";
      this.filters = {};
      this.$nextTick(() => {
        this.getData();
      });
    },
    reset2() {
      // console.log('清空')
      this.filters2 = {};
      this.pageData2.size = 10;
      this.pageData2.current = 1;
      this.$nextTick(() => {
        this.getUserList();
      });
    },
    search() {
      this.pageData.current = 1;
      this.filters.mobile = "";
      this.filters.recordId = "";
      this.filters.pressOrgNo = "";
      // this.filters.orgId = ''
      if (this.searchType == 1) {
        this.filters.mobile = this.inputResult2;
      } else if (this.searchType == 2) {
        this.filters.recordId = this.inputResult;
      } else if (this.searchType == 3) {
        this.filters.pressOrgNo = this.chooseResult1;
      } else if (this.searchType == 4) {
        // this.filters.orgId = this.chooseResult2
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    //搜索条件
    search2() {
      this.pageData2.current = 1;
      this.getUserList();
    },
    // 获取用户详情
    async getUserList() {
      try {
        let params = {}
        params.orgId = this.orgId
        const { search, type } = this.filters2;
        let form = { orgUserId: type == 0 ? search : null, mobile: type == 1 ? search : null };
        // console.log(form)
        // const { data } = await ROLE_API.getUserList(params)
        const { data } = await ROLE_API.getUserList({
          ...this.pageData2,
          ...form,
          ...params,
        });
        this.tableList3 = data.records;
        this.pageTotal2 = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    changePage2(val) {
      this.pageData2.current = val;
      this.getUserList();
    },
    handleSizeChange2(val) {
      this.pageData2.current = 1;
      this.pageData2.size = val;
      this.getUserList();
    },
    changePage(val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    check(row) {
      this.$router.push({
        path: "/donationObject/donationDetail",
        query: { pressOrgNo: row.pressOrgNo },
      });
    },
    async delectObject(row) {
      this.$confirm(`确定删除该捐赠对象吗？`, "提示", {
        type: "warning",
      })
        .then(async () => {
          try {
            let params = {};
            params.doneeId = row.doneeId;
            await ROLE_API.delectObject(params).then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功");
                this.getList(); //获取捐赠对象
              }
            });
            this.$store.commit("SET_TABLE_LOADING", false);
          } catch (err) {
            this.$store.commit("SET_TABLE_LOADING", false);
          }
        })
        .catch(() => {});
    },
    // 获取基础详情
    async getList() {
      try {
        let params = {};
        params.orgId = this.orgId;
        const { data } = await ROLE_API.getChannelListDetail(params);
        this.formMsg = data;
        this.tableList = data.dpObjectList;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    cancelDialog() {
      this.dialogVisible = false;
      this.addForm = {};
    },
    //新增捐赠对象
    async rowSave() {
      if (this.addForm.chooseList == null) {
        this.$message.error("请选择捐赠对象");
        return;
      }
      let params = {};
      let arr = [];
      this.addForm.chooseList.forEach((item) => {
        arr.push({
          orgId: this.orgId,
          doneeLogo: item[0].pressOrgLogo,
          doneeName: item[0].pressOrgName,
          pressOrgNo: item[0].pressOrgNo,
        });
      });
      params.dpObjectList = arr;
      try {
        await ROLE_API.addObject(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("新增成功");
            this.dialogVisible = false;
            this.getList();
          }
        });
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    async getOptions() {
      try {
        let params = {};
        // params.appId = this.appId
        const { data } = await ROLE_API.getOrgList(params);
        data.forEach((item) => {
          this.options.push({
            value: item,
            label: item.pressOrgName,
          });
        });
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    searchUser(row) {
      this.$router.push({
        path: "./userDetail",
        query: { orgUserId: row.orgUserId },
      });
    },
    //tab选项卡
    tabValue(val) {
      this.activeName = val.name;
      if (val.name == -1) {
        // 基础信息
        this.getList();
      } else if (val.name == 1) {
        // 订单记录
        this.getData();
      } else if (val.name == 0) {
        // 用户列表
        this.getUserList();
      }
      // this.pageData.current = 1
      // this.getList()
    },
    changeIsEnabled() {
      this.pageData.current = 1;
      this.pageData.size = 10;
      // this.getOperator();
    },
    // 新增捐赠渠道
    addOrUpdate(row = {}) {
      this.title = "新增捐赠对象";
      this.dialogVisible = true;
    },
    jumptoPage(url) {
      window.location.href = url;
    },
    //多选回调
    selectionChange(val) {
      let that = this;
      this.selectList = val;
      if (this.selectList.length == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
        if (that.selectList.findIndex((item) => item.totalStock === 0) !== -1) {
          that.disabled2 = true;
        } else {
          that.disabled2 = false;
        }
      }
    },
    closeDialog() {
      this.showDetail = false;
      this.formData = {};
      this.packageImg = null;
      this.packageName = null;
      this.packageDetail = null;
      this.tableList = [];
      this.tableList2 = [];
      this.tableList3 = [];
    },
  },
};
</script>
<style lang="scss">
.selectArea {
  .el-checkbox {
    width: 95%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
  }
}
</style>
<style  lang="scss">
.table-style .el-table th {
  background-color: #ffffff !important;
}
</style>
<style scoped lang="scss">
.box_item {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  justify-content: flex-end;
  .redTitle {
    width: 35%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 10px;
    position: relative;
  }
  .redTitle::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: red;
  }
}
.container {
  overflow-x: scroll;
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .box_tab_one {
        width: 100%;
        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
          .item2 {
            margin: 0 0 20px 0;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  width: 1310px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.box_table {
  .bottom_table {
    height: 50px;
    line-height: 50px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    align-content: center;
    font-size: 12px;
    padding: 0 2.5%;
    .bottom_left {
      // margin: 0 0 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .bottom_right {
      // margin: 0 10px 0 0px;
    }
  }
}
.img-box{
  position: relative;
  width: 82px;
  height: 82px;
  margin: 0 auto 6px;
  border-radius: 6px;
  overflow: hidden;
  .operat{
    position: absolute;
    top: 0;
    left: 0;
    width: 82px;
    height: 82px;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    line-height: 82px;
    i{
      color: #fff;
      font-size: 18px;
      margin: 0 10px;
    }
  }
  &:hover{
    cursor: pointer;
    .operat{
      display: block;
    }
  }
}
</style>

<style lang="scss" scoped>
.box_choose {
  display: flex;
  flex-wrap: nowrap;
  .typeSelect {
    border-right: none;
  }
}
</style>
<style>
.itemTble .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.itemTble .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.itemTble .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.itemTble .avatar {
  width: 82px;
  height: 82px;
}
</style>