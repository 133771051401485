<!--
 * @Description: 选择表格需要展示的字段
 * @Author: zjc
 * @Date: 2021-12-15 14:19:27
 * @LastEditTime: 2021-12-30 16:56:48
 * @LastEditors: zjc
-->
<template>
  <div>
    <div class="cheaked-header">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
        @change="handleCheckAllChange">
        全选</el-checkbox>
    </div>

    <div class="checkbox-prop">
      <el-checkbox-group v-model="checkedTable" @change="handleCheckedTableChange">
        <template v-for="item in tableList">
          <el-checkbox :key="item.prop" :label="item.prop">{{item.label}}</el-checkbox>
        </template>
      </el-checkbox-group>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tablePropList: {
      type: Array
    },
  },
  data () {
    return {
      checkAll: true, // 默认全部选中
      checkedTable: [],//选中的值
      visible: false, // 是否需要展示dialog
      checkAllList: [],//保存全选数据
      isIndeterminate: true,
    }
  },
  computed: {
    tableList () {
      return this.tablePropList.filter((item) => !item.hasOwnProperty('hide'))
    }
  },
  methods: {
    //显示dialog触发
    init () {
      this.checkedTable = []
      this.checkAllList = []
      this.visible = true
      for (const i of this.tableList) {
        this.checkAllList.push(i.prop)
        if (i.tableProp) this.checkedTable.push(i.prop)
      }
      this.checkAll = this.checkedTable.length === this.tableList.length;
      if (this.checkAll) this.isIndeterminate = false;
    },
    //全选按钮
    handleCheckAllChange (val) {
      this.checkedTable = val ? this.checkAllList : []
      this.isIndeterminate = false;
      this.confirmShow()
    },
    //单个选中
    handleCheckedTableChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkAllList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableList.length;
      this.confirmShow()
    },
    //确定
    confirmShow () {
      for (let i = 0; i < this.tableList.length; i++) {
        if (this.checkedTable.includes(this.tableList[i].prop)) {
          this.tableList[i].tableProp = true
        } else {
          this.tableList[i].tableProp = false
        }
      }
      this.$emit('changeProp', this.tableList)
    },
  },
}
</script>
<style>
.el-dropdown-menu {
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.checkbox-prop {
  max-height: 240px;
  overflow: auto;
  padding: 0px 0px 0px 10px;
}
.checkbox-prop .el-checkbox {
  min-width: 90px;
  padding: 7px 0;
  display: block;
}
.cheaked-header {
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
}
</style>