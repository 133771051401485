<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 14:14:21
 * @LastEditTime: 2022-05-23 13:42:27
 * @LastEditors: ym ym@geeboo.cn
-->
<template>
  <div class="arcsin-contail" :class="{'arcsin--collapse':isCollapse}">
    <!-- 头部导航栏 -->
    <div class="arcsin-layout">
      <div class="arcsin-left" :style="{zIndex:contentScreen?90:subMenuList.legnth>0?0:101}"
        :class="{'arcsin--sub':subMenuList.length === 0}">
        <logo />
        <!-- 左侧菜单 -->
        <navMenu />
      </div>
      <div class="arcsin-main arcsin-tabs" :class="{'arcsin--tabs1':subMenuList.length === 0}">
        <div :style="{minHeight:height}">
          <top />
          <mainContent />
        </div>
        <div id="el-main-footer">
          &copy; {{new Date().getFullYear()}} 正观易知出品
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import mainContent from './components/main'
import top from './components/top'
import logo from './components/logo'
import navMenu from './components/navMenu'
import admin from '@/utils/admin'
import { mapGetters } from 'vuex'
export default {
  components: { top, navMenu, mainContent, logo },
  data () {
    return {
      loading: false,
      height: null,
    }
  },
  computed: {
    ...mapGetters(['isCollapse', 'name', 'contentScreen', 'subMenuList'])
  },
  watch: {
    '$route': 'getHeight'
  },
  methods: {
    // 初始化
    initData () {
      this.$store.commit('SET_SCREEN', admin.getScreen())
      setTimeout(() => window.addEventListener('resize', this.initData), 10)
    },
    getHeight () {
      this.height = window.innerHeight - 63 + 'px'
    },
    //获取图书馆信息
    async getLibrary () {
      const data = await this.$store.dispatch('getLibraryInfo')
      let { libraryName, libraryImg } = data
      let libraryData = { libraryName, libraryImg }
      if (this.$validatenull(data.libraryName)) libraryData.libraryName = '厦门图书馆'
      if (this.$validatenull(data.libraryImg)) libraryData.libraryImg = require('@/assets/img/login/library_logo.png')
      this.$store.commit('SET_LIBRARY', libraryData)
    },
  },
  mounted () {
    // this.getLibrary();
    this.getHeight();
    this.initData();
  }
}
</script>
<style lang="scss">
.arcsin--sub {
  width: 0px !important;
  z-index: 101px !important;
}
.arcsin--tabs1 {
  left: 0px !important;
  width: calc(100%) !important;
}
.arcsin-contail {
  width: 100%;
  min-height: 100vh;
  background: $content--background-color;
  background-size: 100%;
}

.arcsin-left {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: $navbar--spread-width;
  height: 100%;
  box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
  overflow: auto;
}

.arcsin-main {
  position: relative;
  left: $navbar--spread-width;
  z-index: 100;
  box-sizing: border-box;
  // width: calc(100% - 208px);
  width: calc(100% - 258px);
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.arcsin--collapse .arcsin-left,
.arcsin--collapse .arcsin-logo {
  width: $navbar--packUp-width;
}
.arcsin--collapse .arcsin-tabs,
.arcsin--collapse .arcsin-header {
  left: $navbar--packUp-width;
  width: calc(100% - 60px);
}

.arcsin--collapse .tabs {
  left: 60px;
  width: calc(100% - 60px);
}
#el-main-footer {
  overflow: hidden;
  height: 60px;
  line-height: 60px;
  color: #7a8b9a;
  letter-spacing: 0.8px;
  font-family: Arial, sans-serif !important;
  left: 50%;
  z-index: 99;
  width: 100%;
  font-size: 14px;
  text-align: center;
}
</style>