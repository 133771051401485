<!-- 选择模板 -->
<template>
  <el-dialog id="fullscreen" modal-append-to-body title="选择模板" width="1000px" @close="closeDialog"
    append-to-body :close-on-click-modal="false" top="10vh" :visible.sync="visible"
    v-loading="loading">
    <el-radio-group v-model="radio">
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in tableList" :key="item">
          <div class="grid-content bg-purple">
            <img :src="item.templateImg" alt="">
            <el-radio :label="item">{{item.templateName}}</el-radio>
          </div>
        </el-col>
      </el-row>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowUpdate">提 交</el-button>
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import { CALENDAR_API } from '@/service/bookExtract/calendar'
// import { defalutAvatar } from '@/enums/bookManager'
import { ROLE_API } from "@/service/permissions/user";
export default {

  data () {
    return {
      // defalutAvatar: defalutAvatar,
      visible: false,
      tableList: [],
      // tableList: [{
      //   previewImg: '/static/template1@2x.png',
      //   templateId: 0,
      //   indexNo: 0,
      //   templateName: '山水',
      //   defaultImg: '/static/template1_preview@2x.png',
      //   createImg: '/static/bg_template@2x.png'

      // },
      // {
      //   previewImg: '/static/template2@2x.png',
      //   templateId: 1,
      //   indexNo: 1,
      //   templateName: '信封',
      //   defaultImg: '/static/template2_preview@2x.png',
      //   createImg: '/static/bg_template2@2x.png'

      // },
      // {
      //   previewImg: '/static/template4@2x.png',
      //   templateId: 2,
      //   indexNo: 2,
      //   templateName: '孟菲斯',
      //   defaultImg: '/static/template4_preview@2x.png',
      //   createImg: '/static/bg_template4@2x.png'

      // },
      // {
      //   previewImg: '/static/template5@2x.png',
      //   templateId: 3,
      //   indexNo: 3,
      //   templateName: '酸性风',
      //   defaultImg: '/static/template5_preview@2x.png',
      //   createImg: '/static/bg_template5@2x.png'

      // },
      // {
      //   previewImg: '/static/template3@2x.png',
      //   templateId: 4,
      //   indexNo: 4,
      //   templateName: '日记',
      //   defaultImg: '/static/template3_preview@2x.png',
      //   createImg: '/static/bg_template3@2x.png'

      // },
      // {
      //   previewImg: '/static/template6@2x.png',
      //   templateId: 5,
      //   indexNo: 5,
      //   templateName: '想象力',
      //   defaultImg: '/static/template6_preview@2x.png',
      //   createImg: '/static/bg_template6@2x.png'

      // },
      // {
      //   previewImg: '/static/template7@2x.png',
      //   templateId: 6,
      //   indexNo: 6,
      //   templateName: '新年',
      //   defaultImg: '/static/template7_preview@2x.png',
      //   createImg: '/static/bg_template7@2x.png'

      // },
      // {
      //   previewImg: '/static/template9@2x.png',
      //   templateId: 7,
      //   indexNo: 7,
      //   templateName: '渔歌',
      //   defaultImg: '/static/template9_preview@2x.png',
      //   createImg: '/static/bg_template9@2x.png'

      // },
      // {
      //   previewImg: '/static/template8@2x.png',
      //   templateId: 8,
      //   indexNo: 8,
      //   templateName: '高飞',
      //   defaultImg: '/static/template8_preview@2x.png',
      //   createImg: '/static/bg_template8@2x.png'

      // },
      // {
      //   previewImg: '/static/template10@2x.png',
      //   templateId: 9,
      //   indexNo: 9,
      //   templateName: '生命',
      //   defaultImg: '/static/template10_preview@2x.png',
      //   createImg: '/static/bg_template10@2x.png'

      // },
      // {
      //   previewImg: '/static/template11@2x.png',
      //   templateId: 10,
      //   indexNo: 10,
      //   templateName: '学问',
      //   defaultImg: '/static/template11_preview@2x.png',
      //   createImg: '/static/bg_template11@2x.png'

      // },
      // {
      //   previewImg: '/static/template12@2x.png',
      //   templateId: 11,
      //   indexNo: 11,
      //   templateName: '森林',
      //   defaultImg: '/static/template12_preview@2x.png',
      //   createImg: '/static/bg_template12@2x.png'

      // }
      // ],
      radio: null,
      // checkList: []
    }
  },
  mounted () {
  },
  methods: {
    init (row) {
      console.log(row)
      // this.radio = row.digestTemplate.templateId
      this.visible = true
      this.getList()
    },
    // 获取列表
    async getList () {
      this.$store.commit('SET_TABLE_LOADING', true)
      try {
        // let params = this.filters
        let params = {}
        const { data } = await ROLE_API.getTemplateList(params)
        console.log(data)
        this.tableList = data
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 提交
    rowUpdate () {
      if (this.radio == null) {
        this.$fail('请选择模板')
        return
      }
      this.$emit('choiceImg', this.radio)
      this.closeDialog()
    },
    // 关闭
    closeDialog () {
      this.visible = false
      this.radio = null
    },

  }
}
</script>
<style lang="scss" scoped>
.grid-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin: 0 0 20px 0;
  img {
    width: 100%;
    height: 160px;
    margin: 0 0 20px 0;
  }
}
</style>
