<template>
  <div class="app-container home">
    <div class="title_detail">馆配概况</div>
    <div class="content_detail">
      <div class="box_detail">
        <img src="@/assets/images/icon1.png" alt="" />
        <div class="detail_right">
          <p class="detail_title">总捐赠图书（册）</p>
          <p class="detail_dec">{{formData.bookCntCount}}</p>
        </div>
      </div>
      <div class="box_detail">
        <img src="@/assets/images/icon2.png" alt="" />
        <div class="detail_right">
          <p class="detail_title">总捐赠金额（元）</p>
          <p class="detail_dec">{{formData.totalAmountCount}}</p>
        </div>
      </div>
      <div class="box_detail">
        <img src="@/assets/images/icon3.png" alt="" />
        <div class="detail_right">
          <p class="detail_title">本月捐赠图书（册）</p>
          <p class="detail_dec">{{formData.bookCntCountMonth}}</p>
        </div>
      </div>
      <div class="box_detail">
        <img src="@/assets/images/icon4.png" alt="" />
        <div class="detail_right">
          <p class="detail_title">本月捐赠金额（元）</p>
          <p class="detail_dec">{{formData.totalAmountCountMonth}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Order_API } from "@/service/permissions/order";
export default {
  name: "home",

  data () {
    return {
      formData: {},
    }
  },
  mounted () {
    this.getList();
  },

  methods: {
    // 获取列表
    async getList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { data } = await Order_API.getIndexCount();
        // console.log(data)
        this.formData = data
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-container {
  overflow: scroll;
  height: 100vh;
  // min-width: 1200px;
}

.title_detail {
  font-style: normal;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
  letter-spacing: normal;
  color: #333333;
  padding: 0 0 0 10px;
  position: relative;
  margin: 10px 0 20px 0;
}
.title_detail:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 6px;
  height: 24px;
  background-image: inherit;
  background-position: inherit;
  background-size: inherit;
  background-repeat: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: rgb(93, 169, 231);
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
.content_echart {
  width: 95%;
  font-weight: bold;
  margin: 30px 0 0 30px;
  .box_echart_content {
    margin: 30px auto 30px;
  }
  .box_text {
    width: 200px;
    height: 200px;
    margin: 0 0 0 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    .item1 {
      margin: 40px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      .txt {
        width: 100%;
        text-align: left;
        display: inline-block;
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
      .count {
        margin: 10px 0 0 0;
        width: 100%;
        text-align: left;
        display: inline-block;
        font-weight: 400;
        font-style: normal;
        color: #555555;
        line-height: 22px;
        font-size: 12px;
      }
    }
  }
}
.content_detail {
  width: 1298px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 95%;
  margin: auto;
  .box_detail {
    width: 304px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #e4e8eb;
    border-radius: 16px;
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    img {
      width: 80px;
      height: 80px;
      border-radius: 25px;
      margin: 0 23px 0 23px;
    }
    .detail_right {
      display: flex;
      flex-wrap: wrap;
      height: 80px;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      .detail_title {
        height: 14px;
        line-height: 14px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #9ba6ba;
        text-align: left;
        display: inline-block;
      }
      .detail_dec {
        margin: 15px 0 0 0;
        padding: 0;
        line-height: 37px;
        font-weight: 500;
        font-size: 28px;
        color: #09172f;
        text-align: left;
      }
    }
    p {
      width: 100%;
      text-align: center;
    }

    .detail1 {
      text-align: left;
      width: 80%;
      margin: 10px auto 0;
      font-size: 12px;
    }
  }
}
</style>

