/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-16 14:35:46
 * @LastEditTime: 2022-08-26 11:49:13
 * @LastEditors: ym
 */
import request from '@/utils/request'
const getOperatorList = (params) => request('post', '/oms/opr/page', params); // 操作员列表 
const delOperator = (params) => request('post', `/oms/opr/delete?oprId=${params.oprId}`, params, { requestType: 'JSON' }); // 操作员删除 
const roleList = () => request('post', '/oms/role/list'); // 角色列表 
const deptTree = () => request('get', '/lms/dept/tree'); // 部门列表 
const operatorDetail = (params) => request('get', `/oms/opr/${params.id}`); // 操作员详情 
const addOperator = (params) => request('post', '/oms/opr', params, { requestType: 'JSON' }); // 新增操作员 
const updateOperator = (params) => request('post', '/oms/opr/update', params, { requestType: 'JSON' }); // 修改操作员 
const resetPassword = (params) => request('post', `/oms/opr/update-password`, params); // 重置密码 
const exportUser = (params) => request('post', `/oms/opr/info/oprExport`, params, { responseType: 'blob' }) //操作员导出
// const importByExcel = (params) => request('post', '/oms/opr/importByExcel', params, { requestType: 'multipart' }) //操作员导入
const importByExcel = (params) => request('post', '/oms/opr/fileTemplate-import', params,) //操作员导入

export const OPERATOR_API = {
  getOperatorList,
  delOperator,
  roleList,
  deptTree,
  operatorDetail,
  addOperator,
  updateOperator,
  resetPassword,
  exportUser,
  importByExcel
}