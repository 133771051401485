<!--
 * @Description: 新增、修改密码策略
 * @Author: zjc
 * @Date: 2022-07-07 15:40:39
 * @LastEditTime: 2022-07-08 18:16:06
 * @LastEditors: zjc
-->
<template>
  <div>
    <el-dialog :title="title" width="1200px" :close-on-click-modal="false" append-to-body top="10vh"
      :visible.sync="dialogVisible" @close="closeDialog">
      <el-form ref="submitForm" :model="policyData" size="medium" label-width="200px"
        :rules="formRules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="租户：" prop="tenantId">
              <el-select v-model="policyData.tenantId" clearable placeholder="请选择">
                <el-option v-for="item in tenantOptions" :key="item.tenantId"
                  :label="item.tenantName" :value="item.tenantId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="策略名称：" prop="policyName">
              <el-input v-model="policyData.policyName" placeholder="请输入策略名称" clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="终端编号：" prop="clientId">
              <el-input v-model="policyData.clientId" placeholder="请输入终端编号" clearable
                :disabled="!$validatenull(this.pwdData)">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 密码安全策略 -->
        <div class="title">密码安全策略</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否启用" prop="enablePassword">
              <el-radio v-model="policyData.enablePassword" :label="1">是</el-radio>
              <el-radio v-model="policyData.enablePassword" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最大近期密码数："
              :prop="policyData.enablePassword===1?'notRecentCount':''">
              <el-input type="number" v-model="policyData.notRecentCount" placeholder="请输入最大近期密码数"
                :disabled="policyData.enablePassword===0"
                @input="changInput(policyData.notRecentCount,'notRecentCount')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="密码最小长度：" :prop="policyData.enablePassword===1?'minLength':''">
              <el-input type="number" v-model="policyData.minLength" placeholder="请输入密码最小长度"
                :disabled="policyData.enablePassword===0"
                @input="changInput(policyData.minLength,'minLength')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码最大长度：" :prop="policyData.enablePassword===1?'maxLength':''">
              <el-input type="number" v-model="policyData.maxLength" placeholder="请输入密码最大长度"
                :disabled="policyData.enablePassword===0"
                @input="changInput(policyData.maxLength,'maxLength')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="密码更新频率（天）："
              :prop="policyData.enablePassword===1?'passwordUpdateRate':''">
              <el-input type="number" v-model="policyData.passwordUpdateRate"
                placeholder="请输入密码更新频率（天）" :disabled="policyData.enablePassword===0"
                @input="changInput(policyData.passwordUpdateRate,'passwordUpdateRate')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码修改提醒周期（天）："
              :prop="policyData.enablePassword===1?'passwordReminderPeriod':''">
              <el-input type="number" v-model="policyData.passwordReminderPeriod"
                placeholder="请输入密码修改提醒周期（天）" :disabled="policyData.enablePassword===0"
                @input="changInput(policyData.passwordReminderPeriod,'passwordReminderPeriod')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否开启随机密码：" prop="enableRandomPassword">
              <el-radio v-model="policyData.enableRandomPassword" :label="1"
                :disabled="policyData.enablePassword===0">是
              </el-radio>
              <el-radio v-model="policyData.enableRandomPassword" :label="0"
                :disabled="policyData.enablePassword===0">否
              </el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="policyData.enableRandomPassword === 0">
            <el-form-item label="初始密码：" prop="originalPassword">
              <el-input v-model="policyData.originalPassword" placeholder="请输入初始密码" clearable
                :disabled="policyData.enablePassword===0"
                @input="changInput(policyData.passwordReminderPeriod,'passwordReminderPeriod')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 登录安全策略 -->
        <div class="title">登录安全策略</div>

        <el-row>
          <el-col :span="12">
            <el-form-item label="是否启用：" prop="enableSecurity">
              <el-radio v-model="policyData.enableSecurity" :label="1">是</el-radio>
              <el-radio v-model="policyData.enableSecurity" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否锁定：" prop="enableLock">
              <el-radio v-model="policyData.enableLock" :label="1"
                :disabled="policyData.enableSecurity===0">是
              </el-radio>
              <el-radio v-model="policyData.enableLock" :label="0"
                :disabled="policyData.enableSecurity===0">否
              </el-radio>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12" v-if="policyData.enableLock===1">
            <el-form-item label="最大密码输错次数：" :prop="policyData.enableSecurity===1?'maxErrorTime':''">
              <el-input type="number" v-model="policyData.maxErrorTime" placeholder="请输入最大密码输错次数"
                :disabled="policyData.enableSecurity===0"
                @input="changInput(policyData.maxErrorTime,'maxErrorTime')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="policyData.enableLock===1">
            <el-form-item label="锁定时长（秒）："
              :prop="policyData.enableSecurity===1?'lockExpireTime':''">
              <el-input type="number" v-model="policyData.lockExpireTime" placeholder="请输入锁定时长（秒）"
                :disabled="policyData.enableSecurity===0"
                @input="changInput(policyData.lockExpireTime,'lockExpireTime')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否启用验证码：" prop="enableCaptcha">
              <el-radio v-model="policyData.enableCaptcha" :label="1"
                :disabled="policyData.enableSecurity===0">是
              </el-radio>
              <el-radio v-model="policyData.enableCaptcha" :label="0"
                :disabled="policyData.enableSecurity===0">否
              </el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="policyData.enableCaptcha===1">
            <el-form-item label="密码输错次数显示验证码："
              :prop="policyData.enableSecurity===1?'maxCheckCaptcha':''">
              <el-input type="number" v-model="policyData.maxCheckCaptcha"
                placeholder="请输入密码输错次数显示验证码" :disabled="policyData.enableSecurity===0"
                @input="changInput(policyData.maxCheckCaptcha,'maxCheckCaptcha')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="closeDialog">取消</el-button>
        <el-button size="medium" type="primary" @click="save" :loading="loading">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { PWD_API } from '@/service/permissions/passwordPolicy'
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    tenantOptions: {
      type: Array,
      default: () => []
    },
    pwdData: {
      type: Object,
      default: () => { }
    },
  },
  watch: {
    'policyData.enablePassword' (val) {
      if (val == 0) {
        this.$refs.submitForm.clearValidate(['notRecentCount', 'minLength', 'maxLength', 'passwordUpdateRate', 'passwordReminderPeriod'])
      }
    },
    'policyData.enableSecurity' (val) {
      if (val == 0) {
        this.$refs.submitForm.clearValidate(['maxErrorTime', 'lockExpireTime', 'maxCheckCaptcha'])
      }
    }
  },
  data () {
    // const minLength = (rule, value, callback) => {
    //   if (value > this.policyData.maxLength) {
    //     callback(new Error(`密码最小长度不能大于密码最大长度`))
    //   } else {
    //     callback()
    //   }
    // }
    // const maxLength = (rule, value, callback) => {
    //   if (value < this.policyData.minLength) {
    //     callback(new Error(`密码最大长度不能小于密码最小长度`))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      pwdEnable: true,//密码安全是否禁用
      loginEnable: true,//登录安全是否禁用
      loading: false,
      dialogVisible: false,
      policyData: {
        tenantId: null,//租户id
        policyName: null,//策略名称
        clientId: null,//终端编号
        notRecentCount: null,//最大近期密码数
        enablePassword: 1,//密码安全策略
        minLength: null,//密码最小长度
        maxLength: null,//密码最大长度
        passwordUpdateRate: null,//密码更新频率
        passwordReminderPeriod: null,//密码修改提醒周期
        enableRandomPassword: 1,//是否开启随机密码
        originalPassword: null,//初始密码
        enableSecurity: 1,//是否开启登录安全策略
        enableLock: 1,//登录安全是否锁定
        maxErrorTime: null,//最大密码输入错误次数
        lockExpireTime: null,//锁定时长
        enableCaptcha: 1,//是否启用验证码
        maxCheckCaptcha: null,//密码错误多少次需要验证码
      },
      title: null,
      formRules: {
        tenantId: [
          { required: true, message: '请选择租户', trigger: 'change' }
        ],
        policyName: [
          { required: true, message: '请输入策略名称', trigger: 'blur' }
        ],
        clientId: [
          { required: true, message: '请输入终端编号', trigger: 'blur' }
        ],
        notRecentCount: [
          { required: true, message: '请输入最大近期密码数', trigger: 'blur' }
        ],
        minLength: [
          { required: true, message: '请输入密码最小长度', trigger: 'blur' },
          // { required: true, trigger: 'blur', validator: minLength }
        ],
        // maxLength: [
        //   { required: true, message: '请输入密码最大长度', trigger: 'blur' },
        //   { required: true, trigger: 'blur', validator: maxLength }
        // ],
        passwordUpdateRate: [
          { required: true, message: '请输入密码更新频率', trigger: 'blur' }
        ],
        passwordReminderPeriod: [
          { required: true, message: '请输入密码修改提醒周期', trigger: 'blur' }
        ],
        maxErrorTime: [
          { required: true, message: '请输入最大密码输入错误次数', trigger: 'blur' }
        ],
        lockExpireTime: [
          { required: true, message: '请输入锁定时长', trigger: 'blur' }
        ],
        maxCheckCaptcha: [
          { required: true, message: '请输入密码错误多少次需要验证码', trigger: 'blur' }
        ]
      },
    }
  },

  methods: {
    init () {
      this.dialogVisible = this.open
      if (this.$validatenull(this.pwdData)) {
        this.title = '新增密码策略'
      } else {
        this.policyData = this.pwdData
        this.title = '修改密码策略'
      }
    },
    changInput (indexNo, key) {
      if (indexNo < 0) {
        this.policyData[key] = 1
      } else {
        this.policyData[key] = parseInt(indexNo)
      }
    },
    closeDialog () {
      this.$emit('update:open', false)
    },
    //保存
    save () {
      this.$refs['submitForm'].validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            this.policyData.enableRandomPassword === 1 ? this.policyData.originalPassword = 123456 : this.policyData.originalPassword = this.policyData.originalPassword
            this.policyData.enableCaptcha === 0 ? this.policyData.maxCheckCaptcha = null : this.policyData.maxCheckCaptcha = this.policyData.maxCheckCaptcha
            const { data } = this.$validatenull(this.pwdData) ? await PWD_API.addPolicy(this.policyData) : await PWD_API.updatePolicy(this.policyData)
            this.$ok(this.noticeId ? '修改成功' : '新增成功')
            this.closeDialog()
            this.$emit('onLoad')
            this.loading = false
          } catch (e) {
            this.loading = false
          }
        }
      })
    },
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 700;
}
</style>