<!--
 * @Description: 分页组件
 * @Author: zjc
 * @Date: 2021-12-07 11:48:20
 * @LastEditTime: 2021-12-09 10:11:21
 * @LastEditors: zjc
-->
<template>
  <div>
    <el-pagination @current-change="changePage" @size-change="handleSizeChange" background
      :current-page="pageData.current" :page-sizes="pageSizes" :page-size="pageData.size"
      layout="total, sizes,prev, pager, next,jumper" :total="total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    pageData: {
      type: Object,
      default: () => { }
    },
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50, 70, 100, 200]
    },
    total: {
      type: Number,
      default: 0
    },
  },
  methods: {
    changePage (val) {
      this.$emit('hanleSize', val)
    },
    handleSizeChange (val) {
      this.$emit('hanlePage', val)
    }
  },
}
</script>