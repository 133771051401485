<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 22:41:39
 * @LastEditTime: 2022-04-25 17:48:38
 * @LastEditors: zjc
-->
<template>
  <div class="basic-container" id="basic-container" ref="basic">
    <el-card :class="contentScreen?'fullScreen':''">
      <div class="mod">
        <slot></slot>
      </div>

    </el-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: "basicContainer",
  data () {
    return {
      height: null,
    }
  },
  computed: {
    ...mapGetters(['title', 'isCollapse', 'contentScreen']),
  },
  methods: {
    setFullscreen () {
      this.$store.commit('CONTENT_FULLSCREN')
    },
  },
  mounted () {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode == 27) {
        if (this.contentScreen) {
          this.setFullscreen()
        }
      }
    })
  },
  beforeDestroy () {
    document.removeEventListener('keyup', (e) => { })
  }
}
</script>

<style lang="scss">
.basic-container {
  height: 100%;
  box-sizing: border-box;
  margin: 0px 0px 10px 15px;

  .el-card {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    overflow: auto;
    .el-card__body {
      padding: 20px;
    }
    .mod {
      position: relative;
    }
  }

  &:first-child {
    padding-top: 0;
  }
}
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: #fff;
  padding: 10px;
  overflow: auto;
  width: 100%;
  margin: 0;
  z-index: 999999;
}
</style>