<!--
 * @Author: 租户管理
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-09-02 08:56:50
 * @FilePath: \cloud-library-platform-ui\src\views\version\manage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="100px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters" resetName="重置">
            <template #appType>
              <el-form-item label="应用名称：" prop="appType">
                <el-select v-model="filters.appType" placeholder="请输入">
                  <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
                    :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" @click="dialogVisible = true" size="small">
            新增
          </el-button>
          <el-button type="danger" icon="el-icon-delete" :disabled='!selection.length'
            @click="()=>handleDelete()" size="small">删除
          </el-button>
        </template>
      </form-menu>
    </div>
    <v-table ref="table" v-if="menu.length " :tableData="list"
      @handleSelectionChange='handleSelectionChange' :tableOption="tableOption">
      <template slot="appType" slot-scope="scope">
        <div>
          {{ scope.row.appType&&menu.find(i=>i.tenantId === scope.row.appType).libraryName }}
        </div>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" @click="()=>handleUpdate(scope.row)" size="small">编辑</el-button>
        <el-button type="text" @click="()=>handleChannel(scope.row)" size="small">分发渠道</el-button>
        <el-button type="text" size="small" @click="()=>handleDelete(scope.row)" style="color: red">
          删除</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
    <!-- 新增版本 -->
    <el-dialog @closed="()=>closed('Channel')" :title="title" :visible.sync="dialogVisible"
      width="40%" :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="应用类型：" prop="appType">
          <el-select v-model="ruleForm.appType" placeholder="请输入">
            <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
              :value="item.tenantId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本名称：" prop="versionName">
          <el-input v-model="ruleForm.versionName" maxlength="50" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- <el-form-item label="版本号：" prop="versionNo">
          <el-input v-model="ruleForm.versionNo" maxlength="11" placeholder="请输入"></el-input>
        </el-form-item> -->
        <el-form-item label="版本介绍：" prop="versionIntro">
          <el-input type="textarea" maxlength="200" v-model="ruleForm.versionIntro"
            placeholder="请输入"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分发渠道 -->
    <el-dialog title="分发渠道" :visible.sync="dialogVisibleChannel" width="900px" append-to-body>
      <div class="searchForm">
        <form-menu :tableOption="tableOption" @refresh="reset">
          <template #menuLeft>
            <el-button type="primary" icon="el-icon-plus" @click="handleAddChannelSet" size="small">
              新增
            </el-button>
            <el-button type="danger" icon="el-icon-delete" :disabled='!selectionChannel.length'
              @click="()=>handleDeleteChannelSet()" size="small">删除
            </el-button>
          </template>
        </form-menu>
        <v-table ref="table" v-if="channelTypeList.length"
          @handleSelectionChange='handleSelectionChangeChannel' :tableData="listChannel"
          :tableOption="tableOptionChannel">
          <template slot="operation" slot-scope="scope">
            <el-button type="text" @click="()=>handleUpdateChannelSet(scope.row)" size="small">编辑
            </el-button>
            <el-button type="text" size="small" @click="()=>handleDeleteChannelSet(scope.row)"
              style="color: red">删除</el-button>
          </template>
          <template slot="versionName">
            <span>{{ versionName  }}</span>
          </template>
          <template slot="channelType" slot-scope="{ row }">

            {{ channelTypeList.find(i=>i.value === row.channelType).name }}

          </template>
        </v-table>
      </div>
    </el-dialog>
    <!-- 新增分发渠道 -->
    <el-dialog @closed="()=>closed('channel')" :title="titleChannelSet"
      :visible.sync="dialogVisibleChannelSet" width="40%" :close-on-click-modal="false"
      append-to-body>
      <el-form :model="ruleForm" ref="channel" label-width="128px" class="demo-ruleForm">
        <el-form-item label="版本名称：" prop="versionName">
          <el-input disabled maxlength="50" v-model="ruleForm.versionName" placeholder="请选择">
          </el-input>
        </el-form-item>
        <el-form-item label="渠道：" prop="channelType">
          <el-select v-model="ruleForm.channelType" placeholder="请选择">
            <el-option v-for="i in channelTypeList" :key="i.index" :label="i.name" :value="i.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用地址：" prop="downloadUrl">
          <el-input v-model="ruleForm.downloadUrl" maxlength="100" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="版本号：" prop="versionNo">
          <el-input v-model="ruleForm.versionNo" maxlength="11" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleChannelSet = false">取 消</el-button>
        <el-button type="primary" @click="saveChannelSet" :loading="loadingChannelSet">确 定
        </el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
export default {
  data () {
    return {
      /**
       * list main
       */
      selection: [],
      pageData: {
        current: 1,
        size: 10,
      },
      filters: {},
      //搜索
      channelTypeList: [],
      menu: [
        { tenantId: 1, libraryName: '邻里书香' },
        { tenantId: 2, libraryName: '新华云书馆' },
        { tenantId: 3, libraryName: '学校图书馆' },
        { tenantId: 4, libraryName: '党建图书馆' },
        { tenantId: 5, libraryName: '云图书馆' },
      ],
      searchOption: [
        {
          prop: 'appType',
          label: '应用名称',
          type: 'select',
          slot: true,
          placeholder: '请选择',
        },
        {
          prop: 'versionName',
          label: '版本名称',
          type: 'input',
          maxlength: 50,
          placeholder: '请输入',
        },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: 'center',
        sort: true,
        operationData: {
          prop: 'operation',
          label: '操作',
          slot: true,
          operation: true,
          width: 200,
          fixed: 'right',
        },
        column: [
          { prop: 'appType', label: '应用名称', tableProp: true, slot: true },
          { prop: 'versionName', label: '版本名称', tableProp: true },
          // { prop: 'versionNo', label: '版本号', tableProp: true },
          { prop: 'versionIntro', label: '版本介绍', tableProp: true },
        ],
      },
      list: [],
      pageTotal: 0,
      /**
       * 表单统一内容
       */
      ruleForm: {
        appType: '',
        versionName: '',
        versionIntro: '',
        versionNo: '',
      },
      rules: {
        versionName: [{ required: true, message: '请输入', trigger: 'blur' }],
        versionNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        tenantName: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      /**
       * dialog 新增版本
       */
      loading: false,
      dialogVisible: false,
      title: '新增版本',
      /**
       * 分发渠道
       */
      versionName: '',
      versionId: '',
      dialogVisibleChannel: false,
      listChannel: [],
      selectionChannel: [],
      tableOptionChannel: {
        selection: true,
        align: 'center',
        sort: true,
        operationData: {
          prop: 'operation',
          label: '操作',
          slot: true,
          operation: true,
          width: 200,
          fixed: 'right',
        },
        column: [
          { prop: 'versionName', label: '版本名称', tableProp: true, slot: true },
          { prop: 'channelType', label: '渠道名称', tableProp: true, slot: true },
          { prop: 'versionNo', label: '版本号', tableProp: true },
          { prop: 'downloadUrl', label: '应用地址', tableProp: true },
        ],
      },
      /**
       * 新增分发渠道
       */
      loadingChannelSet: false,
      dialogVisibleChannelSet: false,
      titleChannelSet: '新增分发渠道',
    };
  },
  created () {
    this.getData();
    this.$fetch('get', '/oms/sysAppVersion/channelType', {}).then((res) => {
      this.channelTypeList = res.data;
    });
  },
  methods: {
    handleSelectionChange (value) {
      this.selection = value;
    },
    getData () {
      this.$fetch(
        'post',
        '/oms/sysAppVersion/appVersionPage',
        { ...this.pageData, ...this.filters },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.selection = [];
        this.list = res.data.records;
        this.pageTotal = res.data.total;
      });
    },
    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    /**
     * 新增版本
     */
    closed () {
      Object.keys(this.ruleForm).forEach((k) => {
        this.ruleForm[k] = '';
      });
      this.dialogVisible = false;

      this.dialogVisibleChannelSet = false;
      this.title = '新增版本';
      this.titleChannelSet = '新增分发渠道';
    },
    handleUpdate (row) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
      this.title = '编辑';
    },
    save () {
      const url =
        this.title === '编辑' ? '/oms/sysAppVersion/updateAppVersion' : '/oms/sysAppVersion/addAppVersion';
      this.$refs.ruleForm.validate().then((res) => {
        this.$fetch('post', url, this.ruleForm, {
          requestType: 'application/json',
        }).then(
          (res) => {
            this.loading = false;

            this.closed('ruleForm');
            this.$message.success(res.msg);
            this.getData();
          },
          () => {
            this.loading = false;
          }
        );
      });
    },
    handleDelete (row) {
      const arr = row ? [row.versionId] : this.selection.map((i) => i.versionId);
      this.$confirm('确定删除？', '提示', {
        type: 'warning',
      }).then(() => {
        this.$fetch(
          'post',
          '/oms/sysAppVersion/removeAppVersion',
          {
            ids: arr,
          },
          {
            requestType: 'application/json',
            // reastData:true
          }
        ).then((res) => {
          this.$message.success(res.msg);
          this.getData();
        });
      });
    },
    /**
     *  分发渠道
     */
    handleSelectionChangeChannel (val) {
      this.selectionChannel = val;
    },
    handleChannel (row) {
      this.dialogVisibleChannel = true;
      this.ruleForm.versionName = row.versionName;
      this.versionName = row.versionName;
      this.versionId = row.versionId;
      this.getChannel();
    },
    getChannel () {
      this.$fetch(
        'post',
        '/oms/sysAppVersion/distChannelList',
        { versionId: this.versionId },
        {
          // requestType: 'application/json',
        }
      ).then((res) => {
        this.selectionChannel = [];
        this.listChannel = res.data;
      });
    },
    /**
     * set
     */
    saveChannelSet () {
      const url =
        this.titleChannelSet === '编辑'
          ? '/oms/sysAppVersion/updateAppDistChannel'
          : '/oms/sysAppVersion/addAppDistChannel';
      this.$refs.channel.validate().then((res) => {
        if (res) {
          this.loadingChannelSet = true;
          this.$fetch(
            'post',
            url,
            Object.assign({}, this.ruleForm, {
              versionId: this.versionId,
            }),
            {
              requestType: 'application/json',
            }
          ).then(
            (res) => {
              this.loadingChannelSet = false;

              this.closed('channel');
              this.$message.success(res.msg);
              this.getChannel();
            },
            () => {
              this.loadingChannelSet = false;
            }
          );
        }
      });
    },
    handleUpdateChannelSet (row) {
      this.ruleForm = { ...row, versionName: this.versionName };
      console.log(this.ruleForm, 'ruleForm')
      this.dialogVisibleChannelSet = true;
      this.titleChannelSet = '编辑';
    },
    handleAddChannelSet () {
      this.ruleForm = { versionName: this.versionName };
      this.dialogVisibleChannelSet = true;
    },
    handleDeleteChannelSet (row) {
      const arr = row ? [row.channelId] : this.selectionChannel.map((i) => i.channelId);
      this.$confirm('确定删除？', '提示', {
        type: 'warning',
      }).then(() => {
        this.$fetch(
          'post',
          '/oms/sysAppVersion/removeAppDistChannel',
          {
            ids: arr,
          },
          {
            requestType: 'application/json',
          }
        ).then((res) => {
          this.$message.success(res.msg);
          this.getChannel();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-date-editor {
  width: 100%;
}
</style>
