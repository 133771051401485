/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-05-05 15:14:46
 * @LastEditTime: 2022-05-13 14:06:44
 * @LastEditors: zjc
 */
const defaultBook = require('@/assets/img/defaultBook.png') // 默认图书封面
const defaultAvatar = require('@/assets/img/default_avatar.png') // 默认头像
const bookDomain = 'http://cloud-library-test-default.elibr.cn/'
const importExcel = () => {
  window.location.href = 'http://cloud-library-default.elibr.cn/%E4%BA%91%E5%9B%BE%E4%B9%A6%E9%A6%86%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
}
export {
  defaultBook,
  defaultAvatar,
  importExcel,
  bookDomain
}