<template>
  <basic-container id="fullscreen">
    <el-form style="margin:0 0 60px 0" ref="submitForm" class="updateModal" :model="tableForm"
      size="medium" :rules="tableFormRules">
      <div class="table_one">
        <p class="title_one">捐赠包信息</p>
        <div style="display:flex;flex-wrap:nowrap;width:90%;margin-left:80px">
          <el-form-item style="width: 50%; margin: 0 auto 20px" label="捐赠包名称：" prop="packageName">
            <el-input v-model="tableForm.packageName" clearable placeholder="请输入" style="width: 80%"
              maxlength="10" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item style="width: 50%; margin: 0 auto 20px" label="价格（元）：" prop="price">
            <el-input v-model="tableForm.price" clearable placeholder="请输入" style="width: 80%">
            </el-input>
          </el-form-item>
        </div>
        <el-form-item style="width:90%;margin-left: 90px" label="捐赠包简介：">
            <ue-ditor style="display:inline-block;vertical-align: top;width: 90%;" ref="ue"
            :html="packageDesc" type="image" />
          </el-form-item>
      </div>
      <div class="table_one">
        <p class="title_one">捐赠包图书</p>
        <div class="box_opration">
          <div class="left_opration">
            <el-button style="margin-right: 20px;height:35px" size="small" type="primary"
              @click="checkBooks">
              选择图书
            </el-button>
            <import-xlsx style="margin-right: 10px" @importortXlsx="importortXlsx" />
            <div class="atext">
              <span class="txt1">支持上传的文件类型：xls; </span>
              <span class="txt2">文件最大不能超过10M。</span>
            </div>
          </div>
          <div class="right_opration">
            <div class="box_input">
              <span class="label_input">设置图书数量</span>
              <!-- <el-input size="small" v-model="allCnt" placeholder="请输入数量" style="width:200px">
                </el-input> -->
              <el-input-number :min="1" size="small" v-model="allCnt" placeholder="请输入数量"
                style="width: 200px">
              </el-input-number>
              <el-button size="small" @click="confirmCot()">确定</el-button>
            </div>
            <el-button size="small" @click="downExcel">下载导入模板</el-button>
          </div>
        </div>
        <el-form-item label="" prop="scope">
          <v-table ref="table" :tableData="tableList" :tableOption="tableOption"
            @handleSelectionChange="selectionChange" :maxHeight="420"
            style="margin-bottom: 100px; width: 90%; margin: auto">
            <!-- indexNo -->
            <template slot="indexNo" slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
            <!-- 表格特殊字段处理插槽slot为true -->
            <template slot="book" slot-scope="scope">
              <div style="
                    width: 100%;
                    text-align: left;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                <!-- <img :src="
                      scope.row.bookCover ? scope.row.bookCover : defalutBook
                    " alt="" style="width: 56px; height: 80px" /> -->
                <div style="
                      flex: 1;
                      vertical-align: top;
                      margin-left: 10px;
                      width: 100px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    ">
                  {{ scope.row.bookName }}<br />
                  {{ scope.row.bookAuthor }}<br />
                  <div>
                    <span class="orangeIcon" v-if="scope.row.isSeries == 1">丛书</span>
                    <span class="orangeIcon" v-if="scope.row.isRichMedia == 1">融媒体</span>
                  </div>
                </div>
              </div>
            </template>
            <!-- 分类 -->
            <template slot="sort" slot-scope="scope">
              <div>
                <div>
                  <span style="color: rgb(142 142 142); font-size: 12px">
                    {{ scope.row.categoryName }}</span>
                  <span style="color: rgb(142 142 142); font-size: 12px">
                    {{ scope.row.firstCategoryName }}</span>
                  <span style="color: rgb(142 142 142); font-size: 12px"
                    v-if="scope.row.firstCategoryName">></span><br />
                  <span style="color: rgb(142 142 142); font-size: 12px">
                    {{ scope.row.secondCategoryName }}</span>
                </div>
              </div>
            </template>

            <!-- 定价 -->
            <template slot="paperPrice" slot-scope="scope">
              {{ scope.row.orignalPrice ? "￥" + scope.row.orignalPrice : "-" }}
            </template>
            <!-- 库存 -->
            <template slot="stock" slot-scope="scope">
              {{ scope.row.totalStock }}
            </template>
            <!-- 拷贝数 -->
            <template slot="copyCnt" slot-scope="scope">
              <div v-if="scope.row.totalStock > 0" style="width: 203px">
                <!-- {{scope.row.totalStock}} -->
                <el-input-number ref="refNumber" v-model="scope.row.copyCnt" :min="1"
                  :max="scope.row.totalStock"
                  @input="numberChange( scope.$index,scope.row.copyCnt,scope.row.totalStock)"
                  @change="numberChange(scope.$index,scope.row.copyCnt,scope.row.totalStock)">
                </el-input-number>
              </div>
              <div v-else style="
                    border: 1px solid red;
                    width: 201px;
                    height: 37px;
                    border-radius: 2px;
                    overflow: hidden;">
                <el-input-number :disabled="true" v-model="scope.row.copyCnt" :min="1"
                  :max="scope.row.totalStock">
                </el-input-number>
              </div>
            </template>
            <!-- 销售状态 -->
            <template slot="isSale" slot-scope="scope">
              <el-tag :type="scope.row.isSale === 1 ? '' : 'danger'">
                {{ scope.row.isSale === 1 ? "可售" : "不可售" }}
              </el-tag>
            </template>
            <!-- 操作 -->
            <template slot-scope="{ row }" slot="rank">
              <el-input type="number" @blur="buleNum" style="width: 100px" v-model.number="row.rank"
                :min="0" v-if="
                    ['high_opinion', 'new_book', 'sale'].indexOf(
                      tableForm.rankCode
                    ) == -1
                  ">
              </el-input>
              <span v-else>{{ row.rank }}</span>
            </template>
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="delBook(scope.$index)">删除
              </el-button>
            </template>
          </v-table>
        </el-form-item>
        <div class="box_table">
          <div class="bottom_table">
            <div class="bottom_right">
              <div class="part_one">
                <span class="txt1">总数量：{{ goodsCnt }}本</span>
                <span class="txt2">总金额：{{ totalAmount }}元</span>
              </div>
              <div class="part_two" style="text-align:right">
                <el-button type="primary" @click="rowSave" :loading="loading">保 存</el-button>
                <el-button @click="beforeClose">取 消</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <check-book ref="checkBook" @handleSelect="handleSelectBack" type="setPro" :list="tableList" />
  </basic-container>
</template>

<script>
import { DONATION_API } from "@/service/permissions/donation";
import checkBook from "@/views/bookManeger/component/checkBook.vue";
import importXlsx from '@/views/bookManeger/component/importXlsx.vue' // 导入图书
import { COMMON_API } from "@/service/common";
import ueDitor from "@/components/UEditor";

export default {
  components: { checkBook, importXlsx, ueDitor },
  data () {
    return {
      allCnt: 1, //批量设置
      // defalutBook: defalutBook, // 默认图书封面
      selectList: [], //多选数据
      visible: false,
      loading: false,
      sumData: 0, //总和
      // 上传部分的参数----------
      videoFlag: false,
      tableForm: {
        packageName: "",
        price: "",
      },
      packageDesc: '',
      // 选择图书
      //表格配置
      tableOption: {
        selection: true,
        align: "center",
        operationData: {
          prop: "operation",
          label: "操作",
          operation: true,
          width: 120,
          align: "center",
        },
        column: [
          {
            prop: "indexNo",
            label: "序号",
            slot: true,
            tableProp: true,
            width: 100,
          },
          {
            prop: "book",
            label: "图书",
            tableProp: true,
            slot: true,
            width: 200,
          },
          { prop: "isbn", label: "ISBN", tableProp: true, width: 100 },
          {
            prop: "paperPrice",
            label: "原价（元）",
            tableProp: true,
            slot: true,
          },
          { prop: "stock", label: "库存（册）", tableProp: true, slot: true },
          {
            prop: "copyCnt",
            label: "拷贝数（册）",
            tableProp: true,
            slot: true,
            width: 250,
          },
          {
            prop: "isSale",
            label: "状态",
            tableProp: true,
            slot: true,
            width: 100,
          },
        ],
      },
      tableList: [],
      tableFormRules: {
        packageName: [
          { required: true, message: "请输入捐赠包名称", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入价格", trigger: "blur" },
        ]
      },
      goodsCnt: 0,
      totalAmount: 0,
      row: {},
      id: '',
    };
  },
  watch: {
    tableList: {
      handler: function (val, oldval) {
        let sum = 0;
        let sum2 = 0;
        this.tableList.forEach((element) => {
          // console.log(element.copyCnt)
          // sum += ;
          sum += parseInt(element.copyCnt);
          sum2 += element.orignalPrice * element.copyCnt;
        });
        this.goodsCnt = sum;
        this.totalAmount = sum2.toFixed(2);
      },
      deep: true,
    },
    tableForm: {
      deep: true,
      handler (val) {
        this.$forceUpdate()
        // console.log('1111')
        // this.getData();
      }
    },


  },
  created () {
    // 注意这里之所以是itemId，是因为我们路由配置中配的是path: '/detail/:itemId'
    // this.row = this.$route.query.row;
    // this.themeId = this.$route.query.id;
    this.themeId = this.$route.query.themeId
    if (this.$route.query.packageId) {
      this.packageId = this.$route.query.packageId
    }
  },
  mounted () {
    this.init(this.packageId)
  },
  methods: {
    numberChange (index, val, maxNum) {
      //转换数字类型
      let data = 0
      data = Number(val)
      //重新渲染
      // this.$nextTick(() => {
      //   if (num < 1) {
      //     data = 1
      //   } else if (num > maxNum) {
      //     data = num
      //   }
      // })
      this.tableList[index].copyCnt = data

    },

    sortKey (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },

    // 初始化
    async init (packageId) {
      this.visible = true;

      if (packageId) {
        await DONATION_API.packageDetail(packageId).then((res) => {
          console.log(res.data.packageBooks, "res");
          this.tableForm = res.data;
          this.packageDesc = res.data.packageDesc || ""
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.ue.ueditor.setContent(this.packageDesc, false);
            }, 300);
          });
          let arr = [];
          res.data.packageBooks.forEach((item) => {
            var params = {};
            params.bookAuthor = item.bookInfo.bookAuthor
            params.bookId = item.bookInfo.bookId
            params.bookCover = item.bookInfo.bookCover
            params.bookName = item.bookInfo.bookName
            params.isbn = item.bookInfo.isbn
            params.totalStock = item.bookInfo.totalStock
            params.orignalPrice = item.bookInfo.orignalPrice
            params.price = item.bookInfo.price
            params.isSale = item.bookInfo.isSale

            params.copyCnt = item.cnt

            // console.log(params)
            arr.push(params)
          });
          this.tableList = arr
        });
        // const { data } = await DONATION_API.packageDetail(params);
        // console.log(data)

        // this.tableList = { ...data }.relations;
        // console.log(this.tableList);
        this.sortKey(this.tableList, "isSale");
        this.$forceUpdate
      } else {
      }
    },
    //多选回调
    selectionChange (val) {
      let that = this;
      this.selectList = val;
    },
    // 排序
    ForwardRankingDate (data, p) {
      return data.sort((a, b) => {
        let x = a[p];
        let y = b[p];
        return y - x;
      });
    },
    //获取选择图书或者导入图书去重数据排序 
    handleSelectBack (row, type = null) {
      if (this.tableList.length > 0) {
        var temp = this.tableList //一个新的临时数组
        for (var i = 0; i < row.length; i++) {
          for (var j = 0; j < temp.length; j++) {
            if (temp[j].bookId == row[i].bookId) {
              row.splice(i, 1);//重复则删除
            }
          }

        }
        row.forEach((item) => {
          this.$set(item, "copyCnt", 1);
        });
        this.tableList = this.tableList.concat(row)
      } else {
        this.tableList = [...row, ...this.tableList]
        this.tableList.forEach((item) => {
          this.$set(item, "copyCnt", 1);
        });

      }
      // console.log(row);

      // // this.$set(this.tableList, "copyCnt", 1)
      // this.tableList = [...row, ...this.tableList];

      // this.tableList = COMMON_API.uniqueFunc(this.tableList, "bookId");
      // this.tableList.forEach((item) => {
      //   this.$set(item, "copyCnt", 1);
      // });
      // this.tableList = this.ForwardRankingDate(this.tableList, 'rank')
    },
    checkBooks () {
      this.$refs.checkBook.visible = true;
      this.$refs.checkBook.init(this.tableList);
    },
    // 删除图书
    delBook (index) {
      this.tableList.splice(index, 1);
    },
    //设置 馆配数量
    confirmCot () {
      console.log(this.selectList);
      if (this.selectList.length == 0) {
        this.$message("请选择需要设置的图书");
        return;
      }
      let msg = `<span style="font-size: 13px;
    color: rgba(0, 0, 0, 0.647);font-weight:bold">确定批量设置图书数量吗？</span> `;
      this.$confirm(msg, "提示", {
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          try {
            for (var i = 0; i < this.selectList.length; i++) {
              for (var j = 0; j < this.tableList.length; j++)
                if (this.selectList[i].bookId == this.tableList[j].bookId) {
                  this.tableList[j].copyCnt = this.allCnt;
                }
            }
            this.allCnt = 1;
          } catch (err) { }
        })
        .catch(() => { });
    },
    beforeClose () {
      this.$refs["submitForm"].resetFields();
      this.visible = false;
      this.tableList = [];
      this.tableForm = [];
      this.packageDesc = "";
      this.allCnt = 1;
      this.$router.back()
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      console.log(res);
      // this.tableForm.packageImg = res.url
      // this.$forceUpdate

      this.$set(this.tableForm, "packageImg", res.url);
      this.$forceUpdate
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    downExcel () {
      window.open(
        "https://dp-default.oss.zgepub.cn/捐赠包图书导入模板.xlsx"
        // "https://cloud-library-default.elibr.cn/馆配图书导入模板.xlsx"
      );
    },
    // 导入图书
    async importortXlsx (bookList) {
      // console.log(bookList);
      this.bookList = bookList;
      if (bookList.length > 500) {
        this.$message.error("导入数据不能超过500条");
        return;
      }
      let newList = [];
      if (bookList && bookList.length > 0) {
        bookList.map((item) => {
          newList.push(item.ISBN书号);
        });
        this.$store.commit("SET_TABLE_LOADING", true);
        // console.log(newList)
        let params = {}
        params.ids = newList
        // console.log(params)
        const { data } = await DONATION_API.uploadExcel(params);
        // console.log(data);
        this.$store.commit("SET_TABLE_LOADING", false);
        if (data.length !== 0) {
          this.$message.success("导入成功");
          data.forEach((item) => {
            this.bookList.map((otem) => {
              // console.log(otem)
              if (otem.ISBN书号 == item.isbn) {
                if (otem['拷贝数（册）'] == null) {
                  item.copyCnt = 1;
                } else {
                  item.copyCnt = otem['拷贝数（册）'];
                }
              }
            });
          });
          this.tableList = [...data, ...this.tableList];
          this.tableList = COMMON_API.uniqueFunc(this.tableList, "bookId");
        } else {
          this.$message.error("没有查询到相关书籍");
          return;
        }
      }
    },
    // ----------------------------------------------------------------
    async rowSave () {
      if (!this.tableForm.packageName) {
        this.$message.error("请输入捐赠包名称");
        return;
      }
      if (this.tableList.length == 0) {
        this.$message.error("请选择图书");
        return;
      }
      if (this.tableList.length < 2) {
        this.$message.error("请至少选择2本图书");
        return;
      }
      if (!this.tableForm.price) {
        this.$message.error("请输入价格");
        return;
      }
      let bookRankRelas = [];
      // let bookRankRelas = '';//图书编号，多个用英文逗号隔开
      for (let i = 0; i < this.tableList.length; i++) {
        bookRankRelas.push({
          bookId: this.tableList[i].bookId,
          cnt: this.tableList[i].copyCnt,
        });
      }
      let params = {
        packageName: this.tableForm.packageName, //套装名称
        packageBooks: bookRankRelas, //relations
        price: this.tableForm.price, //定价
        themeId: this.themeId,
        bookCnt: this.goodsCnt,
        orignalPrice: this.totalAmount,
        packageDesc: this.$refs.ue.getContent() == '' ? '' : this.$refs.ue.getContent(),
        // {{ goodsCnt }}本</span>
        //       <span class="txt2">总金额：{{ totalAmount }}元</span>
      };
      if (!this.tableForm.packageId) {
        const data = await DONATION_API.addPackage(params);
        if (data.code === 0) {
          // this.visible = false;
          // this.beforeClose();
          this.$router.back()
        }
      } else {
        params.themeId = this.themeId
        params.packageId = this.tableForm.packageId;
        const data = await DONATION_API.editPackage(params);
        if (data.code === 0) {
          this.$router.back()
        }
      }

      // await this.$emit("getList");
      // this.$router.back()
    },
  },
};
</script>
<style lang="scss">
.ql-editor {
  min-height: 200px;
}
.table_one {
  width: 100%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.box_opration {
  display: flex;
  flex-wrap: nowrap;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 30px auto 20px;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // right: 0;
  display: flex;
  justify-content: flex-end;
  box-shadow: 2px 0px 6px 0px rgb(0 21 41 / 12%);
  padding-top: 0!important;
}
.bottom_table {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end !important;
  width: 100%;
  text-align: right;
  .bottom_right {
    text-align: right;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .part_one {
      width: 100%;
      .txt1 {
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
      .txt2 {
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
        margin: 0 0 0 80px;
      }
    }
    .part_two {
      width: 100%;
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 140px;
    text-align: right;
    padding-right: 12px;
    font-weight: bold;
  }
  .redTit {
    display: inline-block;
    width: 165px;
    text-align: right;
    padding-right: 13px;
    font-weight: bold;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>

</style>