<!--
 * @Description: 菜单按钮、搜索条件隐藏，字段显示隐藏
 * @Author: zjc
 * @Date: 2021-12-08 15:16:23
 * @LastEditTime: 2022-05-12 16:33:40
 * @LastEditors: zjc
-->
<template>
  <div>
    <div class="menu-flex">
      <div class="menu-flex__left">
        <slot name="menuLeft"></slot>
      </div>
      <div class="menu-flex__right" v-if="isMenuRight">
        <slot name="menuRight"></slot>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <i class="el-icon-refresh" @click="refresh"></i>
        </el-tooltip>
        <el-tooltip v-if="fullScreen" class="item" effect="dark"
          :content="contentScreen?'退出全屏':'全屏'" placement="top">
          <i class="el-icon-full-screen" @click="setFullscreen"></i>
        </el-tooltip>
        <el-tooltip v-if="isColumn" class="item" effect="dark" content="列设置" placement="top">
          <el-dropdown :hide-on-click="false" trigger="click" placement="bottom"
            @visible-change="showOrHide">
            <i class="el-icon-s-operation"></i>
            <el-dropdown-menu slot="dropdown">
              <table-prop ref="tableProps" :tablePropList="tableOption.column"
                @changeProp="changeProp">
              </table-prop>
            </el-dropdown-menu>
          </el-dropdown>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import tableProp from './tableProps'
import { mapGetters } from 'vuex'
export default {
  components: { tableProp },
  /**
   * @param {Object} tableOption 需要动态显示列表字段的对象
   * @param {Boolean} fullScreen   是否显示全屏
  */
  props: {
    tableOption: Object,
    fullScreen: {
      type: Boolean,
      default: true
    },
    isColumn: {
      type: Boolean,
      default: true
    },
    isMenuRight: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['contentScreen'])
  },
  data () {
    return {
      isFull: false
    }
  },
  methods: {
    showOrHide (val) {
      if (val) this.$refs.tableProps.init()
    },
    changeProp (val) {
      this.$emit('redesign', val)
    },
    refresh () {
      this.$emit('refresh')
    },
    setFullscreen () {
      this.$store.commit('CONTENT_FULLSCREN')
      this.isFull = this.contentScreen
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 5px;

  &__left {
    flex: 1;
  }

  &__right {
    flex: 0 0 390px;
    text-align: right;

    .el-button + .el-button {
      margin-left: 12px;
    }
  }
}
.menu-flex__right {
  i {
    padding: 0px 8px;
    font-size: 16px;
    color: #9ba6ba;
    cursor: pointer;
  }
}
</style>