/*
 * @Author: 应用接口
 * @Date: 2022-05-24 17:05:23
 * @LastEditors: ym ym@geeboo.cn
 * @LastEditTime: 2022-05-25 18:44:54
 * @FilePath: \cloud-library-platform-ui\src\service\permissions\lessee.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const getApplication = (param) => request('get', '/oms/application/page', param, {
    requestType: 'JSON'
}) //应用管理列表
const getApplicationAdd = (param) => request('post', '/oms/application/save', param) //应用新增
const getApplicationUpdate = (param) => request('post', '/oms/application/update', param) //应用修改
const getApplicationDelete = (param) => request('post', '/oms/application/delete', param) //应用删除

export const APPLY_API = {
    getApplication,
    getApplicationAdd,
    getApplicationUpdate,
    getApplicationDelete
}