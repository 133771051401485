<template>
  <div class="upload-container" :style="{top:buttonStyle}">
    <el-dialog title="上传图片" append-to-body width="700px" :visible.sync="dialogVisible" left>
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="120px"
        style="margin: 0 30px">
        <el-form-item label="图片" prop="insertionImage">
          <el-upload ref="uploadRef" action="/api/file/oss/upload" accept=".jpg,.jpeg,.png,.gif"
            list-type="picture-card" name="file" :headers="headers" :on-remove="handleRemove"
            :on-change="handleFileChange" :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload" :limit="limitImgNumber" :file-list="fileList"
            :class="{hide:uploadDisabled}" class="imgBox">
            <i class="el-icon-plus avatar-uploader-icon" style="line-height: inherit;" />
          </el-upload>
          <div class="el-upload__tip upload-box-tip">
            只能上传JPG/JPEG/PNG/GIF格式图片，图片不能超过2M
          </div>
        </el-form-item>
      </el-form>
      <div class="btn-box">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStore } from '@/utils/store'
export default {
  name: 'UeditorInsertionImage',
  props: {
    buttonStyle: {
      type: String,
      default: () => ({})
    }
  },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      insertionImageNew: "",
      listName: {
        file: '',
        bizType: 5,
      },
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      limitImgNumber: 1,
      // 控制是否显示图片上传+号
      uploadDisabled: false,
      fileList: [],
      fileInfo: {
        fileOriginalName: "",
        fileServerName: "",
        fileUrl: "",
      },
      ruleForm: {
        insertionImage: "",
      },
      rules: {
        insertionImage: [
          { required: true, message: "必填！", trigger: "blur" },
        ],
      }
    }
  },
  created () {
    // this.showDialog()
  },
  methods: {
    // 显示弹窗
    init () {
      this.dialogVisible = true;
      this.ruleForm.insertionImage = ''
      this.listName.file = ''
      this.fileList = []
      this.uploadDisabled = false
    },
    // 图片删除
    handleRemove (file, fileList) {
      this.ruleForm.insertionImage = ''
      this.fileList = []
      this.uploadDisabled = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const imageList = {
            insertionImage: this.ruleForm.insertionImage,
          };
          this.$emit("success", imageList);
          this.dialogVisible = false;
        }
      });
    },
    // 上传图片
    handleAvatarSuccess (res, file) {
      this.ruleForm.insertionImage = res.data.link;
      this.insertionImageNew = res.data.fileName;
      this.fileInfo = {
        fileOriginalName: file.name,
        fileServerName: res.data,
        fileUrl: res.data.link,
      };
    },
    // 判断是否显示添加图片的icon
    handleFileChange (file, fileList) {
      this.uploadDisabled = fileList.length >= this.limitImgNumber
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 重置
    resetForm () {
      this.listQuery = {
        bookId: undefined,
        bookName: undefined,
        bookAuthor: undefined,
        bookType: undefined,
      };
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.btn-box {
  text-align: center !important;
  margin-top: 30px;
}
</style>
<style>
.elDialog {
  z-index: 99999;
}
</style>
<style lang="scss" scoped>
.hide {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}
</style>