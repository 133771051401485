<!--
 * @Author: ym ym@geeboo.cn
 * @Date: 2022-05-26 18:43:39
 * @LastEditors: zjc
 * @LastEditTime: 2022-07-05 14:51:55
 * @FilePath: \cloud-library-platform-ui\src\views\permissions\operator\component\XLSX.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <input class="input-file" type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      @change="exportData" />
    <button @click="btnClick">导入参数</button>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  name: "InputExcel",
  props: {
    accept: {
      type: String,
      default: "选择excel文件",
    },
  },
  methods: {
    btnClick () {
      document.querySelector(".input-file").click();
    },
    exportData (event) {
      if (!event.currentTarget.files.length) {
        return;
      }
      const that = this;
      // 拿取文件对象
      var f = event.currentTarget.files[0];
      that.$emit("getResult", f);

      console.log(f, "f");
      return;
      // 用FileReader来读取
      var reader = new FileReader();
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var wb; // 读取完成的数据
        var outdata; // 你需要的数据
        var reader = new FileReader();
        reader.onload = function (e) {
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // 接下来就是xlsx了，具体可看api
          wb = XLSX.read(binary, {
            type: "binary",
          });
          console.log(wb, "wb");
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // 自定义方法向父组件传递数据
          that.$emit("getResult", outdata);
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },
  },
};
</script>

<style scoped>
.input-file {
  display: none;
}
</style>