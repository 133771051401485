
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="0px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <!-- <template #status>
              <el-form-item label="状态" label-width="100px">
                <el-select v-model="filters.status" clearable placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template> -->
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" size="small" @click="addOrUpdate('')">
            新增扉页模板
          </el-button>
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption" @sortChange="sortChange">
      <template slot="templateId" slot-scope="scope">
        <span>{{scope.row.templateId}}</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="renewal(scope.row)">编辑</el-button>
        <el-button style="color: red;" type="text" size="small" @click="cancel(scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
  </basic-container>
</template>
<script>
// import { BANNER_API } from "@/service/permissions/banner";
import { DONATION_API } from "@/service/permissions/donateTemplate";
export default {
  data () {
    return {
      // authOptions: [
      //   { label: '停用', value: 2 },
      //   { label: '启用', value: 1 },
      // ],
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      filters: {
        templateName: '',
      },
      //搜索
      searchOption: [
        {
          prop: "templateName",
          label: "",
          type: "input",
          placeholder: "搜索模板名称",
          option: { clearable: true }
        },
        // {
        //   prop: "status",
        //   label: "状态",
        //   col: 5,
        //   slot: true,
        //   type: "select",
        //   placeholder: "请输入",
        // },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: false,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'templateName', label: '落款模板名称', tableProp: true },
          // { prop: 'status', label: '状态', slot: true, tableProp: true, },
          { prop: 'createTime', label: '创建时间', tableProp: true, },
        ],
      },
      list: [{}],

    };
  },
  created () {
    this.getData();
  },
  methods: {
    async getData () {
      await DONATION_API.getList({
        ...this.pageData,
        ...this.filters
      }).then((res) => {
        console.log(res)
        this.list = res.data.records
        this.pageTotal = res.data.total;
      });
    },
    addOrUpdate () {
      this.$router.push({
        path: "./addList",
        // query: { appId: row.appId, appName: row.appName }
      });
    },
    // 删除
    cancel (item) {
      let params = {}
      params.templateId = item.templateId,
        this.$confirm(`是否确认删除该捐赠扉页`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          DONATION_API.delectDonation(params).then((res) => {
            console.log(res)
            if (res.code == 0) {
              this.$message.success("删除成功");
              this.getData();
            }
          });
        })
          .catch(() => {
            //
          });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    // 查看
    renewal (row) {
      console.log(row)
      this.$router.push({
        path: "./addList",
        query: { templateId: row.templateId }
      });
    },
    search () {
      this.pageData.current = 1;
      // this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.filters.templateName = '';
      this.$nextTick(() => {
        this.getData();
      });
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>