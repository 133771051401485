<!--
 * @Author: 杨明 ym@geeboo.cn
 * @Date: 2024-06-03 14:52:36
 * @LastEditors: 杨明 ym@geeboo.cn
 * @LastEditTime: 2024-06-05 15:52:00
-->
<template>
  <div class="donation-box">
    <el-dialog
      title="捐赠寄语替换"
      modal-append-to-body
      append-to-body
      :visible.sync="dialogVisible"
      width="860px"
      :before-close="handleClose"
      top="10vh"
      :close-on-click-modal="false"
      @closed="closed"
    >
      <div class="replace-donation">
        <div class="donation-bg">
          <div class="content">
            <div class="titles">捐赠信息</div>
            <div class="name">
              本书由<span style="font-size: 13px; font-weight: 550">{{
                dataObj.fullInscriptionName
              }}</span
              >捐赠
            </div>
            <div class="details">
              {{ content }}
            </div>
          </div>
          <div class="date">
            {{ dataObj.createTime }}
          </div>
        </div>
        <div class="select-donation">
          <div style="font-size: 20px; margin-bottom: 10px">选择寄语：</div>
          <el-radio-group v-model="radio" @change="change">
            <el-radio
              :label="item.id"
              v-for="item in radioList"
              :key="item.id"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="loading" @click="confirm"
          >{{loading? '寄语替换中...': '确定'}}</el-button
        >
      </span>
    </el-dialog>
    <!-- 绘图 -->
    <!-- 模板样式 -->
    <div class="boxContent" id="capture">
      <div class="content">
        <!-- <div class="titles">捐赠信息</div> -->
        <div class="name">
          本书由<span style="font-size: 50px">{{
            dataObj.fullInscriptionName
          }}</span
          >捐赠
        </div>
        <div class="details">
          {{ content1 }}
        </div>
      </div>
      <div class="date">
        {{ dataObj.createTime }}
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import { getStore } from "@/utils/store";
import { DONATION_API } from "@/service/permissions/donation";

export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      link: "",
      loading: false,
      dialogVisible: false,
      radio: 0,
      radioList: [
        { id: 0, value: "一缕书香，一片真情" },
        { id: 1, value: "鸟欲高飞先振翅，人求上进先读书" },
        { id: 2, value: "读书吧，去认识世界，认识自己，认识生命！" },
      ],
      dataObj: {},
      content: "一缕书香，一片真情",
      content1: "一缕书香，一片真情",
    };
  },
  methods: {
    init() {
      this.content1 = "一缕书香，一片真情";
      this.dataObj = Object.assign({}, this.row);
      let regex = /-/g
      this.dataObj.createTime = (this.dataObj.createTime.slice(0, 10)).replace(regex, '.');
      this.dialogVisible = true;
    },
    change(val) {
      this.content = this.radioList[val].value;
      this.content1 = this.radioList[val].value;
    },
    closed() {
      this.radio = 0;
      this.content = "一缕书香，一片真情";
    },
    confirm() {
      var that = this;
      that.loading = true;
      setTimeout(() => {
        html2canvas(document.querySelector("#capture"), {
          width: document.querySelector("#capture").clientWidth, //dom 原始宽度
          height: document.querySelector("#capture").clientHeight,
          scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
          scrollX: 0,
          useCORS: true,
          allowTaint: true,
        })
          .then((canvas) => {
            const img = new Image();
            img.src = canvas.toDataURL("png");
            img.setAttribute("crossOrigin", "anonymous");
            var context = canvas.getContext("2d");
            img.onload = function () {
              var w = img.width;
              var h = img.height;
              context.drawImage(img, 0, 0, w, h, 0, 0, w, h);
              let imgUrl = canvas.toDataURL("image/png");
              that.uploadImg(imgUrl);
            };
          })
          .catch(() => {
            that.loading = false;
          });
      }, 50);
    },
    uploadImg(imgUrl) {
      var formData = new FormData();
      let blob = this.dataURLtoFile(imgUrl, "name");
      let fileOfBlob = new File([blob], new Date() + ".jpg");
      formData.append("file", fileOfBlob);
      formData.append("bizType", 2);
      axios({
        url: "/api/file/oss/upload",
        method: "post",
        data: formData,
        headers: {
          Authorization: "Bearer " + getStore({ name: "userToken" }),
        },
        contentType: false,
        processData: false,
      })
        .then((res) => {
          this.link = res.data.data.link;
          this.getUpdateCopyDonationPage();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    getUpdateCopyDonationPage() {
      DONATION_API.updateCopyDonationPage({
        recordId: this.dataObj.recordId,
        newDonationImg: this.link,
        newDonationMessage: this.content1,
      })
        .then((res) => {
          this.loading = false;
          this.$ok("替换成功");
          this.$emit('refresh')
          this.dialogVisible = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.replace-donation {
  display: flex;
  .select-donation {
    margin: 60px 0 0 30px;
    width: 320px;
  }
  .donation-bg {
    position: relative;
    background: url("../../../assets/img/bg_template8.png") no-repeat;
    height: 552px;
    width: 375px;
    background-size: 100%;
    .content {
      width: 214px;
      margin: 116px auto 0;
      color: #09172f;
      .titles {
        font-size: 13px;
        font-weight: 550;
        margin-bottom: 5px;
      }
      .name {
        font-size: 12px;
      }
      .details {
        font-size: 16px;
        line-height: 24px;
        margin: 66px 0 0 3px;
      }
    }
    .date {
      position: absolute;
      font-size: 12px;
      color: #09172f;
      right: 76px;
      bottom: 214px;
    }
  }
}
.donation-box {
  .boxContent {
    z-index: -1;
    position: absolute;
    top: -2000px;
    left: -2000px;
    overflow: hidden;
    width: 1500px;
    height: 2208px;
    background: url("../../../assets/img/bg_template8.png") no-repeat;
    background-size: 100%;
    .content {
      width: 910px;
      margin: 600px auto 0;
      color: #09172f;
      .name {
        font-size: 48px;
      }
      .details {
        font-size: 60px;
        line-height: 96px;
        margin: 240px 0 0 12px;
        letter-spacing: 8px;
      }
    }
    .date {
      position: absolute;
      font-size: 40px;
      color: #09172f;
      right: 318px;
      bottom: 868px;
    }
  }
}
</style>
<style>
.replace-donation .el-radio-group {
  font-size: 30px;
}
</style>