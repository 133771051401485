<!--
 * @Description: 主入口
 * @Author: zjc
 * @Date: 2021-12-07 11:45:22
 * @LastEditTime: 2021-12-14 14:48:01
 * @LastEditors: zjc
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data () {
    return {};
  },
  methods: {},
  computed: {}
};
</script>
<style>
#app {
  width: 100%;
  min-height: 100%;
}
body {
  background: #fff;
  min-height: 100%;
  font-family: "微软雅黑";
  transition: opacity 0.3s !important;
  /* transform: translate3d(0, 0, 0); */
  /* -webkit-backface-visibility: hidden; */
  /* -webkit-perspective: 1000; */
}
</style>
