<template>
  <basic-container>
    <div>
      <p style="margin:auto;width:100%">2022年{{itemMonth}}月支付记录</p>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;">

        <v-tabs :activeName="activeName" style="width:100%" :tabList="tabList" @tabValue="tabValue">
        </v-tabs>
        <div style="width:100%">
          <div v-show="activeName == '-1'">
            <v-table ref="table" :tableData="tableList" :tableOption="tableOption">
              <template slot="orgName" slot-scope="scope">
                <span v-if="scope.row.orgId == '0'">通用机构</span>
                <span v-else>{{scope.row.orgName}}</span>
              </template>
              <template slot="donationType" slot-scope="scope">
                <span v-if="scope.row.donationType == 1">商城捐赠</span>
                <span v-else>已有书捐赠</span>
              </template>
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="jumpToSearch(scope.row)">查看</el-button>
              </template>
            </v-table>
            <!-- 分页 -->
            <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
              @hanlePage="handleSizeChange"></pagination>
          </div>
          <div v-show="activeName == '1'" style="width:100%">
            <v-table ref="table" :tableData="tableList2" :tableOption="tableOption2">
              <template slot="orderStatus" slot-scope="scope">
                <span v-if="scope.row.orderStatus == 1">待支付</span>
                <span v-else-if="scope.row.orderStatus == 2">处理中</span>
                <span v-else-if="scope.row.orderStatus == 3">已完成</span>
                <span v-else-if="scope.row.orderStatus == 4">处理中</span>
                <span v-else-if="scope.row.orderStatus == 5">已取消</span>
              </template>
              <template slot="orgName" slot-scope="scope">
                <span v-if="scope.row.orgId == '0'">通用机构</span>
                <span v-else>{{scope.row.orgName}}</span>
              </template>
              <template slot="donationType" slot-scope="scope">
                <span v-if="scope.row.donationType == 1">商城捐赠</span>
                <span v-else>已有书捐赠</span>
              </template>
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="jumpToSearch2(scope.row)">查看</el-button>
              </template>
            </v-table>
            <!-- 分页 -->
            <pagination :pageData="pageData2" :total="pageTotal2" @hanleSize="changePage2"
              @hanlePage="handleSizeChange2"></pagination>
          </div>
        </div>
      </div>
    </div>
  </basic-container>
</template>
<script>
import vTabs from '@/components/CRUD/v-tabs'
// import { DONATION_API } from "@/service/permissions/donation";
import { FINATION_API } from "@/service/permissions/finicial";
export default {
  components: { vTabs },
  data () {
    return {
      filters: {
        payType: 1,
      },
      itemMonth: -1,
      activeName: '-1',
      tabList: [
        { label: '在线支付', name: '-1' },
        { label: '线下支付', name: '1' },
      ],
      tableList: [],
      tableList2: [],
      pageData: { size: 10, current: 1 },
      pageData2: { size: 10, current: 1 },
      pageTotal: 0,
      pageTotal2: 0,
      tableOption: {
        selection: false,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'orderId', label: '订单号', tableProp: true, },
          { prop: 'mobile', label: '支付人手机号', tableProp: true, },
          { prop: 'actualAmount', label: '支付金额', tableProp: true, },
          { prop: 'payTime', label: '支付时间', tableProp: true },
        ],
      },
      tableOption2: {
        selection: false,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'userId', label: '代捐用户', tableProp: true, },
          { prop: 'actualAmount', label: '支付金额（元）', tableProp: true, },
          { prop: 'modifyTime', label: '审核通过时间', tableProp: true },

        ],
        year: '',
        month: '',
      },
    }
  },
  created () {
    if (this.$route.query.year) {
      localStorage.setItem("year", this.$route.query.year)
      localStorage.setItem("month", this.$route.query.month)
      this.year = this.$route.query.year
      this.month = this.$route.query.month
    }
    else {
      if (localStorage.getItem("year")) {
        this.year = localStorage.getItem("year")
        this.month = localStorage.getItem("month")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.itemMonth = this.month
    this.startTime = this.month + '00:00'
    this.searchStart = this.year + '-' + this.month + '-' + '01' + ' 00:00:00'
    var thisDate = new Date(this.year, this.month, 0);
    this.searchEnd = this.year + '-' + this.month + '-' + thisDate.getDate() + ' 23:59:59'
    this.getList();
  },

  methods: {
    //tab选项卡
    tabValue (val) {
      console.log(val)
      this.activeName = val.name
      if (val.name == -1) {
        // 在线支付
        this.filters.payType = 1
        this.getList()
      } else if (val.name == 1) {
        // 线下支付
        this.filters.payType = 3
        this.getDate()
      }
    },
    // 主题详情
    async getList () {
      console.log(this.filters)
      const params = {}
      params.startTime = this.searchStart
      params.endTime = this.searchEnd
      await FINATION_API.getDetail({
        ...this.pageData,
        ...this.filters,
        ...params,
      }).then((res) => {
        this.tableList = res.data.records
        this.pageTotal = res.data.total;
      });
    },
    async getDate () {
      console.log(this.filters)
      const params = {}
      params.startTime = this.searchStart
      params.endTime = this.searchEnd
      await FINATION_API.getDetail({
        ...this.pageData2,
        ...this.filters,
        ...params,
      }).then((res) => {
        this.tableList2 = res.data.records
        this.pageTotal = res.data.total;
      });
    },
    // 跳转
    jumpToEdit (row) {
      console.log(row)
      this.$router.push({
        path: "../../../donationRecord/detail",
        query: { recordId: row.recordId }
      });
    },
    // 跳转到修改
    jumpToSearch (row) {
      this.$router.push({
        path: "../../../donationRecord/detail",
        query: { recordId: row.recordId }
        // query: { themeId: row.themeId }
      });
    },
    jumpToSearch2 (row) {
      this.$router.push({
        path: "../../../donationReplace/recordList/donationDetail",
        query: { applyId: row.applyId }
        // query: { themeId: row.themeId }
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getList();
    },
    changePage2 (val) {
      this.pageData2.current = val;
      this.getData();
    },
    handleSizeChange2 (val) {
      this.pageData2.current = 1;
      this.pageData2.size = val;
      this.getData();
    },
    jumptoPage (url) {
      window.location.href = url
    },
    closeDialog () {
      this.tableList = []
    },
  }
}
</script>


