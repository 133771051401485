<template>
  <basic-container>
    <div>
      <div class="container" style="text-align:left;display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;">
        <div class="abox_title">
          <div class="left_title">
            <p class="title">代捐详情</p>
            <p class="rightTitle">状态：
              <span>待支付</span>
            </p>
          </div>
        </div>
        <div style="width:100%">
          <div class="tab-one" style="width:100%">
            <div class="tab-title">
              <span>申请信息</span>
            </div>
            <div class="tab-content2">
              <div class="box_tab_one">
                <div class="content">
                  <div class="item">
                    <span class="l1">代捐用户：</span>
                    <span class="r1">{{tableForm.donateUserMobile}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">用户昵称：</span>
                    <span class="r1">{{tableForm.donateUserName}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">剩余可用额度：</span>
                    <span class="r1">
                      {{ Number((tableForm.donateAmount - tableForm.alreadyDonateAmount).toString().match(/^\d+(?:\.\d{0,2})?/)) }}
                    </span>
                  </div>
                </div>
                <div class="content">
                  <div class="item">
                    <span class="l1">捐赠对象：</span>
                    <span class="r1">{{tableForm.pressOrgName}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">申请说明：</span>
                    <span class="r1">{{tableForm.remark}}</span>
                  </div>
                  <div class="item">
                    <span class="l1">发起时间：</span>
                    <span class="r1">{{tableForm.createTime}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-one" style="width:100%;margin:20px auto 0">
            <div class="tab-title">
              <span>捐赠信息</span>
            </div>
            <div class="tab-content2">
              <el-form style="width:100%;margin:20px 0 10px 0" ref="submitForm" class="updateModal"
                :model="tableForm" size="medium">
                <div class="table_one">
                  <div style="display:flex;flex-wrap:nowrap;width:100%;margin:auto;">
                    <div @click="signDialog" style="width: 25%; margin: 0 0 0 20px">
                      <el-form-item label="" prop="inscriptionName">
                        <span class="redTit">捐赠落款:</span>
                        <el-input v-model="returnList.inscriptionName" clearable placeholder="请输入"
                          style="width:100%">
                        </el-input>
                      </el-form-item>
                    </div>
                    <div @click="imgDialog" style="width: 25%; margin: 0 0 0 20px">
                      <el-form-item label="" prop="price">
                        <span class="redTit">寄语模板:</span>
                        <el-input @click="imgDialog" v-model="returnList2.templateName" clearable
                          placeholder="请输入" style="width: 100%">
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <div class="tab-one" style="width:100%;margin:20px auto 0">
            <div class="tab-title">
              <span>捐赠书单</span>
            </div>
            <el-form style="width:100%;margin:0 0 100px 0" ref="submitForm" class="updateModal"
              :model="tableForm" size="medium" :rules="tableFormRules">
              <div class="table_one">
                <div class="box_opration">
                  <div class="left_opration">
                    <el-button style="margin-right: 20px;height:35px" size="small" type="primary"
                      @click="checkBooks">
                      选择图书
                    </el-button>
                    <import-xlsx style="margin-right: 10px" @importortXlsx="importortXlsx" />
                    <div class="atext">
                      <span class="txt1">支持上传的文件类型：xls; </span>
                      <span class="txt2">文件最大不能超过10M。</span>
                      <span style="font-size: 13px;color: #169BD5;cursor:pointer"
                        @click="downExcel">下载导入模板</span>
                    </div>
                  </div>
                  <div class="right_opration">
                    <div class="box_input">
                      <span class="label_input">设置图书数量</span>
                      <!-- <el-input size="small" v-model="allCnt" placeholder="请输入数量" style="width:200px">
                </el-input> -->
                      <el-input-number :min="1" size="small" v-model="allCnt" placeholder="请输入数量"
                        style="width: 200px">
                      </el-input-number>
                      <el-button size="small" @click="confirmCot()">确定</el-button>
                    </div>

                  </div>
                </div>
                <el-form-item label="" prop="scope">
                  <v-table ref="table" :tableData="tableList" :tableOption="tableOption"
                    @handleSelectionChange="selectionChange" :maxHeight="420"
                    @sortChange="sortChange" style="margin-bottom: 100px; width: 90%; margin: auto">
                    <!-- indexNo -->
                    <template slot="indexNo" slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                    <!-- 表格特殊字段处理插槽slot为true -->
                    <template slot="book" slot-scope="scope">
                      <div style="
                    width: 100%;
                    text-align: left;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                        <!-- <img :src="
                      scope.row.bookCover ? scope.row.bookCover : defalutBook
                    " alt="" style="width: 56px; height: 80px" /> -->
                        <div style="
                      flex: 1;
                      vertical-align: top;
                      margin-left: 10px;
                      width: 100px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    ">
                          {{ scope.row.bookName }}<br />
                          <!-- {{ scope.row.bookAuthor }}<br /> -->
                          <div>
                            <span class="orangeIcon" v-if="scope.row.isSeries == 1">丛书</span>
                            <span class="orangeIcon" v-if="scope.row.isRichMedia == 1">融媒体</span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- 分类 -->
                    <template slot="sort" slot-scope="scope">
                      <div>
                        <div>
                          <span style="color: rgb(142 142 142); font-size: 12px">
                            {{ scope.row.categoryName }}</span>
                          <span style="color: rgb(142 142 142); font-size: 12px">
                            {{ scope.row.firstCategoryName }}</span>
                          <span style="color: rgb(142 142 142); font-size: 12px"
                            v-if="scope.row.firstCategoryName">></span><br />
                          <span style="color: rgb(142 142 142); font-size: 12px">
                            {{ scope.row.secondCategoryName }}</span>
                        </div>
                      </div>
                    </template>

                    <!-- 定价 -->
                    <template slot="paperPrice" slot-scope="scope">
                      {{ scope.row.price ? "￥" + scope.row.price : "-" }}
                    </template>
                    <!-- 库存 -->
                    <template slot="stock" slot-scope="scope">
                      {{ scope.row.totalStock }}
                    </template>
                    <!-- 拷贝数 -->
                    <template slot="copyCnt" slot-scope="scope">
                      <div v-if="scope.row.totalStock > 0" style="width: 203px">
                        <!-- {{scope.row.totalStock}} -->
                        <el-input-number ref="refNumber" v-model="scope.row.copyCnt" :min="1"
                          :max="scope.row.totalStock"
                          @input="numberChange( scope.$index,scope.row.copyCnt,scope.row.totalStock)"
                          @change="numberChange(scope.$index,scope.row.copyCnt,scope.row.totalStock)">
                        </el-input-number>
                      </div>
                      <div v-else style="
                    border: 1px solid red;
                    width: 201px;
                    height: 37px;
                    border-radius: 2px;
                    overflow: hidden;">
                        <el-input-number :disabled="true" v-model="scope.row.copyCnt" :min="1"
                          :max="scope.row.totalStock">
                        </el-input-number>
                      </div>
                    </template>
                    <!-- 销售状态 -->
                    <template slot="isSale" slot-scope="scope">
                      <el-tag :type="scope.row.isSale === 1 ? '' : 'danger'">
                        {{ scope.row.isSale === 1 ? "可售" : "不可售" }}
                      </el-tag>
                    </template>
                    <!-- 操作 -->
                    <template slot-scope="{ row }" slot="rank">
                      <el-input type="number" @blur="buleNum" style="width: 100px"
                        v-model.number="row.rank" :min="0" v-if="
                    ['high_opinion', 'new_book', 'sale'].indexOf(
                      tableForm.rankCode
                    ) == -1
                  ">
                      </el-input>
                      <span v-else>{{ row.rank }}</span>
                    </template>
                    <template slot="operation" slot-scope="scope">
                      <el-button type="text" size="small" @click="delBook(scope.$index)">删除
                      </el-button>
                    </template>
                  </v-table>
                </el-form-item>
                <div class="part_one_box">
                  <div class="bottom_left">
                    <p class="delectBtn" @click="delectBooks()">
                      删除已选
                    </p>
                  </div>
                  <div class="bottom_right">
                    <div class="part_one">
                      <span class="txt1">总书目：{{ tableList.length }}种</span>
                      <span class="txt2">总复本：{{ goodsCnt }}册</span>
                      <span class="txt3">优惠后金额：¥{{ totalAmount }}</span>
                    </div>

                  </div>
                </div>
                <div class="box_table">

                  <div class="bottom_table">

                    <div class="part_two" style="text-align:right">
                      <el-button @click="beforeClose">取 消</el-button>
                      <el-button type="primary" @click="rowSave" :loading="loading">立即捐赠
                      </el-button>

                    </div>
                  </div>

                </div>
              </div>
            </el-form>
          </div>
        </div>

      </div>

    </div>
    <!-- 新增、编辑 -->
    <!-- <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave2" @row-update="rowUpdate">
    </modal-item> -->

    <el-dialog modal-append-to-body title="捐赠落款" width="800px" @close="cancelDialog" append-to-body
      :close-on-click-modal="false" top="10vh" :visible.sync="showDialog">
      <el-form style="margin:0 0 100px 0" ref="submitForm" class="updateModal" size="medium"
        labelWidth="90px" :model="tableForm2" :rules="tableFormRules2">
        <div class="table_one">
          <el-form-item style="width: 89%; margin: 0 auto 20px" label="捐赠对象:" prop="pressOrgNo">
            <el-select disabled v-model="tableForm2.pressOrgNo" placeholder="请选择"
              @change="selectChange($event)" clearable="true">
              <el-option v-for="i in objectList" :key="i.orgId" :label="i.orgName" :value="i.orgId">
              </el-option>
            </el-select>
          </el-form-item>
          <div v-for="(item,index) in this.objectMsg" :key="index">
            <el-form-item v-show="item.identityCode == 'SCHOOL'"
              style="width: 89%; margin: 0 auto 20px" label="所属院校:" prop="pressOrgNo">
              <el-select v-model="school" placeholder="请选择" clearable="true">
                <el-option v-for="i in schoolList" :key="i.schoolName" :label="i.schoolName"
                  :value="i.schoolName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="item.identityCode == 'ENTRY_YEAR' && school !== '社会公益人士'"
              style="width: 89%; margin: 0 auto 20px" label="入学年份:" prop="pressOrgNo">
              <el-select v-model="year" placeholder="请选择" clearable="true">
                <el-option v-for="i in arrYear" :key="i" :label="i" :value="i">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="item.identityCode == 'EDUCATION' && school !== '社会公益人士'"
              style="width: 89%; margin: 0 auto 20px" label="学历:" prop="pressOrgNo">
              <el-select v-model="readType" placeholder="请选择" clearable="true">
                <el-option v-for="i in readList" :key="i" :label="i" :value="i">
                </el-option>
              </el-select>
            </el-form-item>

          </div>
          <div>
            <el-form-item style="width: 89%; margin: 0 auto 20px" label="落款名称:" prop="preview">
              <el-input maxlength="30" show-word-limit v-model="tableForm2.preview" clearable
                placeholder="请输入落款名称" style="width: 100%">
              </el-input>
            </el-form-item>
          </div>

        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog" size="medium">取消</el-button>
        <el-button type="primary" @click="rowSave2" size="medium" :loading="loading">确定</el-button>
      </span>
    </el-dialog>

    <check-book ref="checkBook" @handleSelect="handleSelectBack" type="setPro" :list="tableList" />
    <!-- 选择模板 -->
    <choice-sign ref="choiceSign" @choiceImg="choiceSignBack" @addOrUpdate="addOrUpdate" />
    <!-- 选择模板 -->
    <choice-img ref="choiceImg" @choiceImg="choiceImgBack" />
    <!-- 模板样式 -->
    <div class="boxContent">
      <div id="capture" :style="'background-color:' + '#' + returnList2.templateImg">
        <div class="box_titl">
          <p class="title2">
            <span>本书由</span>
            <span class="box_titleName">
              <span class="titleName" v-show="item.identityCode !== 'LUOKUAN'"
                v-for="(item,index) in returnListArr" :key="index">
                <span>{{item.identityValue}}
                  <!-- <span v-if="item.identityCode == 'ENTRY_YEAR' && item.identityValue">级</span> -->
                </span>
              </span>
              <span class="titleName">
                <span>{{returnList.inscriptionName}}</span>
              </span>
              <!-- <span class="titleName" v-show="item.identityCode == 'LUOKUAN'"
                v-for="(item,index) in returnListArr" :key="index">
                <span>{{item.identityValue}}</span>
              </span> -->
            </span>
            <span>捐赠</span>
          </p>
        </div>
        <div class="bgImg" :style="'background-image:url(' + returnList2.templateImg  + ')'"></div>
        <div class="box_time">{{dataCount}}</div>
      </div>
    </div>
  </basic-container>
</template>

<script>
import { DONATION_API } from "@/service/permissions/donation";
import checkBook from "@/views/bookManeger/component/checkBook.vue";
import choiceImg from "@/views/bookManeger/component/choiceImg.vue";
import choiceSign from "@/views/bookManeger/component/choiceSign.vue";
import importXlsx from '@/views/bookManeger/component/importXlsx.vue' // 导入图书
import { COMMON_API } from "@/service/common";
import { ROLE_API } from "@/service/permissions/channel";
import html2canvas from 'html2canvas'
import { getStore } from '@/utils/store'
import { formatDate1 } from '@/filters/index'
// import { ROLE_API2 } from "@/service/permissions/user";

export default {
  components: { checkBook, importXlsx, choiceImg, choiceSign, html2canvas },
  data () {
    return {
      showDialog: false,
      school: '',
      year: '',
      readType: '',

      dataCount: '',
      // showImg: false,
      // chooseList: [],
      options: [],
      allCnt: 1, //批量设置
      // defalutBook: defalutBook, // 默认图书封面
      selectList: [], //多选数据
      visible: false,
      loading: false,
      sumData: 0, //总和
      // 上传部分的参数----------
      videoFlag: false,
      tableForm: {
        packageName: "",
        price: "",
      },
      tableForm2: {
        preview: "",
        pressOrgNo: ""
      },

      //富文本的参数
      ueContent: "",
      // 选择图书
      //表格配置
      tableOption: {
        selection: true,
        align: "center",
        operationData: {
          prop: "operation",
          label: "操作",
          operation: true,
          width: 120,
          align: "center",
        },
        column: [
          {
            prop: "indexNo",
            label: "序号",
            slot: true,
            tableProp: true,
            width: 100,
          },
          {
            prop: "book",
            label: "名称",
            tableProp: true,
            slot: true,
            width: 200,
          },
          { prop: "isbn", label: "ISBN", tableProp: true, width: 100 },
          {
            prop: "paperPrice",
            label: "捐赠价（元）",
            tableProp: true,
            slot: true,
          },
          { prop: "stock", label: "库存", tableProp: true, slot: true },
          {
            prop: "isSale",
            label: "状态",
            tableProp: true,
            sort: 'custom',
            slot: true,
            width: 100,
          },
          {
            prop: "copyCnt",
            label: "捐赠数量",
            tableProp: true,
            slot: true,
            width: 250,
          },

        ],
      },
      tableList: [],
      tableFormRules: {
        packageName: [
          { required: true, message: "请输入捐赠包名称", trigger: "blur" },
        ],
        inscriptionName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      tableFormRules2: {
        preview: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        pressOrgNo: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
      goodsCnt: 0,
      totalAmount: 0,
      row: {},
      id: '',
      returnList: {},
      returnListArr: [],
      returnList2: {},
      dialogData: {}, //弹窗需要的参数
      // 新增、修改 渠道
      // formOption: {
      //   dialogWdith: "400px",
      //   // dialogHeight: "600px",
      //   labelWidth: "80px",
      //   clearable: true,
      //   size: "medium",
      //   column: [

      //     {
      //       prop: "inscriptionName",
      //       label: "落款名称",
      //       type: "input",
      //       placeholder: "请输入",
      //       maxLength: 10,
      //       limit: true,
      //       rules: [
      //         { required: true, message: "请输入", trigger: "blur" },
      //       ],
      //     },
      //   ],
      // },
      resultList: '',
      objectMsg: [],
      schoolList: [],
      objectList: [],
      arrYear: [],
      readList: ['专科', '本科', '硕士', '博士'],
      // newList
    };
  },
  watch: {
    tableList: {
      handler: function (val, oldval) {
        let sum = 0;
        let sum2 = 0;
        this.tableList.forEach((element) => {
          // console.log(element.copyCnt)
          // sum += ;
          sum += parseInt(element.copyCnt);
          sum2 += element.price * element.copyCnt;
        });
        this.goodsCnt = sum;
        this.totalAmount = sum2.toFixed(2);
      },
      deep: true,
    },

  },
  created () {
    // console.log(this.$route.query)
    if (this.$route.query) {
      this.applyId = this.$route.query.applyId
      this.orderId = this.$route.query.orderId
      localStorage.setItem("applyId", this.$route.query.applyId)
      localStorage.setItem("orderId", this.$route.query.orderId)
    }
    else {
      if (localStorage.getItem("applyId")) {
        this.applyId = localStorage.getItem("applyId")
      } else {
        this.$router.push({
          path: "./index",
        });
      }
    }
    this.getList()
  },
  mounted () {
    this.init(this.packageId)
    this.getOptions();
    this.getTime();
    this.getObjectList()
    this.getAllYear();

    this.getSchool()
  },
  methods: {
    getAllYear () {
      var myDate = new Date();
      var thisYear = myDate.getFullYear(); // 获取当年年份
      var Section = thisYear - 1900; // 声明一个变量 获得当前年份至想获取年份差 eg.2008
      // var arrYear = []; // 声明一个空数组 把遍历出的年份添加到数组里
      for (var i = 0; i <= Section; i++) {
        this.arrYear.push(thisYear-- + '级')
      }
    },
    async getSchool () {
      let params = {};
      const { data } = await DONATION_API.getSchool(params);
      console.log(data)
      this.schoolList = data;
      let arr = {
        schoolName: "社会公益人士",
      }
      this.schoolList.push(arr)
      console.log(this.schoolList)
    },
    selectChange (val) {
      console.log(val)
      this.objectMsg = []
      this.getUseridentity(val);
    },
    // 主题详情
    async getUseridentity (val) {
      try {
        let params = {}
        params.pressOrgNo = val
        await DONATION_API.getUseridentity(params).then(res => {
          // this.tableForm = res.data
          res.data.items.forEach(otem => {
            // if (otem.identityCode !== 'LUOKUAN') {
            this.objectMsg.push({
              identityLabel: otem.identityLabel,
              identityCode: otem.identityCode
            })
            // }

          })
          // this.objectMsg.push({
          //   identityLabel: '落款名称',
          //   identityCode: 'LUOKUAN'
          // })

          console.log(this.objectMsg)

        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 获取捐赠对象列表
    async getObjectList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        let params = {
          size: 100,
          current: 1
        }
        const { data } = await DONATION_API.getObjectList(params);

        this.objectList = data.records;
        console.log(this.objectList)
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    cancelDialog () {
      // this.tableForm = {}
      this.showDialog = false;
      this.tableForm2 = {}
      this.tableForm2.pressOrgNo = ''
      this.school = ''
      this.year = ''
      this.readType = ''
      this.tableForm2.preview = ''
      this.objectMsg = []
      this.$nextTick(() => {
        this.$refs['submitForm'].resetFields();
      })

    },
    getTime () {
      var d = new Date();

      let month = d.getMonth() + 1 < 10 ? '0' + d.getMonth() + 1 : d.getMonth() + 1
      let today = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
      let data = d.getFullYear() + ' ' + month + ' ' + today
      // console.log(data)
      this.dataCount = formatDate1('', 'YYYY.MM.DD')
      console.log(this.dataCount, 'this.dataCount')
    },
    doCut () {
      var that = this;
      this.$store.commit('SET_TABLE_LOADING', true)
      html2canvas(document.querySelector('#capture'), {
        width: document.querySelector('#capture').clientWidth, //dom 原始宽度
        height: document.querySelector('#capture').clientHeight,
        // width: 1500, //dom 原始宽度
        // height: 2206,
        scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
        scrollX: 0,
        useCORS: true,
        allowTaint: true,

      }).then((canvas) => {
        const img = new Image()
        img.src = canvas.toDataURL('png')
        img.setAttribute('crossOrigin', 'anonymous')
        // canvas.width = 1500 // 自定义图片宽度
        // canvas.height = 2206 // 自定义图片宽度
        var context = canvas.getContext('2d')
        img.onload = function () {
          var w = img.width
          var h = img.height
          context.drawImage(img, 0, 0, w, h, 0, 0, w, h)
          that.imgUrl = canvas.toDataURL('image/png')
          // console.log(that.imgUrl)
          that.uploadImg(that.imgUrl)
          // that.cancleDisable = false
        }
      })
    },

    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    // 将文件传给后端服务器
    uploadImg (file) {
      var formData = new FormData();
      // let blob = this.dataURLtoFile(this.imgUrl, 'image/jpeg')
      let blob = this.dataURLtoFile(this.imgUrl, 'name')
      let fileOfBlob = new File([blob], new Date() + '.jpg')
      formData.append('file', fileOfBlob);
      formData.append('bizType', 2);
      axios({
        url: "/api/file/oss/upload",
        method: "post",
        data: formData,
        headers: {
          Authorization: "Bearer " + getStore({ name: 'userToken' }),
        },
        contentType: false,
        processData: false,
      }).then(res => {
        this.$store.commit('SET_TABLE_LOADING', false)
        console.log(res.data)
        this.resultList = res.data.data.link
      })

    },
    // // 排序修改
    sortChange (val) {
      console.log(val)
      if (val.order == "ascending") {
        this.sortKey(this.tableList, "isSale");
        // this.filters.sortSaleCnt = 1

      } else if (val.order == 'descending') {
        this.sortKey2(this.tableList, "isSale");
        // this.filters.sortSaleCnt = 0
      }
      // this.getList();
    },
    // 新增捐赠渠道
    addOrUpdate (row) {
      console.log(row)
      this.selectChange(row.pressOrgNo)
      this.showDialog = true;
      this.tableForm2.pressOrgNo = row.pressOrgNo

    },
    // 代捐详情
    async getList () {
      try {
        let params = {}
        params.applyId = this.applyId
        await DONATION_API.getDetail(params).then(res => {
          console.log(res.data)
          this.tableForm = res.data;
          let list = res.data.orderDetailsList
          list.forEach(item => {
            if (item.orderId == this.orderId) {
              console.log(item)
              this.newList = item
            }
          })
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 获取用户捐赠信息
    async getUserOrg (id, userId) {
      try {
        let params = {}
        params.orgId = id
        params.userId = userId
        const { data } = await ROLE_API.getInfoByOrgId(params)
        // console.log(data)
        this.msgList = data
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    async getOptions () {
      try {
        let params = {}
        params.appId = this.appId
        const { data } = await ROLE_API.getOrgList(params)
        // console.log(data, '111')
        data.forEach((item) => {
          // console.log(item)
          this.options.push({
            value: item,
            label: item.pressOrgName
          })
          // console.log(this.options)
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    numberChange (index, val, maxNum) {
      //转换数字类型
      let data = 0
      data = Number(val)
      //重新渲染
      // this.$nextTick(() => {
      //   if (num < 1) {
      //     data = 1
      //   } else if (num > maxNum) {
      //     data = num
      //   }
      // })
      this.tableList[index].copyCnt = data

    },

    sortKey (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortKey2 (array, key) {
      return array.sort(function (a, b) {
        var y = a[key];
        var x = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    // 初始化
    async init (packageId) {
      this.visible = true;
      if (packageId) {
        await DONATION_API.packageDetail(packageId).then((res) => {
          // console.log(res.data.packageBooks, "res");
          this.tableForm = res.data;
          let arr = [];
          res.data.packageBooks.forEach((item) => {
            var params = {};
            params.bookAuthor = item.bookInfo.bookAuthor
            params.bookId = item.bookInfo.bookId
            params.bookCover = item.bookInfo.bookCover
            params.bookName = item.bookInfo.bookName
            params.isbn = item.bookInfo.isbn
            params.totalStock = item.bookInfo.totalStock
            params.orignalPrice = item.bookInfo.orignalPrice
            params.price = item.bookInfo.price
            params.isSale = item.bookInfo.isSale

            params.copyCnt = item.cnt

            // console.log(params)
            arr.push(params)
          });
          this.tableList = arr
        });
        // const { data } = await DONATION_API.packageDetail(params);
        // console.log(data)

        // this.tableList = { ...data }.relations;
        // console.log(this.tableList);
        this.sortKey(this.tableList, "isSale");
        this.$forceUpdate
      } else {
      }
    },
    //多选回调
    selectionChange (val) {
      let that = this;
      this.selectList = val;
    },
    // 排序
    ForwardRankingDate (data, p) {
      return data.sort((a, b) => {
        let x = a[p];
        let y = b[p];
        return y - x;
      });
    },
    //获取选择图书或者导入图书去重数据排序 
    handleSelectBack (row, type = null) {
      if (this.tableList.length > 0) {
        var temp = this.tableList //一个新的临时数组
        for (var i = 0; i < row.length; i++) {
          for (var j = 0; j < temp.length; j++) {
            if (temp[j].bookId == row[i].bookId) {
              row.splice(i, 1);//重复则删除
            }
          }

        }
        row.forEach((item) => {
          this.$set(item, "copyCnt", 1);
        });
        this.tableList = this.tableList.concat(row)
      } else {
        this.tableList = [...row, ...this.tableList]
        this.tableList.forEach((item) => {
          this.$set(item, "copyCnt", 1);
        });

      }

    },
    choiceSignBack (row, type = null) {
      console.log(row)
      this.returnList = row //捐赠落款
      this.returnListArr = row.inscriptionIdentities
      console.log(this.returnListArr, 'this.returnListArr')
      let that = this;
      that.$nextTick(() => {
        that.doCut()
        // this.showImg = true;
      })
    },
    choiceImgBack (row, type = null) {
      console.log(row)
      this.returnList2 = row//寄语模板
      let that = this;
      that.$nextTick(() => {
        that.doCut()
        // this.showImg = true;
      })
    },
    // 删除已选
    delectBooks () {
      if (this.selectList.length == 0) {
        this.$message.error('请选择要删除的图书');
        return
      }
      let msg = `<span style="font-size: 13px;
    color: rgba(0, 0, 0, 0.647);font-weight:bold">确定要删除已选图书吗？</span> `
      this.$confirm(msg, '提示', {
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        this.$store.commit('SET_TABLE_LOADING', true)
        try {
          for (var i = 0; i < this.selectList.length; i++) {
            for (var j = 0; j < this.tableList.length; j++)
              if (this.selectList[i].bookId == this.tableList[j].bookId) {
                this.tableList.splice(j, 1)
              }
          }
          this.$store.commit('SET_TABLE_LOADING', false)
        } catch (err) {
          this.$store.commit('SET_TABLE_LOADING', false)
        }
      }).catch(() => {
      })
    },
    checkBooks () {
      // console.log('111')
      this.$refs.checkBook.visible = true;
      this.$refs.checkBook.init(this.tableList);
    },
    signDialog () {
      // console.log(this.returnList)
      this.$refs.choiceSign.visible = true
      this.$refs.choiceSign.init(this.tableForm, this.returnList)
    },
    imgDialog () {
      this.$refs.choiceImg.visible = true
      this.$refs.choiceImg.init(this.formData)
    },
    // 删除图书
    delBook (index) {
      this.tableList.splice(index, 1);
    },
    //设置 馆配数量
    confirmCot () {
      // console.log(this.selectList);
      if (this.selectList.length == 0) {
        this.$message("请选择需要设置的图书");
        return;
      }
      let msg = `<span style="font-size: 13px;
    color: rgba(0, 0, 0, 0.647);font-weight:bold">确定批量设置图书数量吗？</span> `;
      this.$confirm(msg, "提示", {
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          try {
            for (var i = 0; i < this.selectList.length; i++) {
              for (var j = 0; j < this.tableList.length; j++)
                if (this.selectList[i].bookId == this.tableList[j].bookId) {
                  this.tableList[j].copyCnt = this.allCnt;
                }
            }
            this.allCnt = 1;
          } catch (err) { }
        })
        .catch(() => { });
    },
    beforeClose () {
      this.$refs["submitForm"].resetFields();
      this.visible = false;
      this.tableList = [];
      this.tableForm = [];
      this.ueContent = "";
      this.allCnt = 1;
      this.$router.back()
    },
    // 上传图片
    handleNomalSuccess (res, file) {
      // console.log(res);
      // this.tableForm.packageImg = res.url
      // this.$forceUpdate

      this.$set(this.tableForm, "packageImg", res.url);
      this.$forceUpdate
    },
    beforeAvatarUpload (file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("图片只能是 JPG/JPEG/PNG/GIF 等格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    downExcel () {
      window.open(
        "https://dp-default.oss.zgepub.cn/捐赠包图书导入模板.xlsx"
        // "https://cloud-library-default.elibr.cn/馆配图书导入模板.xlsx"
      );
    },
    // 导入图书
    async importortXlsx (bookList) {
      // console.log(bookList);
      this.bookList = bookList;
      if (bookList.length > 500) {
        this.$message.error("导入数据不能超过500条");
        return;
      }
      let newList = [];
      if (bookList && bookList.length > 0) {
        bookList.map((item) => {
          newList.push(item.ISBN书号);
        });
        this.$store.commit("SET_TABLE_LOADING", true);
        // console.log(newList)
        let params = {}
        params.ids = newList
        // console.log(params)
        const { data } = await DONATION_API.uploadExcel(params);
        // console.log(data);
        this.$store.commit("SET_TABLE_LOADING", false);
        if (data.length !== 0) {
          this.$message.success("导入成功");
          data.forEach((item) => {
            this.bookList.map((otem) => {
              // console.log(otem)
              if (otem.ISBN书号 == item.isbn) {
                if (otem['拷贝数（册）'] == null) {
                  item.copyCnt = 1;
                } else {
                  item.copyCnt = otem['拷贝数（册）'];
                }
              }
            });
          });
          this.tableList = [...data, ...this.tableList];
          this.tableList = COMMON_API.uniqueFunc(this.tableList, "bookId");
        } else {
          this.$message.error("没有查询到相关书籍");
          return;
        }
      }
    },
    // ----------------------------------------------------------------
    async checkBookStatus () {
      this.tableListNode = []
      this.$store.commit('SET_TABLE_LOADING', true)
      try {
        let params = {}
        let arr = []
        this.tableList.forEach(item => {
          arr.push({
            pressBookNo: item.pressBookNo,
            cnt: item.copyCnt
          })
        })
        params.itemList = arr
        const { data } = await DONATION_API.checkBookStatu(params)
        let noStatusList = data
        console.log(noStatusList)
        let noCot = 0
        this.tableList.map((item, index) => {
          noStatusList.map(j => {
            if (item.pressBookNo == j.pressBookNo) {
              if (item.copyCnt > j.cnt) {
                this.tableListNode.push(item)
                noCot++
                this.$set(item, "totalStock", j.cnt);
                this.$set(item, "copyCnt", 0);
                if (j.cnt == 0) {
                  this.$set(item, "totalStock", 0);
                  this.$set(item, "isSale", 0);

                }
              }
            }
          })
        })
        console.log(this.tableListNode)
        this.$store.commit('SET_TABLE_LOADING', false)
        if (this.tableListNode.length > 0) {
          this.$message.error(`存在` + noCot + `本图书库存不足,请重新提交捐赠书籍`)
          return
        } else {
          this.confirmMethod();
        }
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    // 立即捐赠
    async confirmMethod () {
      let bookRankRelas = [];
      // let bookRankRelas = '';//图书编号，多个用英文逗号隔开
      for (let i = 0; i < this.tableList.length; i++) {
        bookRankRelas.push({
          bookId: this.tableList[i].bookId,
          cnt: this.tableList[i].copyCnt,
        });
      }
      console.log(bookRankRelas);
      console.log(this.returnList)
      let arrList = []
      let identList = []
      if (this.returnList.inscriptionIdentities) {
        arrList = this.returnList.inscriptionIdentities
        arrList.forEach((item) => {
          if (item.identityCode !== 'LUOKUAN') {
            let a = {}
            a.identityCode = item.identityCode
            a.identityValue = item.identityValue
            a.indexNo = item.indexNo
            identList.push(a)
          }

        })
      }


      let params = {
        orderItems: bookRankRelas, //relations
        applyId: this.applyId, //申请编号
        userId: this.tableForm.donateUserId,//用户编号
        fullInscriptionName: this.returnList.inscriptionName,//完整落款名
        donationImg: this.resultList,//捐赠图片 生成寄语模板
        pressOrgNo: this.tableForm.pressOrgNo, //发行平台受赠机构号
        identities: identList
      };
      const data = await DONATION_API.orderListAdd(params);
      if (data.code === 0) {
        this.$ok("代捐成功");
        this.$router.back()
      }
    },

    async rowSave () {
      if (!this.returnList.inscriptionName) {
        this.$message.error("请选择捐赠落款");
        return;
      }
      if (!this.returnList2.templateName) {
        this.$message.error("请选择寄语模板");
        return;
      }

      if (this.tableList.length == 0) {
        this.$message.error("请选择图书");
        return;
      }
      // 先验证图书 再代捐
      this.checkBookStatus()


    },
    // 新增
    async rowSave2 (val) {
      if (!this.tableForm2.pressOrgNo) {
        this.$message.error('请选择捐赠对象');
        return
      }
      // if (!this.tableForm2.preview) {
      //   this.$message.error('请输入落款名称');
      //   return
      // }
      let list = this.objectMsg
      let schoolStatus = false;
      let yearStatus = false;
      let educatStatus = false;
      let luokuanStatus = false;
      console.log(list)
      list.forEach((item, index) => {
        if (item.identityCode == 'SCHOOL') {
          item.identityValue = this.school
          schoolStatus = true;
        }
        if (item.identityCode == 'ENTRY_YEAR' && this.school !== '社会公益人士') {
          item.identityValue = this.year
          yearStatus = true
        }
        if (item.identityCode == 'EDUCATION' && this.school !== '社会公益人士') {
          item.identityValue = this.readType
          educatStatus = true
        }
        if (item.identityCode == 'LUOKUAN') {
          item.identityValue = this.tableForm2.preview
          luokuanStatus = true
        }
        item.indexNo = index
      })

      if (schoolStatus) {
        if (!this.school) {
          this.$message.error('请选择所属院校');
          return
        }
      }
      if (yearStatus) {
        if (!this.year) {
          this.$message.error('请选择入学年份');
          return
        }
      }
      if (educatStatus) {
        if (!this.readType) {
          this.$message.error('请选择学历');
          return
        }
      }
      if (luokuanStatus) {
        if (!this.tableForm2.preview) {
          this.$message.error('请输入落款名称');
          return
        }
      }
      // console.log(val)
      let params = {};
      params.userId = this.tableForm.donateUserId
      params.orgId = 0
      params.inscriptionName = this.tableForm2.preview
      // params.inscriptionName = val.inscriptionName
      params.identities = list
      console.log(params)
      try {
        await ROLE_API.inscriptionAdd(params).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("新增成功");
            // this.getUserOrg
            this.$refs.choiceSign.visible = true
            this.$refs.choiceSign.getUserOrg()
            // this.$refs.modal.loading = false;
            this.showDialog = false;
            this.getUserOrg(this.orgId, this.userId)
          }
        });
      } catch (err) {
        // this.$refs.modal.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.container {
  overflow-x: scroll;
  margin: auto;
  // min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 95%;
  // padding: 0 20px 0 0;
  .title {
    font-size: 16px;
    letter-spacing: normal;
    color: #333333;
    font-weight: bold;
  }
  .tab-one {
    width: 100%;
    font-family: 微软雅黑, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .tab-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: rgba(249, 249, 249, 1);
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      border-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 650;
      font-size: 16px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
      .right_title {
        margin: 0 20px 0 0;
      }
    }
    .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 20px 0px 30px 0;
      .boxForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        el-form-item {
          display: flex;
          flex-wrap: wrap;
          width: 33%;
        }
      }
    }
    .tab-content2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .box_tab_one {
        width: 100%;
        background-color: #ffffff;

        .one-title {
          position: relative;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          color: #666666;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 20px 0 0 20px;
        }
        .one-title:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 20px;
          background-color: #409eff;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          width: 95%;
          margin: 20px auto 0;
          .item {
            width: 33%;
            margin: 0 0 20px 0;
            align-items: center;
            display: flex;
            .l1 {
              color: #999999;
              font-size: 13px;
            }
            .r1 {
              max-width: 75%;
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.abox_title {
  // width: 1310px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .left_title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    height: 60px;
    .title2 {
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 10px;
    }
    .rightTitle {
      box-shadow: none;
      font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      letter-spacing: normal;
      color: #333333;
      span {
        color: #02a7f0;
      }
    }
  }
  .right_title {
    .txt2 {
      color: #02a7f0;
    }
  }
}
.ql-editor {
  min-height: 200px;
}
.table_one {
  // width: 33.33%;
  margin: auto;
  .title_one {
    width: 95%;
    margin: 0 auto 20px;
    height: 30px;
    line-height: 30px;
    border-left: 5px solid #409eff;
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7f7f7f;
    padding: 0 0 0 20px;
  }
}
.box_opration {
  display: flex;
  flex-wrap: nowrap;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 30px auto 20px !important;
  .left_opration {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .atext {
      display: flex;
      flex-wrap: wrap;
      .txt1 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
      .txt2 {
        width: 100%;
        font-size: 12px;
        color: rgb(127, 127, 127);
      }
    }
  }
  .right_opration {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .box_input {
      font-size: 12px;
      letter-spacing: normal;
      color: #333333;
      height: 100%;
      margin: 0 40px 0 0;
      .label_input {
        margin: 0 10px 0 0;
      }
    }
  }
}
.box_table {
  margin: auto;
  background-color: #ffffff;
  z-index: 10;
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // right: 0;
  display: flex;
  justify-content: flex-end;
}
.bottom_table {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}
.part_one_box {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  .bottom_left {
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
    .delectBtn {
      font-weight: 400;
      font-style: normal;
      color: #d9001b;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .bottom_right {
    text-align: right;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    .part_one {
      width: 100%;
      .txt1 {
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
      }
      .txt2 {
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
        margin: 0 0 0 80px;
      }
      .txt3 {
        font-size: 13px;
        letter-spacing: normal;
        color: #333333;
        margin: 0 0 0 80px;
      }
    }
    .part_two {
      width: 100%;
    }
  }
}
.boxItemS {
  width: 80%;
  text-align: right;
  span {
    width: 100%;
    text-align: right;
    display: inline-block;
  }
}
.box-table {
  width: 80%;
  text-align: right;
}
.updateModal {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .redTit2 {
    display: inline-block;
    width: 140px;
    text-align: right;
    padding-right: 12px;
    font-weight: bold;
  }
  .redTit {
    display: inline-block;
    width: 86px !important;
    text-align: right;
    padding-right: 13px;
    font-weight: bold;
  }
  .redTit::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .goodImgBox {
    display: flex;
    flex-wrap: nowrap;
  }
  .rightText {
    display: inline;
    height: 140px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    span.atext {
      font-size: 12px;
    }
  }
}
</style>


<style lang="scss" scoped>
.boxContent {
  width: 1200px;
  height: 1764.8px;
  // width: 1500px;
  // height: 2006px;
  // position: relative;
  margin: 10px auto 0;
  // border: 1px solid blue;
  z-index: -1;
  position: absolute;
  top: -2000px;
  left: -2000px;
}
#capture {
  z-index: 0;
  width: 1200px;
  height: 1764.8px;
  // position: relative;
  margin: auto;
  .box_titl {
    position: absolute;
    left: 24%;
    top: 27%;
    width: 1000px;
  }
  .title1 {
    font-weight: 500;
    font-size: 30px;
    color: #09172f;
    letter-spacing: 0;
  }
  .title2 {
    font-size: 26px;
    color: #09172f;
    letter-spacing: 0;
    margin: 7px 0 0 0;
    max-width: 650px;
    //
    .box_titleName {
      margin: 0 4px;
    }
    .titleName {
      font-size: 32px;
    }
  }
  .box_time {
    position: absolute;
    right: 268px;
    bottom: 693px;
    font-size: 20px;
  }
  .bgImg {
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    // position: absolute;
    // top: 0px;
    // left: 0px;
    z-index: 1;
    background-position: bottom;
    background-size: 100% auto;
  }
}
</style>


