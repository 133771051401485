<!--
 * @Description: 操作员管理
 * @Author: zjc
 * @Date: 2021-12-08 15:21:32
 * @LastEditTime: 2022-08-26 17:55:55
 * @LastEditors: ym
-->
<template>
  <basic-container>
    <el-row>
      <el-col :span="5" class="department-name">
        <div style="width: 90%; text-align: left">
          <el-input clearable style="height: 32px" placeholder="请输入部门名称" v-model="departmentName"
            class="input-with-select" @clear="clear">
            <el-button slot="append" icon="el-icon-search" @click="searchDepartment"></el-button>
          </el-input>
          <el-tree style="margin-top: 20px" :data="treeList" :props="defaultProps"
            @node-click="handleNodeClick"></el-tree>
        </div>
      </el-col>
      <el-col :span="19">
        <div class="searchForm">
          <!-- 搜索条件 -->
          <el-form :model="filters" label-width="60px" size="small" class="formData">
            <el-collapse-transition>
              <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
                @reset="reset" ref="filters">
                <template #isEnabled>
                  <el-form-item label="状态">
                    <el-select @change="changeIsEnabled" clearable v-model="filters.isEnabled"
                      placeholder="请选择">
                      <el-option v-for="item in options" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </form-item>
            </el-collapse-transition>
          </el-form>
          <form-menu :tableOption="tableOption" @refresh="reset">
            <template #menuLeft>
              <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate()">新增
              </el-button>
              <!-- <el-button type="danger" size="small">删除 </el-button> -->
              <el-button size="small" @click="importExcel">导入 </el-button>
              <el-button size="small" @click="uploadExcel" :loading="downLoading">导出
              </el-button>
            </template>
          </form-menu>
        </div>
        <!-- 表格数据 -->
        <v-table ref="table" :tableData="list" :tableOption="tableOption"
          @handleSelectionChange="selectionChange" @sortChange="sortChange">
          <template slot="roleName" slot-scope="{ row }">
            {{ row.roleList.length > 0 ? row.roleList[0].roleName : null }}
          </template>
          <template slot-scope="{ row }" slot="isEnabled">
            <p class="hot-status">
              <span class="hot" :style="{
                  background: row.isEnabled === 1 ? '#1890FF' : '#F5222D',
                }"></span>
              {{ row.isEnabled === 1 ? "正常" : "禁用" }}
            </p>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="addOrUpdate(scope.row.oprId)">编辑</el-button>
            <el-button type="text" size="small" @click="resetPassword(scope.row)">重置密码</el-button>
            <el-button type="text" size="small" style="color: red" @click="del(scope.row)">删除
            </el-button>
          </template>
        </v-table>
        <!-- 分页 -->
        <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
          @hanlePage="handleSizeChange"></pagination>
      </el-col>
    </el-row>

    <!-- 新增、编辑操作员 -->
    <create-operator ref="operator" :operatorId="operatorId" @onLoad="onLoad"
      :deptList="popUpTreeList"></create-operator>

    <!-- 重置密码 -->
    <reset-pwd ref="reset" @onLoad="onLoad"></reset-pwd>

    <!-- 导入弹窗 -->
    <el-dialog title="操作员导入" width="500px" :close-on-click-modal="false" append-to-body top="10vh"
      :visible.sync="showUser" @close="closeDialog">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="模板上传：">
          <el-upload ref="upload" accept=".xls,.xlsx" class="upload-demo" :limit="1"
            action="/api/oms/opr/fileTemplate-import" :on-change="changeUpload"
            :on-remove="changeRemove" :on-success="success" :on-error="error" :file-list="fileList"
            :headers="headers">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              请上传 .xls,.xlsx 标准格式文件
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="模板下载：">
          <a href="https://dp-default.oss.zgepub.cn/捐赠平台操作员导入模板.xlsx">点击下载</a>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button size="medium" type="primary" @click="save" :loading="loading"
          >提交</el-button
        > -->
        <el-button size="medium" @click="showUser = false">关闭</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { OPERATOR_API } from "@/service/permissions/operator";
import { DEPT_API } from "@/service/permissions/dept";
import { stateList } from "@/enums/permissions";
import createOperator from "./component/createOperator.vue";
import resetPwd from "./component/resetPwd.vue";
import { downExcel } from "@/utils/uploadExcel";
import { COMMON_API } from "@/utils/common";
import { getStore } from "@/utils/store";
export default {
  components: { createOperator, resetPwd },
  data () {
    return {
      fileList: [],
      headers: {
        Authorization: "Bearer" + getStore({ name: "userToken" }),
      },
      showUser: false,
      selectList: [],
      list: [],
      treeList: [],
      popUpTreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      downLoading: false,
      departmentName: "", //部门名称
      filters: {},
      pageData: {
        current: 1,
        size: 10,
        deptId: null,
      },
      pageTotal: 0,
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "oprId", label: "操作员编号", tableProp: true, width: 120 },
          { prop: "username", label: "用户名", tableProp: true },
          { prop: "realName", label: "姓名", tableProp: true },
          { prop: "mobile", label: "手机号", tableProp: true, width: 150 },
          { prop: "roleName", label: "角色", tableProp: true, slot: true },
          {
            prop: "isEnabled",
            label: "状态",
            slot: true,
            width: 80,
            tableProp: true,
          },
          {
            prop: "createTime",
            label: "创建时间",
            tableProp: true,
            width: 170,
          },
        ],
      },
      //搜索
      searchOption: [
        {
          prop: "username",
          label: "用户名",
          type: "input",
          placeholder: "请输入用户名",
        },
        {
          prop: "realName",
          label: "姓名",
          type: "input",
          placeholder: "请输入姓名",
        },
        {
          prop: "isEnabled",
          label: "状态",
          type: "select",
          slot: true,
          selectList: stateList,
          placeholder: "请选择状态",
        },
      ],
      options: [
        { label: "正常", value: 1 },
        { label: "禁用", value: 0 },
      ],
      operatorId: null, //操作员id 
    };
  },
  methods: {
    onLoad () {
      this.getOperator();
    },
    getOperator () {
      OPERATOR_API.getOperatorList({ ...this.filters, ...this.pageData }).then(
        (res) => {
          this.list = res.data.records;
          this.pageTotal = res.data.total;
          // console.log(res);
        }
      );
    },
    searchDepartment () {
      this.getData();
      console.log("点击事件");
    },
    handleNodeClick (val) {
      console.log(val)
      this.pageData.deptId = val.id;
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getOperator();
      console.log(val.id, "val");
    },
    clear () {
      this.getData();
    },
    getData () {
      DEPT_API.getTree({ deptName: this.departmentName }).then((res) => {
        this.treeList = res.data;
      });
    },
    getTreeList () {
      DEPT_API.getTree().then((res) => {
        this.popUpTreeList = res.data;
      });
    },
    //重置密码
    resetPassword (val) {
      const data = { oprId: val.oprId, username: val.username };
      this.$refs.reset.initData(data);
    },
    // 复选的数据
    selectionChange (val) {
      this.selectList = val;
      console.log(this.selectList, "this.selectList");
    },
    derive () {
      let dataTable = []; //   dataTable代表excel文件中的数据内容
      for (const i of this.selectList) {
        let obj = {
          操作员编号: i.oprId,
          昵称: i.username,
          真实姓名: i.realName,
          手机号: i.mobile || '',
          邮箱: i.email || "",
          角色名称: i.roleList[0] ? i.roleList[0].roleName : "",
          部门名称: i.deptName,
          状态: i.isEnabled === 1 ? "启用" : "禁用",
          创建时间: i.createTime,
        };
        dataTable.push(obj); //   设置excel每列获取的数据源
      }
      console.log(dataTable, 'dataTable');
      downExcel(dataTable, "操作员列表");
    },
    // 导入
    importExcel () {
      this.showUser = true;
    },
    changeUpload (file, fileList) {
      this.fileList = fileList;
    },
    changeRemove () {
      this.fileList.splice(0, 1);
    },
    success () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$message.success("导入成功");
      this.getOperator();
      console.log("成功");
    },
    error () {
      this.$message.error("导入失败");
      this.fileList = [];
    },
    closeDialog () {
      this.fileList = [];
    },
    getMyExcelData (data) {
      // 处理你的数据
      OPERATOR_API.importByExcel(data);
      console.log(data, "数据");
    },
    //导出excel
    async uploadExcel () {
      this.downLoading = true;
      if (this.selectList.length) {
        this.derive();
        this.downLoading = false;

      } else {
        try {
          const data = await OPERATOR_API.exportUser();
          COMMON_API.downBlob(data, "操作员列表.xls");
          this.downLoading = false;
        } catch (err) {
          this.downLoading = false;
        }
      }
      setTimeout(() => this.$refs.table.clearSelection(), 1000);
    },
    changeIsEnabled () {
      this.pageData.current = 1;
      // this.pageData.size = 10;
      this.getOperator();
    },
    changePage (val) {
      this.pageData.current = val;
      this.getOperator();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getOperator();
    },
    addOrUpdate (id = null) {
      this.operatorId = id;
      this.$nextTick(() => {
        this.$refs.operator.initData();
      });
    },
    //表格字段显隐
    redesign (val) {
      const list = this.tableOption.column.filter((item) => item.hide);
      this.tableOption.column = [...val, ...list];
      this.$refs.table.initTable(); // 表格重新布局
    },
    //重置
    reset () {
      this.departmentName = null;
      this.getData();
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.pageData.deptId = null;
      this.$nextTick(() => {
        this.onLoad();
      });
    },
    //搜索条件
    search () {
      this.pageData.current = 1;
      // this.pageData.size = 10;
      this.getOperator();
    },
    //删除
    del (row) {
      console.log(row, "row");
      this.$confirm("是否确认删除当前操作员？", "提示", {
        type: "warning",
      })
        .then(async () => {
          const { data } = await OPERATOR_API.delOperator({ oprId: row.oprId });
          this.$ok("删除成功");
          this.getOperator();
        })
        .catch(() => { });
    },
  },
  created () {
    this.getOperator();
    this.getTreeList();
    this.$nextTick(() => {
      this.getData();
    });
  },
};
</script>
<style>
.department-name .el-input__inner {
  height: 32px !important;
  line-height: 32px;
}
.department-name .el-input__suffix {
  top: -3px;
  /* transform: translateY(-47px)!important; */
}
</style>