<!--
 * @Description: 登录
 * @Author: zjc
 * @Date: 2021-12-07 14:12:30
 * @LastEditTime: 2022-08-24 13:45:26
 * @LastEditors: zjc
-->
<template>
  <div>
    <div class="login-container">
      <div class="login-left">
        <img src="@/assets/img/login/bg_icon.png" alt="">
      </div>
      <el-form ref="loginForm" :model="userLogin" :rules="loginRules"
        :class="!isMobile()?'animated bounceInDown':''" class="login-form" autocomplete="on"
        label-position="left">

        <div class="login-right">
          <div class="title-container">
            <div class="title">捐赠平台登录</div>
          </div>
          <el-form-item prop="username" class="el-form-item2">
            <span class="svg-container">
              <svg-icon icon-class="user" style="color: #9BA6BA;" />
            </span>
            <el-input ref="username" v-model="userLogin.username" name="username" clearable
              placeholder="请输入用户名" type="text" tabindex="1" autocomplete="on" />

          </el-form-item>

          <el-form-item prop="password">
            <span class="svg-container" style="color: #9BA6BA;">
              <svg-icon icon-class="password" />
            </span>
            <el-input v-model="userLogin.password" placeholder="请输入密码" name="password" tabindex="2"
              :type="passwordType" autocomplete="on" clearable
              @keyup.enter.native="dataFormSubmit" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
          <el-checkbox v-model="userLogin.rememberMe" style="margin:0px 0px 65px 0px;">记住密码
          </el-checkbox>
          <el-button class="abtn" :loading="loading" type="text" @click="dataFormSubmit">登 录
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="login-footer">
      <div class="copyright">
        正观易知有限公司©️版权所有
      </div>
    </div>
  </div>
</template>
<script>
import { encryptCode, decryptCode } from '@/utils/jsencrypt'
import { pwd } from '@/validate/el-validate'
import Cookies from "js-cookie";
export default {
  data () {
    return {
      libraryData: {
        libraryName: '厦门图书馆',
        libraryImg: require('@/assets/img/login/library_logo.png'),
      },
      passwordType: 'password',
      loading: false,
      userLogin: {
        username: null,//账号
        password: null,//密码
        rememberMe: false,
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入正确的用户名' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { required: true, trigger: 'blur', validator: pwd }
        ]
      },
    }
  },
  methods: {
    showPwd () {
      this.passwordType === 'password' ? this.passwordType = '' : this.passwordType = 'password'
    },
    dataFormSubmit () {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          if (this.userLogin.rememberMe) {
            Cookies.set("username", this.userLogin.username, { expires: 30 });
            Cookies.set("password", encryptCode(this.userLogin.password), { expires: 30 });
            Cookies.set('rememberMe', this.userLogin.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.loading = true
          try {
            const userLogin = {
              username: this.userLogin.username,
              password: encryptCode(this.userLogin.password),
              //  rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
            }
            const data = await this.$store.dispatch('loginByUsername', userLogin)
            this.$router.push('/oms/dashboard')
            this.loading = false
          } catch (err) {
            this.loading = false
          }
        }
      })

    },
    isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    //获取图书馆信息
    async getLibrary () {
      const data = await this.$store.dispatch('getLibraryInfo')
      let { libraryName, libraryImg } = data
      this.libraryData = { libraryName, libraryImg }
      if (this.$validatenull(data.libraryName)) this.libraryData.libraryName = '厦门图书馆'
      if (this.$validatenull(data.libraryImg)) this.libraryData.libraryImg = require('@/assets/img/login/library_logo.png')
      this.$store.commit('SET_LIBRARY', this.libraryData)
    },
  },
  mounted () {
    // this.getLibrary()
    if (this.isMobile()) {
      let linkMeta = document.getElementsByTagName('meta')
      if (linkMeta.length === 1) {
        let head = document.getElementsByTagName('head')[0]
        let link = document.createElement('meta')
        link.name = 'viewport'
        link.content = 'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0'
        head.appendChild(link)
      }
    }
    if (Cookies.get("rememberMe")) {
      this.userLogin = {
        username: Cookies.get("username"),
        password: decryptCode(Cookies.get("password")),
        rememberMe: true,
      }
    } else {
      this.userLogin = {
        username: null,//账号
        password: null,//密码
        rememberMe: false,
      }
    }
  },
  beforeDestroy () {
    if (this.isMobile()) {
      let link = document.getElementsByTagName('meta')
      if (link.length > 1) {
        link[1].name = 'viewport'
        link[1].content = ''
      }
    }
  }
}
</script>
<style lang="scss">
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
  }
}

/* reset element-ui css */
</style>

<style lang="scss" scoped>
.el-form-item2 {
  margin: 0 0 40px 0;
}

.login-container {
  position: relative;
  // height: calc(100vh - 120px);
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: url(../../assets/img/login/login_bg.png);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  ::v-deep .el-input {
    display: inline-block;
    height: 47px;
    width: calc(100% - 78px);

    input {
      border: none;
      -webkit-appearance: none;
      border-radius: 0px;
      height: 50px;
      line-height: 50px;
    }
  }

  ::v-deep .el-input__inner:focus,
  ::v-deep .el-input__inner:hover {
    border: none !important;
  }
  ::v-deep .el-form-item {
    border: 1px solid #e8ebf0;
    border-radius: 2px;
    color: #b8b8c3;
    margin: 0 0 40px 0;
    .el-form-item__error {
      padding: 0 0 0 18px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 10px;
      color: #64667b;
      letter-spacing: 0;
      margin: 8px 0 0 0;
    }
    .el-form-item__error::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0px;
      left: 2px;
      background: url(../../assets/img/login/upload_icon24_fail.png);
    }
  }
}
$bg: #2d3a4b;
$dark_gray: #ddd;
$light_gray: #eee;
.login-container {
  position: relative;
  min-width: 1500px;
  margin: auto;
  /* background-color: $bg; */
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  .login-left {
    min-width: 899px;
    height: 656px;
    margin-top: 50px;
  }
  .login-form {
    min-width: 550px;
    height: 494px;
    margin-left: -80px;
    // margin-top: -274px;
    border-radius: 25px;
    background: #fff;
    overflow: hidden;
    display: flex;
    .login-left {
      position: relative;
      width: 430px;
      background: linear-gradient(180deg, #3387f1 0%, #e0edfe 100%);
      height: 100%;
      display: flex;
      .login-left-title {
        margin-top: 57px;
        padding-left: 52px;
        .title {
          color: #fff;
          height: 45px;
          line-height: 45px;
          font-size: 32px;
        }
        .subTitle {
          color: #fff;
          line-height: 22px;
          height: 22px;
          font-size: 16px;
          letter-spacing: 4px;
        }
      }
      img {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 370px;
        height: 375px;
      }
    }
    .login-right {
      flex: 1;
      padding: 0px 91px 0px 91px;
      ::v-deep .el-button--text {
        width: 100%;
        height: 54px;
        background: #1890ff;
        border-radius: 6px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
      }
      .input-title {
        font-size: 14px;
        color: #09172f;
        margin-bottom: 10px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    display: flex;
    margin: auto;
    padding-top: 43px;
    align-items: center;
    margin-bottom: 68px;
    text-align: center;
    font-family: PingFangSC-S0pxibold;
    font-weight: 600;
    font-size: 24px;
    color: #09172f;
    img {
      margin-right: 16px;
    }
    .title {
      font-family: PingFangSC-S0pxibold;
      font-weight: 600;
      font-size: 24px;
      color: #09172f;
      margin: auto;
    }
  }

  .show-pwd {
    position: absolute;
    right: 20px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  // @media screen and (min-width: 768px) and (max-width: 1024px) {
  //   .login-form {
  //     position: absolute;
  //     width: 560px;
  //     height: 550px;
  //     left: 50%;
  //     top: 50%;
  //     margin-left: -280px;
  //     margin-top: -313px;
  //     border-radius: 0;
  //     background: #fff;
  //     overflow: auto;
  //     display: -ms-flexbox;
  //     display: flex;
  //     .login-left {
  //       display: none;
  //     }
  //     .login-right {
  //       flex: 1;
  //       padding: 0px 50px;
  //       border-radius: 25px;
  //     }
  //   }
  // }
  // @media only screen and (max-width: 480px) {
  //   .login-form {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     left: 0;
  //     top: 0;
  //     margin-left: 0px;
  //     margin-top: 0px;
  //     border-radius: 0;
  //     background: #fff;
  //     overflow: auto;
  //     display: -ms-flexbox;
  //     display: flex;
  //     .login-left {
  //       display: none;
  //       img {
  //         position: relative;
  //         left: 30px;
  //       }
  //     }
  //     .login-right {
  //       flex: 1;
  //       padding: 0px 48px;
  //       ::v-deep .el-button--text {
  //         width: 191px !important;
  //         height: 54px;
  //         background: linear-gradient(270deg, #3387f1 0%, #6aa9f8 100%);
  //         border-radius: 27px;
  //         color: #fff !important;
  //         font-size: 16px;
  //         outline: none;
  //         margin-top: 10px;
  //         -webkit-appearance: none;
  //       }
  //       .input-title {
  //         font-size: 14px;
  //         color: #09172f;
  //         margin-bottom: 10px;
  //       }
  //     }
  //   }
  //   .title-container {
  //     img {
  //       width: 50px;
  //       height: 50px;
  //       margin: 0 auto 20px;
  //     }
  //     .title {
  //       font-size: 24px;
  //       padding-top: 100px;
  //       font-weight: 600;
  //       margin-bottom: 6px;
  //     }
  //     .subTitle {
  //       font-size: 12px;
  //     }
  //   }
  //   .thirdparty-button {
  //     display: none;
  //   }
  //   .login-footer {
  //     display: none;
  //   }
  // }
}
.login-footer {
  position: absolute;
  left: 0%;
  bottom: 56px;
  text-align: center;
  width: 100%;
  margin-top: 34px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #09172f;
  .links {
    margin: 20px 0;
    a {
      margin: 0 21px;
      color: #64667b;
    }
  }
}
</style>
