
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="0px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #status>
              <el-form-item label="状态" label-width="100px">
                <el-select v-model="filters.status" clearable placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" size="small" @click="addOrUpdate('')">
            新增banner
          </el-button>
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption" @sortChange="sortChange">
      <template slot="bannerId" slot-scope="scope">
        <span>{{scope.row.bannerId}}</span>
      </template>
      <template slot="img" slot-scope="scope">
        <img style="width:100px;height:50px" :src="scope.row.img" alt="">
      </template>
      <template slot="status" slot-scope="scope">
        <span v-if="scope.row.status == 2">停用</span>
        <span v-else-if="scope.row.status == 1">启用</span>
      </template>
      <template slot="orgName" slot-scope="scope">
        <span v-if="scope.row.orgId == '0'">通用机构</span>
        <span v-else>{{scope.row.orgName}}</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="renewal(scope.row)">编辑</el-button>
        <el-button v-if="scope.row.status == 2" type="text" size="small" @click="cancel(scope.row)">
          启用</el-button>
        <el-button v-else-if="scope.row.status == 1" type="text" size="small"
          @click="cancel(scope.row)">停用</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
  </basic-container>
</template>
<script>
// import { Order_API } from "@/service/permissions/order";
import { BANNER_API } from "@/service/permissions/banner";
export default {
  data () {
    return {
      authOptions: [
        { label: '停用', value: 2 },
        { label: '启用', value: 1 },
      ],
      pageTotal: 0,
      pageData: {
        current: 1,
        size: 10,
      },
      filters: {
        title: '',
      },
      //搜索
      searchOption: [
        {
          prop: "title",
          label: "",
          type: "input",
          placeholder: "搜索标题",
          option: { clearable: true }
        },
        {
          prop: "status",
          label: "状态",
          col: 5,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },
      ],
      tenantList: [],
      // 表格查询条件配置
      tableOption: {
        selection: false,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'bannerId', label: '序号', slot: true, tableProp: true, },
          { prop: 'title', label: '标题', tableProp: true },
          { prop: 'img', label: '图片', slot: true, tableProp: true },
          { prop: 'jumpUrl', label: '链接地址', tableProp: true, },
          { prop: 'indexNo', label: '排序', tableProp: true },
          { prop: 'status', label: '状态', slot: true, tableProp: true, },
          { prop: 'createTime', label: '新增时间', tableProp: true, },
        ],
      },
      list: [{}],

    };
  },
  created () {
    this.getData();
  },
  methods: {
    addOrUpdate () {
      this.$router.push({
        path: "./bannerAdd",
        // query: { appId: row.appId, appName: row.appName }
      });
    },
    async getData () {
      // let params = this.filters;
      await BANNER_API.getList({
        ...this.pageData,
        ...this.filters
      }).then((res) => {
        console.log(res)
        this.list = res.data.records
        this.pageTotal = res.data.total;
      });
    },
    // 取消该订单
    cancel (item) {
      console.log(item)
      let msg = '';
      let itemStatus = 1
      if (item.status == 1) {
        msg = '停用'
        itemStatus = 2
      } else {
        msg = '启用'
      }
      let params = {}
      params.bannerId = item.bannerId,
        params.status = itemStatus
      this.$confirm(`是否` + msg + `该banner？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BANNER_API.changeBanner(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success("更改成功");
            // this.$ok("已取消");
            // this.filters = {};
            this.getData();
          }
        });
      })
        .catch(() => {
          //
        });
    },

    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    // 查看
    renewal (row) {
      console.log(row)
      this.$router.push({
        path: "./bannerAdd",
        query: { bannerId: row.bannerId }
      });
    },
    search () {
      this.pageData.current = 1;
      // this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.filters.title = '';
      this.filters.status = '';
      this.$nextTick(() => {
        this.getData();
      });
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>