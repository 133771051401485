<!--
 * @Description: 密码策略
 * @Author: zjc
 * @Date: 2022-07-07 14:40:19
 * @LastEditTime: 2022-07-08 11:35:25
 * @LastEditors: zjc
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #tenantId>
              <el-form-item label="租户" prop="tenantId">
                <el-select @change="search" v-model="filters.tenantId" clearable placeholder="请选择">
                  <el-option v-for="item in tenantOptions" :key="item.tenantId"
                    :label="item.tenantName" :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @redesign="redesign" @resetChange="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="edit('','create')">新增
          </el-button>
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption">
      <template slot="enablePassword" slot-scope="{row}">
        <el-tag effect="plain" :type="row.enablePassword===1?'':'danger'" size="small">
          {{ row.enablePassword===1?'开启':'关闭' }}
        </el-tag>
      </template>
      <template slot="enableRandomPassword" slot-scope="{row}">
        <el-tag effect="plain" :type="row.enableRandomPassword===1?'':'danger'" size="small">
          {{ row.enableRandomPassword===1?'开启':'关闭' }}
        </el-tag>
      </template>
      <template slot="enableSecurity" slot-scope="{row}">
        <el-tag effect="plain" :type="row.enableSecurity===1?'':'danger'" size="small">
          {{ row.enableSecurity===1?'开启':'关闭' }}
        </el-tag>
      </template>
      <template slot="enableCaptcha" slot-scope="{row}">
        <el-tag effect="plain" :type="row.enableCaptcha===1?'':'danger'" size="small">
          {{ row.enableCaptcha===1?'是':'否' }}
        </el-tag>
      </template>
      <template slot="enableLock" slot-scope="{row}">
        <el-tag effect="plain" :type="row.enableLock===1?'':'danger'" size="small">
          {{ row.enableLock===1?'是':'否' }}
        </el-tag>
      </template>
      <template slot="operation" slot-scope="{row}">
        <el-button type="text" size="small" @click="edit(row)">编辑</el-button>
        <el-button type="text" size="small" @click="del(row)">删除</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 密码策略新增、修改 -->
    <pwd-modal v-if="showPwd" :open.sync="showPwd" :tenantOptions="tenantOptions" :pwdData="pwdData"
      @onLoad="onLoad"></pwd-modal>
  </basic-container>
</template>
<script>
import { PWD_API } from '@/service/permissions/passwordPolicy'
import { AUTHORIZATION_API } from "@/service/permissions/authorization";
import pwdModal from './component/pwdModal.vue'
import { COMMON_API } from '@/utils/common';
export default {
  components: { pwdModal },
  data () {
    return {
      filters: {},//过滤参数
      tenantOptions: [],//租户列表
      pageTotal: 0,//总条数
      showPwd: false,//是否显示密码策略
      pwdData: {},//编辑数据
      list: [],//列表数据
      // 表格查询条件配置
      tableOption: {
        align: 'center',
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: 'tenantName', label: '租户', tableProp: true },
          { prop: 'policyName', label: '策略名称', tableProp: true, },
          { prop: 'clientId', label: '终端编号', tableProp: true, },
          { prop: 'enablePassword', label: '密码安全策略', tableProp: true, slot: true },
          { prop: 'originalPassword', label: '初始密码', tableProp: true, },
          { prop: 'enableRandomPassword', label: '随机密码模式', tableProp: true, slot: true },
          { prop: 'enableSecurity', label: '登录安全策略', tableProp: true, slot: true },
          { prop: 'enableCaptcha', label: '是否启用验证码', tableProp: true, slot: true },
          { prop: 'enableLock', label: '是否锁定', tableProp: true, slot: true },
        ],
      },
      //搜索
      searchOption: [
        { prop: 'tenantId', label: '租户', type: 'select', slot: true },
        { prop: 'clientId', label: '终端编号', type: 'input', placeholder: '请输入终端编号' },
      ],
    }
  },
  methods: {
    changePage (val) {
      this.pageData.current = val
      this.onLoad()
    },
    handleSizeChange (val) {
      this.pageData.current = 1
      this.pageData.size = val
      this.onLoad()
    },
    //表格字段显隐
    redesign (val) {
      const list = this.tableOption.column.filter((item) => item.hide)
      this.tableOption.column = [...val, ...list]
      this.$refs.table.initTable() // 表格重新布局
    },
    //重置
    reset () {
      this.$refs.filters.initForm()
      this.filters.startTime = null
      this.filters.endTime = null
      this.createTime = []
      this.pageData.size = 10
      this.pageData.current = 1
      this.$nextTick(() => {
        this.onLoad()
      })
    },
    //搜索条件
    search () {
      this.pageData.current = 1
      this.onLoad()
    },
    // 获取列表
    async onLoad () {
      console.log(this.pageData, '2')
      this.$store.commit('SET_TABLE_LOADING', true)
      try {
        const { data } = await PWD_API.policyList({ ...this.pageData, ...this.filters })
        this.list = data.records
        this.pageTotal = data.total
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }
    },
    //获取租户列表
    async getList () {
      const { data } = await AUTHORIZATION_API.getTenant()
      this.tenantOptions = data
    },
    del (row = null) {
      this.$confirm(`确定删除密码策略`, '提示', {
        type: 'warning'
      }).then(async () => {
        const { data } = await PWD_API.delPolicy({ tenantId: row.tenantId, clientId: row.clientId })
        if (this.list.length - 1 == 0) this.pageData.current = this.pageData.current > 1 ? this.pageData.current - 1 : 1
        this.$ok('删除成功')
        this.onLoad()
      }).catch(() => {
      })
    },
    //新增、编辑
    edit (row) {
      this.pwdData = COMMON_API.deepClone(row)
      this.showPwd = true
    },
  },
  created () {
    this.getList()
    this.onLoad()
  },
}
</script>
<style lang="scss" scoped>
</style>
