/*
 * @Description: 过滤掉请求参数为空
 * @Author: zjc
 * @Date: 2021-09-13 09:04:58
 * @LastEditTime: 2021-10-14 18:01:54
 * @LastEditors: zjc
 */

const getEmptyObject = (obj) => {
  for (const i in obj) {
    let value = obj[i];
    const type = typeof value;
    // 去除空格
    if (type === 'string') {
      obj[i] = trim(value);
    }
    if (type === 'object') {
      // 是否引用数据类型
      if (Array.isArray(value)) {
        if (value.length === 0) {
          delete obj[i];
          continue;
        }
      }
      getEmptyObject(value);
      if (isEmpty(value)) {
        delete obj[i];
      }
    } else {
      // 基本数据类型
      if (['', null, undefined, 'undefined', 'null'].includes(value)) {
        delete obj[i];
      }
    }
  }
  return obj;
}
const trim = (str) => { // 删除左右两端的空格
  return str.replace(/(^\s*)|(\s*$)/g, '');
}

// 判断对象是否为空
const isEmpty = (obj) => {
  for (let name in obj) {
    return false;
  }
  return true;
}
export const EMPTY_API = {
  getEmptyObject,//清除请求参数为空的属性
}