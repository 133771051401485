/*
 */
import request from '@/utils/request'
const getOrderList = (params) => request('post', '/order/order/page', params); // 订单列表 
// const getOrderDetail = (param) => request('get', '/order/order/info/id/${param}', param,) //订单详情
const getOrderDetail = (param) => request('get', `/order/order/info/id/${param}`) //获取部门详情
const cancelOrder = (params) => request('post', '/order/order/cancel', params); // 取消订单 
const getIndexCount = (param) => request('get', `/order/order/index/count`) //获取部门详情
const replaceBook = (params) => request('post', '/order/order/replace', params); // 
const reorderList = (params) => request('post', '/order/order/reorder', params); // 重新下单
export const Order_API = {
  getOrderList,
  getOrderDetail,
  cancelOrder,
  getIndexCount, replaceBook, reorderList
} 
