/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-05-10 16:24:18
 * @LastEditTime: 2022-05-10 16:26:08
 * @LastEditors: zjc
 */
import ExportJsonExcel from "js-export-excel";
const downExcel = (dataTable, name) => {
  let option = {}; //   option代表的就是excel文件
  option.fileName = name; //excel文件名
  //excel文件数据
  let headerList = Object.keys(dataTable[0]);
  option.datas = [
    {
      //   excel文件的数据源
      sheetData: dataTable,
      //   excel文件sheet的表名
      sheetName: "sheet",
      //   excel文件表头名
      sheetHeader: headerList,
      //   excel文件列名
      sheetFilter: headerList,
    },
  ];
  //   创建ExportJsonExcel实例对象
  let toExcel = new ExportJsonExcel(option);
  //   调用保存方法
  toExcel.saveExcel();
}
export {
  downExcel
}