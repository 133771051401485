<!--
 * @Author: 租户管理
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-08-29 10:11:28
 * @FilePath: \cloud-library-platform-ui\src\views\lessee\apply\applyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #status>
              <el-form-item label="租户状态" label-width="90px">
                <el-select clearable v-model="filters.status" placeholder="请选择"
                  @change="changeStatus">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate(1)">新增
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="batchDel(1)"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot="accountLimit" slot-scope="scope">
        {{ scope.row.accountLimit === -1 ? "不限" : scope.row.accountLimit }}
      </template>

      <template slot="status" slot-scope="scope">
        <el-tag size="small" v-if="scope.row.status === 1">正常</el-tag>
        <el-tag size="small" v-else type="danger">{{
          scope.row.status === 2
            ? "禁用"
            : scope.row.status === 3
            ? "待审核"
            : scope.row.status === 4
            ? "待初始化"
            : "拒绝"
        }}</el-tag>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdate(2, scope.row)">编辑</el-button>
        <el-button type="text" size="small" style="color: red" @click="batchDel(2, scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
    <!-- 新增租户 -->
    <el-dialog @closed="closed" :title="title" :visible.sync="dialogVisible" width="40%"
      :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="租户名称：" prop="tenantName">
          <el-input v-model="ruleForm.tenantName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="租户简称：" prop="shortName" v-if="type === 1">
          <el-input v-model="ruleForm.shortName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="账户额度：" prop="accountLimit">
          <el-input v-model="ruleForm.accountLimit" placeholder="请输入"></el-input>
          <p style="font-size: 12px; color: #929292">-1代表不限</p>
        </el-form-item>
        <el-form-item label="租户类型：" prop="libraryType" v-if="type !== 2">
          <el-select v-model="ruleForm.libraryType" clearable placeholder="请选择">
            <el-option v-for="item in optionsType" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="到期时间：" prop="expirationDate">
          <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd"
            v-model="ruleForm.expirationDate" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="租户状态：" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="租户域名：" v-if="type !== 2">
          <el-radio-group v-model="ruleForm.type" @change="changeRadio">
            <el-radio :label="0">系统生成</el-radio>
            <el-radio :label="1">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="domainInput" v-if="ruleForm.type === 0">
          <div>
            https://
            <el-input style="width: 190px; margin-right: 10px" v-model="ruleForm.domainInput"
              placeholder="请输入" @input="systemInput(ruleForm.domainInput)"></el-input>.elibr.cn
            （主域名，用于PC端访问）
          </div>
          <div style="font-size: 12px; color: #929292">
            支持 4~20 位的字母数字组合，需以字母开头。
          </div>
          <div v-if="
              ruleForm.domainInput.length && ruleForm.domainInput.length > 3
            " style="color: #929292">
            https://lms.{{ ruleForm.domainInput }}.elibr.cn（管理后台）
          </div>
          <div v-if="
              ruleForm.domainInput.length && ruleForm.domainInput.length > 3
            " style="color: #929292">
            https://api.{{ ruleForm.domainInput }}.elibr.cn（接口请求）
          </div>
        </el-form-item>
        <el-form-item prop="customInput" v-else>
          <div>
            <el-input style="width: 240px; margin-right: 10px" v-model="ruleForm.customInput"
              placeholder="请输入" @input="customInputBtn(ruleForm.customInput)"></el-input>
            （主域名，用于PC端访问）
          </div>
          <div style="font-size: 12px; color: #929292">
            以http或https开头的完整域名，结尾不能有"/"
          </div>
          <div v-if="
              ruleForm.customInput.length && ruleForm.customInput.length > 7
            " style="color: #929292">
            <!-- https://lms.{{ ruleForm.customInput }}.elibr.cn（管理后台） -->
            {{ ruleForm.adminDomain }}（管理后台）
          </div>
          <div v-if="
              ruleForm.customInput.length && ruleForm.customInput.length > 7
            " style="color: #929292">
            <!-- https://api.{{ ruleForm.customInput }}.elibr.cn（接口请求） -->
            {{ ruleForm.apiDomain }}（接口请求）
          </div>
        </el-form-item>

        <el-form-item label="域名备案信息：" prop="icpInfo">
          <el-input :disabled="ruleForm.type === 0" v-model="ruleForm.icpInfo" placeholder="请输入">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('ruleForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import { LESSEE_API } from "@/service/permissions/lessee";
let paramsData = {
  tenantId: null,
  tenantName: null, //租户名称
  shortName: null, //租户简称
  status: 2, //租户状态
  domain: null, //域名
  domainInput: "", //输入框展示的域名
  customInput: "", //自定义输入框展示的域名
  apiDomain: null, //api域名
  adminDomain: null, //后台管理域名
  icpInfo: "闽ICP备 135332222-1号", //备案信息
  expirationDate: null, //到期日
  accountLimit: null, //账户额度
  libraryType: null, //租户类型
  type: 0, //域名类别
};
export default {
  data () {
    const validDomain = (rule, value, callback) => {
      let tel = /^[a-zA-Z][a-zA-Z0-9]{3,19}$/;
      if (!value.trim()) {
        callback(new Error("域名不能为空！"));
      } else {
        if (!tel.test(value)) {
          callback(new Error("主域名格式错误!"));
        } else {
          callback();
        }
      }
    };
    const validDomains = (rule, value, callback) => {
      let tel = /(http|https):\/\/([\w.]+\/?)\S*/;
      if (!value.trim()) {
        callback(new Error("域名不能为空！"));
      } else {
        if (!tel.test(value)) {
          callback(new Error("主域名格式错误!"));
        } else {
          // callback();

          if (value.charAt(value.length - 1) === "/") {
            callback(new Error("主域名格式错误!"));
          } else {
            callback();
          }
        }
      }
    };
    return {
      loading: false,
      dialogVisible: false,
      type: null,
      pageTotal: 0,
      ruleForm: Object.assign({}, paramsData),
      rules: {
        tenantName: [
          { required: true, message: "请输入租户名称", trigger: "blur" },
          { max: 30, message: "租户名称最多30个字符", trigger: "blur" },
        ],
        shortName: [
          { required: true, message: "请输入租户简称", trigger: "blur" },
          { max: 10, message: "租户简称最多10个字符", trigger: "blur" },
        ],
        accountLimit: [
          { required: true, message: "请输入账户额度", trigger: "blur" },
        ],
        libraryType: [
          { required: true, message: "请选择租户类型", trigger: "change" },
        ],
        expirationDate: [
          { required: true, message: "请选择到期时间", trigger: "change" },
        ],
        icpInfo: [
          { required: true, message: "请输入域名备案信息", trigger: "blur" },
        ],
        domainInput: [{ validator: validDomain, trigger: "blur" }],
        customInput: [{ validator: validDomains, trigger: "blur" }],
      },
      title: "新增租户",
      pageData: {
        current: 1,
        size: 10,
      },
      filters: {},
      options: [
        { label: "正常", value: 1 },
        { label: "禁用", value: 2 },
        { label: "待审核", value: 3 },
        { label: "待初始化", value: 4 },
        { label: "拒绝", value: 5 },
      ],
      optionsType: [
        { label: "公共图书馆", value: 1 },
        { label: "学校图书馆", value: 2 },
        { label: "物业图书馆", value: 3 },
        { label: "政协图书馆", value: 4 },
        { label: "出版发行图书馆", value: 5 },
        { label: "党建图书馆", value: 6 },
      ],
      //搜索
      searchOption: [
        {
          prop: "tenantName",
          label: "租户名称",
          type: "input",
          placeholder: "请输入",
        },
        {
          prop: "domain",
          label: "租户域名",
          type: "input",
          placeholder: "请输入",
        },
        {
          prop: "status",
          label: "租户状态",
          type: "select",
          slot: true,
          selectList: [
            { label: "正常", value: 1 },
            { label: "禁用", value: 2 },
            { label: "待审核", value: 3 },
            { label: "待初始化", value: 4 },
            { label: "拒绝", value: 5 },
          ],
          placeholder: "请选择",
        },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          {
            prop: "tenantName",
            label: "租户名称",
            tableProp: true,
            width: 120,
          },
          { prop: "tenantId", label: "租户编号", tableProp: true },
          { prop: "domain", label: "租户域名", tableProp: true },
          {
            prop: "accountLimit",
            label: "账户额度",
            slot: true,
            tableProp: true,
            width: 150,
          },
          {
            prop: "expirationDate",
            label: "到期时间",
            tableProp: true,
          },
          {
            prop: "status",
            label: "租户状态",
            slot: true,
            width: 80,
            tableProp: true,
          },
        ],
      },
      list: [],
    };
  },
  created () {
    this.getData();
  },
  methods: {
    //系统域名输入
    systemInput (val) {
      this.ruleForm.domain =
        "https://" + this.ruleForm.domainInput + ".elibr.cn";
      this.ruleForm.apiDomain =
        "https://api." + this.ruleForm.domainInput + ".elibr.cn";
      this.ruleForm.adminDomain =
        "https://lms." + this.ruleForm.domainInput + ".elibr.cn";
    },
    // 自定义域名输入
    customInputBtn (val) {
      if (val.slice(0, 5) === "https" || val.slice(0, 5) === "HTTPS") {
        console.log("自定义api域名为https");
        if (val.slice(8, 11) === "www" || val.slice(8, 11) === "WWW") {
          this.ruleForm.apiDomain =
            val.slice(0, 8) + "api" + val.slice(11, 100);
          this.ruleForm.adminDomain =
            val.slice(0, 8) + "lms" + val.slice(11, 100);
          console.log(this.ruleForm.apiDomain.slice(8, 11), "WWWWWWWW");
        } else {
          this.ruleForm.apiDomain =
            val.slice(0, 8) + "api." + val.slice(8, 100);
          this.ruleForm.adminDomain =
            val.slice(0, 8) + "lms." + val.slice(8, 100);
        }
      } else if (val.slice(0, 5) === "http:" || val.slice(0, 5) === "HTTP:") {
        console.log("自定义api域名为http");
        if (val.slice(7, 10) === "www" || val.slice(7, 10) === "WWW") {
          this.ruleForm.apiDomain =
            val.slice(0, 7) + "api" + val.slice(10, 100);
          this.ruleForm.adminDomain =
            val.slice(0, 7) + "lms" + val.slice(10, 100);
        } else {
          this.ruleForm.apiDomain =
            val.slice(0, 7) + "api." + val.slice(7, 100);
          this.ruleForm.adminDomain =
            val.slice(0, 7) + "lms." + val.slice(7, 100);
        }
      }
      this.ruleForm.domain = val;

      // console.log(val.slice(0, 5), "截取后的地址");
      // console.log(val, "val");
    },
    // 确认提交
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.expirationDate.length === 10) {
            this.ruleForm.expirationDate =
              this.ruleForm.expirationDate + " 00:00:00";
          }
          this.loading = true;
          if (this.type === 1) {
            LESSEE_API.getTenantAdd(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("操作成功");
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            LESSEE_API.getTenantUpdate(this.ruleForm)
              .then((res) => {
                this.dialogVisible = false;
                this.$ok("操作成功");
                this.loading = false;
                this.getData();
              })
              .catch(() => {
                this.loading = false;
              });
          }
          //
        } else {
          return false;
        }
      });
    },
    changeRadio () {
      if (this.ruleForm.type === 0) {
        this.ruleForm.icpInfo = "闽ICP备 135332222-1号";
      } else {
        this.ruleForm.icpInfo = "";
      }
      this.ruleForm.domain = "";
      this.ruleForm.apiDomain = "";
      this.ruleForm.adminDomain = "";
      this.$refs.ruleForm.clearValidate(["domainInput"]);
      this.$refs.ruleForm.clearValidate(["icpInfo"]);
      this.ruleForm.domainInput = "";
      this.$refs.ruleForm.clearValidate(["customInput"]);
      this.ruleForm.customInput = "";
    },
    changeStatus () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();

      console.log(this.filters.status, "改变了");
    },
    getData () {
      LESSEE_API.getTenant({ ...this.pageData, ...this.filters }).then(
        (res) => {
          this.list = res.data.records;
          this.pageTotal = res.data.total;
        }
      );
    },
    addOrUpdate (type, item) {
      this.type = type;
      if (type === 1) {
        this.title = "新增租户";
        //
      } else {
        // this.ruleForm = Object.assign({}, item);
        console.log(item, "item");
        this.ruleForm.type = 1;
        this.ruleForm.tenantId = item.tenantId;
        this.ruleForm.customInput = item.domain;
        this.ruleForm.tenantName = item.tenantName;
        this.ruleForm.status = item.status;
        this.ruleForm.domain = item.domain;
        this.ruleForm.apiDomain = item.apiDomain;
        this.ruleForm.adminDomain = item.adminDomain;
        this.ruleForm.expirationDate = item.expirationDate;
        this.ruleForm.accountLimit = item.accountLimit;
        this.ruleForm.icpInfo = item.icpInfo;
        this.title = "编辑租户";

        //
      }
      this.dialogVisible = true;
    },
    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    batchDel (type, item) {
      this.$confirm(
        `是否确认删除名称为“${item.tenantName}”的数据项？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          LESSEE_API.getTenantDelete({ tenantId: item.tenantId }).then(() => {
            this.$ok("删除成功");
            this.getData();
          });
        })
        .catch(() => {
          //
        });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    closed () {
      this.ruleForm.type = 0;
      this.ruleForm = Object.assign({}, paramsData);
      this.$refs.ruleForm.resetFields();
      this.ruleForm.domain = "";
      this.ruleForm.apiDomain = "";
      this.ruleForm.adminDomain = "";
    },
  },
};
</script>
<style lang="scss" scoped>
</style>