
<template>
  <basic-container>
    <div class="searchForm">
      <el-form :model="filters" size="small" label-width="0" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #roleName>
              <el-form-item label="" label-width="0">
                <el-input placeholder="请输入内容" v-model="filters.search" class="input-with-select">
                  <el-select style="width:100px" v-model="filters.type" slot="prepend"
                    placeholder="请选择">
                    <el-option style="width:100px" v-for="item in searchType" :key="item.value"
                      :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <!-- <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate('')">新增渠道
          </el-button>
        </template> -->
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot-scope="{ row }" slot="oprStatus">
        <el-tag :type="row.oprStatus === 1 ? '' : 'danger'">{{
          row.oprStatus === 1 ? "正常" : "禁用"
        }}</el-tag>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="openPermissions(scope.row)">查看
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 新增、编辑渠道 -->
    <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item>

  </basic-container>
</template>
<script> 
import { ROLE_API } from "@/service/permissions/user";
export default {
  data () {
    return {
      searchType: [
        { 'label': 'ID', value: 0 },
        { 'label': '手机号', value: 1 },
      ],
      filters: { type: 0 },
      pageTotal: 0,
      list: [],
      // 表格查询条件配置
      tableOption: {
        align: "center",
        selection: true,
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "userId", label: "ID", tableProp: true },
          { prop: "mobile", label: "手机号", tableProp: true },
          { prop: "createTime", label: "注册时间", tableProp: true },
          { prop: "lastLoginTime", label: "最后登录时间", tableProp: true },
        ],
      },
      //搜索
      searchOption: [
        // {
        //   prop: "roleName",
        //   label: "创建时间",
        //   type: "roleName",
        //   placeholder: "搜索ID、手机号",
        //   tableProp: true
        // },
        {
          prop: 'roleName',
          label: '创建时间',
          col: 8,
          type: 'roleName',
          slot: true,
          placeholder: '请选择',
        },

      ],
      roleId: null, //操作员id
      showPermissions: false, //是否显示权限
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      // 新增、修改 配置
      formOption: {
        dialogWdith: "600px",
        labelWidth: "80px",
        clearable: true,
        size: "medium",
        column: [
          {
            prop: "roleCode",
            label: "渠道名称",
            type: "input",
            placeholder: "请输入",
            rules: [
              { required: true, message: "请输入", trigger: "blur" },
            ],
          },
          {
            prop: "roleName",
            label: "联系人",
            type: "input",
            placeholder: "请输入",
            // rules: [
            //   { required: true, message: "请输入渠道名称", trigger: "blur" },
            // ],
          },
          {
            prop: "remark",
            label: "联系电话",
            type: "input",
            placeholder: "请输入",
          },
        ],
      },
    };
  },
  watch: {
    filters: {
      deep: true,
      handler (val) {
        if (val.type == 0) {
          this.filters.userId = this.filters.search
          this.filters.mobile = ''
        } else if (val.type == 1) {
          this.filters.mobile = this.filters.search
          this.filters.userId = ''
        }
        console.log(this.filters)
        // this.onLoad();
      }
    },

  },
  created () {
    // this.filters.type = 1
  },
  methods: {
    // 获取列表
    async onLoad () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { mobile, userId } = this.filters;
        this.form = { mobile, userId };
        const { data } = await ROLE_API.getUserList({
          ...this.pageData,
          ...this.form,
        });
        this.list = data.records;
        this.pageTotal = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    changePage (val) {
      this.pageData.current = val;
      this.onLoad();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.onLoad();
    },
    addOrUpdate (row = {}) {
      this.dialogData = {
        id: row.roleId,
        title: row ? "修改渠道" : "新增渠道",
        showDialog: null,
      };
      for (const i of this.formOption.column) {
        if (i.prop === "roleCode" && !this.$validatenull(row))
          i.isDisabled = true;
        else i.isDisabled = false;
      }
      if (!this.$validatenull(row)) {
        const { roleId, roleName, roleCode, remark } = row;
        this.form = { roleId, roleName, roleCode, remark };
      }
      this.dialogData.showDialog = true;
    },
    // 
    openPermissions (row) {
      this.$router.push({
        path: "./userDetail",
        query: { userId: row.userId, mobile: row.mobile, nickName: row.nickName }
      });
    },
    //表格字段显隐
    redesign (val) {
      const list = this.tableOption.column.filter((item) => item.hide);
      this.tableOption.column = [...val, ...list];
      this.$refs.table.initTable(); // 表格重新布局
    },
    //重置
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.$nextTick(() => {
        this.onLoad();
      });
    },
    //搜索条件
    search () {
      this.pageData.current = 1;
      this.onLoad();
    },

    //删除
    del (row) {
      this.$confirm(`是否确认删除名称为“${row.roleName}”的数据项？`, "提示", {
        type: "warning",
      })
        .then(async () => {
          const { data } = await ROLE_API.delRole({ roleId: row.roleId });
          this.$ok("删除成功");
          this.onLoad();
        })
        .catch(() => { });
    },
    //新增渠道
    async rowSave (val) {
      try {
        const { data } = await ROLE_API.addRole(val);
        this.$ok("新增成功");
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
        this.onLoad();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
    //修改渠道
    async rowUpdate (val) {
      try {
        const { data } = await ROLE_API.updateRole(val);
        console.log(data, "2");
        this.$ok("修改成功");
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
        this.onLoad();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
  },
  mounted () {
    this.onLoad();
  },
};
</script>