
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件  -->
      <el-form :model="filters" label-width="80px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #searchType>
              <el-form-item label="" label-width="0">
                <div class="box_choose">
                  <el-select class="typeSelect" v-model="filters.searchType" clearable
                    placeholder="请选择">
                    <el-option v-for="item in searchTypeList" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <div v-if="filters.searchType == 2 || filters.searchType == 3">
                    <el-select v-if="filters.searchType == 2" v-model="chooseResult1" clearable
                      placeholder="请选择">
                      <el-option v-for="item in objectList" :key="item.orgId" :label="item.orgName"
                        :value="item.orgId">
                      </el-option>
                    </el-select>
                    <!-- <el-select v-if="filters.searchType == 3" v-model="chooseResult2" clearable
                      placeholder="请选择">
                      <el-option v-for="item in channelList" :key="item.orgId" :label="item.orgName"
                        :value="item.orgId">
                      </el-option>
                    </el-select> -->
                      <!-- <el-cascader  v-if="filters.searchType == 3" popper-class="selectArea" filterable
                     :options="channelList" v-model="chooseResult2"
                     :props="{ multiple: false, checkStrictly: false }" clearable></el-cascader> -->
                     <press-org v-if="filters.searchType == 3" v-model="chooseResult2" category="2"></press-org>

                  </div>
                  <!-- 手机号 -->
                  <el-input v-else-if="filters.searchType == 4" placeholder="请输入"
                    v-model="inputResult2" class="input-with-select">
                  </el-input>
                  <!-- 订单号 -->
                  <el-input v-else placeholder="请输入" v-model="inputResult"
                    class="input-with-select">
                  </el-input>

                </div>
              </el-form-item>
            </template>
            <!-- <template>
              <el-form-item label="" label-width="0">
                <el-select style="width:130px" v-model="filters.searchType" slot="prepend"
                  placeholder="请选择">
                  <el-option style="width:130px" v-for="item in searchTypeList" :key="item.value"
                    :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template> -->
            <template #orderStatus>
              <el-form-item label="状态" label-width="100px">
                <el-select v-model="filters.orderStatus" clearable placeholder="请选择">
                  <el-option v-for="item in authOptions" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
             <!-- <template #createTime>
              <el-form-item label="捐赠时间" label-width="80px">
                <el-date-picker
                  v-model="createTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
          </template> -->
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
      </form-menu>
    </div>
     <!-- <el-button size="small" class="filter-item"
        @click="exportEvent()" :loading="loading"><span>导出数据</span>
    </el-button> -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption" @sortChange="sortChange">
      <template slot="orderStatus" slot-scope="scope">
        <span v-if="scope.row.orderStatus == 1">待支付</span>
        <span v-else-if="scope.row.orderStatus == 2">处理中</span>
        <span v-else-if="scope.row.orderStatus == 3">已完成</span>
        <span v-else-if="scope.row.orderStatus == 4">处理中</span>
        <span v-else-if="scope.row.orderStatus == 5">已取消</span>
      </template>
      <template slot="orgName" slot-scope="scope">
        <span v-if="scope.row.orgId == '0'">通用机构</span>
        <span v-else>{{scope.row.orgName}}</span>
      </template>

      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="renewal(scope.row)">查看</el-button>
        <el-button v-show="scope.row.orderStatus == 1 " type="text" size="small"
          @click="cancel(scope.row)">取消</el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
  </basic-container>
</template>
<script>
import { Order_API } from "@/service/permissions/order";
import { ROLE_API } from "@/service/permissions/channel";
import { DONATION_API } from "@/service/permissions/donation";
import pressOrg from '@/components/orgList/pressOrg.vue'

export default {
  components:{
       pressOrg
  },
  data () {
    return {
      loading: false,
      createTime: [],
      chooseResult1: '',
      chooseResult2: '',
      inputResult: '',
      inputResult2: '',
      // searchType: { 'label': '订单号', value: 1 },
      searchTypeList: [
        { 'label': '订单号', value: 1 },
        // { 'label': '捐赠对象', value: 2 },
        { 'label': '捐赠机构', value: 3 },
        { 'label': '捐赠人手机号', value: 4 },
      ],
      authOptions: [
        { label: '待支付', value: 1 },
        { label: '处理中', value: 2 },
        { label: '已完成', value: 3 },
        // { label: '捐赠失败', value: 4 }, 
        { label: '已取消', value: 5 },
      ],

      pageTotal: 0,

      pageData: {
        current: 1,
        size: 10,
      },
      filters: {
        searchType: 1,
        size: 10,
        current: 1,
      },
      //搜索
      searchOption: [
        {
          prop: 'searchType',
          label: '创建时间',
          col: 7,
          type: 'roleName',
          slot: true,
          placeholder: '请选择',
        },
        {
          prop: "orderStatus",
          label: "应用名称",
          col: 4,
          slot: true,
          type: "select",
          placeholder: "请输入",
        },
        //  {
        //   prop: 'createTime',
        //   label: '捐赠时间',
        //   slot: true,
        //   type: "select",
        //   placeholder: '请选择',
        //   col: 7,
        // }
      ],
      tenantList: [],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: "center",
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 100,
          fixed: "right",
          align: "left",
        },
        column: [
          { prop: 'orderId', label: '订单号', tableProp: true, },
          // { prop: 'doneeName', label: '捐赠对象', tableProp: true },
          { prop: 'orgName', label: '捐赠机构', slot: true, tableProp: true },
          { prop: 'mobile', label: '捐赠人手机号', tableProp: true, },
          { prop: 'goodsCnt', label: '复本数（册）', tableProp: true },
          { prop: 'totalAmount', label: '原价（元）', tableProp: true, },
          { prop: 'actualAmount', label: '应付金额（元）', width:150, tableProp: true, },
          { prop: 'orderStatus', label: '状态', slot: true, tableProp: true, },
          { prop: 'createTime', label: '下单时间', tableProp: true, },
        ],
      },
      list: [],
      objectList: [],//捐赠对象列表
      channelList: [],
      itemType: -1,

    };
  },
  // { 'label': '订单号', value: 0 },
  //     { 'label': '捐赠对象', value: 1 },
  //     { 'label': '捐赠人手机号', value: 2 },
  //     { 'label': '捐赠机构', value: 3 },
  watch: {
    // 'filters.searchType': function () {
    //   // console.log('1111')
    //   // this.filters.content = ''
    // },
    // 'filters.searchType': {
    //   deep: true,
    //   handler (val) {
    //     console.log(val)
    //     if (val == 1) {
    //       // this.filters.content = this.chooseResult1
    //     } else if (val == 2) {
    //       // this.filters.content = this.chooseResult2
    //     }
    //     // console.log(this.filters.content)
    //     // 
    //     // this.getData();
    //   }
    // },

  },
  created () {
    this.getData();
    this.getObjectList();
    // this.getaChannelList();
    this.filters.searchType = 1
  },
  methods: {
    exportEvent() {
      //  this.loading = true
    },
    // 获取捐赠机构列表
    async getaChannelList () {
      try {
        let params = {
          size: 100,
          current: 1
        }
        await ROLE_API.getChannelList(params).then(res => {
          // console.log(res.data.records)
          // this.channelList = res.data.records;
          res.data.records.forEach((item) => {
          this.channelList.push({
            value: item.orgId,
            label: item.orgName
          })
        });
          this.channelList.unshift({
            label: '通用机构',
            value: 0
          })
        });
        this.$store.commit('SET_TABLE_LOADING', false)
      } catch (err) {
        this.$store.commit('SET_TABLE_LOADING', false)
      }

    },
    // 获取捐赠对象列表
    async getObjectList () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        let params = {
          size: 100,
          current: 1
        }
        const { data } = await DONATION_API.getObjectList(params);
        // console.log(data)
        this.objectList = data.records;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    async getData () {
      // let params = this.filters;
        // if(this.createTime.length) {
      //    this.filters.startTime = this.createTime[0]
      //    this.filters.endTime = this.createTime[1]
      // } else {
      //    this.filters.startTime = ''
      //    this.filters.endTime = ''
      // }
      await Order_API.getOrderList({
        ...this.pageData,
        ...this.filters
      }).then((res) => {
        // console.log(res)
        this.list = res.data.records
        this.pageTotal = res.data.total;
      });
    },
    // 取消该订单
    cancel (item) {
      this.$confirm("是否取消该订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Order_API.cancelOrder({
            orderId: parseInt(item.orderId),
          }).then((res) => {
            console.log(res)
            if (res.code == 0) {
              this.$message.success("取消成功");
              // this.$ok("已取消");
              this.getData();
            }

          });
        })
        .catch(() => {
          //
        });
    },


    // 查看
    renewal (row) {
      console.log(row)
      this.$router.push({
        path: "./orderDetail",
        query: { orderId: row.orderId }
      });
    },




    search () {
      this.pageData.current = 1;
      if (this.filters.searchType == 1) {
        this.filters.content = this.inputResult
      } else if (this.filters.searchType == 2) {
        this.filters.content = this.chooseResult1
      } else if (this.filters.searchType == 3) {
        // this.filters.content = this.chooseResult2.join(',')
        this.filters.content = this.chooseResult2
      } else if (this.filters.searchType == 4) {
        this.filters.content = this.inputResult2
      }
      // this.pageData.size = 10;
      console.log(this.filters)
      this.getData();
    },

    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.chooseResult1 = ''
      this.chooseResult2 = ''
      this.inputResult = ''
      this.inputResult2 = ''
      // this.$refs.filters.initForm();
      this.filters = {}
      this.$nextTick(() => {
        this.getData();
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },

    // changePage (val) {
    //   this.pageData.current = val;
    //   this.getData();
    // },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },


  },
};
</script>
<style lang="scss" scoped>
.box_choose {
  display: flex;
  flex-wrap: nowrap;
  .typeSelect {
    border-right: none;
  }
}
</style>