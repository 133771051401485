<!--
 * @Author: 租户管理
 * @Date: 2022-05-24 10:26:41
 * @LastEditors: zjc
 * @LastEditTime: 2022-09-02 09:08:48
 * @FilePath: \cloud-library-platform-ui\src\views\version\manage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" label-width="100px" size="small" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
            <template #appType>
              <el-form-item label="应用名称：" prop="appType">
                <el-select v-model="filters.appType" placeholder="请选择">
                  <el-option v-for="item in menu" :key="item.tenantId" :label="item.libraryName"
                    :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" @click="dialogVisible = true" size="small">
            新增
          </el-button>
          <el-button type="danger" icon="el-icon-delete" :disabled='!selection.length'
            @click="()=>handleDelete()" size="small">删除
          </el-button>
        </template>
      </form-menu>
    </div>
    <v-table ref="table" :tableData="list" @handleSelectionChange='handleSelectionChange'
      :tableOption="tableOption">
      <template slot="operation" slot-scope="scope">
        <el-button type="text" @click="()=>handleUpdate(scope.row)" size="small">编辑</el-button>
        <el-button type="text" size="small" @click="()=>handleDelete(scope.row)" style="color: red">
          删除</el-button>
      </template>
      <template slot="appType" slot-scope="{row}">
        <div>
          {{ row.appType&&menu.find(i=>i.tenantId === row.appType).libraryName }}
        </div>
      </template>
      <template slot="versionName" slot-scope="{ row }">
        <div>
          {{ row.versionName }}
        </div>
      </template>
      <template slot="channelType" slot-scope="{ row }">
        <div>
          {{ (row.channelType !== null)&&channelTypeList.find(i=>i.value === row.channelType).name }}
        </div>
      </template>
      <template slot="upgradeType" slot-scope="{ row }">
        <div>
          {{ row.upgradeType === 1?"推荐升级":"强制升级" }}
        </div>
      </template>
      <template slot="enableStatus" slot-scope="{ row }">
        <el-tag v-if="row.enableStatus">{{ 
                        row.enableStatus === 2 ? "启用":"待启用"
                        }}</el-tag>

      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>
    <!-- 新增版本 -->
    <el-dialog @closed="closed" :title="title" :visible.sync="dialogVisible" width="40%"
      :close-on-click-modal="false" append-to-body>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="128px"
        class="demo-ruleForm">
        <el-form-item label="策略名称：" prop="strategyName">
          <el-input v-model="ruleForm.strategyName" maxlength="100" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="升级方式：" prop="upgradeType">
          <el-radio-group v-model="ruleForm.upgradeType">
            <el-radio :label="1">推荐升级</el-radio>
            <el-radio :label="2">强制升级</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="应用名称："
                              prop="appType">
                    <el-select v-model="ruleForm.appType"
                               @change="()=>getDataVersion()"
                               placeholder="请选择">
                        <el-option v-for="item in menu"
                                   :key="item.tenantId"
                                   :label="item.libraryName"
                                   :value="item.tenantId">
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item label="应用版本：" prop="versionName">
          <el-select v-model="ruleForm.versionId" remote filterable :loading='versionListLoading'
            reserve-keyword :remote-method="getDataVersion" @change="changeDialogAppType"
            placeholder="请输入关键词">
            <el-option v-for="item in versionList" :key="item.index"
              :label="(menu.find(i=>i.tenantId === item.appType).libraryName ) + item.versionName"
              :value="item.versionId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="升级渠道：" prop="channelType">
          <el-select v-model="ruleForm.channelType" placeholder="请选择">
            <el-option v-for="i in channelTypeList" :key="i.index" :label="i.name" :value="i.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用状态：" prop="enableStatus">
          <el-radio-group v-model="ruleForm.enableStatus">
            <el-radio :label="1">待启用</el-radio>
            <el-radio :label="2">启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="定时推送：" prop="isPush">
          <el-radio-group v-model="ruleForm.isPush">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="推送时间：" prop="pushTime">
          <el-date-picker type="datetime" :default-value='defaultTime' :disabled='disablePlustime'
            :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
            popper-class="no-atTheMoment" v-model="ruleForm.pushTime" placeholder="请输入">
          </el-date-picker>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>

  </basic-container>
</template>
<script>
export default {
  data () {
    return {
      /**
       * list main
       */
      selection: [],
      pageData: {
        current: 1,
        size: 10,
      },
      //搜索
      filters: {},
      menu: [
        { tenantId: 1, libraryName: '邻里书香' },
        { tenantId: 2, libraryName: '新华云书馆' },
        { tenantId: 3, libraryName: '学校图书馆' },
        { tenantId: 4, libraryName: '党建图书馆' },
        { tenantId: 5, libraryName: '云图书馆' },
      ],
      channelTypeList: [],
      versionListLoading: false,
      versionList: [],
      searchOption: [
        {
          prop: 'strategyName',
          label: '策略名称',
          type: 'input',
          maxlength: '100',
          placeholder: '请输入',
        },
        {
          prop: 'appType',
          label: '应用名称',
          type: 'select',
          slot: true,
          placeholder: '请选择',
        },
        {
          prop: 'upgradeType',
          label: '升级方式',
          type: 'select',
          selectList: [
            {
              value: '1',
              label: '推荐升级',
            },
            {
              value: '2',
              label: '强制升级',
            },
          ],
          placeholder: '请选择',
        },
      ],
      // 表格查询条件配置
      tableOption: {
        selection: true,
        align: 'center',
        sort: true,
        operationData: {
          prop: 'operation',
          label: '操作',
          slot: true,
          operation: true,
          width: 200,
          fixed: 'right',
        },
        column: [
          { prop: 'strategyName', label: '策略名称', tableProp: true },
          { prop: 'appType', label: '应用名称', tableProp: true, slot: true },
          { prop: 'versionName', label: '版本名称', tableProp: true, slot: true },
          { prop: 'upgradeType', label: '升级方式', tableProp: true, slot: true },
          { prop: 'channelType', label: '升级渠道', tableProp: true, slot: true },
          { prop: 'enableStatus', label: '启用状态', tableProp: true, slot: true },
        ],
      },
      list: [],
      pageTotal: 0,
      /**
       * 表单统一内容
       */
      title: '新增升级策略',
      dialogVisible: false,
      ruleForm: {
        strategyName: '',
        upgradeType: '',
        appType: '',
        versionName: '',
        channelType: '',
        enableStatus: '',
        isPush: '',
        pushTime: '',
        versionId: '',
      },
      rules: {
        strategyName: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now();
        },
      },
      disablePlustime: false,
    };
  },
  computed: {
    defaultTime () {
      const t = Date.now() + (24 * 60 * 60 * 1000)
      return new Date(t)
    }
  },
  watch: {
    "ruleForm.isPush" (n, o) {
      if (n !== o) {
        this.handlePushTime(n)
      }
    }
  },
  created () {
    this.getData();
    this.$fetch('get', '/oms/sysAppVersion/channelType', {}).then((res) => {
      this.channelTypeList = res.data || [];
    });
    this.getDataVersion();
  },
  methods: {
    /**
     * main list
     */
    handleSelectionChange (value) {
      this.selection = value;
    },
    getData () {
      this.$fetch(
        'post',
        '/oms//appUpgradeStrategy/appUpgradeStrategyPage',
        {
          ...this.pageData,
          ...this.filters,
        },
        {
          requestType: 'application/json',
        }
      ).then((res) => {
        this.list = res.data.records;
        this.pageTotal = res.data.total;
      });
    },

    search () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.getData();
    },
    reset () {
      this.pageData.current = 1;
      this.pageData.size = 10;
      this.$refs.filters.initForm();
      this.$nextTick(() => {
        this.getData();
      });
    },
    changePage (val) {
      this.pageData.current = val;
      this.getData();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.getData();
    },
    /**
     * 新增
     */
    handleUpdate (row) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
      this.title = '编辑';
    },
    closed () {
      this.dialogVisible = false;
      // this.$refs.ruleForm.resetFields();
      Object.keys(this.ruleForm).forEach((k) => {
        this.ruleForm[k] = '';
      });
      this.title = '新增升级策略';
      this.getDataVersion();
      this.disablePlustime = false
    },
    handleDelete (row) {
      const arr = row ? [row.strategyId] : this.selection.map((i) => i.strategyId);
      this.$confirm('确定删除？', '提示', {
        type: 'warning',
      }).then(() => {
        this.$fetch(
          'post',
          '/oms/appUpgradeStrategy/removeBatchByIds',
          {
            ids: arr,
          },
          {
            requestType: 'application/json',
            // reastData:true
          }
        ).then((res) => {
          this.$message.success(res.msg);
          this.getData();
        });
      });
    },
    save () {
      const url =
        this.title === '编辑'
          ? '/oms/appUpgradeStrategy/updateAppVersion'
          : '/oms/appUpgradeStrategy/addAppVersion';

      this.$refs.ruleForm.validate().then((res) => {
        this.$fetch(
          'post',
          url,
          {
            ...this.ruleForm,
            isAllChannel: this.ruleForm.channelType === 0 ? 1 : 0,
          },
          {
            requestType: 'application/json',
          }
        ).then(
          (res) => {
            this.loading = false;
            this.closed('ruleForm');
            this.$message.success(res.msg);
            this.getData();
          },
          () => {
            this.loading = false;
          }
        );
      });
    },
    changeDialogAppType (e) {
      const item = this.versionList.find((i) => i.versionId === e);
      if (item) {
        this.ruleForm.appType = item.appType;
      }
    },
    handlePushTime (n) {
      if (n === 0) {
        this.ruleForm.pushTime = '';
        this.disablePlustime = true;
      } else {
        this.disablePlustime = false;

      }
    },
    getDataVersion (e) {
      this.ruleForm.versionId = '';
      this.versionListLoading = true;
      this.$fetch(
        'post',
        '/oms/sysAppVersion/appVersionPage',
        {
          size: 100,
          current: 1,
          versionName: e,
          // appType: this.ruleForm.appType,
        },
        {
          requestType: 'application/json',
        }
      ).then(
        (res) => {
          this.versionList = res.data.records;
          this.versionListLoading = false;
        },
        () => {
          this.versionListLoading = false;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-date-editor {
  width: 100%;
}
</style>
