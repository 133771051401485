/*
 * @Description:全局组件
 * @Author: zjc
 * @Date: 2021-12-07 13:46:19
 * @LastEditTime: 2022-04-19 19:12:01
 * @LastEditors: zjc
 */
import Vue from 'vue'
import vtable from '@/components/CRUD/v-table'
import formMenu from '@/components/CRUD/formMenu'
import formItem from '@/components/CRUD/formItem'
import modalItem from '@/components/CRUD/modalItem'  //只满足没有逻辑的新增、修改
import pagination from '@/components/pagination/index'
import basicContainer from '@/components/container/index'
import operationTips from '@/components/operationTips/index'
import myMixin from '@/mixins' //全局混入
Vue.component('v-table', vtable)
Vue.component('pagination', pagination)
Vue.component('basic-container', basicContainer)
Vue.component('form-menu', formMenu)
Vue.component('form-item', formItem)
Vue.component('modal-item', modalItem)
Vue.component('operation-tips', operationTips)
Vue.mixin(myMixin)