<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-17 09:26:46
 * @LastEditTime: 2022-07-05 14:52:16
 * @LastEditors: zjc
-->
<template>
  <basic-container>
    <div class="searchForm">
      <!-- 搜索条件 -->
      <el-form :model="filters" size="small" label-width="80px" class="formData">
        <el-collapse-transition>
          <form-item :option="searchOption" :filters.sync="filters" @searchChange="search"
            @reset="reset" ref="filters">
          </form-item>
        </el-collapse-transition>
      </el-form>
      <form-menu :tableOption="tableOption" @refresh="reset">
        <template #menuLeft>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addOrUpdate('')">新增
          </el-button>
          <!-- <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="batchDel()"
            >删除
          </el-button> -->
        </template>
      </form-menu>
    </div>
    <!-- 表格数据 -->
    <v-table ref="table" :tableData="list" :tableOption="tableOption"
      @handleSelectionChange="selectionChange" @sortChange="sortChange">
      <template slot-scope="{ row }" slot="oprStatus">
        <el-tag :type="row.oprStatus === 1 ? '' : 'danger'">{{
          row.oprStatus === 1 ? "正常" : "禁用"
        }}</el-tag>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="small" @click="openPermissions(scope.row)">权限
        </el-button>
        <el-button type="text" size="small" @click="addOrUpdate(scope.row)">
          编辑</el-button>
        <el-button type="text" size="small" style="color: red" @click="del(scope.row)">删除
        </el-button>
      </template>
    </v-table>
    <!-- 分页 -->
    <pagination :pageData="pageData" :total="pageTotal" @hanleSize="changePage"
      @hanlePage="handleSizeChange"></pagination>

    <!-- 新增、编辑角色 -->
    <modal-item ref="modal" v-if="dialogData.showDialog" :dialogData="dialogData" :formData="form"
      :option="formOption" @row-save="rowSave" @row-update="rowUpdate"></modal-item>

    <!-- 权限 -->
    <permissions-modal v-if="showPermissions" :open.sync="showPermissions" :id="roleId">
    </permissions-modal>
  </basic-container>
</template>
<script>
import { ROLE_API } from "@/service/permissions/role";
import permissionsModal from "./component/permissionsModal";
import { COMMON_API } from "@/utils/common";
export default {
  components: { permissionsModal },
  data () {
    return {
      filters: {},
      pageTotal: 0,
      list: [],
      // 表格查询条件配置
      tableOption: {
        align: "center",
        selection: true,
        sort: true,
        operationData: {
          prop: "operation",
          label: "操作",
          slot: true,
          operation: true,
          width: 200,
          fixed: "right",
        },
        column: [
          { prop: "roleName", label: "角色名称", tableProp: true },
          { prop: "roleCode", label: "角色编码", tableProp: true },
          { prop: "remark", label: "角色描述", tableProp: true },
          {
            prop: "createTime",
            label: "创建时间",
            tableProp: true,
            width: 170,
          },
        ],
      },
      //搜索
      searchOption: [
        {
          prop: "roleName",
          label: "角色名称",
          type: "input",
          placeholder: "请输入",
        },
        {
          prop: "roleCode",
          label: "角色编码",
          type: "input",
          placeholder: "请输入",
        },
      ],
      roleId: null, //操作员id
      showPermissions: false, //是否显示权限
      form: {}, //新增修改数据
      dialogData: {}, //弹窗需要的参数
      // 新增、修改 配置
      formOption: {
        dialogWdith: "600px",
        labelWidth: "80px",
        clearable: true,
        size: "medium",
        column: [
          {
            prop: "roleCode",
            label: "角色编码",
            type: "input",
            placeholder: "请输入角色编码",
            rules: [
              { required: true, message: "请输入角色编码", trigger: "blur" },
            ],
          },
          {
            prop: "roleName",
            label: "角色名称",
            type: "input",
            placeholder: "请输入角色名称",
            rules: [
              { required: true, message: "请输入角色名称", trigger: "blur" },
            ],
          },
          {
            prop: "remark",
            label: "备注",
            type: "input",
            inputType: "textarea",
            rows: 3,
            placeholder: "请输入备注",
          },
        ],
      },
    };
  },
  methods: {
    changePage (val) {
      this.pageData.current = val;
      this.onLoad();
    },
    handleSizeChange (val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.onLoad();
    },
    addOrUpdate (row = {}) {
      this.dialogData = {
        id: row.roleId,
        title: row ? "修改角色" : "新增角色",
        showDialog: null,
      };
      for (const i of this.formOption.column) {
        if (i.prop === "roleCode" && !this.$validatenull(row))
          i.isDisabled = true;
        else i.isDisabled = false;
      }
      if (!this.$validatenull(row)) {
        const { roleId, roleName, roleCode, remark } = row;
        this.form = { roleId, roleName, roleCode, remark };
      }
      this.dialogData.showDialog = true;
    },
    // 打开权限
    openPermissions (row) {
      this.showPermissions = true;
      this.roleId = row.roleId;
    },
    //表格字段显隐
    redesign (val) {
      const list = this.tableOption.column.filter((item) => item.hide);
      this.tableOption.column = [...val, ...list];
      this.$refs.table.initTable(); // 表格重新布局
    },
    //重置
    reset () {
      this.$refs.filters.initForm();
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.$nextTick(() => {
        this.onLoad();
      });
    },
    //搜索条件
    search () {
      this.pageData.current = 1;
      this.onLoad();
    },
    // 获取列表
    async onLoad () {
      this.$store.commit("SET_TABLE_LOADING", true);
      try {
        const { data } = await ROLE_API.getRoleList({
          ...this.pageData,
          ...this.filters,
        });
        this.list = data.records;
        this.pageTotal = data.total;
        this.$store.commit("SET_TABLE_LOADING", false);
      } catch (err) {
        this.$store.commit("SET_TABLE_LOADING", false);
      }
    },
    //删除
    del (row) {
      this.$confirm(`是否确认删除名称为“${row.roleName}”的数据项？`, "提示", {
        type: "warning",
      })
        .then(async () => {
          const { data } = await ROLE_API.delRole({ roleId: row.roleId });
          this.$ok("删除成功");
          this.onLoad();
        })
        .catch(() => { });
    },
    //新增角色
    async rowSave (val) {
      try {
        const { data } = await ROLE_API.addRole(val);
        this.$ok("新增成功");
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
        this.onLoad();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
    //修改角色
    async rowUpdate (val) {
      try {
        const { data } = await ROLE_API.updateRole(val);
        console.log(data, "2");
        this.$ok("修改成功");
        this.$refs.modal.loading = false;
        this.dialogData.showDialog = false;
        this.onLoad();
      } catch (err) {
        this.$refs.modal.loading = false;
      }
    },
  },
  mounted () {
    this.onLoad();
  },
};
</script>