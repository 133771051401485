<template>
  <el-dialog
    title="捐赠寄语"
    modal-append-to-body
    append-to-body
    :visible.sync="dialogVisible"
    width="445px"
    :before-close="handleClose"
  >
    <img :src="imgUrl" style="height: 552px; width: 375px" alt="" />
  </el-dialog>
</template>
<script>
export default {
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
  },
};
</script>