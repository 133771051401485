/*
 * @Description: 权限配置
 * @Author: zjc
 * @Date: 2021-12-27 10:56:30
 * @LastEditTime: 2022-05-24 09:42:21
 * @LastEditors: ym ym@geeboo.cn
 */
import request from '@/utils/request'
const getTree = (param) => request('get', '/oms/auth/tree', param ) //获取权限配置树
const getAuthDetail = (param) => request('get', `/oms/auth/${param}`) // 获取权限配置详情
const addPermission = (param) => request('post', '/oms/auth/save', param, { requestType: 'JSON' }) //新增权限配置
const updatePermission = (param) => request('post', '/oms/auth/update', param, { requestType: 'JSON' }) //修改权限配置
const delPermission = (param) => request('post', `/oms/auth/delete?authId=${param.authId}`, param) //删除权限配置
export const PERMISSION_API = {
  getTree,
  getAuthDetail,
  addPermission,
  updatePermission,
  delPermission
}