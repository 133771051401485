<!--
 * @Description: 权限
 * @Author: zjc
 * @Date: 2021-12-17 09:27:33
 * @LastEditTime: 2022-07-05 14:52:13
 * @LastEditors: zjc
-->
<template>
  <div>
    <el-dialog title="角色权限配置" :visible.sync="showPermissions" append-to-body width="950px"
      :close-on-click-modal="false" @close="$emit('update:open', false)" top="5vh">
      <!-- 角色权限树开始 -->
      <el-row style="padding: 0px 10px" v-loading="dialogLoading" element-loading-text="加载中">
        <el-tree class="treeStyle filter-tree" empty-text="" :props="defaultProps" :data="treeData"
          node-key="id" show-checkbox @check="changeRole" :default-expand-all="expand"
          ref="treeList" :default-checked-keys="checkedRoleIds">
        </el-tree>
      </el-row>
      <el-row> </el-row>
      <!-- 角色权限树结束 -->
      <!-- 底部按钮开始 -->
      <span slot="footer" class="dialog-footer">
        <span v-if="treeData.length" style="margin-right: 10px">
          <el-button size="medium" type="success" @click="checkAll('all')">全选</el-button>
          <el-button size="medium" type="primary" @click="checkAll('reset')">取消全选</el-button>
          <el-button size="medium" type="warning" @click="expandAll(true)">展开所有节点</el-button>
          <el-button size="medium" type="danger" @click="expandAll(false)">收起所有节点</el-button>
          <el-button size="medium" type="primary" :loading="loading" @click="saveRole">提交
          </el-button>
        </span>
        <el-button size="medium" @click="$emit('update:open', false)">取消</el-button>
      </span>
      <!-- 底部按钮结束 -->
    </el-dialog>
  </div>
</template>

<script>
import { ROLE_API } from "@/service/permissions/role";
import { COMMON_API } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  props: {
    open: Boolean,
    id: Number,
  },
  computed: {
    ...mapGetters(["name", "roleId"]),
  },
  data () {
    return {
      //资源树列表
      expand: false,
      treeData: [],
      checkedRoleIds: [], //获取选中id
      defaultProps: {
        children: "children",
        label: "name",
      },
      showPermissions: false, //是否显示dialog
      //新增、修改需要的数据
      data: {
        roleId: "", //角色id
        authIds: [], //资源树id
      },
      dialogLoading: false,
      loading: false,
    };
  },
  methods: {
    //全选、取消全选节点
    checkAll (type) {
      if (type === "reset") this.$refs.treeList.setCheckedKeys([]);
      else {
        this.$refs.treeList.setCheckedNodes(this.treeData);
        // 获取所有被选中的子节点
        const getTreeId = this.$refs.treeList.getCheckedNodes(true, true);
        this.data.authIds = getTreeId.map((item) => {
          return {
            applicationId: item.applicationId,
            resourceId: item.id,
            isPublic: item.isPublic,
          };
        });
      }
    },
    // 改变节点的状态
    changeTreeNodeStatus (node) {
      node.expanded = this.expand;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.expandAll;
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeTreeNodeStatus(node.childNodes[i]);
        }
      }
    },
    // 展开收起节点
    expandAll (bool) {
      this.expand = bool;
      this.changeTreeNodeStatus(this.$refs.treeList.store.root);
    },
    //checkList触发
    changeRole () {
      this.data.authIds = [];
      const getTreeId = this.$refs.treeList.getCheckedNodes(false, true);
      console.log(getTreeId, "数据");
      this.data.authIds = getTreeId.map((item) => {
        return item.id;
        // return {
        //   applicationId: item.applicationId,
        //   resourceId: item.id,
        //   isPublic: item.isPublic,
        // };
      });
    },
    //保存用户
    async saveRole () {
      try {
        this.changeRole(); //提交前获取选中的权限
        if (!this.data.authIds.length) {
          this.$message.error("请勾选角色权限");
          return;
        }
        this.loading = true;
        let obj = this.data.authIds.toString();
        console.log(obj, "this.data.authIds");
        const { data } = await ROLE_API.updatePermissions({
          roleId: this.id,
          authIds: obj,
        });
        //判断修改的角色是否跟当前登录的一致，是的话重新获取菜单
        // if (this.id === this.roleId) {
        await this.$store.dispatch("getInfo", { parentId: 0, type: "left" });
        // }
        this.$ok("保存成功");
        this.loading = false;
        this.dialogLoading = false;
        this.$emit("update:open", false);
      } catch (err) {
        this.loading = false;
      }
    },
    //根据角色获取资源树
    async getTreeDetail (list) {
      this.dialogLoading = true;
      const checkedRoleIds = [];
      try {
        const { data } = await ROLE_API.getPermissions1({ roleId: this.id });
        // this.checkedRoleIds = data
        const tree = COMMON_API.treeToList(list);
        console.log(tree, 'tree');
        const treeList = this.getLastTree(tree);
        console.log(treeList, 'treeList');
        for (const i of treeList) {
          for (const j of data) {
            if (i.id === j) {
              checkedRoleIds.push(j);
            }
          }
        }
        this.checkedRoleIds = checkedRoleIds;
        console.log(this.checkedRoleIds, 'this.checkedRoleIds');
        this.dialogLoading = false;
      } catch (err) {
        this.dialogLoading = false;
      }
    },
    async getTreeList () {
      const { data } = await ROLE_API.getPermissions();
      console.log(data, "data");
      this.treeData = data;
      this.getTreeDetail(this.treeData);
    },
    // 获取最后一级节点
    getLastTree (arr) {
      const list = arr.filter((item) => {
        return "children" in item === false;
      });
      return list;
    },
  },
  mounted () {
    this.showPermissions = this.open;
    this.getTreeList();
  },
};
</script>
<style lang="scss" scoped>
.treeStyle {
  background: #fff;
  padding: 10px;
  max-height: 800px;
  overflow: auto;
  ::v-deep .el-tree-node__content {
    margin: 3px 0px;
  }
}
.empty-text {
  text-align: center;
  justify-content: center;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  div {
    color: #7a7a7a;
    padding-top: 10px;
  }
}
</style>
