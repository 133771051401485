<template>
  <div class="upload-container" :style="{top:buttonStyle}">
    <el-tooltip class="item" effect="dark" content="上传视频" placement="bottom" :hide-after="800">
      <el-button :style="buttonStyle" icon="el-icon-picture-outline" size="mini" type="primary"
        @click="showDialog">上传视频</el-button>
    </el-tooltip>
    <el-dialog title="上传视频" append-to-body width="700px" :visible.sync="dialogVisible" left>
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="120px"
        style="margin: 0 30px">
        <el-form-item label="视频" prop="insertionImage">
          <el-upload class="avatar-uploader delM" action="/api/file/oss/upload" accept=".mp4"
            :show-file-list="false" multiple :headers="headers" name="file"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <div style="position: relative;">
              <div class="videoClassHover" v-if="delShow" @click.stop="handleRemove">
                <i class="el-icon-delete avatar-uploader-icon" style="color:#fff"></i>
              </div>
              <video v-if="ruleForm.insertionImage" :src="ruleForm.insertionImage"
                style="width: 148px; height: 148px" title="点击删除" class="avatar "
                @mouseover="delShow = true" @mouseout="delShow = false"
                @click.stop="handleRemove" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>

          </el-upload>
          <div class="el-upload__tip upload-box-tip">
            只能上传mp4格式视频，图片不能超过20M
          </div>
        </el-form-item>
        <div v-if="type">
          <el-form-item label="视频封面" prop="videoImage">
            <el-upload class="avatar-uploader delM" action="/api/file/oss/upload"
              accept=".jpg,.jpeg,.png,.gif" :show-file-list="false" multiple :headers="headers"
              name="file" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1">
              <div style="position: relative">
                <div class="videoClassHover" v-if="delImg" @click.stop="handleRemove1">
                  <i class="el-icon-delete avatar-uploader-icon" style="color:#fff"></i>
                </div>
                <img v-if="ruleForm.videoImage" :src="ruleForm.videoImage"
                  @mouseover="delImg = true" @mouseout="delImg = false" @click.stop="handleRemove1"
                  style="width: 148px; height: 148px" title="点击上传图片" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
            <div class="el-upload__tip upload-box-tip">只能上传JPG/JPEG/PNG/GIF格式图片，图片不能超过2M</div>
          </el-form-item>
          <el-form-item label="视频宽度" prop="width">
            <el-input-number v-model="ruleForm.width" :min="1" :max="100" label="请输入视频宽度">
            </el-input-number>
            <div>只能输入1-100 单位：%</div>
          </el-form-item>
          <el-form-item label="视频高度" prop="height">
            <el-input-number v-model="ruleForm.height" :min="100" :max="1000" label="请输入视频高度">
            </el-input-number>
            <div>只能输入100-1000 单位：px</div>
          </el-form-item>
        </div>
      </el-form>
      <div class="btn-box">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleSubmit" :loading="loading">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStore } from '@/utils/store'
export default {
  name: "UeditorInsertionVideo",
  props: {
    buttonStyle: {
      type: String,
      default: () => ({})
    },
    type: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      insertionImageNew: "",
      delShow: false,
      delImg: false,
      headers: {
        Authorization: "Bearer " + getStore({ name: 'userToken' }),
      },
      fileInfo: {
        fileOriginalName: "",
        fileServerName: "",
        fileUrl: "",
      },
      ruleForm: {
        insertionImage: "",
        videoImage: '',//视频封面
        width: 100,//尺寸比例
        height: null,
      },
      rules: {
        insertionImage: [
          { required: true, message: "必填！", trigger: "blur" },
        ],
        videoImage: [
          { required: true, message: '必填！', trigger: 'blur' }
        ],
        width: [
          { required: true, message: '请输入视频宽度', trigger: 'blur' }
        ],
        height: [
          { required: true, message: '请输入视频高度', trigger: 'blur' }
        ],
      },
    };
  },
  created () {
    // this.showDialog()
    console.log(process.env.NODE_ENV);
  },
  methods: {
    // 显示弹窗
    showDialog () {
      this.dialogVisible = true;
      this.ruleForm.insertionImage = ''
      this.ruleForm.videoImage = ''
      this.ruleForm.width = 100
      this.ruleForm.height = ''
      this.fileList = []
    },
    // 图片删除
    handleRemove (file, fileList) {
      this.ruleForm.insertionImage = ''
      this.fileList = []
      this.uploadDisabled = false
      this.delShow = false
    },
    // 图片删除
    handleRemove1 (file, fileList) {
      this.ruleForm.videoImage = ''
      this.fileList = []
      this.uploadDisabled = false
      this.delImg = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        this.loading = true
        if (valid) {
          const videoList = {
            insertionImage: this.ruleForm.insertionImage,
          };
          if (this.type) this.$emit('success', this.ruleForm)
          else this.$emit('success', { insertionImage: this.ruleForm.insertionImage, width: 100, height: 150, videoImage: 'https://gbcloud-resources.oss.zglibrary.com/white.png' })
          this.dialogVisible = false;
          this.loading = false
        } else {
          this.loading = false
        }
      });
    },
    // 上传图片
    handleAvatarSuccess (res, file) {
      this.ruleForm.insertionImage = res.data.link;
      this.insertionImageNew = res.data.fileName;
      this.fileInfo = {
        fileOriginalName: file.name,
        fileServerName: res.data,
        fileUrl: res.data.link,
      };
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === "video/mp4";
      const isLt2M = file.size / 1024 / 1024 < 21;

      if (!isJPG) {
        this.$message.error("只能上传mp4格式文件");
      }
      if (!isLt2M) {
        this.$message.error("视频大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传图片
    handleAvatarSuccess1 (res, file) {
      this.ruleForm.videoImage = res.data.link
    },
    beforeAvatarUpload1 (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('图片只能是 JPG/JPEG/PNG/GIF 等格式!')
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 重置
    resetForm () {
      this.listQuery = {
        bookId: undefined,
        bookName: undefined,
        bookAuthor: undefined,
        bookType: undefined,
      };
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.btn-box {
  text-align: center !important;
  margin-top: 30px;
}
.delM {
  ::v-deep .el-upload {
    width: 100%;
  }
}
</style>
<style>
.elDialog {
  z-index: 99999;
}
.delM {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.videoClassHover {
  background-color: #00000073;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  font-size: 28px;
  color: #8c939d;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
}
</style>
